import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Box,
  Typography,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getGroupCarrierAction, deleteCarrierByIdAction } from '../../redux/actions/agencyActions';
import { getAllUserPermissionAction } from '../../redux/actions/locationActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/loader';

const CarrierGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const deleteLoading = useSelector((state) => state.agency.deleteLoading);
  const permissions = useSelector((state) => state.locationGroup.all_permission);
  const carrierDetails = useSelector((state) => state.agency.allGroupCarrier);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const carriergroupdata = carrierDetails.content || [];

  useEffect(() => {
    dispatch(getGroupCarrierAction(page, rowsPerPage));
    dispatch(getAllUserPermissionAction());
  }, [dispatch, page, rowsPerPage]);

  const handleAddNewClick = () => {
    navigate('/CreateCarrier');
  };

  const handleDeleteClick = (id) => {
    setSelectedGroupId(id);
    setOpenDialog(true);
  };

  const handleEditClick = (group) => {
    navigate('/CreateCarrier', {
      state: {
        group,
        isEdit: true,
      },
    });
  };

  const handleConfirmDelete = () => {
    if (selectedGroupId !== null) {
      setIsDeleting(true);
      dispatch(deleteCarrierByIdAction(selectedGroupId))
        .then(() => {
          setIsDeleting(false);
          dispatch(getGroupCarrierAction(page, rowsPerPage));
          setSnackbar({
            open: true,
            message: 'Carrier group deleted successfully',
            severity: 'success',
          });
        })
        .catch(() => {
          setIsDeleting(false);
          setSnackbar({
            open: true,
            message: 'Failed to delete carrier group',
            severity: 'error',
          });
        });
      setOpenDialog(false);
      setSelectedGroupId(null);
    }
  };

  const hasPermission = (code) => {
    return permissions.some((permission) => permission.code === code);
  };

  return (
    <Paper
      sx={{
        margin: 2,
        padding: 2,
        border: "1px solid rgba(49, 49, 49, 0.1)",
        boxShadow: "0 4px 8px rgba(104, 104, 104, 0.1)",
      }}
    >
      <Box sx={{ padding: '16px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="30px" marginTop="30px">
          <Typography variant="h5" sx={{ fontFamily: '"Poppins", sans-serif' }}>
            Carrier Group
          </Typography>
          {hasPermission("ADD_CARRIER") && (
            <Button
              id="addnewbutton"
              className="btn-primary"
              variant="contained"
              color="primary"
              onClick={handleAddNewClick}
            >
              Add Carrier Group
            </Button>
          )}
        </Box>

        <TableContainer component={Paper} sx={{ position: 'relative', minHeight: '200px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="styled-table-heading">Carrier Group Program</TableCell>
                <TableCell className="styled-table-heading">Status</TableCell>
                <TableCell className="styled-table-heading" align="right">
                {hasPermission("ADD_CARRIER") &&('Action') }
                </TableCell>
              </TableRow>
              </TableHead>
            <TableBody>
              {deleteLoading || isDeleting ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : carriergroupdata.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No Data Available
                  </TableCell>
                </TableRow>
              ) : (
                carriergroupdata.map((group, index) => (
                  <TableRow className="styled-table-row" key={index}>
                    <TableCell>{group.companyGroupName}</TableCell>
                    <TableCell>{group.status}</TableCell>
                    <TableCell align="right">
                      {hasPermission("DELETE_CARRIER") && (
                        <IconButton onClick={() => handleDeleteClick(group.groupId)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                      {hasPermission("UPDATE_CARRIER") && (
                        <IconButton onClick={() => handleEditClick(group)}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={carrierDetails.totalElements}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            id="tablepagination"
            name="tablePagination"
          />
        </TableContainer>

        {/* Delete Confirmation Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>{"Delete Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this carrier group? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button  className='btn-secondary' onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button className='btn-primary' onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar Notification */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default CarrierGroup;
