import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    FormLabel,
    Box,
    Button,
    MenuItem,
    Autocomplete,
    Select,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { savePolicyAction } from '../../../redux/actions/policyActions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { driverLicenseState, MonthList, YearList } from '../../../common/StaticFieldList';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
 
 
const CreatePolicyForm = ({setPolicyId= null}) => {
   const dispatch = useDispatch();
    const customerId = useSelector((state) => state.user.user?.customer?.id);
    const [errors, setErrors] = useState({});
    const [showPolicyNumber, setShowPolicyNumber] = useState(false);
    const [policyNumber, setPolicyNumber] = useState('');
    const [formData, setFormData] = useState('');
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
 
 
    useEffect(() => {
        setFormData({
            firstName: '',
            lastName: '',
            gender: '',
            email: '',
            dateofbirth: dayjs().subtract(16, 'year'),
            address: '',
            city: '',
            state: '',
            zipcode: '',
            policyNumber: '',
            company: '',
            paymentDueDay: null,
            effectiveDate: null,
            expirationDate: null,
            lineOfBusiness: '',
            policyType: '',
            phoneNumber: '',
            priorPolicyNumber: '',
            
            residenceYear: '',
            term: '',
            customerId: customerId || null,
        });
    }, [customerId]);
 
 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
       
        let sanitizedValue = value;
       
        switch(name) {
            case 'phoneNumber':
                sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
                break;
            case 'email':
                sanitizedValue = value.trim().toLowerCase();
                break;
            case 'city':
                sanitizedValue = value.replace(/[^A-Za-z\s]/g, '');
                break;
            case 'zipcode':
                sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 5);
                break;
            case 'yearofmonth':
                sanitizedValue = value.replace(/[^0-9\/]/g, '');
                break;
            default:
                sanitizedValue = value;
        }
 
 
        setFormData({ ...formData, [name]: sanitizedValue });
       
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };
 
 
    const handleDateChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };
 
 
    const validate = () => {
        let tempErrors = {};
       
        // Required field validation
        const requiredFields = {
            firstName: 'First Name',
            lastName: 'Last Name',
            gender: 'Gender',
            email: 'Email',
            dateofbirth: 'Date of Birth',
            address: 'Address',
            city: 'City',
            state: 'State',
            zipcode: 'Zip Code',
            company: 'Company',
            effectiveDate: 'Effective Date',
            expirationDate: 'Expiration Date',
            lineOfBusiness: 'Line of Business',
            policyType: 'Policy Type',
            // // phoneNumber: 'Phone Number',
            // yearofmonth: 'Years/Month at Residence',
            term: 'Term'
        };
 
 
        Object.entries(requiredFields).forEach(([field, label]) => {
            if (!formData[field]) {
                tempErrors[field] = `${label} is required`;
            }
        });
 
 
        if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            tempErrors.email = 'Please enter a valid email address';
        }
 
 
        // if (formData.phoneNumber && !/^\d{10}$/.test(formData.phoneNumber)) {
        //     tempErrors.phoneNumber = 'Phone number must be 10 digits';
        // }
 
 
        if (formData.zipcode && !/^\d{5}$/.test(formData.zipcode)) {
            tempErrors.zipcode = 'Zip code must be 5 digits';
        }
 
 
        if (formData.effectiveDate && formData.expirationDate) {
            if (formData.expirationDate.isBefore(formData.effectiveDate)) {
                tempErrors.expirationDate = 'Expiration date must be after effective date';
            }
        }
 
 
        if (formData.yearofmonth && !/^\d+\/\d+$/.test(formData.yearofmonth)) {
            tempErrors.yearofmonth = 'Please use format: years/months (e.g., 2/6)';
        }
 
 
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
 
 
    const handleSave = () => {
        if (validate()) {
            const formattedData = {
                ...formData,
                effectiveDate: formData.effectiveDate
                    ? formData.effectiveDate.format('MM-DD-YYYY')
                    : null,
                expirationDate: formData.expirationDate
                    ? formData.expirationDate.format('MM-DD-YYYY')
                    : null,
                dateofbirth: formData.dateofbirth
                    ? formData.dateofbirth.format('MM-DD-YYYY')
                    : null,
            };
 
 
            dispatch(savePolicyAction(formattedData, (response) => {
                setPolicyId(response.id);
                setPolicyNumber(response.policyNumber);
                setShowPolicyNumber(true);
                setSnackbar({
                    open: true,
                    message: 'Policy saved successfully!',
                    severity: 'success',
                });
            }));
        } else {
            setSnackbar({
                open: true,
                message: 'Please fill in all required fields correctly',
                severity: 'error',
            });
        }
    };
 
 
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };
 
 
    return (
        <Box sx={{ padding: 3, background: '#FFFFFF' }}>
 
 
            <Snackbar
                open={snackbar.open}
                autoHideDuration={1000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
 
 
 
 
            <Box container spacing={2} sx={{display: 'flex', flexWrap: 'wrap'}}>
                {showPolicyNumber && (
                    <FormControl
                        fullWidth
                        margin="normal"
                        sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}
                    >
                        <FormLabel>Policy Number</FormLabel>
                        <TextField
                            variant="outlined"
                            name="policyNumber"
                            value={policyNumber}
                            disabled
                        />
                    </FormControl>
                )}
                <FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
                <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Policy Type</FormLabel>
 
 
                    <Select
                        name="policyType"
                        value={formData.policyType}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="New Business">New Business</MenuItem>
                        <MenuItem value="Renewal">Renewal</MenuItem>
                        <MenuItem value="Rewrite">Rewrite</MenuItem>
                    </Select>
                    {errors.policyType && (
                        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.policyType}</span>
                    )}
                </FormControl>
 
 
                <FormControl
                    fullWidth
                    margin="normal"
                    sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}
                >
                    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Term</FormLabel>
                    <Select
                        name="term"
                        value={formData.term}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="6">6 Months</MenuItem>
                        <MenuItem value="12">12 Months</MenuItem>
                    </Select>
                    {errors.term && (
                        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.term}</span>
                    )}
                </FormControl>    
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }} >Company</FormLabel>
                        <TextField
                            variant="outlined"
                            name="company"
                            value={formData.company}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow only alphanumeric characters, spaces, and limit to 30 characters
                                if (/^[a-zA-Z0-9\s- ]{0,30}$/.test(value)) {
                                    handleInputChange(e);
                                }
                            }}
                            inputProps={{ maxLength: 30 }}
                            helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.company}</span>}
                        />
                    </FormControl>
                     
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Line of Business</FormLabel>
                        <Select
                            name="lineOfBusiness"
                            value={formData.lineOfBusiness}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Personal Auto">Personal Auto</MenuItem>
                            <MenuItem value="Commercial Auto">Commercial Auto</MenuItem>
                            <MenuItem value="Homeowners">Homeowners</MenuItem>
                            <MenuItem value="Health">Health</MenuItem>
                            <MenuItem value="General Liability">General Liability</MenuItem>
                        </Select>
                        {errors.lineOfBusiness && (
                        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.lineOfBusiness}</span>
                    )}
                    </FormControl>
 
                     <FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
                    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Effective Date</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={formData.effectiveDate}
                            onChange={(value) => handleDateChange('effectiveDate', value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!errors.effectiveDate}
                                    helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.effectiveDate}</span>}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    {errors.effectiveDate && (
                        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.effectiveDate}</span>
                    )}
                </FormControl>
 
 
                <FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
                    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Expiration Date</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={formData.expirationDate}
                            onChange={(value) => handleDateChange('expirationDate', value)}
                            minDate={formData.effectiveDate}
                            disabled={!formData.effectiveDate}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!errors.expirationDate}
                                    helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.expirationDate}</span>}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    {errors.expirationDate && (
                        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.expirationDate}</span>
                    )}
                </FormControl>
 
 
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>First Name</FormLabel>
                        <TextField
                            variant="outlined"
                            name="firstName"
                            value={formData.firstName}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow only alphanumeric characters, spaces, and limit to 30 characters
                                if (/^[a-zA-Z0-9\s- ]{0,30}$/.test(value)) {
                                    handleInputChange(e);
                                }
                            }}
                            inputProps={{ maxLength: 30 }}
                            helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.firstName}</span>}
                        />
                    </FormControl>
                   
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Last Name</FormLabel>
                        <TextField
                            variant="outlined"
                            name="lastName"
                            value={formData.lastName}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow only alphanumeric characters, spaces, and limit to 30 characters
                                if (/^[a-zA-Z0-9\s- ]{0,30}$/.test(value)) {
                                    handleInputChange(e);
                                }
                            }}
                            helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.lastName}</span>}
                        />
                    </FormControl>
                     
                    <FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Phone Number</FormLabel>
    <TextField
        variant="outlined"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={(e) => {
            let value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters

            // Format as XXX-XXX-XXXX
            if (value.length > 3 && value.length <= 6) {
                value = `${value.slice(0, 3)}-${value.slice(3)}`;
            } else if (value.length > 6) {
                value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
            }

            // Set max limit to 12 characters (formatted)
            if (value.length > 12) {
                value = value.slice(0, 12);
            }

            setFormData({
                ...formData,
                phoneNumber: value,
            });

            // Clear error dynamically
            const isValidPhoneNumber = value.replace(/-/g, '').length === 10;
            setErrors({
                ...errors,
                phoneNumber: isValidPhoneNumber ? '' : 'Phone number must be 10 digits',
            });
        }}
        inputProps={{ maxLength: 12 }}
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber && (
            <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.phoneNumber}</span>
        )}
    />
</FormControl>


                   
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Email</FormLabel>
                        <TextField
                            variant="outlined"
                            name="email"
                            value={formData.email}
                            onChange={(e) => {
                                let value = e.target.value;
                         
                                // Allow input up to 50 characters
                                if (value.length <= 50) {
                                  // Email validation: Check for one "@" and one "."
                                  const atCount = (value.match(/@/g) || []).length;
                                  const dotCount = (value.match(/\./g) || []).length;
                         
                                  // Validate email and update error message accordingly
                                  if ((atCount <= 1 && dotCount <= 1) || value === "") {
                                    // Clear the error if valid
                                    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                         
                                    setFormData({
                                      ...formData,
                                      email: value,
                                    });
                                  } else {
                                    // Set the error message if invalid
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      email: "Email should contain only one '@' and one '.'",
                                    }));
                                  }
                                }
                              }}
                              helperText={<span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.email}</span>}
                              error={!!errors.email} // Set error state based on the presence of an error message
                            />
                          </FormControl>

                    <FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Date of Birth</FormLabel>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            value={formData.dateofbirth}
            onChange={(value) => handleDateChange('dateofbirth', value)}
            helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.address}</span>}
            maxDate={dayjs().subtract(16, 'year')} // Ensure the user is 16+ years old
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'inherit', // Keep default border color regardless of error
                            },
                        },
                    }}
                    helperText={
                        errors.dateofbirth && (
                            <span style={{ color: 'red', fontSize: '0.875rem' }}>
                                {errors.dateofbirth}
                            </span>
                        )
                    }
                />
            )}
        />
    </LocalizationProvider>
    {errors.dateofbirth && (
                        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.dateofbirth}</span>
                    )}
</FormControl>
                   
<FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Years at Residence</FormLabel>
    <Select
        name="residenceYear"
        value={formData.residenceYear}
        onChange={(e) => handleInputChange({ target: { name: 'residenceYear', value: e.target.value } })}
        displayEmpty
    >
        <MenuItem value="" disabled>Select Year</MenuItem>
        {YearList.map((year) => (
            <MenuItem key={year.value} value={year.value}>
                {year.label}
            </MenuItem>
        ))}
    </Select>
    {errors.residenceYear && (
        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.residenceYear}</span>
    )}
</FormControl>
 
{/* <FormControl fullWidth margin="normal" sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}>
    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Months at Residence</FormLabel>
    <Select
        name="yearofmonth"
        value={formData.yearofmonth}
        onChange={(e) => handleInputChange({ target: { name: 'yearofmonth', value: e.target.value } })}
        displayEmpty
    >
        <MenuItem value="" disabled>Select Month</MenuItem>
        {MonthList.map((month) => (
            <MenuItem key={month.value} value={month.value}>
                {month.label}
            </MenuItem>
        ))}
    </Select>
    {errors.yearofmonth && (
        <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.yearofmonth}</span>
    )}
</FormControl> */}

 
 
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}} >
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Address</FormLabel>
                        <TextField
                            variant="outlined"
                            name="address"
                            value={formData.address}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow only alphanumeric characters, spaces, and limit to 30 characters
                                if (/^[a-zA-Z0-9\s- ]{0,30}$/.test(value)) {
                                    handleInputChange(e);
                                }
                            }}
                            inputProps={{ maxLength: 30 }}
                           
                        />
                    </FormControl>
                       
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>City</FormLabel>
                        <TextField
                            variant="outlined"
                            name="city"
                            value={formData.city}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow only alphanumeric characters, spaces, and limit to 30 characters
                                if (/^[a-zA-Z\s- ]{0,30}$/.test(value)) {
                                    handleInputChange(e);
                                }
                            }}
                            inputProps={{ maxLength: 30 }}
                            helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.city}</span>}
                        />
                    </FormControl>
                   
                    <FormControl
                    fullWidth
                    margin="normal"
                    sx={{ width: '25%', padding: '0 10px', boxSizing: 'border-box' }}
                >
                    <FormLabel sx={{ color: 'black', fontWeight: '500' }}>State</FormLabel>
                    <Autocomplete
                        options={driverLicenseState} // Use state options from StaticFieldList
                        getOptionLabel={(option) => option.label || ''}
                        value={
                            driverLicenseState.find((state) => state.value === formData.state) || null
                        }
                        onChange={(event, newValue) => {
                            setFormData({
                                ...formData,
                                state: newValue ? newValue.value : '',
                            });
                            if (errors.state) {
                                setErrors({ ...errors, state: '' });
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                error={!!errors.state}
                                helperText={
                                    errors.state && (
                                        <span style={{ color: 'red', fontSize: '0.875rem' }}>
                                            {errors.state}
                                        </span>
                                    )
                                }
                                fullWidth
                            />
                        )}
                    />
                </FormControl>
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Zip Code</FormLabel>
                        <TextField
                            variant="outlined"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleInputChange}
                           
                            helperText={<span style={{  color: 'red', fontSize: '0.875rem' }}>{errors.zipcode}</span>}
                        />
                    </FormControl>
               
                    <FormControl fullWidth margin="normal" sx={{width:'25%', padding: ' 0 10px', boxSizing: 'border-box'}}>
                        <FormLabel sx={{ color: 'black', fontWeight: '500' }}>Gender</FormLabel>
                        <RadioGroup
                            row
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                        </RadioGroup>
                    </FormControl>
                 
 
 
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                        <Button className="btn-secondary" onClick={handleSave}>
                            Save
                        </Button>
                    </Box>
            </Box>
        </Box>
       
    );
};
 
 
export default CreatePolicyForm;
 