import { getcompanyDetailsService, getcompanyOptionService, getcompanyService, getTermAndPlansService, postCheckedCompany } from "../../services/companyServices";
import { FETCH_COMPANY_SELECTION_SUCCESS,FETCH_COMPANY_SELECTION_FAILURE, START_LOADING,
    STOP_LOADING } from "./actionTypes";
export const getcompanyselectionAction = () => async (dispatch, getState) => {
    try {
      // console.log("Action dispatched");
      dispatch({ type: START_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      const token = getState().auth.token||sessionStorage.getItem("token"); // Adjust based on where you store the token
      const response = await getcompanyService(token);
      // console.log("Action response:", response); // Debug log
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({ type: FETCH_COMPANY_SELECTION_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({
        type: FETCH_COMPANY_SELECTION_FAILURE,
        payload: error.message || 'Failed to fetch company selection. Please try again.',
      });
    }
  };

  export const getcompanyselectOptionAction = (gpid) => async (dispatch, getState) => {
    try {
      // console.log("Action dispatched");
      dispatch({ type: START_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      const token = getState().auth.token||sessionStorage.getItem("token"); // Adjust based on where you store the token
      const response = await getcompanyOptionService(gpid,token);
      // console.log("Action response:", response); // Debug log
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({ type: "FETCH_COMPANY_SELECTION_OPTION_SUCCESS", payload: response });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({
        type: "FETCH_COMPANY_SELECTION_OPTION_FAILURE",
        payload: error.message || 'Failed to fetch company selection. Please try again.',
      });
    }
  };

  export const getcompanyDetialsAction = (gpid) => async (dispatch, getState) => {
    try {
      // console.log("Action dispatched");
      dispatch({ type: START_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      const token = getState().auth.token||sessionStorage.getItem("token"); // Adjust based on where you store the token
      const response = await getcompanyDetailsService(gpid,token);
      // console.log("Action response:", response); // Debug log
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({ type: "FETCH_COMPANY_DETAILS_SUCCESS", payload: response });
      // if (response.gpId && response.term && response.planOptions) {
      //   dispatch({
      //     type: 'FETCH_COMPANY_METADATA_SUCCESS',
      //     payload: {
      //       companies:response.companies,
      //       gpId: response.gpId,
      //       term: response.term,
      //       planOptions: response.planOptions,
      //     },
      //   });
      // }
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({
        type: "FETCH_COMPANY_SELECTION_OPTION_FAILURE",
        payload: error.message || 'Failed to fetch company selection. Please try again.',
      });
    }
  };

  export const postCheckedCompanyAction = (formData) => async (dispatch, getState) => {
    try {
      // console.log("Action dispatched");
      dispatch({ type: START_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      const token = getState().auth.token||sessionStorage.getItem("token"); // Adjust based on where you store the token
      const response = await postCheckedCompany(formData,token);
      // console.log("Action response:", response); // Debug log
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({ type: "POST_CHECKED_COMPANY_SUCCESS", payload: response });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({
        type: "POST_CHECKED_COMPANY_FAILURE",
        payload: error.message || 'Failed to post company checked. Please try again.',
      });
    }
  };

  export const getTermAndPlanoptionsAction = (gpid) => async (dispatch, getState) => {
    try {
      // console.log("Action dispatched");
      dispatch({ type: START_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      const token = getState().auth.token||sessionStorage.getItem("token"); // Adjust based on where you store the token
      const response = await getTermAndPlansService(gpid,token);
      // console.log("Action response:", response); // Debug log
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({ type: "FETCH_TERMANDPLAN_SUCCESS", payload: response });
      if (response.gpId && response.term && response.planOptions) {
        dispatch({
          type: 'FETCH_COMPANY_METADATA_SUCCESS',
          payload: {
            // companies:response.companies,
            gpId: response.gpId,
            term: response.term,
            planOptions: response.planOptions,
          },
        });
      }
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({
        type: "FETCH_TERMANDPLAN_FAILURE",
        payload: error.message || 'Failed to fetch term and planoptions. Please try again.',
      });
    }
  };


  export const resetCompanySelection = () => {
    return { type: 'RESET_COMPANY_SELECTION' };
  };



