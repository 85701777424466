

import React, { useEffect, useState } from 'react';
import { TextField, Table, TableBody,Select, MenuItem, TableCell,Paper, TableContainer, TableHead, TableRow, Button, IconButton, Collapse, Box, Dialog, DialogActions, DialogContent, DialogTitle, TablePagination, Grid , Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { saveTaskAction, getAllTaskAction, searchTaskAction, updateTaskAction, deleteTaskActionById } from '../../../redux/actions/taskActions';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { styled } from '@mui/material/styles';


const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};


const TaskComponent = ({policyId,showPolicyDetails,selectedPolicyDetail,getPolicyNumber}) => {
  
  const [expandedRows, setExpandedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const taskData = useSelector((state) => state.task.allTask);
  const searchtaskData = useSelector((state) => state.task.searchTask);
  const data = searchtaskData && searchtaskData.length > 0 ? searchtaskData : taskData.addTaskList || [];
  const policyListingData =
      useSelector((state) => state.dashboardData.manage_policy_all.content) || [];
       const searchManagePolicyData =
          useSelector((state) => state.dashboardData.searchPolicy.content) || [];
  const finalData = data?.content || [];
  const [defaultPolicy, setDefaultPolicy] = React.useState({});
  //const currentDate = dayjs().format('MM-DD-YYYY');

  useEffect(() => {
      if (showPolicyDetails) {
        // Ensure selectedPolicyDetail is not null or undefined
        if (selectedPolicyDetail && selectedPolicyDetail.length > 0) {
          setDefaultPolicy(selectedPolicyDetail[0]); // Set the first row's data as default
        } else {
          setDefaultPolicy({}); // Fallback to an empty object if no data is available
        }
      }
    }, [selectedPolicyDetail, showPolicyDetails]);

  const [formValues, setFormValues] = useState({
    policyId: policyId,
    createdDate: null,
    policyNumber: getPolicyNumber,
    closedDate: '',
    closedBy: '',
    taskTo: '',
    dueDate: '',
    status: '',
    taskDescription: ''
  });

  const [searchValues, setSearchValues] = useState({
    fromDate: null,
    toDate: null,
    status: ''
  });

  const [subject, setSubject] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editingTask, setEditingTask] = useState(null);

  const handleEditClick = (row) => {
    setEditingTask(row);
    setFormValues({
      id: row.id, // Important to include id for updating
      policyId: row.policyId,
      taskTo: row.taskTo,
      dueDate: row.dueDate,
      subject:row.subject,
      status: row.status,
      taskDescription: row.taskDescription
    });
    setOpen(true);
  };
  
  const handleDeleteClick = (rowId) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      dispatch(deleteTaskActionById(rowId, () => {
        // Option 1: Refresh from API (current approach)
       
        
        // Option 2: Update state locally (optional optimization)
        setFormValues((prev) => ({
          ...prev,
          addTaskList: prev.addTaskList.filter((task) => task.id !== rowId),
        }));
      }));
      dispatch(getAllTaskAction(page, rowsPerPage));
    }
  };

  useEffect(() => {
    dispatch(getAllTaskAction(page,rowsPerPage));
  }, [dispatch,page,rowsPerPage]);

  const handleExpandClick = (index) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter(row => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTask(null);
   
    setFormValues({
      policyId: '',
      taskTo: '',
      dueDate: '',
      subject:'',
      status: '',
      taskDescription: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (field, value) => {
    setSearchValues((prev) => ({
      ...prev,
      [field]: value ? value.format('MM-DD-YYYY') : ''
    }));
  };

  const handleSearch = () => {
    dispatch(searchTaskAction(searchValues, (response) => {
      console.log('Search completed:', response);
    }));
  };
  
  const handleSubmit = () => {
    if (editingTask) {
      const updateData = {
        id: editingTask.id,
        ...formValues,
      };
      dispatch(updateTaskAction(updateData, () => {
        dispatch(getAllTaskAction(page, rowsPerPage));
      }));
    } else {
      const saveData = {
        ...formValues,
        policyId: policyId, // Ensures the policyId is passed
      };
      dispatch(saveTaskAction(saveData, () => {
        dispatch(getAllTaskAction(page, rowsPerPage));
      }));
    }
    handleClose();
  };


  
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    margin: '16px auto',
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  }));
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetSearch = () => {
    setSearchValues({
      fromDate: '',
      toDate: '',
      subject:'',
      status: '',
      searchTask: '',
    });
  };

  

  // const jsonData = {
  //   policyNumber: defaultPolicy.policyNumber || searchManagePolicyData[0]?.policyNumber,
  // };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        gap: 2 
      }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'flex-end',
          gap: 2,
          width: '100%',
          height: '50px',
          mb: 4,
          '& > *:not(:last-child)': {
            marginRight: '0px'
          }
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            width: '225px',
            height: '50px',
          }}>
          <Typography variant="body1" sx={{ mb: 0.5 }}>
            From Date
          </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={searchValues.fromDate ? dayjs(searchValues.fromDate) : null}
                format="MM-DD-YYYY"
                onChange={(value) => handleDateChange('fromDate', value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" label="MM-DD-YYYY" sx={{ height: '56px' }} />
                )}
              />
            </LocalizationProvider>
        </Box>

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '225px',
      height: '50px',
      marginRight: '5px'
    }}>
      <Typography variant="body1" sx={{ mb: 0.5 }}>
          To Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={searchValues.toDate ? dayjs(searchValues.toDate) : null}
            format="MM-DD-YYYY"
            onChange={(value) => handleDateChange('toDate', value)}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="MM-DD-YYYY" sx={{ height: '56px' }} />
            )}
          />
        </LocalizationProvider>
    </Box>

<Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>Subject</Typography>
    <TextField
      sx={poppinsFont}
      value={subject}
      onChange={(e) => setSubject(e.target.value)}
    />
  </Box>

<Box sx={{ display: "flex", flexDirection: "column", mr: 2, mt: 5, position: "relative", top: "34px" }}>
                <Typography sx={{ p: 0, mr: 12,marginTop:'5px',marginBottom:'5px', fontSize: '16px', fontFamily: '"Poppins", sans-serif' }}>Status</Typography>
                <Select
                  id="selectstatus"
                  name="status"
                  value={searchValues.status}
                  onChange={handleSearchChange}
                  sx={{ width: 200, fontFamily: '"Poppins", sans-serif' }}
                >
                  {/* <MenuItem value="Select Status">Select Status</MenuItem> */}
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Close">Close</MenuItem>
                </Select>
              </Box>


              <Box sx={{
                display: 'flex',
                gap: 2,
                height: '40px',
                alignItems: 'flex-end',
                paddingBottom: '2px',
                position: 'relative',
                top: '35px'  // Using position relative and top instead of margin-top
              }}>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: '"Poppins", sans-serif',
                    height: '38px',
                    minWidth: '100px',
                    fontSize: '14px'
                  }}
                  id="searchbutton"
                  className='btn-secondary'
                  onClick={handleSearch}
                >
                  Search
                </Button>
                <Button
              variant="contained"
              sx={{
                fontFamily: '"Poppins", sans-serif',
                height: '38px',
                minWidth: '100px',
                fontSize: '14px',
              }}
              id="resetbutton"
              className="btn-secondary"
              onClick={resetSearch} // Reset functionality
            >
              Reset
            </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: '"Poppins", sans-serif',
                    height: '38px',
                    minWidth: '100px',
                    fontSize: '14px',
                  }}
                  id="addtaskbutton"
                  className='btn-primary'
                  onClick={handleClickOpen}
                >
                  Add Task
                </Button>
              </Box>
          </Box>
        </Box> 
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle className='btn-primary'
      sx={{
      height: '64px', // Ensure the height is exactly 64px
      display: 'flex',
      alignItems: 'center', // Vertically center content
      justifyContent: 'space-between', // Space between title and close button
      padding: '0 16px', // Optional: Adjust padding for better alignment
    }}
    >
        {editingTask ? 'Edit Task' : 'Add Task'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {/* <Box sx={{ width: '23%' }}>
            <Typography variant="body1" sx={{ mb: 1,mt:2 }}>Policy</Typography>
            <TextField
              placeholder="Select Policy"
              name="policyId"
              id="policyid"
              select
              fullWidth
              value={formValues.policyId}
              onChange={handleChange}
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select Policy</option>
    {searchManagePolicyData.map((policy, index) => (
      <option key={index} value={policy.policyNumber}>
        {policy.policyNumber}
      </option>
    ))}
            </TextField>
          </Box> */}
 
          <Box sx={{ width: '23%' }}>
            <Typography variant="body1" sx={{ mb: 1, mt:2 }}>Assigned To</Typography>
            <TextField
              placeholder="Assigned To"
              name="taskTo"
              id="assignedto"
              fullWidth
              value={formValues.taskTo}
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select Assign To</option>
              <option value="MICHELLE MIRABAL">MICHELLE MIRABAL</option>
              <option value="Alan">Alan</option>
              <option value="Sahil">Sahil</option>
              <option value="Sanjeev">Sanjeev</option>
            </TextField>
          </Box>
 
          <Box sx={{ width: '23%' }}>
    <Typography variant="body1" sx={{ mb: 1, mt: 2 }}>Select Due Date</Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            placeholder="MM-DD-YYYY"
            value={formValues.dueDate ? dayjs(formValues.dueDate) : null}
            onChange={(newValue) =>
                handleChange({ target: { name: 'dueDate', value: newValue } })
            }
            format="MM-DD-YYYY"
            shouldDisableDate={(date) => {
                const today = dayjs().startOf('day');
                return date.isBefore(today, 'day');
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
        />
    </LocalizationProvider>
</Box>

          <Box sx={{ width: '23%', height :'50%' }}>
            <Typography variant="body1" sx={{ mb: 1,mt:2 }}>Select Status</Typography>
            <TextField
              placeholder="Select Status"
              name="status"
              id="selectstatus"
              fullWidth
              value={formValues.status}
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select Status</option>
              <option value="Open">Open</option>
              <option value="Close">Close</option>
            </TextField>
          </Box>

          <Box sx={{ width: '23%' }}>
            <Typography variant="body1" sx={{ mb: 1, mt:2 }}>Closed Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            placeholder="MM-DD-YYYY"
            value={formValues.closedDate ? dayjs(formValues.closedDate) : null}
            onChange={(newValue) =>
                handleChange({ target: { name: 'closedDate', value: newValue } })
            }
            format="MM-DD-YYYY"
            shouldDisableDate={(date) => {
                const today = dayjs().startOf('day');
                return date.isBefore(today, 'day');
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
        />
    </LocalizationProvider>
          </Box>

          <Box sx={{ width: '23%' }}>
            <Typography variant="body1" sx={{ mb: 1, mt:2 }}>Closed By</Typography>
            <TextField
              placeholder="Closed By"
              name="closedBy"
              id="ClosedBy"
              fullWidth
              value={formValues.closedBy}
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select</option>
              <option value="MICHELLE MIRABAL">MICHELLE MIRABAL</option>
              <option value="Alan">Alan</option>
              <option value="Sahil">Sahil</option>
              <option value="Sanjeev">Sanjeev</option>
            </TextField>
          </Box>
 
          <Box sx={{ width: '98%', height : '40%' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Task Description</Typography>
            <TextField
              name="taskDescription"
              id="task"
              fullWidth
              multiline
              rows={3}
              value={formValues.taskDescription}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} className='btn-secondary' sx={{mb:2}}>Cancel</Button>
        <Button onClick={handleSubmit} className='btn-secondary' sx={{mr:4,mb:2}}>{editingTask ? 'Update' : 'Submit'}</Button>
      </DialogActions>
    </Dialog>
  
    <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Policy Number</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Date</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Due Date</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Status</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Assigned To</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Subject</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow className="styled-table-row">
                  <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
                  <TableCell sx={poppinsFont}>{row.date}</TableCell>
                  <TableCell sx={poppinsFont}>{row.dueDate}</TableCell>
                  <TableCell sx={poppinsFont}>{row.status}</TableCell>
                  <TableCell sx={poppinsFont}>{row.taskTo}</TableCell>
                  <TableCell sx={poppinsFont}>
                    <IconButton onClick={() => handleExpandClick(index)}>
                      {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                    {row.taskDescription}
                  </TableCell>
                  <TableCell sx={poppinsFont}>
                <IconButton onClick={() => handleEditClick(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(row.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                      <Box margin={1} sx={poppinsFont}>
                        <strong>Task Description:</strong> {row.taskDescription}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        
  
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2 }}>
        <TablePagination
          component="div"
          count={data?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Box>
      </StyledTableContainer >
    </Box>
  );
}
  
export default TaskComponent;
