import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { getVehicleCoverageAction } from '../../../redux/actions/vehicleInfoActions';

const PolicyPremiumListing = () => {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.policy.allDetailPolicy);
  const vehiclelist = customerData?.drivers?.[0]?.vehicles || [];

  useEffect(() => {
    dispatch(getVehicleCoverageAction());
  }, [dispatch]);

  // Handle loading state
  if (!customerData || !customerData.drivers) {
    return <Typography>Loading vehicle data...</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {vehiclelist.map((vehicle, index) => (
        <Box
          sx={{
            width: '23%',
            margin: '0 10px 10px 10px',
            borderRadius: '5px',
            border:'#D9D9D9 solid 1px',
            background: '#FFFFFF',
          }}
          key={vehicle.id}
          id={`accordionvehicle${index}`}
        >
          <Typography sx={{ padding: '0 20px' }}>
            <h4>
              Vehicle {index + 1}: {vehicle.year || 'N/A'} - {vehicle.make || 'N/A'} -{' '}
              {vehicle.model || 'N/A'}
            </h4>
          </Typography>
          <Box id={`accordiondetails${index}`}>
            {vehicle.coverages && vehicle.coverages.length > 0 ? (
              <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table>
                  <TableBody>
                    {vehicle.coverages.map((coverage, coverageIndex) => (
                      <TableRow key={coverage.id}>
                        <TableCell width="50%">
                          <strong>
                            {coverage.type.toUpperCase()}{' '}
                            {coverage.type === 'bi' &&
                              `(${coverage.limit1} / ${coverage.limit2})`}
                              {coverage.type === 'umbi' &&
                              `(${coverage.limit1} / ${coverage.limit2})`}
                              
                            {coverage.type === 'pd' && `(${coverage.limit1})`}
                            {['comp', 'coll'].includes(coverage.type) &&
                              `(${coverage.deductible})`}
                          </strong>
                        </TableCell>
                        <TableCell width="50%">: ${coverage.premium}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography><p>No coverages available for this vehicle.</p></Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PolicyPremiumListing;
