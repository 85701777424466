import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, FormControl, Typography, MenuItem, TextField, Select, RadioGroup, FormControlLabel, Radio, FormHelperText, Button } from '@mui/material';
import dayjs from "dayjs";
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { savePolicyAction } from '../../../redux/actions/policyActions';
import { updatePolicyAction } from '../../../redux/actions/policyActions';
import AllAccountListing from './AllAccountListing';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";


const leadFormFiledData = [
  {
    fieldName: 'office',
    label: 'Office:',
    type: 'text',
    id: 'officeinput'
  },
  {
    fieldName: 'policyEntry',
    label: 'Policy Entry:',
    type: 'dropdown',
    options: ['New Business', 'Rewrite', 'Re-Issue', 'Renewal', 'Rollover', 'TakeOut'],
    id: 'policyentrydropdown'
  },
  {
    fieldName: 'policyNumber',
    label: 'Policy Number: *',
    type: 'text',
    rules: {
      required: 'Policy number is required',
    },
    defaultValue: '',
    id: 'policynumberinput'
  },
  {
    fieldName: 'company',
    label: 'Company: *',
    type: 'dropdown',
    options: ['AMBETTER - AMB', 'AMWINS - AMWI', 'ASCENDANT COMMERCIAL INS CO - ASCE', 'BRIGHT HEALTHCARE - BRIGHT', 'RolCIGNA Health and Life Insurance Company - CIGNA', 'CITIZENS PROPERTY INSURANCE CORPORATION - CPIC', 'COTERIE INSURANCE - CI', 'CRC GROUP - CRC', 'DelToro - ', 'DIRECT GEN INS CO IMPERIAL INTEGON FL - DGIC', 'DIRECT GEN INS CO IMPERIAL INTEGON GA - DGIC', 'DIRECT GEN INS CO IMPERIAL INTEGON TX - DGIC', 'EMBARK GENERAL INCLINE - EGI'],
    rules: {
      required: 'Company is required',
    },
    id: 'companydropdown'
  },
  {
    fieldName: 'product',
    label: 'Product: *',
    type: 'dropdown',
    options: ['AMBETTER - AMB', 'AMWINS - AMWI', 'ASCENDANT COMMERCIAL INS CO - ASCE', 'BRIGHT HEALTHCARE - BRIGHT', 'RolCIGNA Health and Life Insurance Company - CIGNA', 'CITIZENS PROPERTY INSURANCE CORPORATION - CPIC'],
    rules: {
      required: 'Product is required',
    },
    id: 'productdropdown'
  },
  {
    fieldName: 'paymentPlan',
    label: 'Payment Plan',
    type: 'dropdown',
    options: ['A - SELECT PAYMENT PLAN - '],
    rules: {
      required: 'Payment Plan is required',
    },
    defaultValue: '',
    id: 'paymentplandropdown'
  },
  {
    fieldName: 'purePremium',
    label: 'Pure Premium:',
    type: 'number',
    id: 'purepremiuminput'
  },
  {
    fieldName: 'policyFee',
    label: 'Policy Fee:',
    type: 'number',
    id: 'policyfeeinput'
  },
  {
    fieldName: 'tax',
    label: 'Tax:',
    type: 'number',
    id: 'taxinput'
  },
  {
    fieldName: 'mgaFee',
    label: 'MGA Fee:',
    type: 'number',
    id: 'mgafeeinput'
  },
  {
    fieldName: 'paymentDueDay',
    label: 'Payment Due Day:',
    type: 'text',
    id: 'paymentduedayinput'
  },
  {
    fieldName: 'effectiveDate',
    label: 'Eff Date:',
    type: 'date',
    placeholder: 'MM/DD/YYYY',
    id: 'effectivedateinput'
  },
  {
    fieldName: 'expirationDate',
    label: 'Exp Date:',
    type: 'date',
    placeholder: 'MM/DD/YYYY',
    id: 'expirationdateinput'
  },
  {
    fieldName: 'source',
    label: 'Source:',
    type: 'dropdown',
    options: ['DOWNLOAD', 'GR Acquisition', 'JAV Acquisition', 'MARINA BOAT CAMPAIGN', 'MOTORCYCLE CAMPAIGN', 'OVER THE PHONE', 'POST CARD CAMPAIGN', 'PROSPECT CAMPAIGN', 'RADIO', 'CAR DEALERSHIP', 'SOUTH DADE TOYOTA', 'SOUTH DADE KIA', 'LEAD', 'AUTO DEALERS', 'RENEWAL', 'REWRITE', 'AUTO TAG AGENCY', 'WALK IN', 'CUSTOMER REFERRAL', 'BOUND ONLINE AGCY PORTAL', 'INTERNET', 'SFI Acquisition', 'Wm T Martin Acquisition'],
    id: 'sourcedropdown'
  },
  {
    fieldName: 'subSource',
    label: 'Sub Source:',
    type: 'dropdown',
    options: ['A - SELECT SUB SOURCE - '],
    id: 'subsourcedropdown'
  },
  {
    fieldName: 'policyAgentOfRecord',
    label: 'Policy Agent of Record: *',
    type: 'select',
    options: [' - Select Agent of Record - ', '00downloads', 'ADISLAY FERRAZ', 'ADRIANA MEDEROS', 'ADRIANA PINTO', 'AGUSTIN CABELLO', 'ALAN LEYVA', 'ALEANNET MESA-FRANCHISE 02', 'ALEJANDRA MESA-FRANCHISE 02', 'ALEJANDRA NUVIOLA', 'ALESSANDRA GONZALEZ', 'ALEXANDRA SANTOS', 'AMANDA PINEIRO', 'AMARILYS CASTRO', 'ANA CARDOZA', 'ANA FERNANDEZ', 'ANDY PENA', 'ANGEL DEL TORO', 'ANTONELLA BERARDUCCI', 'ARISAY VELOZ', 'ARLET MARTINEZ', 'ARVIN SOSA', 'ASHLEY DELAROSA', 'AYLEMA LAVINA', 'BEATRIZ VALLEJOS', 'CARLOS PRATS', 'CLEIDYS REVILLA', 'CRISTOPHER REYES', 'DAINELYS MOYA', 'DAINELYS TRUJILLO-FRANCHISE 04', 'DANAILY LORENZO', 'DANEIDYS BRAVO', 'DANIA DOMINQUEZ', 'DANIA LEIVA', 'DANIELA CABELLO', 'DANIELA FRANCO', 'DANIELA RODRIGUEZ', 'DAYANA HERRERA', 'DAYNA ROBAINA'],
    id: 'policyagentofrecordselect'
  },
  {
    fieldName: 'policyCSR',
    label: 'Policy CSR:',
    type: 'dropdown',
    options: [' - Select Policy CSR - ', 'ALAN LEYVA', 'AMALIE OLIVA', 'DIANA SOLANO', 'JAMES SANCHEZ', 'KARLA RAMOS', 'MELANIE PUIG', 'OMARA CALZADO', 'ROSALI HERNANDEZ', 'SADY OLIVA', 'Task Administrator', 'Task Administrator 2'],
    id: 'policycsrdropdown'
  },
  {
    fieldName: 'priorPolicyNumber',
    label: 'Prior Policy Number:',
    type: 'text',
    id: 'priorpolicynumberinput'
  }
];



const CreatePolicy = ({setShowButton,setShowHidePolicy }) => {
    const { control, handleSubmit, formState: { errors } } = useFormContext();
    const dispatch = useDispatch();
    const policyState = useSelector((state) => state.policy);
    const [response, setResponse] = useState(null);
    const navigate = useNavigate();
  //   const getCreateUserSuccess = (response) => {
  //     navigate('/allaccount', { state: { response } });
  // }

    const getCreateUserSuccess = async () => {
      navigate('/allaccount')
    }
  //   const onSubmit = (data) => {

      // dispatch(savePolicyAction(data,getCreateUserSuccess));

  // };
  // const onSubmit = (data) => {
  //   // Format the date fields to MM/DD/YYYY format
  //   const formattedData = { ...data };
  //   Object.keys(formattedData).forEach((key) => {
  //     if (formattedData[key] && dayjs(formattedData[key], 'MM-DD-YYYY', true).isValid()) {
  //       formattedData[key] = dayjs(formattedData[key], 'MM-DD-YYYY').format('MM-DD-YYYY');
  //     }
  //   });

  //   if (!setShowButton) {
  //     // Create new policy
  //     dispatch(savePolicyAction(formattedData, getCreateUserSuccess));
  //   } else {
  //     // Update existing policy
  //     dispatch(updatePolicyAction(formattedData));
  //     setShowHideDriverForm(false);
  //   }
  // };
  const onSubmit = (data) => {
    if (!setShowButton) {
      // Create new policy
      dispatch(savePolicyAction(data, getCreateUserSuccess));
    } else {
      // Update existing policy
      dispatch(updatePolicyAction(data));
      setShowHidePolicy(false)
    }
  };

    return(
        <>
            <Box sx={{ padding: '0 2rem 0 5rem', margin: '4rem 0 0 0'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ margin: '0' }}>
            {leadFormFiledData.map(({ fieldName, label, type, options, rules, defaultValue }) => (
                <Grid key={fieldName} item xs={12} sm={6} md={4} sx={{ margin: '0', padding: '0 25px !important' }}>
                <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                    <Typography>{label}</Typography>
                    <Controller
                    name={fieldName}
                    control={control}
                    rules={rules}
                    defaultValue={defaultValue}
                    render={({ field }) => {
                        if (type === 'text' || type === 'number' ) {
                            return (
                                <TextField
                                    {...field}
                                    inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}
                                    id={fieldName}
                                    type={type}
                                    sx={{ width: '100%' }}
                                    error={!!errors[fieldName]}
                                />
                            );
                        } else if (type === 'dropdown' || type==="select") {
                        return (
                            <Select
                            {...field}
                            error={!!errors[fieldName]}
                            >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                            </Select>
                        );
                        } else if (type === 'autocomplete') {
                        return (
                            <Autocomplete
                            {...field}
                            options={options}
                            getOptionLabel={(option) => option.label}
                            onChange={(_, data) => field.onChange(data)}
                            defaultValue={defaultValue}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!!errors[fieldName]}
                                />
                            )}
                            />
                        );
                        }
                        else if(type === 'date'){
                          return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format="MM-DD-YYYY"
                                {...field}
                                value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
                                // onChange={(date) => field.onChange(date ? date.format('MM-DD-YYYY') : null)}
                                // value={formData[fieldName]}
                                // onChange={(date) => handleDateChange(date, fieldName)}
                                slotProps={{
                                  textField: {
                                    size: "medium",
                                    sx: {
                                      width: 357,
                                      "& fieldset": { borderColor: "#077BFD" },
                                    },
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          )
                        }
                        return null;
                    }}
                    />
                    <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                </FormControl>
                </Grid>
            ))}
            </Grid>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
      {!setShowButton ? (
        <>
          <Button type="submit"  id="update" variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Update
          </Button>
          <Button onClick={() => setShowHidePolicy(false)} id="Cancel" variant="outlined" color="primary">
            Cancel
          </Button>
        </>
      ) : (
        <>
        <Button type="submit" id="save" variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={() => setShowHidePolicy(false)} id="cancelbutton"  variant="outlined" color="primary">
        Cancel
      </Button>
      </>
      )}
    </Box>
                </form>
                {/* {response && <AllAccountListing response={response} />} */}
        </Box>
        </>
    )
}

export default CreatePolicy;