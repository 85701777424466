import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  TablePagination,
  Typography,FormHelperText,
  Grid,InputLabel,TextField,Button,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import Loader from "../../loader/loader";
import PolicyDetails from "../PolicyDetails/PolicyDetails";
import { searchPolicyAction ,managepolicyallAction } from "../../../redux/actions/dashboardAction";


const PolicyListing = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedId, setSelectedId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [searchInput, setSearchInput] = useState(""); 
  const [localloading, setLocalLoading] = useState(false);
    const [search, setSearch] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
const [toDate, setToDate] = useState(null);
const [quotes, setQuotes] = useState([]);
const [TotalCount, setTotalCount] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [showTable, setShowTable] = useState(false);
const [isFiltered, setIsFiltered] = useState(false);
const [showPolicyDetails, setShowPolicyDetails] = useState(false);
const [searchValue, setSearchValue] = useState('');
const [policyId, setPolicyId] = useState(null);
const [isRowClicked, setIsRowClicked] = useState(false);
const [selectedPolicyDetail, setSelectedPolicyDetail] = useState(null);
const [policies, setPolicies] = useState([]);
const [error, setError] = useState(false);


  // Load data from Redux
  const policyListingData =
    useSelector((state) => state.dashboardData.manage_policy_all.content) || [];

    const searchManagePolicyData =
    useSelector((state) => state.dashboardData.searchPolicy.content) || [];

  const manage_policy_pagination =
    useSelector((state) => state.dashboardData.searchPolicy) || {};

  useEffect(() => {
    if(!isFiltered){
        dispatch(managepolicyallAction(page, rowsPerPage));
      }    
  }, [dispatch, page, rowsPerPage]);


  useEffect(() => {
    if (searchManagePolicyData.length > 0 && !selectedId) {
      setSelectedId([searchManagePolicyData[0]]); // Select the first row by default
    }
  }, [searchManagePolicyData,page, rowsPerPage, selectedId]);


  const handleSearch = async () => {
    // Check if all search parameters are empty
    if (!fromDate && !toDate && !searchInput.trim()) {
      console.log("Validation triggered: All fields are empty.");
      setError("Please provide at least one parameter for searching"); // Set error message
      return; // Exit the function
    }
  
    // Handle "From Date" validation only if "To Date" is filled
    if (toDate && !fromDate) {
      setError("From Date is required when To Date is filled");
      return; // Exit the function
    }
  
    // Clear previous error messages
    setError(null);
  
    setSearch(searchInput); // Update the actual search state
    setIsLoading(true);
    setPage(0); // Reset pagination on search
  
    const searchData = {
      fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
      toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null,
      search: searchInput ? searchInput : null,
    };
  
    // Show PolicyDetails when a search input is present
    if (searchInput.trim() !== "" || (fromDate && toDate)) {
      setShowPolicyDetails(true);
    }
  
    try {
      // Dispatch search action to get the policy data
      await dispatch(searchPolicyAction(searchData));
  
      // After the search completes, show the table and set relevant states
      setHasSearched(true);
      setShowTable(true);
      setIsFiltered(true);  
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
     const handleChangeRowsPerPage = (event) => {  
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      if (isFiltered) {
        const formData = {
          fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
          toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null,
          search: searchInput,
        };
        dispatch(searchPolicyAction(formData, 0, newRowsPerPage));
      }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (isFiltered) {
          const formData = {
            fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
            toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null,
            search: searchInput,
          };
          dispatch(searchPolicyAction(formData, newPage, rowsPerPage));
        } 
      };

  const handleRowClick = (id) => {
    const selectedRowData = searchManagePolicyData.filter((row) => row.id === id);
    setSelectedId(selectedRowData); // Update selected row
  };


  const resetSearch = async () => {
    setSearchInput("");  // Reset search input
    setFromDate(null);    // Reset From Date
    setToDate(null);      // Reset To Date
    setShowPolicyDetails(false);  // Hide policy details
    setIsLoading(false);  // Reset loading state
    setHasSearched(false); // Reset search flag
    setPage(0); // Reset pagination (if applicable)
    setIsFiltered(false); // Reset any filters
    setPolicies([]); 
    dispatch(searchPolicyAction());
    setError(false); 
    setSelectedId(null);
    setSearch("");
    setShowTable(false);
  
  try {
    // Pass null to trigger the reset case
    await dispatch(searchPolicyAction(null));
    // Then fetch the initial policy listing
    await dispatch(managepolicyallAction(0, rowsPerPage));
  } catch (error) {
    console.error('Error during reset:', error);
  }
};

  return (
    <>
     <Box sx={{padding: '0 50px'}}>
      <Paper
        elevation={3}
        sx={{        
          boxShadow: 'none',
          marginTop: '25px'
        }}
      >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {/* Title */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              className="page-title"
              sx={{fontWeight: '500'}}
            >
              Search Policy
            </Typography>
          </Grid>

        <Grid item xs={12} sm={2}>
          <InputLabel
            sx={{
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            From Date
          </InputLabel>
          <DatePicker
            value={fromDate ? dayjs(fromDate) : null}
            onChange={(newDate) => setFromDate(newDate ? newDate.toISOString() : null)}
            format="MM-DD-YYYY"
            placeholder="MM-DD-YYYY"
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                
                placeholder: 'MM-DD-YYYY',
                // helperText: error ? 'From Date is required if To Date is filled' : '',
                sx: {
                  '& .MuiOutlinedInput-root': {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    minHeight: '55px',
                    lineHeight: '45px',
                  },
                },
              },
            }}
          />
          
        </Grid>
        
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                color: 'black', // Set text color to black
                fontWeight: 'bold', // Optional: make it bold
              }}
            >
              To Date
            </InputLabel>
            <DatePicker
              value={toDate ? dayjs(toDate) : null}
              onChange={(newDate) => setToDate(newDate ? newDate.toISOString() : null)}
              format="MM-DD-YYYY"
              placeholder="MM-DD-YYYY"
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                  placeholder: 'MM-DD-YYYY',
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      },
                      minHeight: '55px',
                      lineHeight: '45px',
                    },
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Enter search term"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  },
                   minHeight: '55px',
                   lineHeight: '45px',
                   marginTop: '20px'
                },
              }}
            />
          </Grid>

          {/* Buttons */}
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleSearch}
              className="btn-primary"
            >
              Search
            </Button>
            

            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={resetSearch}
              className="btn-secondary"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Paper>
     

      <TableContainer
        component={Paper}
        sx={{
          mt: 1,
        }}
      >
        {error && <FormHelperText error>{error}</FormHelperText>}
        <Table sx={{ minWidth: 300, marginTop : '15px' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading" id="nameheader">
                Name
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="policynumberheader"
                align="left"
              >
                Policy Number
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="phonenumberheader"
                align="left"
              >
                Phone Number
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="policytypeheader"
                align="left"
              >
                Policy Type
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="companyheader"
                align="left"
              >
                Company
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="lineofbusinessheader"
                align="left"
              >
                Line of Business
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="effdateheader"
                align="left"
              >
                Eff Date
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="expdateheader"
                align="left"
              >
                Exp Date
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="statusheader"
                align="left"
              >
                Status
              </TableCell>
              <TableCell
                className="styled-table-heading"
                id="premiumheader"
                align="left"
              >
                Premium
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      <Loader />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : searchManagePolicyData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data found
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              searchManagePolicyData.map((row) => (
                <TableRow
                key={row.id}
                onClick={() => handleRowClick(row.id)}
                sx={{
                  backgroundColor: selectedId === row.id ? "#e0f7fa" : "inherit",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                <TableCell>
                  {row.firstName} {row.lastName}
                </TableCell>
                <TableCell align="left">{row.policyNumber}</TableCell>
                <TableCell align="left">{row.phoneNumber}</TableCell>
                <TableCell align="left">{row.policyType}</TableCell>
                <TableCell align="left">{row.company}</TableCell>
                <TableCell align="left">{row.lineOfBusiness}</TableCell>
                <TableCell align="left">{row.effectiveDate}</TableCell>
                <TableCell align="left">{row.expiryDate}</TableCell>
                <TableCell align="left">{row.status || "active"}</TableCell>
                <TableCell align="left">{row.premium}</TableCell>
              </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      <TablePagination
        rowsPerPageOptions={manage_policy_pagination.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        component="div"
        count={manage_policy_pagination.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PolicyDetails selectedPolicyDetail={selectedId} />
        {showPolicyDetails && (
          <PolicyDetails 
            showPolicyDetails={showPolicyDetails} 
            policyId={policyId} 
            isRowClicked={isRowClicked} 
            selectedPolicyDetail={selectedId} 
          />
        )}
      </Box>
    </>
  );
};

export default PolicyListing;