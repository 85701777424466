import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { stateList } from "../../common/StaticFieldList";
import { useDispatch } from "react-redux";
import { saveLocationAction, updateLocationAction } from "../../redux/actions/locationActions";


function AddLocationForm({ location, toggleForm }) {
  const dispatch = useDispatch();
  
  
  const [formValues, setFormValues] = useState({
    locationDescription: "",
    agencyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    secondaryPhone: "",
    faxNumber: "",
    companyGroups: "",
    firstName: "",
    lastName: "",
    cellPhone: "",
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (location) {
      setFormValues({
        id: location.id,
        locationDescription: location.locationDescription || "",
        agencyName: location.agencyName || "",
        address1: location.address1 || "",
        address2: location.address2 || "",
        city: location.city || "",
        state: location.state || "",
        zipCode: location.zipCode || "",
        phone: location.phone || "",
        secondaryPhone: location.secondaryPhone || "",
        faxNumber: location.faxNumber || "",
        companyGroups: location.companyGroups || "",
        firstName: location.firstName || "",
        lastName: location.lastName || "",
        cellPhone: location.cellPhone || "",
        email: location.email || "",
        password: location.password || "",
      });
      setIsActive(location.isActive || false);
    }
  }, [location]);

  // Validation functions
  const validateLetterInput = (value) => /^[a-zA-Z\s]*$/.test(value);
  const validateAddressInput = (value) => /^[a-zA-Z0-9\s,]*$/.test(value);
  const validateNumericInput = (value) => /^\d{0,10}$/.test(value);
  const validatePassword = (value) => value.length >= 6 && /\d/.test(value);
  const validateEmail = (value) => /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(value);
  const validateZipCode = (value) => /^\d{5}$/.test(value); // Exactly 5 numeric characters
  const validateCellPhone = (value) => /^\d{10}$/.test(value); // Exactly 10 numeric characters

  const validateFaxNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    return /^\d{10}$/.test(cleanedValue); // Check for exactly 10 digits
  };


// Updated formatPhoneNumber to format both phone and cellPhone numbers
const formatPhoneNumber = (value) => {
  const cleaned = value.replace(/\D/g, ""); // Remove all non-numeric characters
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    const formatted = [
      match[1], // First 3 digits
      match[2], // Next 3 digits
      match[3], // Last 4 digits
    ]
      .filter(Boolean) // Remove empty groups
      .join("-"); // Join with hyphens or another separator
    return formatted;
  }
  return value;
};

// Validate cell phone number format and length
const validatePhoneNumber = (value) => {
  const cleanedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
  return cleanedValue.length === 10; // Validate exactly 10 digits
};


// Handle input change to apply formatting
const handleInputChange = (event) => {
  const { name, value } = event.target;
  let errorMessage = "";

  const characterLimits = {
    address1: 75,
    address2: 75,
    locationDescription: 50,
    agencyName: 50,
    city: 50,
    firstName: 50,
    lastName: 50,
    zipCode: 5,
    phone: 12,
    secondaryPhone: 12,
    cellPhone: 12,
    faxNumber: 12,
    email: 50,
    password: 50,
  };

  // Check character limits
  if (characterLimits[name] && value.length > characterLimits[name]) {
    errorMessage = `${name.charAt(0).toUpperCase() + name.slice(1)} must be ${characterLimits[name]} characters or less.`;
  }

  let formattedValue = value;

  if (name === "zipCode") {
    formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (formattedValue.length > characterLimits[name]) {
      formattedValue = formattedValue.slice(0, characterLimits[name]); // Enforce 5 characters max
    }
    if (!validateZipCode(formattedValue)) {
      errorMessage = "Zip Code must be exactly 5 numeric digits.";
    }
  }

  // Apply phone number formatting
  else if (["phone", "secondaryPhone", "faxNumber", "cellPhone"].includes(name)) {
    formattedValue = formatPhoneNumber(value);
    const cleanedValue = formattedValue.replace(/\D/g, ""); // Remove formatting for validation
    if (!validatePhoneNumber(formattedValue)) {
      errorMessage = `${name.charAt(0).toUpperCase() + name.slice(1)} must be exactly 10 digits.`;
    }
  }

  // Handling other validations
  else if (characterLimits[name] && value.length > characterLimits[name]) {
    errorMessage = `${name.charAt(0).toUpperCase() + name.slice(1)} must be ${characterLimits[name]} characters or less.`;
  } else if (
    ["locationDescription", "agencyName", "city", "firstName", "lastName"].includes(name) &&
    !validateLetterInput(value)
  ) {
    errorMessage = `${name.charAt(0).toUpperCase() + name.slice(1)} contains invalid characters. Only letters and spaces are allowed.`;
  } else if (["address1", "address2"].includes(name) && !validateAddressInput(value)) {
    errorMessage = `${name === "address1" ? "Primary Address" : "Secondary Address"} contains invalid characters.`;
  }else if (name === "email" && value && !validateEmail(value)) {
    errorMessage = "Invalid email format.";
  } else if (name === "password" && value && !validatePassword(value)) {
    errorMessage = "Password must be at least 6 characters and include a number.";
  }

  setFormValues({
    ...formValues,
    [name]: formattedValue.slice(0, characterLimits[name] || formattedValue.length),
  });
  setFormErrors({ ...formErrors, [name]: errorMessage });
};


  
const handleSave = () => {
  console.log("Save button clicked");

  const newErrors = {};
  const requiredFields = [
    "locationDescription",
    "agencyName",
    "address1",
    "city",
    "state",
    "zipCode",
    "phone",
    "email",
  ];

  // Check required fields
  requiredFields.forEach((field) => {
    if (!formValues[field].trim()) {
      newErrors[field] = "This field is required.";
    }
  });

  // Additional validations
  if (!validateFaxNumber(formValues.faxNumber)) {
    newErrors.faxNumber = "Fax number must be exactly 10 digits.";
  }

  if (!validateZipCode(formValues.zipCode)) {
    newErrors.zipCode = "Zip Code must be exactly 5 digits.";
  }

  if (Object.keys(newErrors).length > 0) {
    setFormErrors(newErrors); // Display errors
    console.log("Form validation failed:", newErrors); // Log errors
    return;
  }

  const FormValues = { ...formValues, isActive };
  console.log("Dispatching save or update action:", FormValues);

  // Dispatch actions
  const onSuccess = () => {
    console.log("Success callback triggered");
    setSnackbarMessage("Location updated successfully.");
    setOpenSnackbar(true); // Show snackbar
    // Delay form close and reload
    setTimeout(() => {
      console.log("Closing form after success");
      toggleForm(true); // Close the form
      window.location.reload(); // Reload the page (optional)
    }, 2000); // 2 seconds delay
  };
  const onSuccesssave = () => {
    console.log("Success callback triggered");
    setSnackbarMessage("Location saved successfully.");
    setOpenSnackbar(true); // Show snackbar
    // Delay form close and reload
    setTimeout(() => {
      console.log("Closing form after success");
      toggleForm(true); // Close the form
      window.location.reload(); // Reload the page (optional)
    }, 2000); // 2 seconds delay
  };
  // Dispatch based on whether it's an update or a new location
  if (location && location.id) {
    dispatch(updateLocationAction(FormValues, onSuccess));
  } else {
    dispatch(saveLocationAction(FormValues, onSuccesssave));
  }
};


  

  const isFormValid = () => {
    const requiredFields = [
      "locationDescription",
      "agencyName",
      "address1",
      "city",
      "state",
      "zipCode",
      "phone",
      "email",
    ];
  
    const areRequiredFieldsFilled = requiredFields.every(
      (field) => formValues[field] && formValues[field].trim() !== ""
    );
  
    const areThereAnyErrors = Object.values(formErrors).some((error) => error !== "");
  
    return areRequiredFieldsFilled && !areThereAnyErrors;
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#fff", fontFamily: '"Poppins", sans-serif' }}>
      <Box sx={{ width: "100%", maxWidth: "900px", padding: "0px", backgroundColor: "#fff" ,  border: "1px solid #e3e3e3", }}>
      <Box sx={{  padding: "32px" }}>
        <Typography variant="h5" sx={{ marginBottom: "3px" }}>
          {location ? "Edit Location" : "Add Location"}
        </Typography>
        </Box>
        <hr
  style={{
    borderTop: "1px solid #ccc",
    width: "100%",
    margin: "0", // Remove default margin
  }}
/>
<Box sx={{  padding: "32px" }}>
 

        <Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, gap: "24px", marginBottom: "24px" }}>
          {[{ name: "locationDescription", label: "Location Description", inputProps: { maxLength: 50 } },
            { name: "agencyName", label: "Agency Name" },
            { name: "address1", label: "Address Line 1" , inputProps: { maxLength: 50, pattern: "^[a-zA-Z0-9\\s]*$" } },
            { name: "address2", label: "Address Line 2" },
            { name: "city", label: "City" },
            {
              name: "state", label: "State", renderInput: (
                <Select
                  value={formValues.state}
                  onChange={(e) => handleInputChange({ target: { name: "state", value: e.target.value } })}
                  sx={{ width: "100%" }}
                >
                  {stateList.map((state) => (
                    <MenuItem key={state.value} value={state.value}>{state.label}</MenuItem>
                  ))}
                </Select>
              ),
            },
            { name: "zipCode", label: "Zip Code" },
            { name: "phone", label: "Phone" },
            { name: "secondaryPhone", label: "Alternate Phone" },
            { name: "faxNumber", label: "Fax Number" },
            { name: "firstName", label: "First Name" },
            { name: "lastName", label: "Last Name" },
            { name: "cellPhone", label: "Cell Phone" },
            { name: "email", label: "Email" },
            { name: "password", label: "Password" },
          ].map(({ name, label, renderInput }) => (
            <Box key={name}>
              <Typography variant="body2" component="label" htmlFor={name} sx={{ display: "block", marginBottom: "4px" }}>
                {label}
              </Typography>
              {name === "state" && renderInput ? (
                renderInput
              ) : (
                <TextField
                  id={name}
                  name={name}
                  value={formValues[name]}
                  onChange={handleInputChange}
                  error={!!formErrors[name]}
                  helperText={formErrors[name] || ""}
                  fullWidth
                  type={name === "password" ? "password" : "text"}
                />
              )}
            </Box>
          ))}
        </Box>

        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label="Is Active"
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "32px" }}>
      

        

          <Button variant="outlined" className="btn-secondary" color="secondary"  sx={{ marginRight: "16px" }} onClick={() => toggleForm(false)}>
            Cancel
          </Button>

          <Button 
  variant="contained" 
  className="btn-primary" 
  onClick={handleSave} 
  disabled={!isFormValid()}
>
  {location ? "Update" : "Save"}
</Button>

        </Box>
        <Snackbar
  open={openSnackbar}
  autoHideDuration={2000}  // This will auto-hide the snackbar after 2 seconds
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
  <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
    {snackbarMessage}
  </Alert>
</Snackbar>

      </Box>
    </Box>
    </Box>
  );
}

export default AddLocationForm;
