import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  SnackbarContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import ImageLoader from "./imageLoader";
import { getUser as getUserAction } from "../../redux/actions/userActions";
import {
  CommonYesNo,
  liabbi,
  liabpd,
  Rental,
  Rentals,
  Roadside,
  TermTenture,
  umbi,
  medPay,
  coll,
  comp,
  payPlanOptionIns,
  payPlanOptions,
  pipDed,
  pipDedOption,
  defaultRates,
} from "../../common/StaticFieldList";
import { CircleOutlined } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { GridRowModes, DataGrid, GridCheckCircleIcon } from "@mui/x-data-grid";
import { getRatesAction } from "../../redux/actions/carrierSelectionsInfoActions";
import {
  findValueByKey,
  formattedCurrency,
  getValueByKey,
  populateValue,
} from "../../common/helpers/utils";
import { tabActions } from "../../redux/actions/tabActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@emotion/react";
import {
  getRates as getJSONAction,
  getAllRatesAction,
  getAllVehicleDetailsAction,
  getEFTByIdAction,
  getInstallmentDetailsAction,
} from "../../redux/actions/userActions";
import {
  getRatesUnited,
  getRatesPearlHolding,
} from "../../redux/actions/userActions";
import {
  getcompanyselectionAction,
  getcompanyselectOptionAction,
  getcompanyDetialsAction,
  postCheckedCompanyAction,
  getTermAndPlanoptionsAction,
  // resetCompanySelection
} from "../../redux/actions/companySelectionAction";
import {
  getDriverNameInfoById,
  getAllDriverInfo,
} from "../../redux/actions/driverInfoActions";
import { getAllVehicleInfo } from "../../redux/actions/vehicleInfoActions";
import { comparisionResponseById } from "../../redux/actions/generalPartyInfoActions";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },
  fullWidth: {
    width: "100%",
  },
  root: {
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-window": {
      borderTop: "none",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontFamily: '"Poppins", sans-serif',
    id: "table",
  },
  thead: {
    backgroundColor: "#f5f5f5",
    textAlign: "left",
    fontWeight: 600,
    fontSize: "16px",
    id: "thead",
  },
  th: {
    padding: "12px 16px",
    textAlign: "left",
    fontWeight: "500",
    borderBottom: "2px solid #ddd",
    id: "th",
  },
  tbody: {
    fontSize: "14px",
    color: "#333",
    id: "tbody",
  },
  td: {
    padding: "12px 16px",
    borderBottom: "1px solid #ddd",
    fontWeight: "500",
    id: "td",
  },
  actionCell: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    id: "actionCell",
  },
}));

const CompareQuotesTab = ({ setShowHideDriverForm, id }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { quoteId } = useParams();
  const driverDetails = useSelector((state) => state.driverInfo.allDriverInfo);
  const [isReRated, setIsReRated] = useState(false);
  const [isReRating, setIsReRating] = useState(false);
  const [open, setOpen] = useState(false);
  const [allInstallments, setAllInstallments] = useState([]);
  const allVehicles = useSelector((state) => state.user.allVehicles) || [];
  const normalizedVehciles = Array.isArray(allVehicles)
    ? allVehicles
    : [allVehicles];
  // const vehiclevalues=normalizedVehciles[0] || [];

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [noCompanySelected, setNoCompanySelected] = useState(false);

  const handleOpenDialog = (companyName) => {
    if (!companyName) {
      console.error("Invalid companyName:", companyName);
      return;
    }

    const selected = allVehicles.find(
      (data) => data.vehicleFeesResponse?.companyName === companyName
    );
    setSelectedCompany(selected || null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompany(null);
  };

  const clientName = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );
  const jsonvalue = useSelector(
    (state) => state.generalPartyInfo.allComparisionInfo
  );

  const allrates = useSelector((state) => state.user.allRates) || [];
  const eftvalue = useSelector((state) => state.user.eft) || [];
  const installmentsAmount =
    useSelector((state) => state.user.installment) || [];
  // console.log("installmentsAmount", installmentsAmount);

  const normalizedRates = Array.isArray(allrates) ? allrates : [allrates];
  // console.log("all raates", allrates);
  const [load, setLoad] = useState(false);


  const getJson = jsonvalue.jsonResponse;
  // const parsedData = JSON.parse(JSON.stringify(jsonfixvalue));
  const [selectedDriver, setSelectedDriver] = useState("");

  const [rowModesModel, setRowModesModel] = React.useState({});

  const [msgStatus, setMsgStatus] = useState("");
  const [mergeXMLjson, setMergeXMLjson] = useState([
    // JSON.stringify(StarHealth),
    // JSON.stringify(TATAHealth),
  ]);
  const [mergeXMLjson1, setMergeXMLjson1] = useState([
    // JSON.stringify(StarHealth),
    // JSON.stringify(TATAHealth),
  ]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector(
    (state) => state?.user?.isCreateUserModalOpen
  );

  const [loadings, setLoading] = useState(false);
  // const companies = useSelector((state) => state.companies?.companies || []);
  const options = useSelector((state) => state.companies?.optionList || []);
  const checkedcompany = useSelector(
    (state) => state.companies?.checkedCompany || []
  );
  // console.log("companies", companies);

  const companySelected = useSelector(
    (state) => state.companies?.companySelectedList
  );

  const gpId = useSelector((state) => state.companies?.gpId);
  const term = useSelector((state) => state.companies?.term);
  const planOptions = useSelector((state) => state.companies?.planOptions);
  // console.log("companyList", companyList);
  // console.log("gpid and term", gpId, term,planOptions);
  // const companyList = useSelector(
  //   (state) => state.companies?.companiesList || []
  // );
  const companies = useSelector((state) => state.companies?.companies || []);
  const companyList = useSelector((state) => state.companies?.companiesList || {});

  // console.log("companyList", companyList);
  const selectedCompanies  = companyList?.companies || [];


  // console.log("selectedCompanies", selectedCompanies);

  const [checkedItemss, setCheckedItemss] = useState([]);

  useEffect(() => {
    // Update checked items when companyList changes
    const checkedIds = selectedCompanies.map(company =>
      companies.find(c => c.companyName === company.companyName)?.id.toString()
    ).filter(Boolean);

    setCheckedItemss(checkedIds);
  }, [companyList, companies]);

  useEffect(() => {
    dispatch(getcompanyselectionAction());
  }, [dispatch]);

  useEffect(() => {
    if (quoteId) {
      dispatch(getcompanyselectOptionAction(quoteId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (quoteId) {
      dispatch(getEFTByIdAction(quoteId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getcompanyDetialsAction(quoteId));
    } else {
      // console.log("id in driver useeffect",id);
      dispatch(getcompanyDetialsAction(id));
    }
    // }
  }, [quoteId, id]);

  useEffect(() => {
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getTermAndPlanoptionsAction(quoteId));
    } else {
      // console.log("id in driver useeffect",id);
      dispatch(getTermAndPlanoptionsAction(id));
    }
    // }
  }, [quoteId, id]);

  // useEffect(() => {
  //   // Ensure companies is a valid array
  //   if (Array.isArray(companies) && companies.length > 0) {
  //     companies.forEach((company) => {
  //       const companyName = company.companyName?.toLowerCase();
  //       console.log("companyName",companyName)

  //       // Only dispatch if companyName is valid (non-empty)
  //       if (companyName) {
  //         dispatch(
  //           getInstallmentDetailsAction(gpId, planOptions, term, companyName)
  //         );
  //       }
  //     });
  //   }
  // }, [quoteId, companies, gpId, planOptions, term, dispatch]);

  // useEffect(() => {
  //   // Ensure all required variables have valid values before dispatching
  //   // console.log("companies in useeffect", companies);
  //   const isValidDispatchCondition =
  //   selectedCompanies.length > 0 && gpId && planOptions && term;

  //   if (isValidDispatchCondition) {
  //     selectedCompanies.forEach((company) => {
  //       const companyName = company.companyName.toLowerCase();

  //       // Only dispatch if companyName is also valid
  //       console.log("companyName",companyName);
  //       if (companyName) {
  //         // dispatch(
  //         //   getAllVehicleDetailsAction(gpId, planOptions, term, companyName)
  //         // );
  //         dispatch(getAllRatesAction(gpId, planOptions, term, companyName));
  //       }
  //     });
  //   }
  // }, [gpId]);

  // useEffect(() => {
  //   // Ensure all required variables have valid values before dispatching
  //   // console.log("companies in useeffect", companies);
  //   const isValidDispatchCondition =
  //   selectedCompanies.length > 0 && gpId && planOptions && term;

  //   if (isValidDispatchCondition) {
  //     selectedCompanies.forEach((company) => {
  //       const companyName = company.companyName.toLowerCase();

  //       // Only dispatch if companyName is also valid
  //       console.log("companyName",companyName);
  //       if (companyName) {
  //         dispatch(
  //           getAllVehicleDetailsAction(gpId, planOptions, term, companyName)
  //         );
  //         // dispatch(getAllRatesAction(gpId, planOptions, term, companyName));
  //       }
  //     });
  //   }
  // }, [gpId]);

  // useEffect(() => {
  //   // Ensure all required variables have valid values before dispatching
  //   const isValidDispatchCondition =
  //   selectedCompanies?.length > 0 && gpId && planOptions && term;

  //   if (!isValidDispatchCondition) {
  //     console.warn("Missing required dependencies for API calls");
  //     return;
  //   }

  //   // Asynchronous function to handle API calls
  //   const fetchData = async () => {
  //     try {
  //       for (const company of selectedCompanies) {
  //         const companyName = company?.companyName?.toLowerCase();

  //         if (companyName) {
  //           console.log("Dispatching for company:", companyName);

  //           // Dispatch actions with error handling
  //           await Promise.all([
  //             dispatch(getAllRatesAction(gpId, planOptions, term, companyName)),
  //             dispatch(getAllVehicleDetailsAction(gpId, planOptions, term, companyName)),
  //             getInstallmentDetailsAction(gpId, planOptions, term, companyName)
  //           ]);
  //         } else {
  //           console.warn("Invalid companyName for company:", company);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error during API calls:", error);
  //     }
  //   };

  //   fetchData();
  // }, [companies, term,planOptions,dispatch]);

  useEffect(() => {
    // Validate required data
    const isValidDispatchCondition =
      Array.isArray(selectedCompanies) &&
      selectedCompanies.length > 0 &&
      gpId &&
      planOptions &&
      term;

      // console.log("useeffect")
    if (!isValidDispatchCondition) {
      console.warn("Missing required dependencies for API calls");
      return;
    }

    // Track API calls for each company
    const apiTracker = new Map();

    const fetchDataForCompany = async (company) => {
      const companyName = company.companyName?.toLowerCase();

      if (!companyName) {
        console.warn("Invalid company name for:", company);
        return;
      }

      // Create unique key for tracking
      const trackingKey = `${companyName}-${gpId}-${planOptions}-${term}`;

      // Prevent duplicate calls
      if (apiTracker.get(trackingKey)) {
        return;
      }

      apiTracker.set(trackingKey, true);

      try {
        // Dispatch all three actions concurrently with timeout
        const timeout = 30000; // 30 seconds timeout

        const apiCalls = [
          dispatch(getAllRatesAction(gpId, planOptions, term, companyName))
            .catch(error => {
              console.error(`Error fetching rates for ${companyName}:`, error);
              return null;
            }),

          dispatch(getAllVehicleDetailsAction(gpId, planOptions, term, companyName))
            .catch(error => {
              console.error(`Error fetching vehicle details for ${companyName}:`, error);
              return null;
            }),

          dispatch(getInstallmentDetailsAction(gpId, planOptions, term, companyName))
            .catch(error => {
              console.error(`Error fetching installment details for ${companyName}:`, error);
              return null;
            })
        ];

        // Add timeout to each promise
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('API Timeout')), timeout);
        });

        // Wait for all promises to resolve or timeout
        const results = await Promise.race([
          Promise.all(apiCalls),
          timeoutPromise
        ]);

        // Check if any API call failed
        const failedCalls = results.filter(result => result === null).length;
        if (failedCalls > 0) {
          console.warn(`${failedCalls} API calls failed for ${companyName}`);
        }

      } catch (error) {
        console.error(`Failed to fetch data for ${companyName}:`, error);
      } finally {
        // Cleanup tracking
        apiTracker.delete(trackingKey);
      }
    };

    // Process companies sequentially to avoid overwhelming the server
    const processCompanies = async () => {
      for (const company of selectedCompanies) {
        await fetchDataForCompany(company);
      }
    };

    processCompanies();

    // Cleanup function
    return () => {
      apiTracker.clear();
    };

  }, [selectedCompanies,term,planOptions,quoteId,id, dispatch]);

  // term,planOptions,quoteId,id, dispatch
  // useEffect(() => {
  //   // Ensure all required variables have valid values before dispatching
  //   const isValidDispatchCondition =
  //   selectedCompanies?.length > 0 && gpId && planOptions && term;

  //   if (!isValidDispatchCondition) {
  //     console.warn("Missing required dependencies for API calls");
  //     return;
  //   }

  //   // Asynchronous function to handle API calls
  //   const fetchData = async () => {
  //     try {
  //       for (const company of selectedCompanies) {
  //         const companyName = company?.companyName?.toLowerCase();

  //         if (companyName) {
  //           console.log("Dispatching for company:", companyName);

  //           // Dispatch actions with error handling
  //           await Promise.all([
  //             getInstallmentDetailsAction(gpId, planOptions, term, companyName)
  //           ]);
  //         } else {
  //           console.warn("Invalid companyName for company:", company);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error during API calls:", error);
  //     }
  //   };

  //   fetchData();
  // }, [selectedCompanies, term,dispatch]);


  // useEffect(() => {
  //   // Ensure all required variables have valid values before dispatching
  //   // console.log("companies in useeffect", companies);
  //   const isValidDispatchCondition =
  //   selectedCompanies.length > 0 && gpId && planOptions && term;

  //   if (isValidDispatchCondition) {
  //     selectedCompanies.forEach((company) => {
  //       const companyName = company.companyName.toLowerCase();

  //       // Only dispatch if companyName is also valid
  //       if (companyName) {
  //         dispatch(
  //           getInstallmentDetailsAction(gpId, planOptions, term, companyName)
  //         );
  //       }
  //     });
  //   }
  // }, [gpId]);

  // quoteId, companies, gpId, planOptions, term, dispatch

  useEffect(() => {
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getAllDriverInfo(quoteId));
      dispatch(comparisionResponseById(quoteId));
    } else {
      // console.log("id in driver useeffect",id);
      dispatch(getAllDriverInfo(id));
      dispatch(comparisionResponseById(id));
    }
    // }
  }, [quoteId, id]);
  let initialRows = [];
  if (Array.isArray(companies)) {
    initialRows = companies.map((carrier) => ({
      company: carrier.companyName,
      id: carrier.id,
      code: carrier.companyCode,
    }));
  }
  // console.log("initialrows", initialRows);
  const [rows, setRows] = React.useState(initialRows);
  const handleCheckChanges = (event) => {
    const value = event.target.value;
    setCheckedItemss((prev) => {
      const newChecked = prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value];

      // Refresh the page if a checkbox is unchecked
      // if (!newChecked.includes(value)) {
      //   window.location.reload();
      // }

      return newChecked;
    });
  };

  // const ratesToDisplay = load
  //   ? defaultRates.filter((rate) =>
  //       checkedItemss.length > 0
  //         ? checkedItemss.some((item) => {
  //             const company = initialRows.find(
  //               (row) => row.id === parseInt(item)
  //             );
  //             return (
  //               company &&
  //               rate.companyName.toLowerCase() === company.company.toLowerCase()
  //             );
  //           })
  //         : defaultRates
  //     )
  //   : normalizedRates && normalizedRates.length > 0
  //   ? normalizedRates
  //   : defaultRates;

  const [filteredRates, setFilteredRates] = useState([]);
  const normalizeCompanyName = (name) => name?.toLowerCase().trim();
  useEffect(() => {
    const filterRates = () => {
      // Create a Set of normalized selected company names for faster lookup
      const selectedCompanyNames = new Set(
        selectedCompanies.map(company => normalizeCompanyName(company.companyName))
      );

      let result = [];

      if (load) {
        // When loading, filter based on checked items and selected companies
        if (checkedItemss.length > 0) {
          result = defaultRates.filter(rate => {
            // Check if rate's company matches any checked company
            const matchesChecked = checkedItemss.some(item => {
              const company = initialRows.find(row => row.id === parseInt(item));
              return company &&
                     normalizeCompanyName(rate.companyName) === normalizeCompanyName(company.company);
            });

            // Check if rate's company is in selectedCompanies
            const matchesSelected = selectedCompanyNames.has(normalizeCompanyName(rate.companyName));

            // Rate must match both conditions
            return matchesChecked && matchesSelected;
          });
        } else {
          // If no checked items, filter only based on selected companies
          result = defaultRates.filter(rate =>
            selectedCompanyNames.has(normalizeCompanyName(rate.companyName))
          );
        }
      } else {
        // Not loading - use normalized rates if available
        if (normalizedRates && normalizedRates.length > 0) {
          result = normalizedRates.filter(rate =>
            selectedCompanyNames.has(normalizeCompanyName(rate.companyName))
          );
        } else {
          // Fall back to filtered default rates
          result = defaultRates.filter(rate =>
            selectedCompanyNames.has(normalizeCompanyName(rate.companyName))
          );
        }
      }

      setFilteredRates(result);
    };

    filterRates();
  }, [load, checkedItemss, defaultRates, normalizedRates, selectedCompanies, initialRows]);

  // Replace the existing ratesToDisplay definition with this:
  const ratesToDisplay = filteredRates;

  const [opens, setOpens] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);

  // React.useEffect(() => {
  //   if (!Object.keys(user).length) {
  //     dispatch(getUserAction());
  //   }
  // }, []);
  // const getJson = useSelector((state) => state.user.xmlJson);
  // console.log("getJson", getJson);

  // const getJsons = useSelector((state) => state.user.xmlJson);

  // React.useEffect(() => {
  //   let MsgStatus = "";

  //   if (getJson) {
  //     try {
  //       // Assuming getJson is a JSON object; if it's a string, you should parse it first
  //       const parsedJson =
  //         typeof getJson === "string" ? JSON.parse(getJson) : getJson;

  //       // Retrieve MsgStatus from parsed JSON
  //       MsgStatus = findValueByKey(parsedJson, "MsgStatus");

  //       setMsgStatus(MsgStatus);

  //       // Check the status and update the mergeXMLjson state
  //       if (
  //         MsgStatus &&
  //         !["Error", "REJECTED"].includes(MsgStatus.MsgStatusCd)
  //       ) {
  //         // setMergeXMLjson(prev => [...prev, JSON.stringify(getJson)]);
  //         setMergeXMLjson([...mergeXMLjson, JSON.stringify(parsedJson)]);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing JSON:", error);
  //     }
  //   }
  // }, [jsonvalue.jsonResponse]);

  const allVehicleInfo = useSelector(
    (state) => state.vehicleInfo.allVehicleInfo
  );

  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getAllVehicleInfo(quoteId));
    } else {
      dispatch(getAllVehicleInfo(id));
    }
    // }
  }, [quoteId]);
  const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);
  const customerDataForUpdate =
    allUser &&
    allUser.length &&
    allUser.filter((user) => user.id === selectedUserId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const getSelectedRates = useSelector(
    (state) => state.carrierSelectionsInfo.selectedRateList
  );
  const [checkedItems, setCheckedItems] = useState(getSelectedRates || []);
  // const fullName = `${clientName.firstName} ${clientName.lastName}`;

  const simplifiedDriverDetails = driverDetails.map((driver) => ({
    fullName: `${driver.firstName} ${driver.lastName}`,
  }));

  const firstDriverFullName =
    simplifiedDriverDetails.length > 0
      ? simplifiedDriverDetails[0].fullName
      : "";
  // console.log("firstDriverFullname",firstDriverFullName);
  const [formState, setFormState] = useState({
    payPlanOptions: "INS",
    term: "6",
    vehicle: [],
    // gpId: parseInt(id),
    gpId: id ? parseInt(id) : parseInt(quoteId),
    changeNameInsuredTo: "",
    liabbi: "",
    liabpd: "",
    pipDed: "",
    pipDedOption: "",
    umbi: "",
    umStacked: "",
    medPay: "",
  });

  useEffect(() => {
    // console.log("gpid and term", gpId, term,planOptions);
    setFormState((prevState) => ({
       ...prevState,
       term: term || "", // Use Redux value or empty string as fallback
       payPlanOptions: planOptions || "",
     }));
   }, [term, planOptions]);
  useEffect(() => {
    if (clientName.state === "TX") {
      const fullName = `${clientName.firstName} ${clientName.lastName}`;
      setSelectedDriver(fullName);

      // Use `options` data if available; fallback to defaults otherwise
      setFormState((prevState) => ({
        ...prevState,
        changeNameInsuredTo: options.changeNameInsuredTo || firstDriverFullName, // Prefer `options` data
        liabbi: options.liabbi || liabbi[4]?.value || "", // Prefer `options` data
        liabpd: options.liabpd || liabpd[1]?.value || "",
        pipDed: options.pipDed || pipDed[3]?.label || "",
        pipDedOption: options.pipDedOption || pipDedOption[0]?.label || "",
        umbi: options.umbi || umbi[3]?.value || "",
        umStacked: options.umStacked || CommonYesNo[1]?.value || "",
        medPay: options.medPay || umbi[1]?.value || "",
      }));
    } else {
      const fullName = `${clientName.firstName} ${clientName.lastName}`;
      setSelectedDriver(fullName);

      // Use `options` data if available; fallback to defaults otherwise
      setFormState((prevState) => ({
        ...prevState,
        changeNameInsuredTo: options.changeNameInsuredTo || firstDriverFullName, // Prefer `options` data
        liabbi: options.liabbi || liabbi[2]?.value || "", // Prefer `options` data
        liabpd: options.liabpd || liabpd[0]?.value || "",
        pipDed: options.pipDed || pipDed[3]?.label || "",
        pipDedOption: options.pipDedOption || pipDedOption[0]?.label || "",
        umbi: options.umbi || umbi[1]?.value || "",
        umStacked: options.umStacked || CommonYesNo[1]?.value || "",
        medPay: options.medPay || umbi[1]?.value || "",
      }));
    }
  }, [clientName, firstDriverFullName, options]);

  const [vehicleState, setVehicleState] = useState([]);

  React.useEffect(() => {
    let newVehicleObj = [];
    allVehicleInfo.map((row) => {
      let obj = {
        id: row.id,
        //cid
        comp: row.comp || comp[5]?.value,
        coll: row.coll || coll[11]?.value,
        roadside: Roadside[1]?.value || "",
        rental: Rentals[1]?.value || "",
        safety: CommonYesNo[1]?.value || "",
        manufacturer: row.manufacturer,
        model: row.model,
      };

      newVehicleObj.push(obj);
      // newVehicleObj[row.cid] = obj;
    });

    setVehicleState(newVehicleObj);
    setFormState((prevState) => ({
      ...prevState,
      vehicle: newVehicleObj,
    }));
  }, [allVehicleInfo]);
  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };


  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log("handleinputchanges");

    // Update form state and ensure the updated state is used
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };

      // Trigger side effects after updating the state
      if (
        (name === "payPlanOptions" || name === "term") &&
        updatedState.gpId &&
        checkedItemss.length > 0
      ) {
        // Reset existing rates
        dispatch({ type: "RESET_ALL_RATES" });

        const fetchRatesForSelectedCompanies = async () => {
          try {
            setLoad(true);

            const validCheckedItems = checkedItemss.filter(
              (item) => item != null
            );

            const promises = validCheckedItems
              .map((item) => {
                const company = initialRows.find(
                  (row) => row.id === parseInt(item)
                );
                if (company) {
                  const payload = {
                    ...updatedState,
                    companyCode: company.code,
                  };

                  // Create an array of all three actions for each company
                  return Promise.all([
                    // Action 1: Get Rates
                    dispatch(
                      getAllRatesAction(
                        payload.gpId,
                        name === "payPlanOptions"
                          ? value
                          : updatedState.payPlanOptions,
                        name === "term" ? value : updatedState.term,
                        company.company.toLowerCase()
                      )
                    ),
                    // Action 2: Get Vehicle Details
                    dispatch(
                      getAllVehicleDetailsAction(
                        payload.gpId,
                        name === "payPlanOptions"
                          ? value
                          : updatedState.payPlanOptions,
                        name === "term" ? value : updatedState.term,
                        company.company.toLowerCase()
                      )
                    ),
                    // Action 3: Get Installment Details
                    dispatch(
                      getInstallmentDetailsAction(
                        payload.gpId,
                        name === "payPlanOptions"
                          ? value
                          : updatedState.payPlanOptions,
                        name === "term" ? value : updatedState.term,
                        company.company.toLowerCase()
                      )
                    )
                  ])
                    .then((results) => ({
                      company: company.company,
                      results,
                      success: true,
                    }))
                    .catch((error) => ({
                      company: company.company,
                      error,
                      success: false,
                    }));
                }
                return null;
              })
              .filter((promise) => promise != null);

            const results = await Promise.all(promises);

            results.forEach(({ company, results, error, success }) => {
              if (!success) {
                console.error(`Error fetching data for ${company}:`, error);
              } else {
                console.log(`Successfully fetched all data for ${company}`);
              }
            });

            setLoad(false);
          } catch (error) {
            console.error("Error getting data:", error);
            setLoad(false);
          }
        };

        fetchRatesForSelectedCompanies();
      } else if (name === "payPlanOptions" || name === "term") {
        console.warn(
          "Cannot fetch data: Missing GP ID or no companies selected"
        );
      }

      return updatedState;
    });

    // Set selected driver if applicable
    setSelectedDriver(value);
  };


  const handleInputChangeVehicle = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Create a new array instead of mutating the existing one
    const vehicleStateNew = [...vehicleState];
    vehicleStateNew[index] = {
      ...vehicleStateNew[index],
      // [name]: value.toString(),
      [name]: value,
    };

    // Update the state with the new array
    setVehicleState(vehicleStateNew);
    setFormState((prevState) => ({
      ...prevState,
      vehicle: vehicleStateNew,
    }));
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };
  // const handleClickOpenInvoice = (event) => {
  //   event.preventDefault();
  //   setOpenInvoice(true);
  // };

  const handleClickOpenInvoice = (companyName) => () => {
    if (!companyName) {
      console.error('Company name is undefined');
      return;
    }

    // Since installmentsAmount is an array of arrays, we need to find the correct array
    const selectedCompanyInstallments = installmentsAmount.find(
      installmentGroup => installmentGroup?.[0]?.companyName?.toLowerCase() === companyName.toLowerCase()
    ) || [];

    console.log("Selected company installments:", selectedCompanyInstallments);
    setOpenInvoice(true);

    // Update the state with the found installments
    setAllInstallments(selectedCompanyInstallments);
  };

  const getRates = async (payload, company) => {
    // console.log("Payload:", payload);

    const companyName = company === "Pearl Holding" ? "pearl" : company.toLowerCase();

   const result= await dispatch(getJSONAction(payload, companyName));
   return result;
    // if (company === "Responsive") {
    //   return await dispatch(getJSONAction(payload, quoteId)); // Dispatch action for Pearl
    // } else if (company === "United") {
    //   return await dispatch(getRatesUnited(payload, quoteId)); // Dispatch action for United
    // }
    //  else if (company === "Pearl Holding") {
    //   return await dispatch(getRatesPearlHolding(payload, quoteId)); // Dispatch action for United
    // }
  };



  const handleGetRates = async () => {
    if (checkedItemss.length === 0) {
      setNoCompanySelected(true); // Set a state to display the error message
      return;
    }
    console.log("handleGetRates");
    try {
      setLoad(true);
      setNoCompanySelected(false); // Reset the error state
      dispatch({ type: "RESET_ALL_RATES" });

      const validCheckedItems = checkedItemss.filter((item) => item != null);

      const companyNameRequestList = validCheckedItems.map((item) => {
        const company = initialRows.find((row) => row.id === parseInt(item));
        return company ? { companyName: company.company } : null;
      }).filter((entry) => entry != null); // Remove null entries

      if (companyNameRequestList.length === 0) {
        console.error("No valid companies found to process.");
        setLoad(false);
        return;
      }

      const postCheckedData={
        gpId: formState.gpId,
        companyNameRequestList,
      };
      await dispatch(postCheckedCompanyAction(postCheckedData));
      await dispatch(getcompanyDetialsAction(formState.gpId));
      const promises = validCheckedItems
        .map((item) => {
          const company = initialRows.find((row) => row.id === parseInt(item));
          if (company) {
            const payload = {
              ...formState,
              companyCode: company.code,
            };
            return getRates(payload, company.company)
                  .then(() => {
                    const ratePromise = dispatch(
                      getAllRatesAction(
                        payload.gpId,
                        payload.payPlanOptions,
                        formState.term,
                        company.company.toLowerCase()
                      )
                    );

                    const vehicleDetailsPromise = dispatch(
                      getAllVehicleDetailsAction(
                        payload.gpId,
                        payload.payPlanOptions,
                        formState.term,
                        company.company.toLowerCase()
                      )
                    );

                    const installmentPromise= dispatch(
                      getInstallmentDetailsAction( payload.gpId,
                        payload.payPlanOptions,
                        formState.term,
                        company.company.toLowerCase())
                    );

                    return Promise.all([ratePromise, vehicleDetailsPromise,installmentPromise])
                      .then(([rateResult, vehicleResult,installMentResult]) => ({
                        company: company.company,
                        rateResult,
                        vehicleResult,
                        installMentResult,
                        success: true,
                      }))
                      .catch((error) => ({
                        company: company.company,
                        error,
                        success: false,
                      }));
                  })
                  .catch((error) => ({
                    company: company.company,
                    error,
                    success: false,
                  }))


          }
          return null;
        })
        .filter((promise) => promise != null);

      const results = await Promise.all(promises);

      const successfulResults = results.filter(({ success }) => success);

      results.forEach(
        ({ company, rateResult, vehicleResult, error, success }) => {
          if (!success) {
            console.error(`Error fetching data for ${company}:`, error);
          } else {
            console.log(`Rates for ${company}:`, rateResult);
            console.log(`Vehicle details for ${company}:`, vehicleResult);
          }
        }
      );

      setLoad(false);

      if (successfulResults.length === validCheckedItems.length) {
        setIsReRated(true);
      } else {
        console.warn(
          "Not all data were successfully fetched. isReRated not set to true."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false);
    }
  };

  return (
    <Box
      maxWidth="xl"
      sx={{
        backgroundColor: "#fff",
        margin: "0 auto",
        border: "#E7E7E7 solid 1px",
        padding: "20px",
        borderRadius: "4px",
      }}
    >
      {/* {load && (
        <div style={{ textAlign: "center" }}>
          <ImageLoader
            text1="Communicating with Insurance companies"
            text2="Result will be shown momentarily "
          />
        </div>
      )} */}
      <Paper
        elevation={0}
        style={{
          borderRadius: 4,
          backgroundColor: "#fff",
          borderBottom: "1px solid #e3e3e3",
          padding: "30px 0 20px",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              id="comparisonsection"
              variant="h5"
              fontWeight="600"
              sx={{ fontFamily: '"Poppins", sans-serif' }}
            >
              Comparison Section
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{ mt: 2, flexGrow: 1 }}>
          <Grid
            container
            spacing={1}
            sx={{ fontFamily: '"Poppins", sans-serif' }}
          >
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={6} sm={2} lg={3} sx={{ marginBottom: "10px" }}>
                <Typography
                  id="changenameinsuredto"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Change Name Insured To
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="Change Name Insured To"
                    // label={"Change Name Insured To"}
                    name={"changeNameInsuredTo"}
                    // value={formState["changeNameInsuredTo"] }
                    value={formState["changeNameInsuredTo"]}
                    // value={"Smith"}
                    // sx={{marginTop:"5px"}}
                    size="small"
                    onChange={(e) => handleInputChange(e)}

                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {simplifiedDriverDetails.map((driver, i) => (
                      <MenuItem
                        key={i}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={driver.fullName}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {driver.fullName}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} lg={3} sx={{ marginBottom: "10px" }}>
                <Typography
                  id="LiabBI"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Liab BI
                  <TextField
                    select={"select"}
                    fullWidth
                    placeholder="Liab BI"
                    // label={"Liab BI"}
                    name={"liabbi"}
                    value={formState["liabbi"]}
                    // || Liab[2].value
                    // value={}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      minHeight: "55px",
                    }}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {liabbi.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="LiabPD"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Liab PD
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="Liab PD"
                    // label={"Liab PD"}
                    name={"liabpd"}
                    // value={Liab1[0].value}
                    value={formState["liabpd"]}
                    size="small"
                    //  || Liab1[0].value
                    // value={formData[fieldName]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {liabpd.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="PIPDED"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  PIP DED
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="PIP DED"
                    // label={"PIP DED"}
                    name={"pipDed"}
                    // value={formData[fieldName]}
                    value={formState["pipDed"]}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {pipDed.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={6} sm={2} lg={3} sx={{ marginBottom: "10px" }}>
                <Typography
                  id="PIPDEDoption"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  PIP DED Option
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="PIP DED Option"
                    // label={"PIP DED Option"}
                    name={"pipDedOption"}
                    size="small"
                    value={formState["pipDedOption"]}
                    // value={formData[fieldName]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {pipDedOption.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} lg={3} sx={{ marginBottom: "10px" }}>
                <Typography
                  id="UMBI"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  UMBI
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="UMBI"
                    // label={"UMBI"}
                    name={"umbi"}
                    size="small"
                    // disabled={formState.Liabbi ? false : true}
                    // value={formData[fieldName]}
                    value={formState["umbi"]}
                    onChange={(e) => handleInputChange(e)} // onBlur={() => handleBlur(fieldName)}
                  >
                    {umbi.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="UMstacked"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  UM Stacked
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="UM Stacked"
                    // label={"UM Stacked"}
                    name={"umStacked"}
                    size="small"
                    // value={formData[fieldName]}
                    value={formState["umStacked"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {CommonYesNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="medpay"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Med Pay
                  <TextField
                    // select={"select"}
                    select
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Med Pay"
                    // label={"Med Pay"}
                    name={"medPay"}
                    size="small"
                    // value={formState["medPay"]}
                    // name="medPay"
                    value={formState.medPay}
                    // value={formData[fieldName]}
                    // onChange={(e) => handleInputChange(e)}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {medPay.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            <hr
              style={{
                borderTop: "1px solid #ccc",
                width: "100%",
                marginTop: "30px",
              }}
            />
            {/* Toyota */}
            <Grid item xs={12} sm={12}>
              {vehicleState.length > 0 &&
                vehicleState.map((vehicle, index) => (
                  <Grid container item xs={12} sm={12} spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                          fontWeight: "bold",
                        }}
                      >
                        {`#${index + 1} ${vehicle.manufacturer} ${
                          vehicle.model
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="comp"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Comp
                        <TextField
                          // select={"select"}
                          size="small"
                          select
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          fullWidth
                          placeholder="Comp"
                          name={"comp"}
                          value={vehicle.comp}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {comp.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="coll"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Coll
                        <TextField
                          // select={"select"}
                          select
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Coll"}
                          fullWidth
                          placeholder="Coll"
                          name={"coll"}
                          value={vehicle.coll}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {coll.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="roadside"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Roadside
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Roadside"}
                          fullWidth
                          placeholder="Roadside"
                          name={"roadside"}
                          value={vehicle.roadside}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {Roadside.map((option) => (
                            <MenuItem
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              key={option.value}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="rental"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Rental
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Rental"}
                          fullWidth
                          placeholder="Rental"
                          name={"rental"}
                          value={vehicle.rental}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {Rentals.map((option) => (
                            <MenuItem
                              key={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="safety"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Safety
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Safety"}
                          fullWidth
                          placeholder="safety"
                          name={"safety"}
                          value={vehicle.safety}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {CommonYesNo.map((option) => (
                            <MenuItem
                              key={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={1}
              sx={{ marginTop: "10px" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  // backgroundColor: theme?.palette?.primary?.main,
                  color: "black",
                  padding: 5,
                  marginLeft: 5,
                  fontFamily: '"Poppins", sans-serif',
                }}
                sx={{ position: "sticky" }}
              >
                <Typography
                  id="companyselection"
                  variant="h5"
                  fontWeight="600"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Company Selection
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  padding: 5,
                  marginLeft: 5,
                  width: "100%",
                  overflowX: "hidden",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr>
                      <th
                        className="styled-table-heading"
                        style={{
                          padding: 12,
                        }}
                      >
                        #
                      </th>
                      <th
                        style={{
                          padding: 12,
                        }}
                        className="styled-table-heading"
                      >
                        Company
                      </th>
                      <th
                        style={{
                          padding: 12,
                        }}
                        className="styled-table-heading"
                      >
                        Rates
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {initialRows.length === 0 ? (
                      <tr>
                        <td
                          id="nocompanyfound"
                          className={classes.td}
                          colSpan="3"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#888",
                          }}
                        >
                          No Company found
                        </td>
                      </tr>
                    ) : (
                      initialRows.map((row, index) => (
                        <tr className="styled-table-row" key={row.id}>
                          <td className={classes.td}>{index + 1}</td>
                          <td className={classes.td}>{row.company}</td>
                          <td className={classes.td}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkedItemss.includes(
                                    row.id.toString()
                                  )}
                                  onChange={handleCheckChanges}
                                  name={`rates-${row.id}`}
                                  value={row.id}
                                  color="primary"
                                />
                              }
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <style>{`
        .Mui-even {
          background-color: #f4f5f9;
        }

        .Mui-odd {
          background-color: transparent;
        }
      `}</style>
              </Grid>
              <Grid item xs={12} md={12} sx={{ position: "sticky" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={handleGetRates}
                    variant="contained"
                    sx={{ mt: 3, mb: 2, fontFamily: '"Poppins", sans-serif' }}
                    disabled={loading} // Optionally disable the button while loading
                    className="btn-secondary"
                  >
                    {loading ? (
                      <CircleOutlined size="24" color="secondary" />
                    ) : isReRated ? (
                      "Re-rate"
                    ) : (
                      "Get Rates"
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="term"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Term
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Term"
                    // label={"Term"}
                    name={"term"}
                    value={formState["term"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {TermTenture.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="payplanoptions"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Pay Plan Options
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Pay Plan Options"
                    // label={"Pay Plan Options"}
                    name={"payPlanOptions"}
                    value={formState["payPlanOptions"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {payPlanOptionIns.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  border: "#E7E7E7 solid 1px",
                  marginTop: "1rem",
                  borderRadius: "4px",
                }}
              >
                <Table
                  aria-label="collapsible table"
                  size="small"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 0.5rem",
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          backgroundColor: "transparent",
                          color: "#242929",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          fontFamily: '"Poppins", sans-serif',
                          border: "none",
                        },
                      }}
                    >
                      <TableCell
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      ></TableCell>
                      <TableCell
                        className="styled-table-heading"
                        id="quote"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Quote
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      ></TableCell> */}
                      <TableCell
                        id="companyname"
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        id="totalpremium"
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Total Premium
                      </TableCell>
                      <TableCell
                        className="styled-table-heading"
                        id="downpayment"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Down Payment
                      </TableCell>
                      <TableCell
                        className="styled-table-heading"
                        id="schedules"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Schedules
                      </TableCell>
                      {/* <TableCell
                        id="paymentamount"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Payment Amount
                      </TableCell> */}
                      <TableCell
                        id="term"
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Term
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!selectedCompanies.length >0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={7} // Match this number to the total columns in your table
                          align="center"
                          sx={{
                            backgroundColor: "#F8F8F8",
                            fontSize: "14px",
                            fontFamily: '"Poppins", sans-serif',
                            color: "#757575", // Neutral gray color for the message
                          }}
                        >
                          Please select a company
                        </TableCell>
                      </TableRow>
                    ) : (
                      ratesToDisplay.map((rate, index) => (
                        <TableRow
                          key={index} // Add a unique key for each row
                          sx={{
                            backgroundColor: "#F8F8F8",
                            "& td": {
                              borderBottom: "#E7E7E7 solid 1px",
                              fontSize: "14px",
                              fontFamily: '"Poppins", sans-serif',
                            },
                          }}
                          className="styled-table-row"
                        >
                          <TableCell>
                            {load ? (
                              <CircularProgress size={20} />
                            ) : (
                              <GridCheckCircleIcon
                                sx={{
                                  color: "green",
                                  fontSize: 20,
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <a
                              href={rate.bridgingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                fontFamily: '"Poppins", sans-serif',
                                color: "#1976d2",
                                textDecoration: "none",
                              }}
                            >
                              Bridge
                            </a>
                            <Button
                              onClick={() =>
                                handleOpenDialog(rate.companyName || "")
                              }
                              id="details"
                            >
                              Details
                            </Button>
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              maxWidth="md"
                              fullWidth
                            >
                              <DialogTitle>
                                <Typography fontWeight="600" fontSize="18px">
                                  Breakdown Details
                                </Typography>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </DialogTitle>
                              <DialogContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 2,
                                  }}
                                >
                                  {/* Vehicle Details Section */}
                                  <Grid container spacing={2}>
                                    {selectedCompany?.vehicleDetailsResponseList?.map(
                                      (vehicle, index) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          key={vehicle.id}
                                        >
                                          <Box
                                            sx={{
                                              border: "1px solid #e0e0e0",
                                              borderRadius: "12px",
                                              padding: "16px",
                                              backgroundColor: "#f9f9f9",
                                              boxShadow:
                                                "0 4px 6px rgba(0,0,0,0.1)",
                                              transition: "transform 0.3s ease",
                                              "&:hover": {
                                                transform: "scale(1.02)",
                                              },
                                            }}
                                          >
                                            <Typography
                                              fontWeight="bold"
                                              fontSize="16px"
                                              color="primary"
                                              gutterBottom
                                              component="div"
                                              sx={{
                                                borderBottom: "2px solid",
                                                paddingBottom: "8px",
                                              }}
                                            >
                                              Vehicle {index + 1}
                                            </Typography>
                                            <TableContainer>
                                              <Table
                                                size="small"
                                                sx={{
                                                  fontFamily:
                                                    '"Poppins", sans-serif',
                                                }}
                                              >
                                                <TableBody>
                                                  {[
                                                    {
                                                      label: "BI",
                                                      value: vehicle.bi,
                                                    },
                                                    {
                                                      label: "COLL",
                                                      value: vehicle.coll,
                                                    },
                                                    {
                                                      label: "COMP",
                                                      value: vehicle.comp,
                                                    },
                                                    {
                                                      label: "PD",
                                                      value: vehicle.pd,
                                                    },
                                                    {
                                                      label: "UM",
                                                      value: vehicle.um,
                                                    },
                                                  ].map((item) => (
                                                    <TableRow key={item.label}>
                                                      <TableCell
                                                        sx={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {item.label}
                                                      </TableCell>
                                                      <TableCell>
                                                        ${item.value || "0"}
                                                      </TableCell>
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Box>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>

                                  {/* Policy Fees Section */}
                                  {selectedCompany?.vehicleFeesResponse && (
                                    <Box
                                      sx={{ width: "100%", marginTop: "20px" }}
                                    >
                                      <Typography
                                        fontWeight="bold"
                                        fontSize="18px"
                                        color="primary"
                                        gutterBottom
                                        component="div"
                                        sx={{
                                          borderBottom: "2px solid",
                                          paddingBottom: "8px",
                                        }}
                                      >
                                        Policy Fees
                                      </Typography>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                          <TableContainer>
                                            <Table size="small">
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{ fontWeight: "bold" }}
                                                  >
                                                    Policy Fee
                                                  </TableCell>
                                                  <TableCell>
                                                    $
                                                    {selectedCompany
                                                      .vehicleFeesResponse
                                                      .policyFee || "0.00"}
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{ fontWeight: "bold" }}
                                                  >
                                                    Underwriting Fee
                                                  </TableCell>
                                                  <TableCell>
                                                    $
                                                    {selectedCompany
                                                      .vehicleFeesResponse
                                                      .underwritingFee ||
                                                      "0.00"}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}

                                  {/* EFT Details Section */}
                                  {eftvalue.length > 0 &&
                                    eftvalue.some(
                                      (eft) =>
                                        eft.companyName.toLowerCase() ===
                                        selectedCompany?.vehicleFeesResponse?.companyName.toLowerCase()
                                    ) && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Typography
                                          fontWeight="bold"
                                          fontSize="18px"
                                          color="primary"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            borderBottom: "2px solid",
                                            paddingBottom: "8px",
                                          }}
                                        >
                                          Other Payment Plan Options
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {eftvalue
                                            .filter(
                                              (eft) =>
                                                eft.companyName.toLowerCase() ===
                                                selectedCompany.vehicleFeesResponse.companyName.toLowerCase()
                                            )
                                            .map((eft, index) => (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={index}
                                              >
                                                <Box
                                                  sx={{
                                                    border: "1px solid #e0e0e0",
                                                    borderRadius: "12px",
                                                    padding: "16px",
                                                    backgroundColor: "#f5f5f5",
                                                    boxShadow:
                                                      "0 4px 6px rgba(0,0,0,0.1)",
                                                    transition:
                                                      "transform 0.3s ease",
                                                    "&:hover": {
                                                      transform: "scale(1.02)",
                                                    },
                                                  }}
                                                >
                                                  <Typography
                                                    fontWeight="bold"
                                                    fontSize="16px"
                                                    color="secondary"
                                                    gutterBottom
                                                  >
                                                    Plan: {eft.planCode}
                                                  </Typography>
                                                  <TableContainer>
                                                    <Table size="small">
                                                      <TableBody>
                                                        <TableRow>
                                                          <TableCell
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            Plan Option
                                                          </TableCell>
                                                          <TableCell>
                                                            {eft.planOption}
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                          <TableCell
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            Down Payment
                                                          </TableCell>
                                                          <TableCell>
                                                            $
                                                            {eft.downPaymentAmt}
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                          <TableCell
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            Installment Payment
                                                          </TableCell>
                                                          <TableCell>
                                                            $
                                                            {
                                                              eft.installmentPayment
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                          <TableCell
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            Number of Payments
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              eft.numberOfPayments
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </Box>
                                              </Grid>
                                            ))}
                                        </Grid>
                                      </Box>
                                    )}
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </TableCell>
                          <TableCell>{rate.companyName}</TableCell>
                          <TableCell>
                            {formattedCurrency(rate.totalPremium)}
                          </TableCell>
                          <TableCell>
                            {formattedCurrency(rate.depositAmt)}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                          >
                            <Button
                              id="detailsbutton"
                              onClick={handleClickOpenInvoice(rate.companyName || "")}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                             Installment Details
                            </Button>
                            <Dialog
                              open={openInvoice}
                              onClose={handleCloseInvoice}
                              maxWidth="lg"
                              fullWidth
                            >
                              <DialogTitle
                                sx={{
                                  borderBottom: "#E7E7E7 solid 1px",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Typography
                                  id="scheduledetails"
                                  sx={{
                                    fontFamily: '"Poppins", sans-serif',
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                  }}
                                  component="div"
                                >
                                  Schedule Details
                                </Typography>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleCloseInvoice}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </DialogTitle>

                              <DialogContent>
                                <Box sx={{ margin: 1 }}>
                                  <Table
                                    size="small"
                                    aria-label="installment details"
                                    sx={{ border: "#E7E7E7 solid 1px" }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          id="installmentnumber"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                          }}
                                        >
                                          Installment Number
                                        </TableCell>
                                        <TableCell
                                          id="amount"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                          }}
                                        >
                                          Amount
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {allInstallments.map(
                                        (installment, index) => (
                                          <TableRow
                                            key={installment.id} // Use the id instead of index for key
                                            sx={{
                                              backgroundColor:
                                                index % 2 === 0
                                                  ? "white"
                                                  : "#F8F8F8",
                                            }}
                                          >
                                            <TableCell
                                              sx={{
                                                fontFamily:
                                                  '"Poppins", sans-serif',
                                              }}
                                            >
                                              {installment.installmentNumber}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                fontFamily:
                                                  '"Poppins", sans-serif',
                                              }}
                                            >
                                              {formattedCurrency(
                                                installment.installmentAmt
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </TableCell>
                          <TableCell>{rate.terms}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompareQuotesTab;
