import { useState, useEffect, useCallback } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  TablePagination,
} from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getDriverAction, saveDriverAction, updatedDriverAction } from '../../../redux/actions/driverInfoActions';
import { getDetailPolicyAction } from '../../../redux/actions/policyActions';
import { driverLicenseState } from '../../../common/StaticFieldList';

const PolicyDriver = ({ policyId, setDriverId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  const customerData = useSelector((state) => state.policy.allDetailPolicy);
  const driverData = customerData?.drivers || [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drivers, setDrivers] = useState(driverData);
  const [errors, setErrors] = useState({});

  const calculateDefaultDOB = () => {
    return dayjs().subtract(16, "year").startOf("day");
  };

  const initialDriverState = {
    firstName: "",
    lastName: "",
    dateOfBirth: calculateDefaultDOB().format("YYYY-MM-DD"),
    gender: "",
    relationshipToPolicyholder: "",
    driversLicenseState: "",
    driversLicenseNumber: "",
    studentType: "",
    maritalStatus: "",
    dateLicensed: "",
    policyId: policyId || setDriverId,
  };

  const [newDriver, setNewDriver] = useState(initialDriverState);

  useEffect(() => {
    const fetchData = async () => {
      if (policyId) {
        await dispatch(getDetailPolicyAction(policyId));
        dispatch({ type: "RESET_GET_DETAIL_POLICY_SUCCESS" });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, policyId]);

  useEffect(() => {
    if (driverData?.length > 0) {
      setDrivers(driverData);
    }
  }, [driverData]);

  const handleDateChange = useCallback((date, fieldName) => {
    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
    setNewDriver((prev) => ({ ...prev, [fieldName]: formattedDate }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDriver((prev) => ({ ...prev, [name]: value }));
    if (value.trim()) setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
  if (!newDriver) {
    return false; // Invalid state
  }

  const tempErrors = {};
  const dob = dayjs(newDriver.dateOfBirth);
  const licenseDate = dayjs(newDriver.dateLicensed);

  if (!newDriver.firstName?.trim()) tempErrors.firstName = "First name is required";  
  if (!newDriver.dateOfBirth?.trim()) tempErrors.dateOfBirth = "Date of birth is required";
  if (!newDriver.dateLicensed?.trim()) {
    tempErrors.dateLicensed = "License date is required";
  } else if (!dob.isValid() || !licenseDate.isValid()) {
    tempErrors.dateLicensed = "Invalid date format.";
  } else if (licenseDate.diff(dob, "year") < 16) {
    tempErrors.dateLicensed = "You must be at least 16 years old to get a license.";
  }

  setErrors(tempErrors);

  return Object.keys(tempErrors).length === 0; // Return true if no errors
};

  const callbackfn = (response) => {
    if (!isEditing) {
      setDriverId(response.id); // Only update `setDriverId` in save case
    }
  };

  const handleAddDriver = () => {
    const isValid = validate();
    if (isValid) {
      if (isEditing) {
        // Edit existing driver
        dispatch(updatedDriverAction({ ...newDriver, id }, callbackfn));
      } else {
        // Save new driver
        dispatch(saveDriverAction({ ...newDriver, policyId }, callbackfn));
      }
  
      // Update state and close dialog
      setDrivers((prevDrivers) =>
        isEditing
          ? prevDrivers.map((d) => (d.id === id ? { ...d, ...newDriver } : d))
          : [...prevDrivers, newDriver]
      );
  
      setOpen(false);
      setIsEditing(false);
    }
  };
  

  const handleEditClick = (driver) => {    
    setId(driver.id)
    setSelectedDriver(driver);
    setNewDriver({
      ...initialDriverState, // Fill missing fields with defaults
      ...driver,
    });
    setIsEditing(true);
    setOpen(true);
  };
  

  const handleCancel = () => {
    setNewDriver(initialDriverState);
    setSelectedDriver(null);
    setIsEditing(false);
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = drivers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <div>
      {paginatedRows.map((driver, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography id={`driver-${index + 1}`}><strong>Driver: {driver.firstName} {driver.lastName}</strong></Typography>
            <IconButton onClick={() => handleEditClick(driver)} size="small" sx={{ marginLeft: "auto" }}>
              <EditIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>First Name:</strong></TableCell>
                    <TableCell>{driver.firstName}</TableCell>
                    <TableCell><strong>Relationship:</strong></TableCell>
                    <TableCell>{driver.relationshipToPolicyholder}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Last Name:</strong></TableCell>
                    <TableCell>{driver.lastName}</TableCell>
                    <TableCell><strong>Driver License State:</strong></TableCell>
                    <TableCell>{driver.driversLicenseState}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Date of Birth:</strong></TableCell>
                    <TableCell>{driver.dateOfBirth}</TableCell>
                    <TableCell><strong>Driver License:</strong></TableCell>
                    <TableCell>{driver.driversLicenseNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Gender:</strong></TableCell>
                    <TableCell>{driver.gender}</TableCell>
                    <TableCell><strong>Licensed Date:</strong></TableCell>
                    <TableCell>{driver.dateLicensed}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Marital Status:</strong></TableCell>
                    <TableCell>{driver.maritalStatus}</TableCell>
                    <TableCell><strong>Student Type:</strong></TableCell>
                    <TableCell>{driver.studentType}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
      {drivers.length > 4 && <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={drivers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}

      <Button variant="contained" className='btn-primary' onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
        Add Driver
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{backgroundColor: '#ffae00'}}>{isEditing ? "Edit Driver" : "Add Driver"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
    <label>First Name</label>
    <TextField
      name="firstName"
      value={newDriver.firstName}
      onChange={(e) => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9 ]*$/; // Alphanumeric with spaces
        if (regex.test(value) && value.length <= 50) {
          setNewDriver((prev) => ({
            ...prev,
            firstName: value,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstName: "", // Clear error when valid input is entered
          }));
        }
      }}
      onBlur={() => {
        // Validate on blur
        if (!newDriver.firstName.trim()) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstName: "First Name is required.",
          }));
        }
      }}
      error={!!errors.firstName} // Highlight the field if there's an error
      helperText={errors.firstName} // Show the error message
      fullWidth
    />
  </Grid>
  

  <Grid item xs={12} sm={6}>
  <label>Last Name</label>
  <TextField
    name="lastName"
    value={newDriver.lastName}
    onChange={(e) => {
      const value = e.target.value;
      const regex = /^[a-zA-Z0-9 ]*$/; // Alphanumeric with spaces
      if (regex.test(value) && value.length <= 50) {
        setNewDriver((prev) => ({
          ...prev,
          lastName: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "", // Clear error when valid input is entered
        }));
      }
    }}
    onBlur={() => {
      // Validate on blur
      if (!newDriver.lastName.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last Name is required.",
        }));
      }
    }}
    error={!!errors.lastName} // Highlight the field if there's an error
    helperText={errors.lastName} // Show the error message
    fullWidth
  />
</Grid>

            <Grid item xs={12} sm={6}>
              <label>Date of Birth</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(newDriver.dateOfBirth)} // Use the DOB from the state
                  onChange={(date) => handleDateChange(date, "dateOfBirth")} // Update state on change
                  maxDate={calculateDefaultDOB()} // Prevent future dates
                  renderInput={(params) => <TextField {...params} error={!!errors.firstName} // Highlight input on error
                  helperText={errors.firstName} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className='fnt-we'>Gender</label>
              <FormControl fullWidth>
                <Select
                  name="gender"
                  value={newDriver.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className='fnt-we'>Marital Status</label>
              <FormControl fullWidth>
                <Select
                  name="maritalStatus"
                  value={newDriver.maritalStatus}
                  onChange={handleChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className='fnt-we'>Student Type</label>
              <FormControl fullWidth>
                <Select
                  name="studentType"
                  value={newDriver.studentType}
                  onChange={handleChange}
                >
                  <MenuItem value="Full-Time">Full-Time</MenuItem>
                  <MenuItem value="Part-Time">Part-Time</MenuItem>
                  <MenuItem value="Not a Student">Not a Student</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
  <label>Licensed Date</label>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      value={dayjs(newDriver.dateLicensed)}
      onChange={(date) => handleDateChange(date, "dateLicensed")}
      minDate={
        newDriver.dateOfBirth
          ? dayjs(newDriver.dateOfBirth).add(16, "year")
          : null
      } // Set the minimum date to 16 years after DOB
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errors.dateLicensed}
          helperText={errors.dateLicensed}
          fullWidth
        />
      )}
    />
  </LocalizationProvider>
</Grid>
            <Grid item xs={12} sm={6}>
              <label>Driver License Number</label>
              <TextField
                name="driversLicenseNumber"
                value={newDriver.driversLicenseNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[a-zA-Z0-9- ]*$/; // Alphanumeric with spaces
                  if (regex.test(value) && value.length <= 18) {
                    setNewDriver((prev) => ({
                      ...prev,
                      driversLicenseNumber: value,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      driversLicenseNumber: "", // Clear error when valid input is entered
                    }));
                  }
                }}
                onBlur={() => {
                  // Validate on blur
                  if (!newDriver.lastName.trim()) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      driversLicenseNumber: "Driver Liscence Number is required.",
                    }));
                  }
                }}
                error={!!errors.driversLicenseNumber} // Highlight the field if there's an error
                helperText={errors.driversLicenseNumber} // Show the error message
 
                fullWidth
             
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label className='fnt-we'>Driver License State</label>
              <Autocomplete
                options={driverLicenseState}
                getOptionLabel={(option) => option.label || ""} // Safeguard against undefined
                value={
                  driverLicenseState.find(
                    (state) => state.value === newDriver.driversLicenseState
                  ) || null
                } // Match the selected value to the state array
                onChange={(event, newValue) => {
                  setNewDriver((prev) => ({
                    ...prev,
                    driversLicenseState: newValue ? newValue.value : "",
                  }));
                  setSelectedState(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} className='btn-secondary' color="secondary">Cancel</Button>
          <Button onClick={handleAddDriver} className='btn-secondary' color="primary">{isEditing ? "Save Changes" : "Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PolicyDriver;
