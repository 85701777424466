
const initialState = {
    emails: JSON.parse(localStorage.getItem("emails")) || {},
    sms: JSON.parse(localStorage.getItem("sms")) || {},
    phones: JSON.parse(localStorage.getItem("phones")) || {},
    allEmail: [],
    allSearch:[],
    allSms:[],
    searchSms:[],
    allPhones:[],
    searchPhone:[],
    message: [],
    error: null,
    
  };

  const communicationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_EMAIL_SUCCESS":
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allEmail: action.payload, error: null };
      case "GET_SMS_SUCCESS":
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allSms: action.payload, error: null };
      case "GET_PHONE_SUCCESS":
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allPhones: action.payload, error: null };
      case "SEARCH_EMAIL_SUCCESS":
        return { ...state, allSearch: action.payload, error: null };
      case "SEARCH_SMS_SUCCESS":
        return { ...state, searchSms: action.payload, error: null };
      case "SEARCH_PHONE_SUCCESS":
        return { ...state, searchPhone: action.payload, error: null };
      case "SAVE_EMAIL_SUCCESS":
        localStorage.setItem("emails", JSON.stringify(action.payload));
        return { ...state, emails: action.payload, error: null };
      case "SAVE_SMS_SUCCESS":
        localStorage.setItem("sms", JSON.stringify(action.payload));
        return { ...state, sms: action.payload, error: null };
      case "SAVE_COMMENT_SUCCESS":
        localStorage.setItem("comment", JSON.stringify(action.payload));
        return { ...state, comment: action.payload, error: null };
      case "UPDATE_NOTE_SUCCESS":
        localStorage.setItem("notes", JSON.stringify(action.payload));
        return { ...state, notes: action.payload, error: null };
        case "SEND_MESSAGE_SUCCESS":
          return { ...state, message: action.payload, error: null }; 
      case "DELETE_NOTE_SUCCESS":
        const updatedNote = state.allNote.filter(
          (policy) => policy.id !== action.payload
        );
        return { ...state, allNote: updatedNote, error: null };
      case "GET_EMAIL_FAILURE":
      case "GET_SMS_FAILURE":
      case "SAVE_EMAIL_FAILURE":
      case "SAVE_SMS_FAILURE":
      case "SEARCH_EMAIL_FAILURE":
      case "SAVE_PHONE_FAILURE":
      case "DELETE_EMAIL_FAILURE":
      case "UPDATE_EMAIL_FAILURE":
      case "SEND_MESSAGE_FAILURE":
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  export default communicationReducer;
