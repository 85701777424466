// import React, { useState, useEffect } from 'react';
// import { TextField, Popper, Paper, MenuList, MenuItem } from '@mui/material';
// import { Search } from '@mui/icons-material';

// const SearchableSelect = ({
//   options,
//   value,
//   onChange,
//   onBlur,
//   name,
//   label,
//   required,
//   error,
//   helperText,
//   placeholder,
//   dynamicTheme
// }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [open, setOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [filteredOptions, setFilteredOptions] = useState(options);

//   const selectedOption = options.find(option => option.value === value);

//   useEffect(() => {
//     const filtered = options.filter(option =>
//       option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       option.value.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredOptions(filtered);
//   }, [searchTerm, options]);

//   const handleInputChange = (event) => {
//     setSearchTerm(event.target.value);
//     setOpen(true);
//     setAnchorEl(event.currentTarget);
//   };

//   const handleOptionSelect = (option) => {
//     onChange({
//       target: {
//         name,
//         value: option.value
//       }
//     });
//     setSearchTerm(option.label);
//     setOpen(false);
//   };

//   const handleBlur = (event) => {
//     setTimeout(() => {
//       setOpen(false);
//       setSearchTerm(selectedOption?.label || '');
//       if (onBlur) {
//         onBlur(event);
//       }
//     }, 200);
//   };

//   const handleFocus = (event) => {
//     setAnchorEl(event.currentTarget);
//     setOpen(true);
//     setSearchTerm('');
//   };

//   return (
//     <>
//       <TextField
//         value={open ? searchTerm : selectedOption?.label || ''}
//         onChange={handleInputChange}
//         onFocus={handleFocus}
//         onBlur={handleBlur}
//         placeholder={placeholder}
//         required={required}
//         error={error}
//         helperText={helperText}
//         InputProps={{
//           endAdornment: <Search style={{ color: '#757575' }} />,
//           style: { height: 52, fontSize: 16 }
//         }}
//         sx={{
//           width: '100%',
//           "& fieldset": {
//             borderColor: "#C1C1C1",
//             fontFamily: '"Poppins", sans-serif',
//           },
//           "& .MuiInputBase-root": {
//             height: "52px",
//             backgroundColor: "#fff",
//             fontSize: "14px",
//             fontFamily: '"Poppins", sans-serif',
//           },
//           "& .MuiInputBase-input": {
//             color: dynamicTheme?.secondaryColor,
//             fontFamily: '"Poppins", sans-serif',
//           }
//         }}
//       />
//       <Popper
//         open={open}
//         anchorEl={anchorEl}
//         placement="bottom-start"
//         style={{ width: anchorEl?.offsetWidth, zIndex: 1300 }}
//       >
//         <Paper
//           elevation={3}
//           sx={{
//             maxHeight: 250,
//             overflow: 'auto',
//             marginTop: '2px',
//             width: '100%'
//           }}
//         >
//           <MenuList>
//             {filteredOptions.map((option) => (
//               <MenuItem
//                 key={option.value}
//                 onClick={() => handleOptionSelect(option)}
//                 sx={{
//                   fontFamily: '"Poppins", sans-serif',
//                   fontSize: '14px',
//                   color: dynamicTheme?.secondaryColor,
//                   '&:hover': {
//                     backgroundColor: 'rgba(7, 123, 253, 0.1)'
//                   }
//                 }}
//               >
//                 {option.label}
//               </MenuItem>
//             ))}
//             {filteredOptions.length === 0 && (
//               <MenuItem disabled sx={{ fontFamily: '"Poppins", sans-serif' }}>
//                 No matches found
//               </MenuItem>
//             )}
//           </MenuList>
//         </Paper>
//       </Popper>
//     </>
//   );
// };

// export default SearchableSelect;


import React, { useState, useEffect } from 'react';
import { TextField, Popper, Paper, MenuList, MenuItem } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchableSelect = ({
  options,
  value,
  onChange,
  onBlur,
  name,
  label,
  required,
  error,
  helperText,
  placeholder,
  dynamicTheme
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [touched, setTouched] = useState(false);

  const selectedOption = options.find(option => option.value === value);

  useEffect(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      option.value.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setOpen(true);
    setAnchorEl(event.currentTarget);
    setTouched(true);
  };

  const handleOptionSelect = (option) => {
    // Create a synthetic event that mimics the full event structure
    const syntheticEvent = {
      target: {
        name,
        value: option.value
      }
    };

    onChange(syntheticEvent);
    setSearchTerm(option.label);
    setOpen(false);

    // Trigger blur event with the selected value to update validation
    if (onBlur) {
      setTimeout(() => {
        onBlur({
          target: {
            name,
            value: option.value
          }
        });
      }, 0);
    }
  };

  const handleBlur = (event) => {
    setTimeout(() => {
      setOpen(false);
      setSearchTerm(selectedOption?.label || '');
      setTouched(true);
      if (onBlur) {
        onBlur(event);
      }
    }, 200);
  };

  const handleFocus = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSearchTerm('');
  };

  return (
    <>
      <TextField
        value={open ? searchTerm : selectedOption?.label || ''}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        required={required}
        error={error && touched}
        helperText={touched ? helperText : ""}
        InputProps={{
        //   endAdornment: <Search style={{ color: '#757575' }} />,
          style: { height: 52, fontSize: 16 }
        }}
        sx={{
          width: '100%',
          "& fieldset": {
            borderColor: error && touched ? "#d32f2f" : "#C1C1C1",
            fontFamily: '"Poppins", sans-serif',
          },
          "& .MuiInputBase-root": {
            height: "52px",
            backgroundColor: "#fff",
            fontSize: "14px",
            fontFamily: '"Poppins", sans-serif',
          },
          "& .MuiInputBase-input": {
            color: dynamicTheme?.secondaryColor,
            fontFamily: '"Poppins", sans-serif',
          }
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: anchorEl?.offsetWidth, zIndex: 1300 }}
      >
        <Paper
          elevation={3}
          sx={{
            maxHeight: 250,
            overflow: 'auto',
            marginTop: '2px',
            width: '100%'
          }}
        >
          <MenuList>
            {filteredOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleOptionSelect(option)}
                sx={{
                  fontFamily: '"Poppins", sans-serif',
                  fontSize: '14px',
                  color: dynamicTheme?.secondaryColor,
                  '&:hover': {
                    backgroundColor: 'rgba(7, 123, 253, 0.1)'
                  }
                }}
              >
                {option.label}
              </MenuItem>
            ))}
            {filteredOptions.length === 0 && (
              <MenuItem disabled sx={{ fontFamily: '"Poppins", sans-serif' }}>
                No matches found
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Popper>
    </>
  );
};

export default SearchableSelect;