import { stateList } from "../StaticFieldList";
export const validateEmail = (email) => {
  if (!email) {
      return 'Please enter email address';
  } else if (!/\S+@\S+\.\S+/.test(email)) {
      return 'Invalid email address';
  }
  return '';
};

export const validateField = (value, fieldName) => {
  if (!value) {
      return `Please enter ${fieldName}`;
  }

  return '';
};

// Add this specific validation function for state
export const validateStateField = (value) => {
  if (!value || value.trim() === '') {
    return 'Please select a state';
  }
  // Check if it's a valid state value
  const isValidState = stateList.some(state => state.value === value);
  if (!isValidState) {
    return 'Please select a valid state';
  }
  return '';
};

// export const validateMobile = (value) => {
//     const isValid = /^\d{10}$/.test(value);

//     if (!isValid) {
//       return 'Please enter a valid 10-digit mobile number.';
//     }

//     return ''; // No error
//   };

export const validateMobile = (value) => {
const isValid = /^\d{3}-\d{3}-\d{4}$/.test(value); // Updated format with dashes

if (!isValid) {
  return 'Please enter a valid 10-digit phone number.';
}

return ''; // No error
};



export const alphanumericValidation = (value) => {
  const isValid = /^[a-zA-Z0-9]+$/.test(value);

  if (!isValid) {
    return 'Please enter only letters and numbers.';
  }

  return ''; // No error
};

// export const alphanumericWithSpaceValidation = (value) => {
//   const isValid = /^[a-zA-Z0-9 ]+$/.test(value);

//   if (!isValid) {
//     return 'Please enter only letters and numbers.';
//   }

//   return ''; // No error
// };

// export const alphanumericWithSpaceValidation = (value) => {
//   const isValid = /^[a-zA-Z0-9 ]+$/.test(value);
//   const isValidLength = value.length <= 15;

//   if (!isValid) {
//     return 'Please enter only letters and numbers.';
//   }
//   if (!isValidLength) {
//     return 'Text should not exceed 15 characters.';
//   }

//   return ''; // No error
// };

// export const alphanumericWithSpaceValidation = (value) => {
//   const isValid = /^[a-zA-Z0-9 ]+$/.test(value);
//   const isValidLength = value.length <= 50;
//   const isNotOnlyNumbers = !/^[0-9]+$/.test(value); // Check if the value is not just numbers

//   if (!isValid) {
//     return 'Please enter only letters, numbers, and spaces.';
//   }
//   if (!isNotOnlyNumbers) {
//     return 'Please include  letter also.';
//   }
//   if (!isValidLength) {
//     return 'Text should not exceed 50 characters.';
//   }

//   return ''; // No error
// };
export const alphanumericWithSpaceValidation = (value) => {
  // Trim leading and trailing spaces to check if the value only contains spaces
  const trimmedValue = value.trim();

  const isValid = /^[a-zA-Z0-9 ]+$/.test(value);
  const isValidLength = value.length <= 50;
  const isNotOnlyNumbers = !/^[0-9]+$/.test(value); // Check if the value is not just numbers
  const isNotOnlySpaces = trimmedValue.length > 0; // Check if the value is not only spaces

  if (!isValid) {
    return 'Please enter only letters, numbers, and spaces.';
  }
  if (!isNotOnlyNumbers) {
    return 'Please include a letter also.';
  }
  if (!isNotOnlySpaces) {
    return 'The input cannot consist of only spaces.';
  }
  if (!isValidLength) {
    return 'Text should not exceed 50 characters.';
  }

  return ''; // No error
};


// export const zipCodeValidation = (value) => {
//   const isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
//   return !isValidZipCode ? "Please enter valid zipcode" : "";
// }

export const zipCodeValidation = (value) => {
  // const isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  const isValidZipCode = /^\d{5}$/.test(value);
  return !isValidZipCode ? "Please enter a valid 5-digit zipcode" : "";

}

export const apartmentNumberValidation = (value) => {
  // const isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  const isValidZipCode = /^\d{5,9}$/.test(value);
  return !isValidZipCode ? "Please enter a valid number between 5 and 9 digits" : "";

}

export const validateCity = (value) => {
  const regex = /^[a-zA-Z\s]+$/; // Only alphabets and spaces allowed
  if (!value) return "City is required";
  if (!regex.test(value)) return "City can only contain alphabets";
  return ""; // No error
};


 export const validateAddress = (value) => {
  const addressRegex = /^[a-zA-Z0-9,-\s]*$/; // Allows alphanumeric, comma, hyphen, and spaces
  if (!value) return "Address is required";
  if (!addressRegex.test(value)) return "Invalid address format";
  return ""; // No error
};

