import React, { useEffect, useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Grid,
  FormControl,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { saveNoteAction, updateNoteAction } from '../../redux/actions/noteActions';
import { getPolicySearchPolicyListAction } from '../../redux/actions/policyActions';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';

function AddNotes({ setshowNote, setShowButton }) {
  const { control, handleSubmit, formState: { errors }, reset } = useFormContext();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Callback function to handle API response
  const callbackfn = (res) => {
    const policyList = res.content || [];
    const list = policyList.map((policy) => ({
      id: policy.id, // Include the id
      policyNumber: policy.policyNumber, // Label for display
    }));
    setOptions(list);
    setLoading(false);
  };

  // Debounced function to fetch policy options
  const fetchPolicyOptions = useCallback(
    debounce((query) => {
      setLoading(true);
      dispatch(getPolicySearchPolicyListAction(query, callbackfn));
    }, 300),
    [dispatch]
  );

  // Trigger API call when inputValue changes
  useEffect(() => {
    if (inputValue.length >= 3) {
      fetchPolicyOptions(inputValue);
    } else {
      setOptions([]);
      setLoading(false);
    }
  }, [inputValue, fetchPolicyOptions]);

  // Form submission handler
  const onSubmit = (data) => {
    console.log(data, 'data');
    const selectedPolicy = data.policyId; // The selected option object
    const policyId = selectedPolicy?.id || null; // Extract id or fallback to null

    const formData = { ...data, policyId, notesSource: 'USER' };
    console.log(formData, 'formData');

    const action = setShowButton ? saveNoteAction : updateNoteAction;

    dispatch(
      action(formData, () => {
        reset();
        setshowNote(false);
      })
    );
  };

  return (
    <Dialog open={true} onClose={() => setshowNote(false)} fullWidth maxWidth="sm">
      <DialogTitle
        className="btn-primary"
        sx={{
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 16px',
        }}
      >
        Add Notes
        <IconButton
          aria-label="close"
          onClick={() => setshowNote(false)}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Autocomplete for Policy Selection */}
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.policyId}>
                <Typography>Select Policy</Typography>
                <Controller
                  name="policyId"
                  control={control}
                  rules={{ required: 'Policy is required' }}
                  render={({ field }) => (
                    <div style={{ minHeight: '100px' }}>
                      <Autocomplete
                        {...field}
                        options={options}
                        getOptionLabel={(option) => option?.policyNumber || ''}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        onChange={(event, value) => field.onChange(value)}
                        onInputChange={(event, newValue) => setInputValue(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.policyId}
                            placeholder="Type at least 3 characters"
                            helperText={errors.policyId?.message}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                />
              </FormControl>
            </Grid>

            {/* Notes TextField */}
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.message}>
                <Typography>Notes</Typography>
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: 'Notes cannot be empty' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={4}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions>
          <Button
            onClick={() => setshowNote(false)}
            className="btn-secondary"
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn-secondary"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddNotes;
