import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getProfileAction,
  searchActiveProfileAction,
  deleteUserAction,
  clearProfileData,
  saveProfileAction,
} from "../../../redux/actions/agencyActions";
import UserProfileForm from "./UserProfileForm";
import { getAllLocationAction } from "../../../redux/actions/locationActions";
import Loader from "../../loader/loader";


export default function UserList({ toggleForm }) {
  const [status, setStatus] = useState("All");
  const [defaultLocation, setDefaultLocation] = useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filtering, setFiltering] = useState(false);  // New state to manage filtering process
  

 const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });




  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.agency.allProfile.content);
  const userPagination = useSelector((state) => state.agency.allProfile);
  const searchData = useSelector((state) => state.agency.profilesSearch.content);
  const searchPagination = useSelector((state) => state.agency.profilesSearch);
  const locationDetails = useSelector((state) => state.location.allLocation);

  const userData = isSearching ? searchData || [] : userDetails || [];
  const paginationData = isSearching ? searchPagination || {} : userPagination || {};

  let locationList = [];
if (Array.isArray(locationDetails)) {
  locationList = locationDetails.map((location, index) => ({
    value: location.locationDescription,
    label: location.locationDescription,
    id: `${location.locationDescription}-${index}`, // Ensure uniqueness
  }));
}

  
  // State for search query
  const [searchQuery, setSearchQuery] = useState(""); 
  
  // Filtered locations based on the search query
  const filteredLocations = locationList.filter((location) =>
    location.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    dispatch(getAllLocationAction());
    if (!isSearching) {
      dispatch(getProfileAction(page, rowsPerPage));
    }
  }, [dispatch, page, rowsPerPage, isSearching]);


  useEffect(() => {
    console.log("Filter state updated", { status, defaultLocation });

    const isValidLocation =
      defaultLocation === "All" ||
      locationList.some((location) => location.value === defaultLocation);

    if (!isValidLocation) {
      console.log("Invalid location selected. Clearing data.");
      setIsSearching(false);
      dispatch(clearProfileData());
      setFiltering(false); // Stop filtering since location is invalid

      return;
    }

    const shouldSearch = status !== "All" || defaultLocation !== "All";
    if (isSearching !== shouldSearch) {
      setIsSearching(shouldSearch);
      setFiltering(true);  // Start filtering when status/location changes

    }

    if (shouldSearch) {
      console.log("Applying filters", { status, defaultLocation });
      const payload = { status, location: defaultLocation };
      setFiltering(true);  // Trigger loader before fetching data

      dispatch(searchActiveProfileAction(payload, page, rowsPerPage))
        .then(() => {
          console.log("Filter applied successfully");
        })
        .catch((err) => {
          console.error("Error applying filter", err);
        
            // Check for 400 error
        if (err.response && err.response.status === 400) {
          setSnackbar({
            open: true,
            message: "Invalid location selected. Please choose a valid location.",
            severity: "error",
          });

         
        }
      })
      
        .finally(() => {
          setFiltering(false); // Stop filtering after the request completes
        });

    } else {
      console.log("Fetching all profiles");
      setFiltering(true); // Trigger loader before fetching data

      dispatch(getProfileAction(page, rowsPerPage))
      .finally(() => {
        setFiltering(false); // Stop filtering after the request completes
      });
    }
  }, [dispatch, status, defaultLocation, page, rowsPerPage, JSON.stringify(locationList), isSearching]);

  

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPage(0); // Reset pagination on filter change
    console.log("Status changed to:", e.target.value);
  };
  
  const handleLocationChange = (e) => {
    setDefaultLocation(e.target.value);
    setPage(0); // Reset pagination on filter change
    console.log("Location changed to:", e.target.value);
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };
  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };

  const handleFormClose = () => {
    setSelectedUser(null);
    setIsEditing(false);
  };

  const handleDeleteUser = (id) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };
  const handleCancelDelete = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleConfirmDelete = () => {
    setOpenDialog(false); 
    setLoading(true); 

    if (selectedUserId !== null) {
      dispatch(deleteUserAction(selectedUserId))
        .then(() => {
          dispatch(getProfileAction(page, rowsPerPage));
          setSnackbar({
            open: true,
            message: 'User deleted successfully',
            severity: 'success',
          });
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message: 'Failed to delete user',
            severity: 'error',
          });
        })
        .finally(() => {
          setLoading(false); 
          setSelectedUserId(null);
        });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  return (
    
     <Paper sx={{  margin: 3,
      padding: 2,
      border: "1px solid rgba(104, 104, 104, 0.1)", 
      boxShadow: "0 4px 8px rgba(83, 83, 83, 0.1)", // Optional: Adds a shadow to the inner container
}}>
      {isEditing ? (
        <UserProfileForm user={selectedUser} toggleForm={handleFormClose} />
      ) : (
        <>
        <Box container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 , padding: 1}}>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              id="allusers"
              variant="h5"
              sx={{ p: 2, fontFamily: '"Poppins", sans-serif' }}
            >
              All Users ({userData.length})
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 16px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
                <Typography
                  sx={{
                    p: 0,
                    mr: 12,
                    fontSize: "16px",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  Active Status
                </Typography>
                <Select
  id="selectactivestatus"
  name="status"
  value={status}
  onChange={handleStatusChange}
  sx={{ width: 200, fontFamily: '"Poppins", sans-serif' }}
>
  <MenuItem value="All">All</MenuItem>
  <MenuItem value="Active">Active</MenuItem>
  <MenuItem value="Not Active">Not Active</MenuItem>
</Select>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
  <Typography sx={{ fontFamily: '"Poppins", sans-serif' }}>
    User in Default Location
  </Typography>
  <Autocomplete
  id="autocomplete-default-location"
  options={filteredLocations} // Filtered list
  getOptionLabel={(option) => option.label}
  value={locationList.find((loc) => loc.value === defaultLocation) || null}
  onChange={(event, newValue) => {
    setDefaultLocation(newValue ? newValue.value : "All");
    setPage(0);
  }}
  onInputChange={(event, inputValue) => {
    setSearchQuery(inputValue); // Update search query
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="All Location"
      variant="outlined"
      sx={{ fontFamily: '"Poppins", sans-serif', width: 260 }}
    />
  )}
  renderOption={(props, option) => (
    <li {...props} key={option.id}>
      {option.label}
    </li>
  )}
  isOptionEqualToValue={(option, value) => option.value === value.value}
  clearOnEscape
  fullWidth
/>

</Box>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleForm}
                id="buttonadduser"
                name="addUser"
                className="btn-primary"
                sx={{ marginTop: "20px", marginLeft: "15px" }}
              >
                Add New User
              </Button>
            </div>
          </Box>

          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              marginTop: "15px",
              borderRadius: "10px",
            }}
          >
            <TableContainer sx={{ maxHeight: 440, }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="styled-table-heading">First Name</TableCell>
                    <TableCell className="styled-table-heading">Last Name</TableCell>
                    <TableCell className="styled-table-heading">User Name</TableCell>
                    <TableCell className="styled-table-heading">Email Address</TableCell>
                    <TableCell className="styled-table-heading">Role</TableCell>
                    <TableCell className="styled-table-heading">Status</TableCell>
                    <TableCell className="styled-table-heading">Location</TableCell>
                    <TableCell className="styled-table-heading">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {filtering ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Loader />
                    </TableCell>
                  </TableRow>
                ) : userData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8} align="center">No Data Available</TableCell>
                      </TableRow>
                    ) : (
                     userData.map((user, index) => (
                    <TableRow className="styled-table-row" key={index}
                    >
                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>{user.userName}</TableCell>
                      <TableCell>{user.emailAddress}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.status}</TableCell>
                      <TableCell>{user.defaultLocation}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => handleEditClick(user)}
                            id={`editbutton${user.id}`}
                          >
                            <EditIcon sx={{ fontSize: "18px" }} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteUser(user.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
           

            <TablePagination
              sx={{ fontFamily: '"Poppins", sans-serif' }}
              rowsPerPageOptions={
                paginationData.totalPages === 0
                  ? []
                  : [5, 10, 15, 25, 50, 100]
              }
              component="div"
              count={paginationData.totalElements || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          </Box>
        </>
      )}

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="delete-confirmation-dialog-title"
          aria-describedby="delete-confirmation-dialog-description"
        >
          <DialogTitle id="delete-confirmation-dialog-title">
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-confirmation-dialog-description">
              Are you sure you want to delete this user? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} className="btn-secondary" color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}            
            variant="contained"
            autoFocus
            className="btn-primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000} 
        onClose={handleSnackbarClose} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity} 
        >
          {snackbar.message} 
        </Alert>
      </Snackbar>
      </Paper>
  );
}
