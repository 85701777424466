import { useCallback } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import PageLayout from "../components/PageLayout";
// import InformationBlock from "../components/InformationBlock";
// import VehicleCard from "./Vehicleinfo/VehicleCard";
// import VehicleTab from "./Vehicle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import { Typography, Box,Container, Grid, Paper, IconButton,TablePagination } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import VehicleSave from "./vehicleSave1";
import {
  getRates as getRatesAction,
} from "../../redux/actions/userActions";
import { Link } from "react-router-dom";
import VehicleManagement from "./Vehicleinfo/VehicleManagement";
import { getAllVehicleInfo,deleteVehicleInfoById } from "../../redux/actions/vehicleInfoActions";
import VehicleAttribute from "./VehicleAttribute";
import VehicleAdditionalAttribute from "./VehicleAdditionalAttribute";
import { getcompanyselectionAction } from "../../redux/actions/companySelectionAction";
const initialRows = [
  {
    id: 1,
    car_type: "Rate",
    vin: 5633,
    model_year: "2020",
    model: "shift",
    make: 2019,
    zip_code: 34523,
    country: "USA",
    state: "London",
  },
];

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-window': {
      borderTop: 'none',
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontFamily: '"Poppins", sans-serif',
  },
  thead: {
    backgroundColor: "#f5f5f5",
    textAlign: "left",
    fontWeight: 600,
    fontSize: "16px",
  },
  th: {
    padding: "12px 16px",
    textAlign: "left",
    fontWeight: '500',
    borderBottom: "2px solid #ddd",
  },
  tbody: {
    fontSize: "14px",
    color: "#333",
  },
  td: {
    padding: "12px 16px",
    borderBottom: "1px solid #ddd",
    fontWeight: '500',
  },
  actionCell: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
});

const VehicleInformationList = ({handleBack,handleNext,id}) => {
  const dispatch = useDispatch();
  let { quoteId } = useParams();
  const classes = useStyles();
  const [showVehicle,setShowVehicle]=useState(false);
  const [showHideVehicleForm, setShowHideVehicleForm] = React.useState(false);
  const [step, setStep] = useState(1);
  const [vehicleSaveFormData, setVehicleSaveFormData] = useState({});
  const [vehicleAttributeFormData, setVehicleAttributeFormData] = useState({});
  const handleSubmitVehicleInfos = (data) => {
    console.log(data,"raushan")
    setVehicleSaveFormData(data);
    handleForward();
  };
  const handleSubmitVehicleAttr = (data) => {
    setVehicleAttributeFormData(data);
    handleForward();
  };


  const handleForward = () => {
    setStep(step + 1);
  };

  const handleBackward = () => {
    setStep(step - 1);
  };


  const allVehicleInfo = useSelector(
    (state) => state.vehicleInfo.allVehicleInfo
  );
  // console.log("allVehicleInfo", allVehicleInfo)

  // const [rows, setRows] = React.useState(allVehicleInfo);
  const [vehicleData, setVehicleData] = useState({})
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // if(id)
  // {
  // dispatch(getAllVehicleInfo(id));
  // }

  useEffect(() => {
    // if (!Object.keys(user).length) {
      // dispatch(getUserAction());
      if(quoteId){
        // console.log("quoteId", quoteId)
        dispatch(getAllVehicleInfo(quoteId));
      }
      else
      {
        dispatch(getAllVehicleInfo(id));
      }
    // }
  }, []);

  useEffect(() => {
    dispatch(getcompanyselectionAction());
  }, [dispatch]);

  useEffect(() => {
    allVehicleInfo.filter((row)=> row.id = row.id)
    //cid
    setRows(allVehicleInfo);
  }, [allVehicleInfo]);
  // console.log("row", rows)

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setShowHideVehicleForm(true);
    setShowVehicle(true);
    const editedRow = rows.find((row) => row.id === id);
    // console.log(id, "editedRow", editedRow)
    setVehicleData(editedRow)
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setShowHideVehicleForm(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async() => {
    // setRows(rows.filter((row) => row.id !== id));
    const response = await dispatch(deleteVehicleInfoById(id))
    if(response){
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "id",
      //cid
      headerName: "#",
      width: 60,
      id:"id",
    },
    // {
    //   field: "vehBodyType",
    //   headerName: "Car Type",
    //   width: 120,
    // },
    // { field: "vin", headerName: "VIN", width: 200 },
    {
      field: "modelYear",
      headerName: "Model Year",
      width: 130,
      id:"modelyear",
    },
    {
      field: "manufacturer",
      headerName: "Make",
      width: 130,
      id:"manufacturer",
    },
    {
      field: "model",
      headerName: "Model",
      width: 130,
      id:"model",
    },
    { field: "vin", headerName: "VIN", width: 200, id:"vin" },
    // {
    //   field: "zip_code",
    //   headerName: "Zip Code",
    //   width: 80,
    // },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   width: 150,
    // },
    // {
    //   field: "state",
    //   headerName: "State",
    //   width: 150,
    // },
    {
      field: "actions",
      id:"actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
            id="save"
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
             id="cancel"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />
          <Box sx={{ display: "flex", gap: "11.5px", alignItems: "center" }}>
          <IconButton
          id={`editbutton${id}`}
          onClick={handleEditClick(id)}>
            <EditIcon />
          </IconButton>
          <IconButton
          id={`deletebutton${id}`}
          onClick={handleDeleteClick(id)}>
            <DeleteIcon />
          </IconButton>
        </Box>,
          ,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  function handleClick() {
    setShowHideVehicleForm(true);
      setVehicleData({})
  }
  // const EditToolbar = (props) => {
  //   // const { setRows, setRowModesModel } = props;

  //   function handleClick() {
  //     setShowHideVehicleForm(true);
  //     setVehicleData({})
  //     // const id = rows.length + 1;
  //     // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
  //     // setRowModesModel((oldModel) => ({
  //     //     ...oldModel,
  //     //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
  //     // }));
  //   }

  //   return (
  //     <GridToolbarContainer>
  //       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
  //         Add Car
  //       </Button>
  //     </GridToolbarContainer>
  //   );
  // };


  return (
   <>
    {showHideVehicleForm  ? (
        <>
         <Box sx={{marginLeft:"0px"}} id="vehicleForm" name="vehicleForm">
         {step === 1 && (
              <VehicleSave
                setShowHideVehicleForm={setShowHideVehicleForm}
                handleNext={handleNext}
                vehicleData={vehicleData}
                showVehicle={showVehicle}
                handleForward={handleForward}
                handleBackward={handleBackward}
                handleSubmitVehicleInfo={handleSubmitVehicleInfos}
                searchQuoteId={id}
                id="vehiclesave"
                name="vehicleSave"
              />
            )}
            {step === 2 && (
              <VehicleAttribute
              handleNext={handleNext}
              setShowHideVehicleForm={setShowHideVehicleForm}
              handleForward={handleForward}
              handleBackward={handleBackward}
              handleSubmitVehicleAttr={handleSubmitVehicleAttr}
              vehicleData={vehicleData}
              vehicleInfoData={vehicleSaveFormData}
              id="vehicleattribute"
              name="vehicleAttribute"
              />
            )}
            {step === 3 && (
              <VehicleAdditionalAttribute
                handleNext={handleNext}
                handleBackward={handleBackward}
                vehicleInfoData={vehicleSaveFormData}
                vehicleAttrData={vehicleAttributeFormData}
                vehicleData={vehicleData}
                setShowHideVehicleForm={setShowHideVehicleForm}
                searchQuoteId={id}
                id="vehicleadditionalattribute"
                name="vehicleAdditionalAttribute"
              />
            )}
         {/* <VehicleSave setShowHideVehicleForm={setShowHideVehicleForm} vehicleData = {vehicleData} showVehicle={showVehicle} handleBack={handleBack} handleNext={handleNext}/> */}
         {/* <VehicleAttribute />
         <VehicleAdditionalAttribute /> */}

          </Box>
        </>
     ):(
      <>
   <Box
    sx={{
      width: "100%",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: "0px 0px 34px",
      boxSizing: "border-box",
      lineHeight: "normal",
      letterSpacing: "normal",
    }}
    id="vehiclemanagementcontainer"
    name="vehicleManagementContainer"
  >
    <VehicleManagement handleBack={handleBack} handleClick={handleClick} id="vehicleManagement" name="vehicleManagement"/>
    <Box
      sx={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: "0px 23px 0px 20px",
        boxSizing: "border-box",
        maxWidth: "100%",
      }}
      id="vehiclemanagementdetails"
      name="vehicleManagementDetails"
    >
      <Box
        sx={{
          width: "853px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          gap: "24px",
          maxWidth: "100%",
        }}
        id="vehicledetailscontainer"
        name="vehicleDetailsContainer"
      >
        <Paper
    sx={{
      alignSelf: 'stretch',
      borderRadius: '4px',
      backgroundColor: '#fff',
      border: '1px solid #e3e3e3',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '28px 46px 22px 47px',
      gap: '24px',
      maxWidth: '100%',
      textAlign: 'left',
      fontSize: '22px',
      color: '#242929',
      fontFamily: '"Poppins", sans-serif',
    }}
    id="vehicledetails"
    name="vehicleDetails"
  >
    <Box
      sx={{
        width: '853px',
        height: '642px',
        position: 'relative',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #e3e3e3',
        boxSizing: 'border-box',
        display: 'none',
        maxWidth: '100%',
        fontFamily: '"Poppins", sans-serif',
      }}
      id="hiddendetails"
      name="hiddenDetails"
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0px 0px 6px',
      }}
      id="vehicletitle"
      name="vehicleTitle"
    >
      <Typography
        variant="h3"
        sx={{
          margin: '0',
          position: 'relative',
          fontSize: 'inherit',
          lineHeight: '20px',
          fontWeight: '600',
          fontFamily: 'inherit',
          display: 'inline-block',
          minWidth: '115px',
          zIndex: '1',
          fontFamily: '"Poppins", sans-serif',
        }}
        id="vehicletypography"
        name="vehicleTypography"
      >
        Vehicles
      </Typography>
    </Box>
    <Box
      sx={{
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0px 0px 8px',
        gap: '20px',
        fontSize: '16px',
        fontFamily: '"Poppins", sans-serif',
        color: '#888',
      }}
      id="vehiclebody"
      name="vehicleBody"
    >
        {/* <DrvierTab /> */}
         {/* <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              hideFooterPagination={true}
              autoHeight={true}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "Mui-even"
                  : "Mui-odd"
              }
              sx={{
                fontFamily: '"Poppins", sans-serif',
                border: 'none',
                '& .MuiDataGrid-cell': {
                  border: 'none',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-row': {
                  borderBottom: 'none',
                },
              }}
              className={classes.root}

            /> */}

<table className={classes.table} id="vehicletable" name="vehicleTable">
          <thead className={classes.thead}>
            <tr>
              <th className={classes.th}>#</th>
              <th className={classes.th}>Model Year</th>
              <th className={classes.th}>Make</th>
              <th className={classes.th}>Model</th>
              <th className={classes.th}>VIN</th>
              <th className={classes.th}>Actions</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {rows.length === 0 ? (
              <tr>
                <td
                  className={classes.td}
                  colSpan="7"
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#888",
                  }}
                >
                  No Vehicle found
                </td>
              </tr>
            ) : (
              paginatedRows.map((row, index) => (
                <tr key={row.id} id={`vehicleRow_${row.id}`} name={`vehicleRow_${row.id}`}>
                  <td className={classes.td}>{page * rowsPerPage + index + 1}</td>
                  <td className={classes.td}>{row.modelYear}</td>
                  <td className={classes.td}>{row.manufacturer}</td>
                  <td className={classes.td}>{row.model}</td>
                  <td className={classes.td}>{row.vin}</td>
                  <td className={classes.td}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "11.5px",
                        alignItems: "center",
                      }}
                    >
                      <IconButton onClick={handleEditClick(row.id)} id={`editVehicle_${row.id}`} name={`editVehicle_${row.id}`}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleDeleteClick(row.id)}
                        id={`deleteVehicle_${row.id}`}
                        name={`deleteVehicle_${row.id}`}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Box>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15]}
        id="tablepagination"
        name="tablePagination"
      />
    </Paper>
          <Box
            sx={{
              width: "222px",
              height: "42px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "14px",
            }}
            id="vehicleactionbuttons"
            name="vehicleActionButtons"
          >
            <Button
              style={{
                alignSelf: "stretch",
                flex: "1",
                cursor: "pointer",
                zIndex: "1",
              }}
              startIcon={<ArrowBackIosIcon />}
              disableElevation={true}
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "#077BFD",
                fontSize: "16px",
                borderColor: "#077BFD",
                borderRadius: "4px",
                fontFamily: '"Poppins", sans-serif',
                "&:hover": { borderColor: "#077BFD" },
              }}
              onClick={handleBack}
              id="vehiclebackbutton"
              name="vehicleBackButton"
              className="btn-secondary"
            >
              Back
            </Button>

            <Button
              style={{
                alignSelf: "stretch",
                flex: "1",
                cursor: "pointer",
                zIndex: "1",
                marginRight: "20px",
              }}
              endIcon={<ArrowForwardIosIcon />}
              disableElevation={true}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "16px",
                backgroundColor: "#077BFD",
                borderColor: "#077BFD",
                borderRadius: "4px",
                fontFamily: '"Poppins", sans-serif',
                "&:hover": { backgroundColor: "#077BFD" },
              }}
              onClick={handleNext}
              id="vehiclenextbutton"
              name="vehicleNextButton"
              className="btn-secondary"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
        </>
      )}
   </>

  )
}

  export default VehicleInformationList;