import * as policyServices from '../../services/dashboardServices';
import { fetchTaskSearch, updatedDashboardData, fetchFilterPriorityTaskData, fetchData, fetchAllPolicyType, fetchFilterTaskTypeData, fetchPriorityTaskData, fetchTaskTypeData, fetchPolicyTypeFilteredData, fetchDashboardPolicyData, fetchDashboardTask, fetchPolicyFilteredData, fetchQuoteFilteredData, managePolicyAllListingServices } from '../../services/dashboardServices';
import { START_LOADING, STOP_LOADING,  SEARCH_POLICY_FAILURE,
  SEARCH_POLICY_SUCCESS } from './actionTypes';
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const setDashboardData = (page = 1, size = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchData(token, page, size);
    dispatch({ type: 'SET_DASHBOARD_DATA', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({
        type: 'SET_DASHBOARD_DATA_FAILURE',
        payload: error.message || 'Failed to fetch dashboard data. Please try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};

export const fetchFilteredData = (formData, page, rowsPerPage) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchPolicyFilteredData(formData, token, page, rowsPerPage);
    dispatch({ type: 'FETCH_FILTERED_DATA_SUCCESS', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else if(error?.includes('500')){
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      dispatch({
        type: 'FETCH_FILTERED_DATA_FAILURE',
        payload: error.message || 'Error filtering policy data. Please check your inputs and try again.',
      });
    } else {
      dispatch({
        type: 'FETCH_FILTERED_DATA_FAILURE',
        payload: error.message || 'Error filtering policy data. Please check your inputs and try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};


export const getDashboardPolicyListAction = (page = 1, rowsPerPage = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchDashboardPolicyData(token, page, rowsPerPage);
    dispatch({ type: 'SET_DASHBOARD_POLICY_DATA', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else if(error?.includes('500')){
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      dispatch({
        type: 'SET_DASHBOARD_POLICY_DATA_FAILURE',
        payload: error.message || 'Failed to load policy data. Please try again.',
      });
    } else {
      dispatch({
        type: 'SET_DASHBOARD_POLICY_DATA_FAILURE',
        payload: error.message || 'Failed to load policy data. Please try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};

export const fetchQuoteFilteredDataAction = (formData, page = 1, rowsPerPage = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchQuoteFilteredData(formData, token, page, rowsPerPage);
    dispatch({ type: 'FETCH_QUOTE_FILTERED_DATA_SUCCESS', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({
        type: 'FETCH_QUOTE_FILTERED_DATA_FAILURE',
        payload: error.message || 'Failed to filter quote data. Please try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};

export const fetchTaskDataAction = (page = 1, size = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchDashboardTask(token, page, size);
    dispatch({ type: 'FETCH_TASK_DATA_SUCCESS', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({
        type: 'FETCH_TASK_DATA_FAILURE',
        payload: error.message || 'Error fetching task data. Please reload and try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};

export const fetchTaskFilterDataAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchTaskSearch(formData, token, page, rowsPerPage);
    dispatch({ type: 'FETCH_TASK_FILTER_DATA_SUCCESS', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({
        type: 'FETCH_TASK_FILTER_DATA_FAILURE',
        payload: error.message || 'Failed to filter task data. Please try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};

export const fetchAllPolicyTypeAction = (page = 1, size = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem('token');
    if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
    const response = await fetchAllPolicyType(token, page, size);
    dispatch({ type: 'FETCH_ALL_POLICY_TYPE_SUCCESS', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    if (error?.includes('403')) {
      dispatch(openSessionTimeoutModal());
    } else if(error?.includes('500')){
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      dispatch({
        type: 'FETCH_ALL_POLICY_TYPE_FAILURE',
        payload: error.message || 'Failed to fetch all policy types. Please try again.',
      });
    } else {
      dispatch({
        type: 'FETCH_ALL_POLICY_TYPE_FAILURE',
        payload: error.message || 'Failed to fetch all policy types. Please try again.',
      });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    }
  }
};

  export const fetchPolicyTypeFilteredDataAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await fetchPolicyTypeFilteredData(formData, token, page, rowsPerPage);
      dispatch({ type: 'FETCH_POLICYTYPE_FILTERED_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      if (error?.includes('403')) {
        dispatch(openSessionTimeoutModal());
      } else if(error?.includes('500')){
        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
        dispatch({
          type: 'FETCH_POLICYTYPE_FILTERED_DATA_FAILURE',
          payload: error.message || 'Failed to fetch all policy types. Please try again.',
        });
      } else {
        dispatch({
          type: 'FETCH_POLICYTYPE_FILTERED_DATA_FAILURE',
          payload: error.message || 'Failed to fetch all policy types. Please try again.',
        });
        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      }
    }
  };

  export const fetchTaskTypeAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem('token');
      if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
      const response = await fetchTaskTypeData(token, page, size);
      dispatch({ type: 'FETCH_TASK_TYPE_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      if (error?.includes('403')) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({
          type: 'FETCH_TASK_TYPE_DATA_FAILURE',
          payload: error.message || 'Error loading task types. Please try again.',
        });
      }
    }
  };

  export const fetchFilterTaskTypeAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await fetchFilterTaskTypeData(formData, token, page, rowsPerPage);
      dispatch({ type: 'FETCH_FILTER_TASK_TYPE_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: 'FETCH_FILTER_TASK_TYPE_DATA_FAILURE', payload: error.message || 'Failed to fetch task type. Please try again.' });
      }
    }
  };

  export const fetchPriorityTaskAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem('token');
      if (!token) {
      dispatch(openSessionTimeoutModal());
      return;
    }
      const response = await fetchPriorityTaskData(token, page, size);
      dispatch({ type: 'FETCH_PRIORITY_TASK_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      if (error?.includes('403')) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({
          type: 'FETCH_PRIORITY_TASK_DATA_FAILURE',
          payload: error.message || 'Failed to fetch priority task data. Please refresh and try again.',
        });
      }
    }
  };

  export const fetchFilterPriorityTaskAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await fetchFilterPriorityTaskData(formData, token, page, rowsPerPage);
      dispatch({ type: 'FETCH_FILTER_PRIORITY_TASK_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: 'FETCH_FILTER_PRIORITY_TASK_DATA_FAILURE', payload: error.message || 'Failed to fetch all policy types. Please try again.' });
      }
    }
  };

  export const updatedTaskData = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await updatedDashboardData(formData, token);
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'UPDATE_TASK_TABLE_DATA', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: 'UPDATE_TASK_TABLE_DATA_FAILURE', payload: error.message || 'Failed to fetch task data. Please try again.' });
      }
    }
  };

  export const updatedPriorityTaskData = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'FETCH_PRIORITY_TASK_DATA_SUCCESS' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await updatedDashboardData(formData, token);
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SAVE_PRIORITY_TASK_UPDATED_DATA', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: 'SAVE_PRIORITY_TASK_UPDATED_DATA_FAILURE', payload: error.message || 'Failed to fetch priority task data. Please try again.' });
      }
    }
  };

  export const updatedOpenTaskData = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'FETCH_TASK_TYPE_DATA_SUCCESS' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await updatedDashboardData(formData, token);
      dispatch({ type: STOP_LOADING, service: 'FETCH_TASK_TYPE_DATA_SUCCESS' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SAVE_OPEN_TASK_UPDATED_DATA', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: 'SAVE_OPEN_TASK_UPDATED_DATA_FAILURE', payload: error.message || 'Failed to fetch open task data. Please try again.' });
      }
    }
  };

  export const managepolicyallAction = (page = 1, rowsPerPage = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem('token');
      if (!token) {
        dispatch(openSessionTimeoutModal());
        return;
      }
      const response = await managePolicyAllListingServices(token, page, rowsPerPage);
      dispatch({ type: 'MANAGE_POLICY_ALL_DATA_SUCCESSS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({
          type: 'MANAGE_POLICY_ALL_DATA_FAILURE',
          payload: error.message || 'Failed to manage policy listings. Please reload the page and try again.',
        });
      }
    }
  };


  export const searchPolicyAction = (formData, page = 0, rowsPerPage = 5) => async (dispatch, getState) => {

    try {
      if (!formData) {
        dispatch({
          type: 'SEARCH_POLICY_SUCCESS',
          payload: {
            content: [],
            totalElements: 0,
            totalPages: 0,
            empty: true
          }
        });
        return;
      }
      dispatch({ type: START_LOADING, service: 'POLICY_SUCCESS' });
      const token = getState().auth.token || sessionStorage.getItem("token");

      const response = await policyServices.searchPolicy(formData, token, page, rowsPerPage);

      dispatch({ type: STOP_LOADING, service: 'POLICY_SUCCESS' });

      dispatch({ type: 'SEARCH_POLICY_SUCCESS', payload: response });
    }
     catch (error) {
      dispatch({ type: STOP_LOADING, service: 'POLICY_SUCCESS' });
    }
  };


