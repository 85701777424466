import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import Dropbox from './Dropbox';
import { saveUploadAttachmentAction } from '../../redux/actions/uploadAttachmentActions';

const UploadForm = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState(''); // Automatically set name from file
  const [type, setType] = useState('');
  const [policy, setPolicy] = useState('');
  const [fileObjects, setFileObjects] = useState([]);
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state?.user?.user?.id);

  const handleAdd = (newFileObjs) => {
    const file = newFileObjs[0].file;
    const fileType = file.type.split('/')[1];

    // Set file name and type automatically
    setName(file.name);
    setFileType(fileType === 'pdf' || fileType === 'jpeg' || fileType === 'png' ? fileType : 'docx');
    setFileObjects((prev) => [...prev, ...newFileObjs]);
  };

  const handleDelete = (deleteFileObj) => {
    setFileObjects(fileObjects.filter((fileObject) => fileObject.file !== deleteFileObj.file));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (fileObjects.length === 0) {
      alert("Please upload a file");
      return;
    }

    setLoading(true);

    const cd = new Date();
    const dd = cd.toISOString().split('T')[0];

    const attachmentInfo = {
      name, // File name set automatically
      fileType,
      type,
      policy,
      date: dd,
      userId: user,
    };

    const formData = new FormData();
    formData.append('uploadFile', fileObjects[0].file);
    formData.append('attachmentInfo', JSON.stringify(attachmentInfo));

    dispatch(saveUploadAttachmentAction(formData, () => {
      setLoading(false);
    }));

    console.log(formData, 'formData')
    setType('');
    setPolicy('');
    setFileObjects([]);
  };

  return (
    <Box sx={{backgroundColor: 'transparent', borderRadius: 0, boxShadow: 0, marginLeft: 1, padding: '0', margin: '0'}}>
      <Typography variant="h6" gutterBottom>
        Upload Document
      </Typography>
      <Dropbox
        fileObjects={fileObjects}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
      />
      
      <form onSubmit={handleSubmit} id="uploadform" name="uploadForm" >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
                >Type</Typography>
                <FormControl fullWidth  sx={{
                  width: "220px",
                  height: "56px",
                }}>
                <Select
                  id="typeselect"
                  name="type"
                  value={type}
                  sx= {{fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "19.6px",
                    textAlign: "left",
                    marginBottom: "8px",}}
                  onChange={(e) => setType(e.target.value)}
                  required
                  //label="Type"
                >
                <MenuItem value="Signed Applicatio">Signed Applicatio</MenuItem>
                <MenuItem value="Unsigned Application">Unsigned Application</MenuItem>
                <MenuItem value="ID Cards">ID Cards</MenuItem>
                <MenuItem value="Receipt">Receipt</MenuItem>
                <MenuItem value="Pictures">Pictures</MenuItem>
              </Select>
            </FormControl>
            <Button
              id="uploadbutton"
              name="upload-button"
              type="submit"
              variant="contained"
              className='btn-primary'
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
              sx={{ padding: '0', margin:'0 0 23px 10px'}}
            >
              {loading ? 'Uploading...' : 'Upload'}
            </Button>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                  marginLeft:"-150px",
                }}
              >
                Select Policy
              </Typography>
            <FormControl fullWidth  sx={{
          width: "220px",
          height: "56px",
          marginLeft:"-150px",
        }}>
              <Select
                id="policyselect"
                name="policy"
                value={policy}
                sx= {{fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",}}
                onChange={(e) => setPolicy(e.target.value)}
                required
                //label="Policy"
              >
                <MenuItem value="Policy1">Policy1</MenuItem>
                <MenuItem value="Policy2">Policy2</MenuItem>
                <MenuItem value="Policy3">Policy3</MenuItem>
              </Select>
            </FormControl>
           
          </Grid> */}
          
        </Grid>
      </form>
    </Box>
  );
};

export default UploadForm;

