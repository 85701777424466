import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  updatedLoggedInUserProfile
} from "../../../redux/actions/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocationAction } from "../../../redux/actions/locationActions";
import { getRolesList } from "../../../redux/actions/roleActions";
import { useNavigate } from 'react-router-dom';

export default function UserProfileForm({ user, toggleForm }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const locationDetails = useSelector((state) => state.location.allLocation);
    const getAllRoles = useSelector((state) => state.location.getAllRolesList);
   
    // console.log("usererrro",userError)
    const navigate = useNavigate();
    // State variables (unchanged)
    const [prefix, setPrefix] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [suffix, setSuffix] = useState("");
    const [userType, setUserType] = useState("");
    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [homePhone, setHomePhone] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [faxNumber, setFaxNumber] = useState("");
    const [workPhone, setWorkPhone] = useState("");
    const [defaultLocation, setDefaultLocation] = useState("");
    const [roles, setRole] = useState("");
    const [status, setStatus] = useState("");
    const userId = useSelector((state) => state.user?.user);
    console.log (userId, 'UserID')
    const [permissions, setPermissions] = useState({
      personalAuto: false,
      idCard: false,
      binders: false,
      homeowner: false,
    });
  
    const [errors, setErrors] = useState({}); // State for form errors
  
    let locationList = [];
    if (Array.isArray(locationDetails)) {
      locationList = locationDetails.map((location) => ({
        value: location.locationDescription,
        label: location.locationDescription,
      }));
    }

    useEffect(() => {
        if (userId) {
          setPrefix(userId.prefix || "");
          setFirstName(userId.firstName || "");
          setMiddleName(userId.middleName || "");
          setLastName(userId.lastName || "");
          setSuffix(userId.suffix || "");
          setUserType(userId.userType || "");
          setEmail(userId.email || "");
          setEmailConfirm(userId.emailConfirm || "");
          setUserName(userId.userName || "");
          setPassword(userId.password || "");
          setConfirmPassword(userId.confirmPassword || "");
          setPhone(userId.phone || "");
          setHomePhone(userId.homePhone || "");
          setFaxNumber(userId.faxNumber || "");
          setWorkPhone(userId.workPhone || "");
          setDefaultLocation(userId.defaultLocation || "");
          setRole(userId.role || "");
          setStatus(userId.status || "");
      
          setPermissions({
            personalAuto: userId.permissions?.personalAuto || false,
            idCard: userId.permissions?.idCard || false,
            binders: userId.permissions?.binders || false,
            homeowner: userId.permissions?.homeowner || false,
          });
        }
      }, [userId]);
      
  
    useEffect(() => {
      dispatch(getAllLocationAction());
      dispatch(getRolesList());
    }, [dispatch]);
  
    useEffect(() => {
      if (userId) {
        setPrefix(userId.prefix || "");
        setFirstName(userId.firstName || "");
        setMiddleName(userId.middleName || "");
        setLastName(userId.lastName || "");
        setSuffix(userId.suffix || "");
        setEmail(userId.email || "");
        setEmailConfirm(userId.email || "");
        setUserType(userId.userType || "");
        setStatus(userId.status || "");
        setUserName(userId.userName || "");
        setPassword("");
        setConfirmPassword("");
        setPhone(userId.phone || "");
        setHomePhone(userId.homePhone || "");
        setFaxNumber(userId.faxNumber || "");
        setWorkPhone(userId.workPhone || "");
        setCellPhone(userId.cellPhone || "");
        setDefaultLocation(userId.locationDescription || "");
        setRole(userId.role || ""); // Make sure this line is setting the role
        setPermissions(
          userId.permissions || {
            personalAuto: false,
            idCard: false,
            binders: false,
            homeowner: false,
          }
        );
      }
    }, [userId]);
    
  
    const handlePermissionChange = (event) => {
      setPermissions({
        ...permissions,
        [event.target.name]: event.target.checked,
      });
    };
  
  
    const handleSubmit = (event) => {
        event.preventDefault();
  
      const formData = {
        prefix,
        firstName,
        middleName,
        lastName,
        suffix,
        email,
        emailConfirm,
        userType,
        status,
        userName,
        password: password || null,
        confirmPassword: confirmPassword || null,
        phone,
        homePhone,
        faxNumber,
        workPhone,
        cellPhone,
        defaultLocation,
        role : 'SUPER',
      };
  
      const handleResponse = (response,error) => {
        if (response) {
          toggleForm(); // Close form after successful save/update
        } else if (error && error.errors && error.errors.length > 0) {
          // Initialize an empty errors object to store errors for email and username
          const newErrors = {};
  
          // Iterate through each error in the errors array
          error.errors.forEach(({ errorMessage, target }) => {
            // Check if the target relates to 'username' or 'email' and add it to the newErrors object
        
            if (target.toLowerCase().includes("username")) {
              newErrors.userName = errorMessage;
            } else if (target.toLowerCase().includes("email")) {
              newErrors.email = errorMessage;
            } else {
              // For any other errors, use a general error message
              newErrors.general = errorMessage || "An error occurred. Please check your inputs.";
            }
          });
  
          // Update the state with the collected errors
          setErrors(newErrors);
        }
      };
  
  
      if (userId) {
        const updatedFormData = {
          ...formData,
          id: userId.id, // Assuming `user.id` contains the ID
        };
        dispatch(updatedLoggedInUserProfile(updatedFormData, handleResponse));
      } 
    };
  
  
    const formFieldStyle = () => ({
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 1200,
          padding: theme.spacing(3),
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            textAlign: "center",
            color: theme.palette.primary.main,
          }}
        >
          {user ? "Edit User Profile" : "Edit User Profile"}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Prefix
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={prefix}
                  onChange={(e) => setPrefix(e.target.value)}
                  id="prefix"
                  name="prefix"
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                First Name
              </Typography>
              <TextField
                required
                fullWidth
                value={firstName}
                onChange={(e) => {
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setFirstName(e.target.value);
                  }
                }}
                id="firstname"
                name="firstName"
                error={!!errors.firstName}
                helperText={errors.firstName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Middle Name
              </Typography>
              <TextField
                fullWidth
                value={middleName}
                onChange={(e) => {
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setMiddleName(e.target.value);
                  }
                }}
                id="middlename"
                name="middleName"
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Last Name
              </Typography>
              <TextField
                required
                fullWidth
                value={lastName}
                onChange={(e) => {
                  // Validate last name
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setLastName(e.target.value);
                  }
                }}
                id="lastname"
                name="lastName"
                error={!!errors.lastName}
                helperText={errors.lastName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Suffix
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={suffix}
                  onChange={(e) => setSuffix(e.target.value)}
                  id="suffix"
                  name="suffix"
                >
                  <MenuItem value="Jr.">Jr.</MenuItem>
                  <MenuItem value="Sr.">Sr.</MenuItem>
                  <MenuItem value="II">II</MenuItem>
                  <MenuItem value="III">III</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ color: theme.palette.success.main, marginY: 2 }}
              >
                Account Status: Active
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                User Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  id="usertype"
                  name="userType"
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    Email Address
  </Typography>
  <TextField
    fullWidth
    autoComplete="off"
    value={email}
    onChange={(e) => {
      const email = e.target.value;
      setEmail(email);
    }}
    id="email"
    name="email"
    //helperText={email}
    inputProps={{ maxLength: 50 }}
  />
</Grid>

<Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    Confirm Email
  </Typography>
  <TextField
    fullWidth
    autoComplete="off"
    value={emailConfirm}
    onChange={(e) => {
      const confirmEmail = e.target.value;
      setEmailConfirm(confirmEmail);
    }}
    id="emailconfirm"
    name="emailConfirm"
    inputProps={{ maxLength: 50 }}
  />
</Grid>


<Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    User Name
  </Typography>
  <TextField
    
    autoComplete="off"
    fullWidth
    value={userName}
    id="username"
    onChange={(e) => {
      // Validate last name
      if (/^[A-Za-z\s]*$/.test(e.target.value) || e.target.value === "") {
        setUserName(e.target.value);
      }
    }}
    inputProps={{ maxLength: 30 }}
  />
</Grid>


            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                value={password}
                onChange={(e) => { setPassword(e.target.value);  
           
            }}
                id="password"
                name="password"
             
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Confirm Password
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value); 
               
                }}
                id="confirmpassword"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Phone
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setPhone(value);
                  }
                }}
                id="phone"
                name="phone"
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Home Phone
              </Typography>
              <TextField
                fullWidth
                value={homePhone}
                autoComplete="off"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setHomePhone(value);
                  }
                }}
                id="homephone"
                name="homePhone"
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "19.6px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              Fax Number
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              type="tel"
              value={faxNumber}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numbers and limit length to 10 digits
                if (/^\d{0,10}$/.test(value)) {
                  setFaxNumber(value);
                }
              }}
              id="faxnumber"
              name="faxNumber"
              inputProps={{
                maxLength: 10, // Optional: Limits the input to 10 digits
              }}
            />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Work Phone
              </Typography>
              <TextField
                fullWidth
                value={workPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setWorkPhone(value);
                  }
                }}
                id="workphone"
                name="workPhone"
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Role
              </Typography>
              <Select
                fullWidth
                value={roles}  // Make sure this is prefilled
                onChange={(e) => setRole(e.target.value)}
                id="roles"
                name="roles"
                displayEmpty
                disabled // Disable the field to make it read-only
              >
                {/* If no role is set, display a default message */}
                <MenuItem value="" disabled>
                  {roles ? roles : "Select a Role"} 
                </MenuItem>

                {getAllRoles && getAllRoles.length > 0 && getAllRoles.map((roleItem) => (
                  <MenuItem 
                    key={roleItem.id} 
                    value={roleItem.displayName}
                  >
                    {roleItem.isSuperUser ? `${roleItem.displayName}` : roleItem.displayName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

<Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    Location
  </Typography>
  <Select
    fullWidth
    value={defaultLocation}  // Make sure this is prefilled
    onChange={(e) => setDefaultLocation(e.target.value)}
    id="defaultlocation"
    name="defaultLocation"
    displayEmpty
    disabled // Disable the field to make it read-only
  >
    <MenuItem value="" disabled>
      {defaultLocation ? defaultLocation : "Select a Location"}
    </MenuItem>
    {locationList.map((location, index) => (
      <MenuItem key={index} value={location.value}>
        {location.label}
      </MenuItem>
    ))}
  </Select>
</Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Status
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  id="status"
                  name="status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Not Active">Not Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Company Group"
                fullWidth
                value={companyGroup}
                onChange={(e) => setCompanyGroup(e.target.value)}
                id="companyGroup"
                name="companyGroup"
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography id="permissions" variant="h6" sx={{ marginY: 2 }}>
                Permissions
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.personalAuto}
                      id="personalauto"
                      onChange={handlePermissionChange}
                      name="personalAuto"
                    />
                  }
                  label="Personal Auto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.idCard}
                      id="idcard"
                      onChange={handlePermissionChange}
                      name="idCard"
                    />
                  }
                  label="ID Card"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.binders}
                      id="binders"
                      onChange={handlePermissionChange}
                      name="binders"
                    />
                  }
                  label="Binders"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.homeowner}
                      id="homeowner"
                      onChange={handlePermissionChange}
                      name="homeowner"
                    />
                  }
                  label="Homeowner"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button id="cancelbutton" onClick={() => navigate('/home')} variant="outlined" color="secondary">
              Cancel
            </Button>
            {/* <Button type="submit" variant="contained" color="primary">
                Save
              </Button> */}
            <Button id="submitbutton" type="submit" variant="contained" color="primary">
              {user ? "Update" : "Save"}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}