import {
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    Box,
    MenuItem
  } from '@mui/material';
import { useState } from 'react';
 
const EditPolicyUserDetail = ({formData, handleChange, handleDialogClose, handleSubmit}) => {
   
    const [errors, setErrors] = useState({});
 
    const validate = () => {
        let tempErrors = {};
        if (!formData.firstName) tempErrors.firstName = "First Name is required";
        if (!formData.lastName) tempErrors.lastName = "Last Name is required";
        if (!formData.dateOfBirth) tempErrors.dateOfBirth = "Date of Birth is required";
        if (!formData.gender) tempErrors.gender = "Gender is required";
        if (!formData.email) tempErrors.email = "Email is required";
        if (!formData.address) tempErrors.address = "Address is required";
        if (!formData.zip) tempErrors.zip = "Zip is required";
        if (!formData.city) tempErrors.city = "City is required";
        if (!formData.state) tempErrors.state = "State is required";
        if (!formData.yearsAtResidence) tempErrors.yearsAtResidence = "Years/Months at Residence is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
 
    const handleSubmitWithValidation = (e) => {
        e.preventDefault();
        if (validate()) {
            handleSubmit(e);
        }
    };
 
    return(
       
         <form onSubmit={handleSubmitWithValidation}>
            <DialogTitle sx={{ backgroundColor: '#ffae00' }}>Edit Policy Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>First Name</label>
                            <TextField
                                name="firstName"
                                value={formData.firstName}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[a-zA-Z0-9 ]*$/.test(value) && value.length <= 30) {
                                        handleChange(e);
                                    }
                                }}
                                fullWidth
                                error={!!errors.firstName}
                                helperText={errors.firstName}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Last Name</label>
                            <TextField
                                name="lastName"
                                value={formData.lastName}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[a-zA-Z0-9 ]*$/.test(value) && value.length <= 30) {
                                        handleChange(e);
                                    }
                                }}
                                fullWidth
                                error={!!errors.lastName}
                                helperText={errors.lastName}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Date of Birth</label>
                            <TextField
                                type="date"
                                name="dateOfBirth"
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.dateOfBirth}
                                helperText={errors.dateOfBirth}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Gender</label>
                            <TextField
                                select
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.gender}
                                helperText={errors.gender}
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Email</label>
                            <TextField
                                name="email"
                                value={formData.email}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[a-zA-Z0-9]*@?[a-zA-Z0-9]*\.?[a-zA-Z0-9]*$/.test(value)
                                        && value.split('@').length <= 2
                                        && value.split('.').length <= 2
                                        && value.length <= 30) {
                                        handleChange(e);
                                    }
                                }}
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Address</label>
                            <TextField
                                name="address"
                                value={formData.address}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[a-zA-Z0-9 ,]*$/.test(value) && value.length <= 50) {
                                        handleChange(e);
                                    }
                                }}
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Zip</label>
                            <TextField
                                name="zip"
                                value={formData.zip}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[0-9]*$/.test(value) && value.length <= 10) {
                                        handleChange(e);
                                    }
                                }}
                                fullWidth
                                error={!!errors.zip}
                                helperText={errors.zip}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>City</label>
                            <TextField
                                name="city"
                                value={formData.city}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (/^[a-zA-Z]*$/.test(value) && value.length <= 30) {
                                        handleChange(e);
                                    }
                                }}
                                fullWidth
                                error={!!errors.city}
                                helperText={errors.city}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>State</label>
                            <TextField
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.state}
                                helperText={errors.state}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ mb: 2 }}>
                            <label className='fnt-we'>Years/Months at Residence</label>
                            <TextField
                                name="yearsAtResidence"
                                value={formData.yearsAtResidence}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.yearsAtResidence}
                                helperText={errors.yearsAtResidence}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className='btn-secondary' onClick={handleDialogClose} color="secondary">Cancel</Button>
                <Button className='btn-secondary' color="primary" type="submit">Save</Button>
            </DialogActions>
        </form>
   
                                    );
                                };
 
export default EditPolicyUserDetail;
 
