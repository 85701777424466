import apiService from "./apiServices";

  export const getQuotelisting = async (token,page, size) => {
    try {
        const response = await apiService(`/quoteslist/all?page=${page}&size=${size}`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }

  export const searchQuote = async(formData, token,page, size) => {
    try {
        const response = await apiService(`/search-quotes/search?page=${page}&size=${size}`, 'POST', formData, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'search quote service is failed. Please try again.');
      }
  }

