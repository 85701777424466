import React, { useState, useEffect } from 'react';
import { Collapse, Box, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmailAction, searchAction,getAllSmsAction,searchSmsAction,getAllPhoneAction,searchPhoneAction } from '../../../redux/actions/communicationAction';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

// Define default data for phones in case phones prop is undefined
const Phone = ({ phones = [], poppinsFont}) => {
  const [phonePage, setPhonePage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(5);
   const [phoneData, setPhoneData] = useState([]);
   const [rowsPerPagePhone, setRowsPerPagePhone] = useState(5);
  const [expandedRows, setExpandedRows] = useState([]);

  const phonesPagination = useSelector((state) => state.communication?.allPhones || { totalElements: 0, page: 0 });

  const dispatch = useDispatch();

  useEffect(() => {
  
      dispatch(getAllPhoneAction(phonePage, rowsPerPagePhone));
    }, [dispatch,phonePage, rowsPerPagePhone]);
  

  const handleExpandClick = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((row) => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleChangePage = (event, newPage) => setPhonePage(newPage);

  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPhonePage(0); // Reset to the first page
  };

  useEffect(() => {
    console.log("Phones Pagination:", phonesPagination);
  }, [phonesPagination]);
  
  useEffect(() => {
    console.log("Phone Data:", phoneData);
  }, [phoneData]);
  

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={poppinsFont}>
          <TableHead>
            <TableRow className="styled-table-row">
              <TableCell className="styled-table-cell"  sx={poppinsFont}>Policy Number</TableCell>
              <TableCell className="styled-table-cell" sx={poppinsFont}>Date</TableCell>
              <TableCell className="styled-table-cell" sx={poppinsFont}>Subject</TableCell>
              <TableCell className="styled-table-cell" sx={poppinsFont}>Delivered</TableCell>
              <TableCell className="styled-table-cell" sx={poppinsFont}>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Ensure phones is an array before calling .map */}
            {Array.isArray(phones) && phones.length > 0 ? (
              phones.map((row, index) => (
                <React.Fragment key={index}>
                  <StyledTableRow id={`phone-row-${index}`}>
                    <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
                    <TableCell sx={poppinsFont}>{row.date}</TableCell>
                    <TableCell sx={poppinsFont}>
                      <IconButton onClick={() => handleExpandClick(index)}>
                        {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                      </IconButton>
                      {row.subject || 'No Subject'}
                    </TableCell>
                    <TableCell sx={poppinsFont}>{row.delivered ? 'Yes' : 'No'}</TableCell>
                    <TableCell sx={poppinsFont}>{row.response}</TableCell>
                  </StyledTableRow>
                  <TableRow>
                    <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                        <Box margin={1} sx={poppinsFont}>
                          <div id={`details${index}`}>
                            Detailed information about the subject: {row.subject || 'No Subject'}
                          </div>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={poppinsFont} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={phonesPagination?.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        count={phonesPagination?.totalElements}
        page={phonePage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Phone;
