

import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  Snackbar,
  Alert,
  FormGroup,
  FormControlLabel,
  Box,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  saveProfileAction,
  updateProfileAction,
  getProfileAction
} from "../../../redux/actions/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocationAction } from "../../../redux/actions/locationActions";
import { getRolesList } from "../../../redux/actions/roleActions";
import { getAllLocationGroupsAction } from '../../../redux/actions/locationGroupActions';

export default function UserProfileForm({ user, toggleForm }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const locationDetails = useSelector((state) => state?.location?.allLocation);
  const getAllRoles = useSelector((state) => state?.location?.getAllRolesList);
  const locationGroups = useSelector((state) => state?.locationGroup?.allLocationGroups || []);
  
  const [prefix, setPrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  
  const [emailAddress, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [userType, setUserType] = useState("");
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [defaultLocation, setDefaultLocation] = useState("");
  const [role, setRole] = useState("");
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', 
      });
  
  
      
  const [permissions, setPermissions] = useState({
    personalAuto: false,
    idCard: false,
    binders: false,
    homeowner: false,
  });

  const [errors, setErrors] = useState({}); // State for form errors

  let locationList = [];
  if (Array.isArray(locationDetails)) {
    locationList = locationDetails.map((location) => ({
      value: location.locationDescription,
      label: location.locationDescription,
    }));
  }

  let locationGroupList = [];
  if (Array.isArray(locationGroups)) {
    locationGroupList = locationGroups.map((location) => ({
      value: location.locationGroupName,
      label: location.locationGroupName,
    }));
  }


  
  useEffect(() => {
    dispatch(getAllLocationAction());
    dispatch(getRolesList());
    dispatch(getAllLocationGroupsAction());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setPrefix(user.prefix || "");
      setFirstName(user.firstName || "");
      setMiddleName(user.middleName || "");
      setLastName(user.lastName || "");
      setSuffix(user.suffix || "");
      // setDesignation(user.designation || '');
      setEmail(user.emailAddress || "");
      setEmailConfirm(user.emailAddress || ""); // Assuming emailConfirm is same as email
      setUserType(user.userType || "");
      setStatus(user.status || "");
      setUserName(user.userName || "");
      setPassword(""); // Do not prefill password for security reasons
      setConfirmPassword(""); // Do not prefill password confirmation
      setPhone(user.phone || "");
      setHomePhone(user.homePhone || "");
      setFaxNumber(user.faxNumber || "");
      setWorkPhone(user.workPhone || "");
      setCellPhone(user.cellPhone || "");
      setDefaultLocation(user.locationDescription || "");
      setRole(user.role || "");
      // setCompanyGroup(user.companyGroup || '');
      setPermissions(
        user.permissions || {
          personalAuto: false,
          idCard: false,
          binders: false,
          homeowner: false,
        }
      );
    }
  }, [user]);

  const handlePermissionChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  const validatePasswords = (password, confirmPassword) => {
    const errors = {};
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    } else {
      errors.confirmPassword = ""; // Clear error if passwords match
    }
    return errors;
  };



  const validateForm = () => {
    const newErrors = {};
    
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!emailAddress) newErrors.emailAddress = "Email Address is required";
    if (emailAddress !== emailConfirm) newErrors.emailConfirm = "Emails do not match";
    if (!userName) newErrors.userName = "User Name is required";
  
    // Only validate password fields if not in edit mode (i.e., if no user is passed)
    if (!user) {
      if (!password) newErrors.password = "Password is required";
      if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match";
    }
  
    return newErrors;
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState("");
const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default severity

const handleSnackbarOpen = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
};

const handleSnackbarClose = () => {
  setSnackbar((prev) => ({
    ...prev,
    open: false,
  }));
};

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = {
      prefix,
      firstName,
      middleName,
      lastName,
      suffix,
      emailAddress,
      emailConfirm,
      userType,
      status,
      userName,
      password,
      confirmPassword,
      phone,
      homePhone,
      faxNumber,
      workPhone,
      cellPhone,
      defaultLocation,
      role,
      permissions,
    };

    if (user) {
      const updatedFormData = {
        ...formData,
        id: user.id, // Assuming `user.id` contains the ID
      };
      dispatch(updateProfileAction(updatedFormData, handleResponse));
      dispatch(getProfileAction()); // Fetch the updated profile
      setSnackbar({
        open: true,
        message: 'User ProfileUpdated successfully',
        severity: 'success',
      });
    } else {
      dispatch(saveProfileAction(formData, handleResponse));
      dispatch(getProfileAction()); // Fetch the updated profile
      setSnackbar({
        open: true,
        message: 'User Profile updated successfully',
        severity: 'success',
      });

    }
  };

  const handleResponse = (success, error) => {
    if (success) {
        // Show success feedback to the user
        handleSnackbarOpen("Profile updated successfully", "success");

        // Reload the page after successful action
        setTimeout(() => {
            window.location.reload(); // This will reload the page
        }, 1000); // Delay before reload
    } else {
        // Handle errors based on the response
        if (error && error.errors && error.errors.length > 0) {
            const newErrors = {};

            error.errors.forEach(({ errorMessage, target }) => {
                if (target.toLowerCase().includes("username")) {
                    newErrors.userName = errorMessage;
                } else if (target.toLowerCase().includes("email")) {
                    newErrors.emailAddress = errorMessage;
                } else {
                    newErrors.general = errorMessage || "An error occurred. Please check your inputs.";
                }
            });

            setErrors(newErrors); // Set errors to display
        } else {
            handleSnackbarOpen("Something went wrong, please try again", "error");
        }
    }
};


  const formatPhoneNumber = (value) => {
    const phone = value.replace(/\D/g, ""); // Remove all non-digit characters
    const phoneLength = phone.length;
  
    if (phoneLength < 4) {
      return phone;
    } else if (phoneLength < 7) {
      return `${phone.slice(0, 3)}-${phone.slice(3)}`;
    } else {
      return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    }
  };

  const formFieldStyle = () => ({
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.6px",
    textAlign: "left",
    marginBottom: "8px",
  });
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        padding:'24px 0',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "calc(100% - 32px)", // Ensures 16px gap on both sides

          width: "94%",
          padding: theme.spacing(3),
          // borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 3,
            color: theme.palette.primary.main,
          }}
        >
          {user ? "Edit User Profile" : "Add New User"}
        </Typography>

        <div style={{ paddingBottom: '20px' }}>
     <hr
    style={{
      borderTop: "1px solid #ccc",
      width: "100%",
      margin: "0", // No default margin
    }}
     />
      </div>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={1.5}>
    <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "8px",
      }}
    >
      Prefix
    </Typography>
    <FormControl fullWidth>
      <Select
        value={prefix}
        onChange={(e) => setPrefix(e.target.value)}
        id="prefix"
        name="prefix"
      >
        <MenuItem value="Mr.">Mr.</MenuItem>
        <MenuItem value="Ms.">Ms.</MenuItem>
        <MenuItem value="Mrs.">Mrs.</MenuItem>
        <MenuItem value="Dr.">Dr.</MenuItem>
      </Select>
    </FormControl>
  </Grid>

  {/* First Name Field */}
  <Grid item xs={12} sm={6} md={3}>
    <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "8px",
      }}
    >
      First Name
    </Typography>
    <TextField
      required
      fullWidth
      value={firstName}
      onChange={(e) => {
        if (/^[A-Za-z\s]*$/.test(e.target.value) || e.target.value === "") {
          setFirstName(e.target.value);
        }
      }}
      id="firstname"
      name="firstName"
      error={!!errors.firstName}
      helperText={errors.firstName}
      inputProps={{ maxLength: 30 }}
    />
  </Grid>

  {/* Middle Name Field */}
  <Grid item xs={12} sm={6} md={3}>
    <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "8px",
      }}
    >
      Middle Name
    </Typography>
    <TextField
      fullWidth
      value={middleName}
      onChange={(e) => {
        if (/^[A-Za-z\s]*$/.test(e.target.value) || e.target.value === "") {
          setMiddleName(e.target.value);
        }
      }}
      id="middlename"
      name="middleName"
      inputProps={{ maxLength: 30 }}
    />
  </Grid>

  {/* Last Name Field */}
  <Grid item xs={12} sm={6} md={3}>
    <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "8px",
      }}
    >
      Last Name
    </Typography>
    <TextField
      required
      fullWidth
      value={lastName}
      onChange={(e) => {
        if (/^[A-Za-z\s]*$/.test(e.target.value) || e.target.value === "") {
          setLastName(e.target.value);
        }
      }}
      id="lastname"
      name="lastName"
      error={!!errors.lastName}
      helperText={errors.lastName}
      inputProps={{ maxLength: 30 }}
    />
  </Grid>

  {/* Suffix Field */}
  <Grid item xs={12} sm={6} md={1.5}>
    <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "8px",
      }}
    >
      Suffix
    </Typography>
    <FormControl fullWidth>
      <Select
        value={suffix}
        onChange={(e) => setSuffix(e.target.value)}
        id="suffix"
        name="suffix"
      >
        <MenuItem value="Jr.">Jr.</MenuItem>
        <MenuItem value="Sr.">Sr.</MenuItem>
        <MenuItem value="II">II</MenuItem>
        <MenuItem value="III">III</MenuItem>
      </Select>
    </FormControl>
  </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ color: theme.palette.success.main, marginY: 0.5 }}
              >
                Account Status: Active
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                User Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  id="usertype"
                  name="userType"
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Email Address
              </Typography>
              <TextField
                required
                fullWidth
                autoComplete="off"
                value={emailAddress}
                onChange={(e) => { const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
                  const email = e.target.value; setEmail(email);
                  if (!emailPattern.test(email)) 
                    { setErrors(prevErrors => ({ ...prevErrors, emailAddress: 'Please enter a valid email address.', }));
                 } 
                 else 
                  { setErrors(prevErrors => ({ ...prevErrors, emailAddress: '', }));
                 } 
                }}
                id="emailaddress"
                name="emailAddress"
                error={!!errors.emailAddress}
                helperText={errors.emailAddress}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Confirm Email
              </Typography>
              <TextField
                required
                fullWidth
                autoComplete="off"
                value={emailConfirm}
                onChange={(e) => { const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
                  const confirmEmail = e.target.value; 
                  setEmailConfirm(confirmEmail);  
                  if (!emailPattern.test(confirmEmail))
                     { setErrors(prevErrors => ({ ...prevErrors, emailConfirm: 'Please enter a valid email address.', })); 
                } else if (confirmEmail !== emailAddress) 
                  { setErrors(prevErrors => ({ ...prevErrors, emailConfirm: 'Email addresses do not match.', })); 
                } else { setErrors(prevErrors => ({ ...prevErrors, emailConfirm: '', })); 
              } 
            }}
                id="emailconfirm"
                name="emailConfirm"
                error={!!errors.emailConfirm}
                helperText={errors.emailConfirm}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                User Name
              </Typography>
              <TextField
                required
                autoComplete="off"
                fullWidth
                value={userName}
                id="username"
                error={!!errors.userName}
                helperText={errors.userName}
                onChange={(e) => {
                  // Validate last name
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setUserName(e.target.value);
                  }
                }}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                value={password}
                onChange={(e) => {
                  const newPassword = e.target.value;
                  setPassword(newPassword);
                  const updatedErrors = validatePasswords(newPassword, confirmPassword);
                  setErrors((prevErrors) => ({ ...prevErrors, ...updatedErrors }));
                }}
                id="password"
                name="password"
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Confirm Password
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  const newConfirmPassword = e.target.value;
                  setConfirmPassword(newConfirmPassword);
                  const updatedErrors = validatePasswords(password, newConfirmPassword);
                  setErrors((prevErrors) => ({ ...prevErrors, ...updatedErrors }));
                }}
                id="confirmPassword"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Phone
              </Typography>
              <TextField
                    fullWidth
                    autoComplete="off"
                    value={phone}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[\d-]*$/.test(value)) {
                        setPhone(formatPhoneNumber(value));
                      }
                    }}
                    id="phone"
                    name="phone"
                    inputProps={{
                      maxLength: 12, // Max length including the dashes
                    }}
                    error={phone.length > 0 && phone.length < 12}
                    helperText={
                      phone.length > 0 && phone.length < 12
                        ? "Please enter a valid 10-digit phone number."
                        : ""
                    }
                  />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Home Phone
              </Typography>
              <TextField
                  fullWidth
                  autoComplete="off"
                  value={homePhone}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[\d-]*$/.test(value)) {
                      setHomePhone(formatPhoneNumber(value));
                    }
                  }}
                  id="homephone"
                  name="homePhone"
                  inputProps={{
                    maxLength: 12, // Max length including the dashes
                  }}
                  error={homePhone.length > 0 && homePhone.length < 12}
                  helperText={
                    homePhone.length > 0 && homePhone.length < 12
                      ? "Please enter a valid 10-digit phone number."
                      : ""
                  }
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "19.6px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              Fax Number
            </Typography>
            <TextField
                  fullWidth
                  autoComplete="off"
                  type="tel"
                  value={faxNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[\d-]*$/.test(value)) {
                      setFaxNumber (formatPhoneNumber(value));
                    }
                  }}
                  id="faxnumber"  
                  name="faxNumber"
                  inputProps={{
                    maxLength: 12, // Max length including the dashes
                  }}
                  error={faxNumber.length > 0 && faxNumber.length < 12}
                  helperText={
                    faxNumber.length > 0 && faxNumber.length < 12
                      ? "Please enter a valid 10-digit fax number."
                      : ""
                  }
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    Work Phone
  </Typography>
  <TextField
    fullWidth
    value={workPhone}
    onChange={(e) => {
      const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
      if (value.length <= 10) {
        setWorkPhone(formatPhoneNumber(value));
      }
    }}
    id="workphone"
    name="workPhone"
    inputProps={{
      maxLength: 12, // Limit the formatted string to 12 characters (including dashes)
    }}
    error={workPhone.length > 0 && workPhone.replace(/\D/g, "").length < 10}
    helperText={
      workPhone.length > 0 && workPhone.replace(/\D/g, "").length < 10
        ? "Please enter a 10-digit work phone number."
        : ""
    }
  />
</Grid>



            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Location
              </Typography>
              <Select
                fullWidth
                value={defaultLocation}
                onChange={(e) => setDefaultLocation(e.target.value)}
                id="defaultlocation"
                name="defaultLocation"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a location
                </MenuItem>
                {locationList.map((location, index) => (
                  <MenuItem key={index} value={location.value}>
                    {location.label}
                  </MenuItem>
                ))}
                {locationGroupList.map((location, index) => (
                  <MenuItem key={index} value={location.value}>
                    {location.label}
                  </MenuItem>
                ))}
                
              </Select>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    Role
  </Typography>
  <Select
    fullWidth
    value={role}
    onChange={(e) => setRole(e.target.value)}
    id="role"
    name="role"
    displayEmpty
  >
    <MenuItem value="" disabled>
      Select a Role
    </MenuItem>
    {getAllRoles
      .filter((role) => !role.isSuperUser) // Only include roles where isSuperUser is false
      .map((role) => (
        <MenuItem key={role.id} value={role.displayName}>
          {role.displayName}
        </MenuItem>
      ))}
  </Select>
</Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Status
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  id="status"
                  name="status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Not Active">Not Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Company Group"
                fullWidth
                value={companyGroup}
                onChange={(e) => setCompanyGroup(e.target.value)}
                id="companyGroup"
                name="companyGroup"
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography id="permissions" variant="h6" sx={{ marginY: 2 }}>
                Permissions
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.personalAuto}
                      id="personalauto"
                      onChange={handlePermissionChange}
                      name="personalAuto"
                    />
                  }
                  label="Personal Auto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.idCard}
                      id="idcard"
                      onChange={handlePermissionChange}
                      name="idCard"
                    />
                  }
                  label="ID Card"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.binders}
                      id="binders"
                      onChange={handlePermissionChange}
                      name="binders"
                    />
                  }
                  label="Binders"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.homeowner}
                      id="homeowner"
                      onChange={handlePermissionChange}
                      name="homeowner"
                    />
                  }
                  label="Homeowner"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              marginTop: 4,
            }}
          >
    <Button id="cancelbutton"  onClick={toggleForm} variant="outlined"   className="btn-secondary"
  color="primary">
              Cancel
            </Button>

<Button
  id="submitbutton"
  type="submit"
  variant="contained"
  className="btn-primary" 
 color="secondary"
  onClick={handleSubmit} // Call handleSubmit directly, as it already handles both saving and updating
>
  {user ? "Update" : "Save"}
</Button>

        




            
          </Grid>
        </form>
      </Paper>

        <Snackbar
                    open={snackbar.open}
                    autoHideDuration={1000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={snackbar.severity}
                    >
                      {snackbar.message}
                    </Alert>
                  </Snackbar>
    </Box>
  );
}
