import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  TextField,
  Checkbox,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Paper,
  useTheme,
  TablePagination,
  Typography
} from '@mui/material';
import { getCarrierAction, saveCarrierGroupAction, updateCarrierAction } from '../../redux/actions/agencyActions';
import { styled } from "@mui/material/styles";
import { Label } from '@mui/icons-material';


 


const CreateCarrier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the state passed during navigation
  const theme = useTheme();

  const [companyGroupName, setGroupName] = useState('');
  const [agencyName, setAgencyName] = useState([]);
  const [carrierId, setCarrierId] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]); // This will hold the selected carrier agencyIds
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [groupId, setGroupId] = useState(null); // This will hold the group ID if editing
  const [error, setError] = useState('');

  const carrierDetails = useSelector((state) => state.agency.allCarrier); // Get carrier details from Redux
  const carriers = carrierDetails || []; // Make sure carriers exist
  const caarr = location.state?.group?.carriers;
   const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success', 
    });


  // Populate form fields if editing
  useEffect(() => {
    if (location.state) {
      setIsEdit(location.state.isEdit); // Set edit mode
      setGroupId(location.state?.group?.groupId); // Set the group ID
      setGroupName(location.state?.group?.companyGroupName); // Pre-fill the group name field
  
      if (caarr && caarr.length > 0) {
        // Extract and set agency IDs that should be selected
        const selectedCarrierIds = caarr.map((carrier) => carrier.agencyId);
        const getAgencyName = caarr.map((carrier) => carrier.agencyName);
        
        setAgencyName(getAgencyName);
        setCarrierId(selectedCarrierIds); // Set the carrierId array
        setSelectedCheckbox(selectedCarrierIds); // Set pre-selected checkboxes
      }
    }
  }, [location.state, caarr]);
  

  useEffect(() => {
    dispatch(getCarrierAction()); // Fetch all carriers when the component mounts
  }, [dispatch]);

  
  const handleCheckboxChange = (id) => {
    setSelectedCheckbox((prev) =>
      prev.includes(id) ? prev.filter((c) => c !== id) : [...prev, id]
    );
  };
  
  // Handle the Create or Update action
  const handleCancel = () => {
    navigate('/CarrierGroup');
  }

  const handleCreateOrUpdateGroupClick = () => {
    if (!companyGroupName) {
      setError('Carrier Group Name is required.');
      return;
    }
    if (selectedCheckbox.length === 0) {
      setError('Please select at least one carrier.');
      return;
    }
      const carrierIds = selectedCheckbox; // Carrier IDs that have been selected

      const groupData = {
        groupId: isEdit && groupId ? groupId : undefined, // Only send groupId if editing
        companyGroupName,
        carrierIds, // Carrier IDs to associate with the group
        status: 'Active',
      };

      if (isEdit) {
        dispatch(updateCarrierAction(groupData)).then(() => {
          dispatch(getCarrierAction()); // Refresh the carrier list
          setTimeout(() => {
          navigate('/CarrierGroup');
          },2000);
        });
        setSnackbar({
          open: true,
          message: 'Carrier Group Updated successfully',
          severity: 'success',
        });
      } else {
        dispatch(saveCarrierGroupAction(groupData)).then(() => {
          dispatch(getCarrierAction()); // Refresh the carrier list
          setTimeout(() => {
            navigate('/CarrierGroup');
            },1000);

        });
        setSnackbar({
          open: true,
          message: 'Carrier Group Created successfully',
          severity: 'success',
        });
      }

       // Navigate back to the CarrierGroup component

      // Reset the form
      setSelectedCheckbox([]);
      setGroupName('');
      setIsEdit(false); // Reset edit flag
      setGroupId(null);
      setError(''); 
    
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = carriers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); // Paginate carrier rows

  return (
    <Box sx={{ padding: 3 ,
      margin: '20px', 
      border : '1px solid  rgba(57, 57, 57, 0.1)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)' 
    }}

    id="carriergroupscreen">
  <Typography
    id="Create Carrier Group"
    variant="h5"
    sx={{ 
      color: theme.palette.primary.main,
      marginBottom: 0 // Add margin here to create spacing
    }}
  >
    Create Carrier Group
  </Typography>

              
      {/* Input for Carrier Group Name */}
      <div
  style={{
    display: "flex",
    flexDirection: "column", // Stack items vertically
    padding: "16px 0",
  }}
>
  <Box sx={{ display: "flex", flexDirection: "column", marginBottom: 2 }}>
    
     <Typography
     variant="body1"
     sx={{
       fontFamily: "Poppins",
       fontSize: "14px",
       fontWeight: "500",
       lineHeight: "19.6px",
       textAlign: "left",
       marginBottom: "4px",
     }}
   >
      Carrier Group Name
    </Typography>

    <Box sx={{ display: "flex", alignItems: "center", gap: 0  }}>
    <TextField
  variant="outlined"
  size="small"
  value={companyGroupName} // Pre-filled value if editing
  onChange={(e) => {
    const value = e.target.value;
   
    if (/^[a-zA-Z0-9\s'-]*$/.test(value)) {
      setGroupName(value);
      setError('');
    } else {
      setError('Only alphanumeric characters are allowed');
    }
  }}
  error={!!error }
  helperText={error}
  sx={{
    width: "220px",
    height: "55px", // Ensure the height is explicitly set
    "& .MuiInputBase-root": {
      height: "55px", // Apply the height to the input container
    },
  }}
/>

      <Box sx={{ marginTop: "2px", marginLeft: "10px"  }}>
      <Button
        variant="contained"
        className="btn-primary"
        color="primary"
        onClick={handleCreateOrUpdateGroupClick}
        sx={{ marginTop: "-5px" }}
      >
        {isEdit ? "Update Group" : "Create Group"}
      </Button>
      <Button
        variant="contained"
        className="btn-secondary"
        color="secondary"
        onClick={handleCancel}
        sx={{ marginTop: "-5px", marginLeft: "10px" }}
      >
        Cancel
      </Button>
      </Box>
    </Box>
  </Box>
</div>
   

      {/* Carrier Selection Table */}
      <TableContainer component={Paper} sx={{marginTop:'0px'}}>
        <Table id="carriergrouptable">
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading">Active</TableCell>
              <TableCell className="styled-table-heading">Company Name</TableCell>
              <TableCell className="styled-table-heading">Program Name</TableCell>
              <TableCell className="styled-table-heading">Producer Code</TableCell>
              <TableCell className="styled-table-heading">Last Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((group, index) => (
              <TableRow key={index} className="styled-table-row" id={`carriergrouprow${index}`}>
                <TableCell>
                <Checkbox
                  checked={selectedCheckbox.includes(group.agencyId)} // Check if the current agencyId is in the selectedCheckbox array
                  onChange={() => handleCheckboxChange(group.agencyId)} // Handle checkbox change
                  id={`active-${index}`}
                />
                </TableCell>
                <TableCell id={`companyname${index}`}>{group.insuranceCarrier}</TableCell>
                <TableCell id={`programname${index}`}>{group.programName}</TableCell>
                <TableCell id={`producercode${index}`}>{group.producerCode}</TableCell>
                <TableCell id={`lastactivity${index}`}>{group.updatedTimeStamp}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination for Carriers Table */}
      <TablePagination
        component="div"
        count={carriers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={carriers.length === 0 ? [] : [5, 10, 15]}
        id="tablepagination"
        name="tablePagination"
      />

        <Snackbar
              open={snackbar.open}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbar.severity}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
    </Box>
  );
};

export default CreateCarrier;
