
import React, { useEffect, useState } from "react";
import { AppBar, Tab, Tabs, Typography, Button, Box, Container, Paper, Grid } from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import GeneralTab from "../components/general/clientOther";
import GeneralQuote from "../components/general/generalQuote";
import DriverSave from "../components/drivers/driverSave1";
import DriverInformationList from "../components/drivers/driverInformationList";
import VehicleSave from "../components/cars/vehicleSave1";
import VehicleAttribute from "../components/cars/VehicleAttribute";
import VehicleAdditionalAttribute from "../components/cars/VehicleAdditionalAttribute";
import VehicleInformationList from "../components/cars/VehicleInformationList";
// import Coverage from "../components/Coverage/coverage";
// import Comparision from "../components/compareQuotes/comparision";
import CompareQuotesTab from "../components/compareQuotes/comparision";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
const theme = createTheme({
  palette: {
    text: {
      primary: "#242929",
      secondary: "#077BFD",
    },
  },
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    minWidth: "unset",
    fontWeight: 600,
    padding: '25px 0',
    fontSize: "1rem",
    textTransform: "none",
    letterSpacing: "0.5px",
    fontFamily: '"Poppins", sans-serif',
    color: theme.palette.text.primary,
    borderBottom: "3px solid transparent",
    transition: "border-bottom-color 0.3s",
    "&:hover": {
      borderBottomColor: theme.palette.text.secondary,
    },
  },
  "& .Mui-selected": {
    color: theme.palette.text.secondary,
    borderBottom: "3px solid #077BFD"
  },
  "& .MuiTabs-indicator": {
    display: "none", // Hide the default indicator
  },
  "& .Mui-visited": {
    color: theme.palette.text.secondary,
  },
}));

function MainView({setView}) {
  const [value, setValue] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [vehicleSaveFormData, setVehicleSaveFormData] = useState({});
  const [vehicleAttributeFormData, setVehicleAttributeFormData] = useState({});
  const [vehicleData,setVehicleData]=useState({});
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { generalData } = location.state || {};
  // console.log("general data in main view",generalData)
  // const id=generalData.gpId;
  const id = generalData?.gpId;
  // console.log("id in main view",id);

  const [visitedTabs, setVisitedTabs] = useState(new Array(6).fill(false)); // Initialize an array of 6 boolean values to keep track of visited tabs

  const refreshComparisonTab = () => {
    // console.log("Refreshing Comparison Tab Data...");
    setRefresh(true); // Trigger re-render if needed
  };

  useEffect(() => {
    if (value === 3) {
      const timeout = setTimeout(() => {
        refreshComparisonTab();
      }, 1000);

      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [value]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch({ type: "RESET_ALL_RATES" });
    setPageIndex(0); // Reset pageIndex when switching tabs
    setVisitedTabs((prevVisitedTabs) => {
      const newVisitedTabs = [...prevVisitedTabs];
      newVisitedTabs[newValue] = true; // Set the new tab as visited
      return newVisitedTabs;
    });
  };

  const handleNext = () => {
    const totalPages = formPages[value].length;
    if (pageIndex < totalPages - 1) {
      setPageIndex(pageIndex + 1); // Move to the next page of the form
    } else {
      if (value < formPages.length - 1) {
        setValue(value + 1); // Move to the next tab
        setPageIndex(0); // Reset pageIndex when switching tabs
        setVisitedTabs((prevVisitedTabs) => {
          const newVisitedTabs = [...prevVisitedTabs];
          newVisitedTabs[value + 1] = true; // Set the new tab as visited
          return newVisitedTabs;
        });
      }
    }
  };

  const handleBack = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1); // Move to the previous page of the form
    } else {
      if (value > 0) {
        setValue(value - 1); // Move to the previous tab
        const prevTabPages = formPages[value - 1].length;
        setPageIndex(prevTabPages - 1); // Set pageIndex to the last page of the previous tab
        setVisitedTabs((prevVisitedTabs) => {
          const newVisitedTabs = [...prevVisitedTabs];
          newVisitedTabs[value - 1] = true; // Set the previous tab as visited
          return newVisitedTabs;
        });
      }
    }
  };

  const handleSubmitGeneralTab = (data) => {
    setFormData(data);
    handleNext();
  };

  const handleSubmitVehicleInfos = (data) => {
    // console.log(data,"raushan")
    setVehicleSaveFormData(data);
    handleNext();
  };

  const handleSubmitVehicleAttr = (data) => {
    setVehicleAttributeFormData(data);
    handleNext();
  };

  const handleVehicleData=(data)=>{
    setVehicleData(data);
  }

const formPages=[
  [
    <GeneralTab  handleNext={handleNext} handleSubmitGeneralTab={handleSubmitGeneralTab} id={id} />,
    <GeneralQuote handleNext={handleNext} handleBack={handleBack} data={formData}  id={id}/>
  ],
  [
    // <DriverSave handleNext={handleNext} handleBack={handleBack}/>,
    // <DrvierTab handleNext={handleNext} handleBack={handleBack}/>,
    // id={generalData.gpId}
    <DriverInformationList handleNext={handleNext} handleBack={handleBack} id={id}/>
  ],
  [
    // <VehicleSave handleNext={handleNext} handleBack={handleBack} handleSubmitVehicleInfo={handleSubmitVehicleInfos} handleVehicleData={handleVehicleData}/>,
    // <VehicleAttribute handleNext={handleNext} handleBack={handleBack} handleSubmitVehicleAttr={handleSubmitVehicleAttr} vehicleData={vehicleData}/>,
    // <VehicleAdditionalAttribute handleNext={handleNext} handleBack={handleBack} vehicleInfoData={vehicleSaveFormData} vehicleAttrData={vehicleAttributeFormData} vehicleData={vehicleData}/>,
    <VehicleInformationList handleNext={handleNext} handleBack={handleBack} vehicleData={vehicleData} id={id}/>
  ],
  // [
  //   <Coverage handleNext={handleNext} handleBack={handleBack}/>
  // ],
  [
     <CompareQuotesTab handleNext={handleNext} handleBack={handleBack} id={id}/>
  ],
]



  return (
    <ThemeProvider theme={theme}>
        <AppBar position="static" color="default">
          <Container>
            <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab id="General" label="General" />
              <Tab id="Drivers" label="Drivers" />
              <Tab id="Cars" label="Cars" />
              {/* <Tab label="Coverage" /> */}
              <Tab id="Comparison" label="Comparison" />
              {/* <Tab label="Breakdown" /> */}
            </StyledTabs>
          </Container>
        </AppBar>

        <Paper sx={{ p: 2, margin: '0 auto', boxShadow: 'none'}}>
          <Grid item xs={12} sx={{ margin: '0 auto'}}>
            {formPages[value][pageIndex]}
          </Grid>
        </Paper>
    </ThemeProvider>
  );
}

export default MainView;
