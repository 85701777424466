import { STOP_LOADING,START_LOADING } from './actionTypes';
  import { getAllLocation, saveLocation, updateLocation, getDeleteLocation,
    getAllPermissionService
   } from '../../services/locationServices';
import { openSessionTimeoutModal } from './sessionTimeOutActions';


export const saveLocationAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'SAVE_LOCATION' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await saveLocation(formData, token);
    
    dispatch({ type: STOP_LOADING, service: 'SAVE_LOCATION' });
    
    // Trigger callback after saving
    if (response) {
      callbackfn(response);
    }

    dispatch({ type: 'SAVE_LOCATION_SUCCESS', payload: response }); // Dispatch success action
  } catch (error) {
    if (error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'SAVE_LOCATION' });
      dispatch({ type: 'SAVE_LOCATION_FAILURE', payload: error || 'Save location service failed. Please try again.' });
    }
  }
};


    export const updateLocationAction = (formData,callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'UPDATE_LOCATION' });
        const token = getState().auth.token || sessionStorage.getItem("token");;
        const response = await updateLocation(formData, token);
        dispatch({ type: STOP_LOADING, service: 'UPDATE_LOCATION' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: 'UPDATE_LOCATION_SUCCESS', payload: response });
      } catch (error) {
        dispatch({ type: STOP_LOADING, service: 'UPDATE_LOCATION' });
        dispatch({ type: 'UPDATE_LOCATION_FAILURE', payload: error || 'Update location service failed. Please try again.' });
      }
    };

    export const getAllLocationAction= (page = 1, size = 5) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'ALL_LOCATION' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await getAllLocation(token, page, size);
        dispatch({ type: STOP_LOADING, service: 'ALL_LOCATION' });
        dispatch({ type: 'GET_LOCATION_SUCCESS', payload: response });
      } catch (error) {
        dispatch({ type: STOP_LOADING, service: 'ALL_LOCATION' });
          dispatch({ type: 'GET_LOCATION_FAILURE', payload: error || 'All location service failed. Please try again.' });
      }
    };


    export const getDeleteLocationAction = (id) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'START_LOADING' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        await getDeleteLocation(id, token); // API call to delete the group
        dispatch({ type: 'DELETE_LOCATION', payload: id });

        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' }); // Stop loading after fetch is done
      } catch (error) {
        dispatch({ type: 'GET_LOCATION_GROUPS_FAILURE', payload: error || 'Failed to delete group. Please try again.' });
        dispatch({type: STOP_LOADING, service: 'STOP_LOADING' }); // Stop loading on error as well
      }
    };

    export const getAllUserPermissionAction = () => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'START_LOADING' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await getAllPermissionService(token);
        dispatch({ type: 'GET_ALL_USER_PERMISSION', payload: response });
        
        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      }
      catch (error) {
        dispatch({ type: 'GET_ALL_USER_PERMISSION_FAILURE', payload: error || 'Faile to load user permission. Please try again after some time.' });
        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      }
    }