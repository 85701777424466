import React, { useState } from 'react';
import {
    Button,
    Box,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreatePolicyForm from './CreatePolicyForm';
import PolicyDriver from '../PolicyDetails/PolicyDriver';
import PolicyVehicle from '../PolicyDetails/PolicyVehicle';
import PremiumDetails from '../PolicyDetails/PremiumDetails';

const CreatePolicy = () => {
    const [step, setStep] = useState(1);
    const [value, setValue] = useState(0);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false); // Manage tab enable state
    const [policyId, setPolicyId] = useState(null);
    const [driverId, setDriverId] = useState(null);
    const [vehicleId, setVehicleId] = useState(null);
    const [premiumId, setPremiumId] = useState(null);

    const handleNext = () => {
        setStep(2);
    };

    const handleBack = () => {
        setStep(1);
    };

    const handleSubmit = () => {
        setIsSubmitClicked(true); // Enable other tabs
        setValue(1); // Move to "Notes" tab
    };

    const handleChange = (event, newValue) => {
        // Prevent switching to other tabs until Submit is clicked
        if (isSubmitClicked || newValue === 0) {
            setValue(newValue);
        }
    };

    return (
        <div style={{ padding: '0 40px', marginTop: '50px' }}>
            <Grid item xs={12}>
                <Box className="policy-detail-main-container">
                    <AppBar position="static" id="policydetailsappbar">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Policy Details" id="tabpolicydetails" />
                            <Tab label="Notes" id="tabnotes" disabled={!isSubmitClicked} />
                            <Tab label="Task" id="tabtask" disabled={!isSubmitClicked} />
                            <Tab label="Policy Documents" id="tabpolicydocuments" disabled={!isSubmitClicked} />
                            <Tab label="Activity" id="tabactivity" disabled={!isSubmitClicked} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} id="policyinfotabpanel">
                        {step === 1 && (
                            <Accordion defaultExpanded sx={{ margin: '0 0 10px 0', width: 'calc(100% + 2px)', background: '#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1header"
                                >
                                    <Typography sx={{ fontWeight: '500', fontSize: '19px' }}>Details</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <CreatePolicyForm setPolicyId={setPolicyId} />
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {step === 2 && (
                            <>
                                <Accordion sx={{ margin: '0 0 10px 0', width: 'calc(100% + 2px)', background: '#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2header"
                                    >
                                        <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Drivers</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <PolicyDriver setDriverId={setDriverId} policyId={policyId} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion sx={{ margin: '0 0 10px 0', width: 'calc(100% + 2px)', background: '#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3-content"
                                        id="panel3header"
                                    >
                                        <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Vehicle</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <PolicyVehicle policyId={policyId} driverId={driverId} setPremiumId={setPremiumId} setVehicleId={setVehicleId} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion sx={{ margin: '0 0 10px 0', width: 'calc(100% + 2px)', background: '#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4-content"
                                        id="panel4header"
                                    >
                                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Coverage</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <PremiumDetails  policyId={premiumId} vehicleId={vehicleId} />
                                        {/* <AddCoverage policyId={policyId} vehicleId={vehicleId} /> */}
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )}

                        {/* Step Navigation Buttons */}
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            {step === 2 && (
                                <Button className="btn-secondary" onClick={handleBack}>
                                    Back
                                </Button>
                            )}
                            {step === 1 ? (
                                <Button className="btn-primary" onClick={handleNext}>
                                    Next
                                </Button>
                            ) : (
                                <Button className="btn-primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1} id="notestabpanel">
                        Notes content here...
                    </TabPanel>
                    <TabPanel value={value} index={2} id="tasktabpanel">
                        Task content here...
                    </TabPanel>

                    <TabPanel value={value} index={3} id="attachmentstabpanel">
                        Attachments content here...
                    </TabPanel>
                    <TabPanel value={value} index={4} id="activitytabpanel">
                        Activity content here...                        
                    </TabPanel>
                </Box>
            </Grid>
        </div>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simpletabpanel${index}`}
            aria-labelledby={`simpletab${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default CreatePolicy;
