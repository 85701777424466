import { CLOSE_CREATE_VEHICLE_INFO_MODAL, OPEN_CREATE_VEHICLE_INFO_MODAL, OPEN_UPDATE_VEHICLE_INFO_MODAL } from "../actions/actionTypes";

const initialState = {
    allVehicleInfo: [],
    years:[],
    makes:[],
    models:[],
    styles:[],
    vins:[],
    error: null,
    isCreateVehicleInfoModalOpen: false,
    selectedVehicleInfoId: null,
    get_vehicle_detail: [],
  };

  const vehicleInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_VEHICLE_INFOS_SUCCESS':
            return { ...state, allVehicleInfo: action.payload, error: null };
        case 'ALL_VEHICLE_INFOS_YEARS_SUCCESS':
            return {...state,years: action.payload,error:null};
        case 'GET_VEHICLE_DETAIL_BY_ID_SUCCESS':
            return {...state, get_vehicle_detail: action.payload,error:null};
        case 'UPDATE_VEHICLE_DETAIL_BY_ID_SUCCESS':
            return {...state, get_vehicle_detail: action.payload,error:null};            
         case 'ALL_VEHICLE_INFOS_MAKES_SUCCESS':
            return {...state,makes: action.payload,error:null};
         case 'ALL_VEHICLE_INFOS_MODELS_SUCCESS':
            return {...state,models: action.payload,error:null};
         case 'ALL_VEHICLE_INFOS_STYLES_SUCCESS':
            return {...state,styles: action.payload,error:null};
         case 'ALL_VEHICLE_INFOS_VINS_SUCCESS':
            return {...state,vins: action.payload,error:null};
        case 'CREATE_VEHICLE_INFO_SUCCESS':
        case 'UPDATE_VEHICLE_INFO_SUCCESS':
            return {
                ...state,
                allVehicleInfo: [ ...state.allVehicleInfo, ...action.payload ],
                error: null,
            };
        case OPEN_CREATE_VEHICLE_INFO_MODAL:
            return {...state, isCreateVehicleInfoModalOpen: true}
        case OPEN_UPDATE_VEHICLE_INFO_MODAL:
            return {...state, isCreateVehicleInfoModalOpen: true, selectedVehicleInfoId: action.payload}
        case CLOSE_CREATE_VEHICLE_INFO_MODAL:
            return {...state, isCreateVehicleInfoModalOpen: false, selectedVehicleInfoId: null}
        case 'ALL_VEHICLE_INFOS_FAILURE':
        case 'CREATE_VEHICLE_INFO_FAILURE':
        case 'UPDATE_VEHICLE_INFO_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };

  export default vehicleInfoReducer;