
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const OPEN_CREATE_LEAD_MODAL = 'OPEN_CREATE_LEAD_MODAL';
export const CLOSE_CREATE_LEAD_MODAL = 'CLOSE_CREATE_LEAD_MODAL';
export const OPEN_UPDATE_LEAD_MODAL = 'OPEN_UPDATE_LEAD_MODAL';
export const FORM_LAYOUT_SAVE_SUCCESS = 'FORM_LAYOUT_SAVE_SUCCESS';
export const FORM_LAYOUT_SAVE_FAILURE = 'FORM_LAYOUT_SAVE_FAILURE';
export const FORM_LAYOUT_UPDATE_SUCCESS = 'FORM_LAYOUT_UPDATE_SUCCESS';
export const FORM_LAYOUT_UPDATE_FAILURE = 'FORM_LAYOUT_UPDATE_FAILURE';
export const FORM_LAYOUT_GET_SUCCESS = 'FORM_LAYOUT_GET_SUCCESS';
export const FORM_LAYOUT_GET_FAILURE = 'FORM_LAYOUT_GET_FAILURE';
export const OPEN_CREATE_ORGANIZATION_MODAL = 'OPEN_CREATE_ORGANIZATION_MODAL';
export const CLOSE_CREATE_ORGANIZATION_MODAL = 'CLOSE_CREATE_ORGANIZATION_MODAL';
export const OPEN_UPDATE_ORGANIZATION_MODAL = 'OPEN_UPDATE_ORGANIZATION_MODAL';
export const OPEN_CREATE_CUSTOMER_MODAL = 'OPEN_CREATE_CUSTOMER_MODAL';
export const CLOSE_CREATE_CUSTOMER_MODAL = 'CLOSE_CREATE_CUSTOMER_MODAL';
export const OPEN_UPDATE_CUSTOMER_MODAL = 'OPEN_UPDATE_CUSTOMER_MODAL';
export const OPEN_CREATE_USER_MODAL = 'OPEN_CREATE_USER_MODAL';
export const CLOSE_CREATE_USER_MODAL = 'CLOSE_CREATE_USER_MODAL';
export const OPEN_UPDATE_USER_MODAL = 'OPEN_UPDATE_USER_MODAL';
export const OPEN_SESSION_TIMEOUT_MODAL = 'OPEN_SESSION_TIMEOUT_MODAL';
export const CLOSE_SESSION_TIMEOUT_MODAL = 'CLOSE_SESSION_TIMEOUT_MODAL';
export const OPEN_UPDATE_ROLE_MODAL = 'OPEN_UPDATE_ROLE_MODAL';
export const OPEN_CREATE_ROLE_MODAL = 'OPEN_CREATE_ROLE_MODAL';
export const CLOSE_CREATE_ROLE_MODAL = 'CLOSE_CREATE_ROLE_MODAL';
export const OPEN_UPDATE_PERMISSION_MODAL = 'OPEN_UPDATE_PERMISSION_MODAL';
export const OPEN_CREATE_PERMISSION_MODAL = 'OPEN_CREATE_PERMISSION_MODAL';
export const CLOSE_CREATE_PERMISSION_MODAL = 'CLOSE_CREATE_PERMISSION_MODAL';
//rater
export const TAB_CHANGE = 'TAB_CHANGE';
export const OPEN_CREATE_GENERAL_PARTY_INFO_MODAL = 'OPEN_CREATE_GENERAL_PARTY_INFO_MODAL';
export const CLOSE_CREATE_GENERAL_PARTY_INFO_MODAL = 'CLOSE_CREATE_GENERAL_PARTY_INFO_MODAL';
export const OPEN_UPDATE_GENERAL_PARTY_INFO_MODAL = 'OPEN_UPDATE_GENERAL_PARTY_INFO_MODAL';
export const OPEN_CREATE_DRIVER_INFO_MODAL = 'OPEN_CREATE_DRIVER_INFO_MODAL';
export const CLOSE_CREATE_DRIVER_INFO_MODAL = 'CLOSE_CREATE_DRIVER_INFO_MODAL';
export const OPEN_UPDATE_DRIVER_INFO_MODAL = 'OPEN_UPDATE_DRIVER_INFO_MODAL';
export const OPEN_CREATE_VEHICLE_INFO_MODAL = 'OPEN_CREATE_VEHICLE_INFO_MODAL';
export const CLOSE_CREATE_VEHICLE_INFO_MODAL = 'CLOSE_CREATE_VEHICLE_INFO_MODAL';
export const OPEN_UPDATE_VEHICLE_INFO_MODAL = 'OPEN_UPDATE_VEHICLE_INFO_MODAL';
export const OPEN_CREATE_EXCLUSIONS_INFO_MODAL = 'OPEN_CREATE_EXCLUSIONS_INFO_MODAL';
export const CLOSE_CREATE_EXCLUSIONS_INFO_MODAL = 'CLOSE_CREATE_EXCLUSIONS_INFO_MODAL';
export const OPEN_UPDATE_EXCLUSIONS_INFO_MODAL = 'OPEN_UPDATE_EXCLUSIONS_INFO_MODAL';
export const OPEN_CREATE_CARRIER_SELECTIONS_INFO_MODAL = 'OPEN_CREATE_CARRIER_SELECTIONS_INFO_MODAL';
export const CLOSE_CREATE_CARRIER_SELECTIONS_INFO_MODAL = 'CLOSE_CREATE_CARRIER_SELECTIONS_INFO_MODAL';
export const OPEN_UPDATE_CARRIER_SELECTIONS_INFO_MODAL = 'OPEN_UPDATE_CARRIER_SELECTIONS_INFO_MODAL';
export const OPEN_CREATE_CARRIER_QUESTIONS_INFO_MODAL = 'OPEN_CREATE_CARRIER_QUESTIONS_INFO_MODAL';
export const CLOSE_CREATE_CARRIER_QUESTIONS_INFO_MODAL = 'CLOSE_CREATE_CARRIER_QUESTIONS_INFO_MODAL';
export const OPEN_UPDATE_CARRIER_QUESTIONS_INFO_MODAL = 'OPEN_UPDATE_CARRIER_QUESTIONS_INFO_MODAL';
export const FETCH_PRIOR_INSURANCE_CARRIERS_REQUEST = 'FETCH_PRIOR_INSURANCE_CARRIERS_REQUEST';
export const FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS = 'FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS';
export const FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE = 'FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE';
export const FETCH_COMPANY_SELECTION_SUCCESS = 'FETCH_COMPANY_SELECTION_SUCCESS';
export const FETCH_COMPANY_SELECTION_FAILURE = 'FETCH_COMPANY_SELECTION_FAILURE';
// policy
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS';
export const SAVE_POLICY_SUCCESS = 'SAVE_POLICY_SUCCESS';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const GET_POLICY_FAILURE = 'GET_POLICY_FAILURE';
export const SAVE_POLICY_FAILURE = 'SAVE_POLICY_FAILURE';
export const UPDATE_POLICY_FAILURE = 'UPDATE_POLICY_FAILURE';
export const DELETE_POLICY_SUCCESS='DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_FAILURE='DELETE_POLICY_FAILURE';
// task
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const SAVE_TASK_SUCCESS = 'SAVE_TASK_SUCCESS';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';
export const SAVE_TASK_FAILURE = 'SAVE_TASK_FAILURE';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';
export const DELETE_TASK_SUCCESS='DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE='DELETE_TASK_FAILURE';
// notes
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS';
export const SAVE_NOTE_SUCCESS = 'SAVE_NOTE_SUCCESS';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const GET_NOTE_FAILURE = 'GET_NOTE_FAILURE';
export const SAVE_NOTE_FAILURE = 'SAVE_NOTE_FAILURE';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';
export const DELETE_NOTE_SUCCESS='DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILURE='DELETE_NOTE_FAILURE';
export const SEARCH_NOTE_SUCCESS='SEARCH_NOTE_SUCCESS';
export const SEARCH_NOTE_FAILURE='SEARCH_NOTE_FAILURE';
//ALERT
export const SAVE_ALERT_SUCCESS = 'SAVE_ALERT_SUCCESS';
export const SAVE_ALERT_FAILURE = 'SAVE_ALERT_FAILURE';
//COMMENT
export const SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS';
export const SAVE_COMMENT_FAILURE = 'SAVE_COMMENT_FAILURE';
//SEARCHQUOTE
export const SEARCH_QUOTE_SUCCESS = 'SEARCH_QUOTE_SUCCESS';
export const SEARCH_QUOTE_FAILURE = 'SEARCH_QUOTE_FAILURE';
export const QUOTE_LISTING_SUCCESS='QUOTE_LISTING_SUCCESS';
export const QUOTE_LISTING_FAILURE='QUOTE_LISTING_FAILURE';
//COMMUNICATION
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const SAVE_EMAIL_SUCCESS = 'SAVE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const SAVE_EMAIL_FAILURE = 'SAVE_EMAIL_FAILURE';
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE';
export const DELETE_EMAIL_SUCCESS='DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_FAILURE='DELETE_EMAIL_FAILURE';
export const SEARCH_EMAIL_SUCCESS='SEARCH_EMAIL_SUCCESS';
export const SEARCH_EMAIL_FAILURE='SEARCH_EMAIL_FAILURE';
export const GET_SMS_SUCCESS = 'GET_SMS_SUCCESS';
export const SAVE_SMS_SUCCESS = 'SAVE_SMS_SUCCESS';
export const UPDATE_SMS_SUCCESS = 'UPDATE_SMS_SUCCESS';
export const GET_SMS_FAILURE = 'GET_SMS_FAILURE';
export const SAVE_SMS_FAILURE = 'SAVE_SMS_FAILURE';
export const UPDATE_SMS_FAILURE = 'UPDATE_SMS_FAILURE';
export const DELETE_SMS_SUCCESS='DELETE_SMS_SUCCESS';
export const DELETE_SMS_FAILURE='DELETE_SMS_FAILURE';
export const SEARCH_SMS_SUCCESS='SEARCH_SMS_SUCCESS';
export const SEARCH_SMS_FAILURE='SEARCH_SMS_FAILURE';
export const GET_PHONE_SUCCESS = 'GET_PHONE_SUCCESS';
export const GET_PHONE_FAILURE = 'GET_PHONE_FAILURE';
export const SEARCH_PHONE_SUCCESS='SEARCH_PHONE_SUCCESS';
export const SEARCH_PHONE_FAILURE='SEARCH_PHONE_FAILURE';
//ATTACHMENTS
export const SAVE_UPLOAD_ATTACHMENT_SUCCESS = 'SAVE_UPLOAD_ATTACHMENT_SUCCESS';
export const SAVE_UPLOAD_ATTACHMENT_FAILURE = 'SAVE_UPLOAD_ATTACHMENT_FAILURE';
export const UPDATE_UPLOAD_ATTACHMENT_SUCCESS = 'UPDATE_UPLOAD_ATTACHMENT_SUCCESS';
export const UPDATE_UPLOAD_ATTACHMENT_FAILURE = 'UPDATE_UPLOAD_ATTACHMENT_FAILURE';
export const GET_UPLOAD_ATTACHMENTS_SUCCESS = 'GET_UPLOAD_ATTACHMENTS_SUCCESS';
export const GET_UPLOAD_ATTACHMENTS_FAILURE = 'GET_UPLOAD_ATTACHMENTS_FAILURE';
export const DELETE_UPLOAD_ATTACHMENT_SUCCESS = 'DELETE_UPLOAD_ATTACHMENT_SUCCESS';
export const DELETE_UPLOAD_ATTACHMENT_FAILURE = 'DELETE_UPLOAD_ATTACHMENT_FAILURE';
export const PREVIEW_FILE_SUCCESS='PREVIEW_FILE_SUCCESS';
export const PREVIEW_FILE_FAILURE='PREVIEW_FILE_FAILURE,';

//POLICYDETAILS
export const GET_DRIVER_FAILURE = 'GET_DRIVER_FAILURE';
export const GET_DRIVER_SUCCESS = 'GET_DRIVER_SUCCESS';


export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_POLICY_SUCCESS = 'FETCH_POLICY_SUCCESS';
export const SEARCH_POLICY_SUCCESS = 'SEARCH_POLICY_SUCCESS';
export const SEARCH_POLICY_FAILURE = 'SEARCH_POLICY_FAILURE';
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DATA_FAILURE';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_DASHBOARD_POLICY_DATA = 'SET_DASHBOARD_POLICY_DATA';

export const FETCH_FILTERED_DATA_REQUEST = 'FETCH_FILTERED_DATA_REQUEST';
export const FETCH_FILTERED_DATA_SUCCESS = 'FETCH_FILTERED_DATA_SUCCESS';
export const FETCH_FILTERED_DATA_FAILURE = 'FETCH_FILTERED_DATA_FAILURE';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const FETCH_QUOTE_FILTERED_DATA_SUCCESS = 'FETCH_QUOTE_FILTERED_DATA_SUCCESS';
export const FETCH_QUOTE_FILTERED_DATA_FAILURE = 'FETCH_QUOTE_FILTERED_DATA_FAILURE';
export const FETCH_TASK_DATA_SUCCESS = 'FETCH_TASK_DATA_SUCCESS';
export const FETCH_TASK_DATA_SUCCESS_FAILURE = 'FETCH_TASK_DATA_SUCCESS_FAILURE';
export const FETCH_TASK_FILTER_DATA_SUCCESS = 'FETCH_TASK_FILTER_DATA_SUCCESS';
export const FETCH_TASK_FILTER_DATA_FAILURE = 'FETCH_TASK_FILTER_DATA_FAILURE';
export const FETCH_ALL_POLICY_TYPE_SUCCESS = 'FETCH_ALL_POLICY_TYPE_SUCCESS';
export const FETCH_ALL_POLICY_TYPE_FAILURE = 'FETCH_ALL_POLICY_TYPE_FAILURE';
export const FETCH_POLICYTYPE_FILTERED_DATA_SUCCESS= "FETCH_POLICYTYPE_FILTERED_DATA_SUCCESS";
export const FETCH_POLICYTYPE_FILTERED_DATA_FAILURE= "FETCH_POLICYTYPE_FILTERED_DATA_FAILURE";
export const FETCH_TASK_TYPE_DATA_SUCCESS= "FETCH_TASK_TYPE_DATA_SUCCESS";
export const FETCH_TASK_TYPE_DATA_FAILURE= "FETCH_TASK_TYPE_DATA_FAILURE";
export const FETCH_FILTER_TASK_TYPE_DATA_SUCCESS = 'FETCH_FILTER_TASK_TYPE_DATA_SUCCESS';
export const FETCH_FILTER_TASK_TYPE_DATA_FAILURE = 'CFETCH_FILTER_TASK_TYPE_DATA_FAILURE';
export const FETCH_PRIORITY_TASK_DATA_SUCCESS= "FETCH_PRIORITY_TASK_DATA_SUCCESS";
export const FETCH_PRIORITY_TASK_DATA_FAILURE= "FETCH_PRIORITY_TASK_DATA_FAILURE";
export const FETCH_FILTER_PRIORITY_TASK_DATA_SUCCESS= "FETCH_FILTER_PRIORITY_TASK_DATA_SUCCESS";
export const FETCH_FILTER_PRIORITY_TASK_DATA_FAILURE= "FETCH_FILTER_PRIORITY_TASK_DATA_FAILURE";
export const UPDATE_TASK_TABLE_DATA = "UPDATE_TASK_TABLE_DATA";
export const UPDATE_TASK_TABLE_DATA_FAILURE = "UPDATE_TASK_TABLE_DATA_FAILURE";
export const SAVE_TASK_UPDATED_DATA = "SAVE_TASK_UPDATED_DATA";
export const SAVE_PRIORITY_TASK_UPDATED_DATA = "SAVE_PRIORITY_TASK_UPDATED_DATA";
export const SAVE_PRIORITY_TASK_UPDATED_DATA_FAILURE = "SAVE_PRIORITY_TASK_UPDATED_DATA_FAILURE";
export const SAVE_OPEN_TASK_UPDATED_DATA = "SAVE_OPEN_TASK_UPDATED_DATA";
export const SAVE_OPEN_TASK_UPDATED_DATA_FAILURE = "SAVE_OPEN_TASK_UPDATED_DATA_FAILURE";
export const SET_ALL_ACTIVITY = "SET_ALL_ACTIVITY";
export const DELETE_GROUP_LOCATIONS = "DELETE_GROUP_LOCATIONS";
export const ALL_LOCATION_GROUPS = "ALL_LOCATION_GROUPS";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const GET_ALL_ROLES_LIST = "GET_ALL_ROLES_LIST";
export const SAVE_PROFILE_FAILURE = "SAVE_PROFILE_FAILURE";
export const DELETE_CARRIER_GROUP = "DELETE_CARRIER_GROUP";
                
export const UPDATE_INSURANCE_CARRIER = "UPDATE_INSURANCE_CARRIER";
export const DELETE_CARRIER_LISTING_ITEM_SUCCESS = "DELETE_CARRIER_LISTING_ITEM_SUCCESS";
export const DELETE_CARRIER_LISTING_ITEM_FAILURE = "DELETE_CARRIER_LISTING_ITEM_FAILURE";
export const LOGGEDIN_USER_PROFILE_SUCCESS = "LOGGEDIN_USER_PROFILE_SUCCESS";
export const SET_THEME = "SET_THEME";
export const GET_DEFAULT_THEME_SUCCESS = 'GET_DEFAULT_THEME_SUCCESS';
export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
export const MANAGE_POLICY_ALL_DATA_SUCCESSS = 'MANAGE_POLICY_ALL_DATA_SUCCESSS';
export const MANAGE_POLICY_ALL_DATA_SUCCESSS_FAILURE = 'MANAGE_POLICY_ALL_DATA_SUCCESSS_FAILURE';
export const GET_ALL_USER_PERMISSION = 'GET_ALL_USER_PERMISSION';
export const GET_ALL_USER_PERMISSION_FAILURE = 'GET_ALL_USER_PERMISSION_FAILURE';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const GET_DRIVER_DETAIL_BY_ID = 'GET_DRIVER_DETAIL_BY_ID';
export const GET_DRIVER_DETAIL_BY_ID_FAILUER = 'GET_DRIVER_DETAIL_BY_ID_FAILUER';
export const UPDATED_DRIVER_SUCCESS = 'UPDATED_DRIVER_SUCCESS';
export const UPDATED_DRIVER_FAILURE = 'UPDATED_DRIVER_FAILURE';
export const GET_POLICY_BY_ID_SUCCESS = 'GET_POLICY_BY_ID_SUCCESS';
export const GET_POLICY_BY_ID_FAILURE = 'GET_POLICY_BY_ID_FAILURE';
export const GET_VEHICLE_DETAIL_BY_ID_SUCCESS = 'GET_VEHICLE_DETAIL_BY_ID_SUCCESS';
export const GET_VEHICLE_DETAIL_BY_ID_FAILURE = 'GET_VEHICLE_DETAIL_BY_ID_FAILURE';
export const UPDATE_VEHICLE_DETAIL_BY_ID_SUCCESS = 'UPDATE_VEHICLE_DETAIL_BY_ID_SUCCESS';
export const UPDATE_VEHICLE_DETAIL_BY_ID_FAILURE = 'UPDATE_VEHICLE_DETAIL_BY_ID_FAILURE';
export const SEARCH_POLICY_LIST_SUCCESS = 'SEARCH_POLICY_LIST_SUCCESS';
export const SEARCH_POLICY_LIST_FAILURE = 'SEARCH_POLICY_LIST_FAILURE';