import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Paper,
  useTheme,
  Checkbox,
  TablePagination,
} from '@mui/material';
import { saveLocationGroupsAction, updateLocationGroupsAction } from '../../redux/actions/locationGroupActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocationAction } from '../../redux/actions/locationActions';
import { styled } from "@mui/material/styles";

const LocationList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
   const theme = useTheme();
 
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [error, setError] = useState('');

  const locationDetails = useSelector((state) => state.location.allLocation || []);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', 
  });

  useEffect(() => {
    if (location.state) {
      setGroupName(location.state.groupName || ''); // Pre-fill group name

      const locationIds = location.state.selectedLocations.map((loc) => loc.id || loc.locationId);
      setSelectedLocationIds(locationIds);

      setIsEdit(location.state.isEdit); 
      setGroupId(location.state.groupId || null); 
    }
    dispatch(getAllLocationAction());
  }, [location.state]);

  const handleCheckboxChange = (id) => {
    setSelectedLocationIds((prev) =>
      prev.includes(id) ? prev.filter((locationId) => locationId !== id) : [...prev, id]
    );
  };

  const handleCreateOrUpdateGroupClick = () => {
    if (!groupName) {
      setError('Please provide a group name.');
      return;
    }

    if (selectedLocationIds.length === 0) {
      setError('Please select at least one location.');
      return;
    }

    // Clear any previous error messages if validation passes
    setError('');

    const groupData = {
      locationGroupName: groupName,
      locationIds: selectedLocationIds,
      status: 'Active',
    };

    const updatedGroupLocation = {
      locationGroupId: groupId,
      locationGroupName: groupName,
      locationIds: selectedLocationIds,
      status: 'Active',        
    };

    if (isEdit && groupId) {
      dispatch(updateLocationGroupsAction(updatedGroupLocation)); 
      setTimeout(() => {
        navigate('/locationgrp');
      }, 2000);
      setSnackbar({
        open: true,
        message: 'Location Group Updated successfully',
        severity: 'success',
      });
    } else {
      dispatch(saveLocationGroupsAction(groupData)); 
    }
     // Show success Snackbar after creating/updating the group
     setSnackbar({
      open: true,
      message: 'Location Group Created successfully',
      severity: 'success',
    });


   // Redirect after 3 seconds
    setTimeout(() => {
      navigate('/locationgrp');
    }, 2000);
    
    // Reset form fields
    setSelectedLocationIds([]);
    setGroupName('');
    setIsEdit(false);
    setGroupId(null);
    setError(''); 
  };

  // Close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleCancel = () => {
    setGroupName(''); // Clears the input
    setError(''); // Clears any error
    navigate('/locationgrp');
    }
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = locationDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: '20px' ,
      margin: '20px', 
      border : '1px solid  rgba(57, 57, 57, 0.1)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)' 
    }}>
      
     <Typography variant="h5" sx={{ marginBottom: 3, color: theme.palette.primary.main, }}>
        All Location List
      </Typography>

      {/* Group Name Input */}
      <Box sx={{ marginBottom: '20px' }}>
      <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "6px",
      }}
    >
      Location Group Name
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
  <TextField
    variant="outlined"
    size="small"
    value={groupName}
    onChange={(e) => {
      const value = e.target.value;
     
      if (/^[a-zA-Z0-9\s'-]*$/.test(value)) {
        setGroupName(value);
        setError('');
      } else {
        setError('Only alphanumeric characters are allowed');
      }
    }}
    error={!!error}
    helperText={error} // Display error message
    sx={{
      width: "230px",
      height: "55px", // Ensure the height is explicitly set
      "& .MuiInputBase-root": {
        height: "55px", // Apply the height to the input container
      },
    }}
  />
  <Box sx={{ display: 'flex', marginTop: "2px", marginLeft: "10px" }}>
    <Button
      variant="contained"
      className="btn-primary"
      color="primary"
      onClick={handleCreateOrUpdateGroupClick}
      sx={{ marginTop: "-5px" }}
    >
      {isEdit ? "Update Group" : "Create Group"}
    </Button>
    <Button
      variant="contained"
      className="btn-secondary"
      color="secondary"
      onClick={handleCancel}
      sx={{ marginTop: "-5px", marginLeft: "10px" }}
    >
      Cancel
    </Button>
  </Box>
</Box>

      </Box>

      {/* Location List */}
      <TableContainer component={Paper} sx={{marginTop:'40px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading"  padding="checkbox"></TableCell>
              <TableCell className="styled-table-heading">Description</TableCell>
              <TableCell className="styled-table-heading">Address</TableCell>
              <TableCell className="styled-table-heading">City</TableCell>
              <TableCell className="styled-table-heading">State</TableCell>
              <TableCell className="styled-table-heading">Zip Code</TableCell>
              <TableCell className="styled-table-heading">Phone</TableCell>
              <TableCell className="styled-table-heading">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((location, index) => (
              <TableRow key={location.id} className="styled-table-row">
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedLocationIds.includes(location.id)}
                    onChange={() => handleCheckboxChange(location.id)}
                    id={`active-${index}`}
                  />
                </TableCell>
                <TableCell>{location.locationDescription}</TableCell>
                <TableCell>{`${location.address1} ${location.address2}`}</TableCell>
                <TableCell>{location.city}</TableCell>
                <TableCell>{location.state}</TableCell>
                <TableCell>{location.zipCode}</TableCell>
                <TableCell>{location.phone}</TableCell>
                <TableCell>{location.isActive ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={locationDetails.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>


       {/* Snackbar for success message */}
       <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LocationList;
