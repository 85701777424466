import * as vehicleInfoServices from '../../services/vehicleInfoServices';
import {
  CLOSE_CREATE_VEHICLE_INFO_MODAL,
  OPEN_CREATE_VEHICLE_INFO_MODAL,
    OPEN_UPDATE_VEHICLE_INFO_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"

export const getAllVehicleInfo = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getAllVehicleInfo(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const getVehicleInfoById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicleInfoById(quoteId);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const createVehicleInfo = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_VEHICLE_INFO' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.createVehicleInfo(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_VEHICLE_INFO' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_VEHICLE_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'CREATE_VEHICLE_INFO' });
    dispatch({ type: 'CREATE_VEHICLE_INFO_FAILURE', payload: error?.body?.message || 'Create VehicleInfo service failed. Please try again.' });
  }
};

export const updateVehicleInfo = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_VEHICLE_INFO' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.updateVehicleInfo(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_VEHICLE_INFO' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_VEHICLE_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'UPDATE_VEHICLE_INFO' });
    dispatch({ type: 'UPDATE_VEHICLE_INFO_FAILURE', payload: error?.body?.message || 'Update VehicleInfo service failed. Please try again.' });
  }
};

export const openCreateVehicleInfoModal = () => ({
  type: OPEN_CREATE_VEHICLE_INFO_MODAL,
});

export const closeCreateVehicleInfoModal = () => ({
  type: CLOSE_CREATE_VEHICLE_INFO_MODAL,
});

export const openUpdateVehicleInfoModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_VEHICLE_INFO_MODAL, payload: id });
};

export const getVehicleInfoByVIN = (vin) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    return await vehicleInfoServices.getVehicleInfoByVIN(vin);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    // dispatch({ type: 'ALL_VEHICLE_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const deleteVehicleInfoById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    return await vehicleInfoServices.deleteVehicleInfoById(quoteId,token)
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const saveVehicleAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token|| sessionStorage.getItem("token");
    // console.log(formData,"raushan");
    const response = await vehicleInfoServices.saveVehicle(formData, token);
    
    if(response) {
      callbackfn(response);
    }
    dispatch({ type: 'SAVE_VEHICLE_INFO_SUCCESS', payload: response });
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'SAVE_VEHICLE_INFO_FAILURE', payload: error?.body?.message || 'Save vehicle service failed. Please try again.' });
  }
};

export const getVehicleAction= () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicle(token);
    // console.log("response value of vehicle",response);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE' });
    dispatch({ type: 'GET_VEHICLE_SUCCESS', payload: response });
  } catch (error) {

      dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE' });
      dispatch({ type: 'GET_VEHICLE_FAILURE', payload: error || 'All driver failed. Please try again.' });
  }
};

export const saveVehicleCoverageAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'SAVE_VEHICLE_INFO' });
    const token = getState().auth.token|| sessionStorage.getItem("token");
    // console.log(formData,"raushan");
    const response = await vehicleInfoServices.saveVehicleCoverage(formData, token);
    dispatch({ type: STOP_LOADING, service: 'SAVE_VEHICLE_INFO' });
    if(response) {
      callbackfn(response);
    }
    dispatch({ type: 'SAVE_VEHICLE_COVERAGE_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'SAVE_VEHICLE_INFO' });
    dispatch({ type: 'SAVE_VEHICLE_COVERAGE_INFO_FAILURE', payload: error?.body?.message || 'Save vehicle service failed. Please try again.' });
  }
};

export const getVehicleCoverageAction= () => async (dispatch, getState) => {
  // console.log("in policydriver action");
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehiclecoverage(token);
    // console.log("response value of vehicle",response);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE' });
    dispatch({ type: 'GET_VEHICLE_COVERAGE_SUCCESS', payload: response });
  } catch (error) {

      dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE' });
      dispatch({ type: 'GET_VEHICLE_COVERAGE_FAILURE', payload: error || 'All Vehicle failed. Please try again.' });

  }
};

//YMS
export const getVehicleInfoActionByYmsYears = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicleInfoByYmsYears(token);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_YEARS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_YEARS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const getVehicleInfoActionByYmsMakes = (year) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicleInfoByYmsMakes(year,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_MAKES_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_MAKES_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const getVehicleInfoActionByYmsModels = (year,makes) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicleInfoByYmsModels(year,makes,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_MODELS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_MODELS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const getVehicleInfoActionByYmsStyles = (year,makes,models) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicleInfoByYmsStyles(year,makes,models,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_STYLES_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_STYLES_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const getVehicleInfoActionByYmsVins = (year,makes,models,styles) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_VEHICLE_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await vehicleInfoServices.getVehicleInfoByYmsVins(year,makes,models,styles,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_VINS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_VEHICLE_INFOS' });
    dispatch({ type: 'ALL_VEHICLE_INFOS_VINS_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};

export const getVehicleInfoByIdAction = (id, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response =  await vehicleInfoServices.getVehicleDetailById(token, id)
    if(response){
      callbackfn(response)
    }
    dispatch({ type: 'GET_VEHICLE_DETAIL_BY_ID_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'GET_VEHICLE_DETAIL_BY_ID_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};


export const VehicleUpdateAction = (formdata) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response =  await vehicleInfoServices.vehicleUpdateService(token, formdata)
    dispatch({ type: 'UPDATE_VEHICLE_DETAIL_BY_ID_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'GET_VEHICLE_DETAIL_BY_ID_FAILURE', payload: error.message || 'All VehicleInfos service failed. Please try again.' });
  }
};