import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import PrevNextButton from "../../common/PrevNextButton";
import {
  Button,
  TextField,
  Box,
  Container,
  Snackbar,
  Alert,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import {
  alphanumericWithSpaceValidation,
  validateField,
} from "../../common/helpers/validation";
import { getAllUser as getAllUserAction } from "../../redux/actions/userActions";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  createDriverInfo as createDriverInfoAction,
  getAllDriverInfo,
  updateDriverInfo,
} from "../../redux/actions/driverInfoActions";
import { CircleOutlined } from "@mui/icons-material";
import {
  BooleanYesNo,
  CityList,
  CommonYesNo,
  DefensiveDriver,
  DriverLicenseStatus,
  DriverType,
  Fileintype,
  GoodStudent,
  Industry,
  MonthList,
  Relationship,
  RelationshipToInsured,
  VoilationType,
  blankOptions,
  foreignLicensed,
  genderList,
  martialStatus,
  driverLicenseState,
  YearList,
  priorInsurance,
  priorTransferLevel,
  residenceType,
  sr22,
  sr22ReasonFiling,
  stateList,
  timewithPriorInsurance,
} from "../../common/StaticFieldList";
import { populateValue } from "../../common/helpers/utils";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import SearchableSelect from "../../common/SearchableSelect";

const requiredFields = [
  "driverType",
  "dob",
  "firstName",
  "marital",
  "relationshipToInsured",
  "driverTrainingDate",
  "gender"
];



const ViolationsRules = [
  {
    fieldName: "violation",
    label: "Select Violation",
    type: "select",
    option: VoilationType,
    id:"violation"
  },
  {
    fieldName: "timeSinceYear",
    label: "Years",
    group: true,
    type: "number",
    groupLabel: "Time Since",
    id:"timesinceyear"
  },
  {
    fieldName: "timeSinceMonth",
    label: "Months",
    group: true,
    type: "select",
    option: MonthList,
    id:"timesincemonth"
  },
  {
    fieldName: "occDate",
    label: "Occ Date",
    type: "text",
    id:"occdate"
  },
  {
    fieldName: "amt1",
    label: "Amt1",
    group: true,
    type: "number",
    id:"amt1"
  },
  {
    fieldName: "amt2",
    label: "Amt2",
    group: true,
    type: "number",
    addButton: true,
    id:"amt2"
  },
];

const validationRules = [
  {
    fieldName: "driverType",
    label: "Driver Type",
    type: "select",
    option: DriverType,
    id:"drivertype"
  },
  { fieldName: "dob", label: "Date of Birth", type: "text",id:"dob" },
  { fieldName: "firstName", label: "First Name", type: "text",id:"Firstname" },
  { fieldName: "lastName", label: "Last Name", type: "text" ,id:"lastname"},
  { fieldName: "gender", label: "Gender", type: "select", option: genderList,id:"gender" },
  {
    fieldName: "relationshipToInsured",
    label: "Relationship",
    type: "select",
    option: RelationshipToInsured,
    id:"relationshiptoinsured"
  },
  {
    fieldName: "marital",
    label: "Marital Status",
    type: "select",
    option: martialStatus,
    id:"marital"
  },
  { fieldName: "driversLicenseNumber", label: "Driver License", type: "text",id:"driverlicensenumber" },
  {
    fieldName: "driversLicenseState",
    label: "Driver License State",
    type: "select",
    option: driverLicenseState,
    id:"driverlicensestate"
  },
  {
    fieldName: "dateFirstLicensedInUsa",
    label: "Year Licensed in USA",
    // type: "number",
    type: "select",
    option: YearList,
    id:"driverlicensedinusa"
  },
  {
    fieldName: "driverTrainingDate",
    label: "Driver Training Date",
    type: "text",
    id:"drivertrainingdate"
  },
  {
    fieldName: "driverLicenseDate",
    label: "Driver License Date",
    type: "text",
    id:"driverlicensedate"
  },
  {
    fieldName: "sr22",
    label: "File in type",
    type: "select",
    option: Fileintype,
    id:"sr22"
  },
  {
    fieldName: "sr22StateCaseNumber",
    label: "File in Case Number ",
    type: "number",
    id:"sr22statecasenumber"
  },
  {
    fieldName: "BusinessOwner",
    label: "Business Owner ",
    type: "select",
    option: CommonYesNo,
    id:"BusinessOwner"
  },
  {
    fieldName: "Occupation",
    label: "Occupation",
     type: "text",
    id:"Occupation"
  },
  {
    fieldName: "industry",
    label: "Industry ",
     type: "text",
    id:"industry"
  },

];


const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },
  fullWidth: {
    width: "100%",
  },
}));

const DriverSave = ({
  setShowHideDriverForm,
  setshowbutton,
  driverData,
  handleNext,
  id
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { quoteId } = useParams();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // const [inputError, setInputError] = useState('');
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector(
    (state) => state?.user?.isCreateUserModalOpen
  );
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(isModalOpen);
  const newFieldArg = [...validationRules];

  const [formState, setFormState] = useState({
    formData: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        // [fieldName]: "",
        [fieldName]: fieldName === "sr22" ? "No" : "",
      }),
      {}
    ),
    touchedFields: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }),
      {}
    ),
    fieldErrors: newFieldArg.reduce(
      (errors, { fieldName }) => ({
        ...errors,
        [fieldName]: "",
      }),
      {}
    ),
  });

  const [violationShow, setViolationShow] = useState(false);
  const [violationState, setViolationState] = useState({
    violationData: ViolationsRules.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: "",
      }),
      {}
    ),
    violationtouchedFields: ViolationsRules.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }),
      {}
    ),
    violationfieldErrors: ViolationsRules.reduce(
      (errors, { fieldName }) => ({
        ...errors,
        [fieldName]: "",
      }),
      {}
    ),
  });



  const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);
  const customerDataForUpdate =
    allUser &&
    allUser.length &&
    allUser.filter((user) => user.id === selectedUserId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const { formData, touchedFields, fieldErrors } = formState;
  const { violationData, violationtouchedFields, violationfieldErrors } =
    violationState;

  const validationFunctions = {
    firstName: alphanumericWithSpaceValidation,
    lastName: alphanumericWithSpaceValidation,
    dob: (value) => {
      // If value is null, undefined, or empty string
      if (!value || value.trim() === '') {
        return "Date of Birth is required";
      }

      // Parse the date using dayjs
      const dobDate = dayjs(value);
      const today = dayjs();
      const minDate = dayjs("1940-01-01");
      const maxDate = dayjs("2008-04-04"); // Adding max date validation

      // Check if the date is valid
      // if (!dobDate.isValid()) {
      //   return "Please enter a valid date";
      // }

      // Check if date is in the future
      if (dobDate.isAfter(today)) {
        return "Date of Birth cannot be in the future";
      }

      // Check if date is before minimum allowed date
      if (dobDate.isBefore(minDate)) {
        return "Date of Birth must be after January 1, 1940";
      }

      // Check if date is after maximum allowed date
      if (dobDate.isAfter(maxDate)) {
        return "Date of Birth must be before April 4, 2008";
      }

      return ""; // Return empty string if validation passes
    }
  };

  // Reusable date handling function
const handleDateChange = (date, fieldName, validationFunc) => {
  const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";
  const validationError = validationFunc ? validationFunc(formattedDate) : "";

  // Update form state
  setFormState((prevState) => ({
    ...prevState,
    [fieldName]: formattedDate,
    fieldErrors: {
      ...prevState.fieldErrors,
      [fieldName]: validationError,
    },
  }));

  // Synthetically trigger input change
  const customEvent = {
    preventDefault: () => {},
    target: {
      name: fieldName,
      value: formattedDate,
    },
  };

  handleInputChange(customEvent);
};


const getFieldBorderColor = (fieldName) => {
  // Check if the field is either driverTrainingDate or driverLicenseDate
  if (fieldName === "driverTrainingDate" || fieldName === "driverLicenseDate") {
    // Check if the field is empty and has been touched or is invalid
    if (formState.formData[fieldName] === "" && formState.touchedFields[fieldName]) {
      // Apply red border if it's empty and touched
      return "red";
    }
  }

  // Return grey border for other cases or untouched fields
  return "grey";
}


const handleDateChangedob = (date, fieldName) => {
  // Always show validation error when the field is empty
  if (!date) {
    setFormState(prevState => ({
      ...prevState,
      [fieldName]: "", // Clear the field value
      fieldErrors: {
        ...prevState.fieldErrors,
        [fieldName]: "Date of Birth is required"
      }
    }));
    return;
  }

  // Format the date
  const formattedDate = dayjs(date).format("MM-DD-YYYY");

  // Create a synthetic event
  const customEvent = {
    preventDefault: () => {},
    target: {
      name: fieldName,
      value: formattedDate,
    },
  };

  // Always validate the date
  const validationError = validationFunctions.dob(formattedDate);

  // Update form state
  setFormState(prevState => ({
    ...prevState,
    [fieldName]: formattedDate,
    fieldErrors: {
      ...prevState.fieldErrors,
      [fieldName]: validationError
    }
  }));

  // Call handleInputChange with the custom event object
  handleInputChange(customEvent);
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'driverType') {
      setFormState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
          // If driverType is Insured, set relationshipToInsured to "IN"
          relationshipToInsured: value === 'Insured' ? 'IN' : prevState.formData.relationshipToInsured
        },
      }));
    } else {
      // For all other fields, handle normally unless it's relationshipToInsured and driverType is "Insured"
      if (name === 'relationshipToInsured' && formState.formData.driverType === 'Insured') {
        // Don't update if driverType is Insured
        return;
      }
      setFormState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }));
    }
  };

  // Add helper function to check if driver type is Insured
  const isDriverTypeInsured = (driverType) => {
    return driverType === 'Insured';
  };


  const handleFieldBlur = (fieldName) => {
    const validationError = validationFunctions[fieldName]?.(formData[fieldName]);

    setFormState((prevState) => ({
      ...prevState,
      touchedFields: {
        ...prevState.touchedFields,
        [fieldName]: true,
      },
      fieldErrors: {
        ...prevState.fieldErrors,
        [fieldName]: validationError,
      },
    }));
  };



  const handleBlur = (field) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(
      value,
      newFieldArg.find((rule) => rule.fieldName === field).label
    );

    // Additional validations
    const additionalError = validationFunctions[field]
      ? validationFunctions[field](value)
      : "";

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleInputChangeVio = (e) => {
    const { name, value } = e.target;
    setViolationState((prevState) => ({
      ...prevState,
      violationData: {
        ...prevState.violationData,
        [name]: value,
      },
    }));
  };

  const handleBlurVio = (field) => {
    setViolationState((prevFormState) => ({
      ...prevFormState,
      violationtouchedFields: {
        ...prevFormState.violationtouchedFields,
        [field]: true,
      },
    }));

    // if (!requiredFields.includes(field)) {
    //   return;
    // }

    const value = formData[field];
    const error = validateField(
      value,
      ViolationsRules.find((rule) => rule.fieldName === field).label
    );

    // Additional validations
    const additionalError = validationFunctions[field]
      ? validationFunctions[field](value)
      : "";

    setViolationState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.violationfieldErrors,
        [field]: error || additionalError,
      },
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Run validation for required fields
    const validationErrors = newFieldArg.reduce((errors, { fieldName, label }) => {
      if (requiredFields.includes(fieldName)) {
        const value = formData[fieldName];
        const error = validateField(value, label);
        const additionalError = validationFunctions[fieldName]
          ? validationFunctions[fieldName](value)
          : "";

        return {
          ...errors,
          [fieldName]: error || additionalError,
        };
      }
      return errors;
    }, {});

    // Update state with all validation errors
    setFormState((prevState) => ({
      ...prevState,
      fieldErrors: validationErrors,
      touchedFields: Object.keys(validationFunctions).reduce(
        (fields, fieldName) => ({ ...fields, [fieldName]: true }),
        {}
      ),
    }));

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        // Format all necessary dates
        const formattedLicenseDate = formData.driverLicenseDate
        ? dayjs(formData.driverLicenseDate, ["YYYY-MM-DD", "MM-DD-YYYY", "MM/DD/YYYY"], true).isValid()
          ? dayjs(formData.driverLicenseDate, ["YYYY-MM-DD", "MM-DD-YYYY", "MM/DD/YYYY"], true).format("MM-DD-YYYY")
          : ""
        : "";

      // Format driverTrainingDate with strict parsing
      const formattedTrainingDate = formData.driverTrainingDate
        ? dayjs(formData.driverTrainingDate, ["YYYY-MM-DD", "MM-DD-YYYY", "MM/DD/YYYY"], true).isValid()
          ? dayjs(formData.driverTrainingDate, ["YYYY-MM-DD", "MM-DD-YYYY", "MM/DD/YYYY"], true).format("MM-DD-YYYY")
          : ""
        : "";

        // Construct payload for API call
        const newFormData = {
          ...formData,
          driverLicenseDate: formattedLicenseDate,
          driverTrainingDate: formattedTrainingDate,
          gpId: id ? parseInt(id) : parseInt(quoteId),
          ...(driverData.id && { did: driverData.id }), // Conditionally add 'did'
        };

        // Dispatch the appropriate action
        if (driverData.id) {
          await dispatch(updateDriverInfo(newFormData, getCreateUserSuccess));
        } else {
          console.log("newFormData",newFormData);
          await dispatch(createDriverInfoAction(newFormData, getCreateUserSuccess));
        }
      } catch (error) {
        handleSnackbarOpen(
          error.message || "User service failed. Please try again.",
          "error"
        );
      }
    } else {
      handleSnackbarOpen("Validation errors detected. Please correct them.", "error");
    }
  };



  const getCreateUserSuccess = async () => {
    // selectedUserId
    //   ? handleSnackbarOpen("User updated successfully.", "success")
    //   :
    handleSnackbarOpen("Driver created successfully.", "success");
    setShowHideDriverForm(false);
    if(quoteId)
    {
    dispatch(getAllDriverInfo(quoteId));
    }
    if(id)
    {
      dispatch(getAllDriverInfo(id));
    }

    setFormState({
      formData: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: "",
        }),
        {}
      ),
      touchedFields: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: false,
        }),
        {}
      ),
      fieldErrors: newFieldArg.reduce(
        (errors, { fieldName }) => ({
          ...errors,
          [fieldName]: "",
        }),
        {}
      ),
    });
  };
  // console.log("driverData", driverData);


  const getDefaultFieldValue = (fieldName, driverData, fieldArgs) => {
    // Special case for driverType - always default to "Insured" if no value exists
    if (fieldName === 'driverType') {
      return driverData?.[fieldName] || 'Insured';
    }

    // For relationshipToInsured, set to "IN" if driverType is "Insured"
    if (fieldName === 'relationshipToInsured') {
      const driverType = driverData?.driverType || 'Insured';
      if (driverType === 'Insured') {
        return 'IN';
      }
    }

    if (fieldName === 'dob') {
      const rawDOB = driverData?.[fieldName];
      // console.log('Raw DOB from driver data:', rawDOB);

      if (!rawDOB) {
        return '';
      }

      // Specify common date formats that could be returned
      const parsedDate = dayjs(rawDOB, ["MM-DD-YYYY", "YYYY-MM-DD", "DD-MM-YYYY"], true);
      if (!parsedDate.isValid()) {
        // console.log('Invalid date format received:', rawDOB);
        return '';
      }

      // Format to MM-DD-YYYY for the form field
      const formattedDate = parsedDate.format('MM-DD-YYYY');
      // console.log('Formatted DOB for prefill:', formattedDate);
      return formattedDate;
    }

    // For all other fields, use the existing logic
    return (driverData && populateValue(driverData[fieldName], fieldName, fieldArgs)) || '';
  };


  const shouldShowError = (fieldName) =>
    touchedFields[fieldName] && !!fieldErrors[fieldName];


  useEffect(() => {
    const updatedFormData = newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: getDefaultFieldValue(fieldName, driverData, newFieldArg),
      }),
      {}
    );

    // Initialize fieldErrors without premature validation
    const initialFieldErrors = {};

    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: updatedFormData,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        ...initialFieldErrors, // Do not populate initial errors for untouched fields
      },
      touchedFields: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: false, // Mark all fields as untouched initially
        }),
        {}
      ),
    }));
  }, [driverData]);



  return (
    <>

      <Box

        sx={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "36px",
          maxWidth: "100%",
        }}
      >
        {/* <FrameComponent1 /> */}
        <section

          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 23px 0px 20px",
            boxSizing: "border-box",
            maxWidth: "100%",
            textAlign: "left",
            fontSize: "14px",
            color: "#242929",
            fontFamily: "Poppins",
          }}
        >
          <Box
          id="Form"
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: "853px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              border: "1px solid #e3e3e3",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "30px 0px 24px",
              gap: "23px",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                alignSelf: "stretch",
                height: "798px",
                position: "relative",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "none",
              }}
            />

            <Typography
            id="driverinformation"
              variant="h1" // Adjust the variant as needed, e.g., h1, h2, h3, etc.
              component="div"
              sx={{
                border: "none",
                backgroundColor: "transparent",
                alignSelf: "stretch",
                height: "51px",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "600",
                fontSize: "25px",
                color: "#242929",
                paddingLeft: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Driver Information
            </Typography>
            <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
            <Box
              sx={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                padding: "0px 44px 0px 52px",
                boxSizing: "border-box",
                maxWidth: "100%",
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    minWidth: "240px",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "stretch",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "5px 20px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                    }}
                  >
                  {validationRules.map(
  ({ fieldName, label, type, option }, index) => (
    <Box
      key={index}
      sx={{
        width: "100%",
        margin: "0 0 15px 0",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Typography
        key={fieldName} // Assign a unique key to each element
        variant="body1"
        sx={{
          position: "relative",
          lineHeight: "20px",
          fontWeight: "500",
          display: "inline-block",
          zIndex: "1",
          marginBottom: "10px",
          fontFamily: '"Poppins", sans-serif',
        }}
      >
        {label}
      </Typography>
      {fieldName === "dob" ? (
       <LocalizationProvider dateAdapter={AdapterDayjs}>
       <DatePicker
         format="MM-DD-YYYY"
          value={
              formData[fieldName]
                ? dayjs(formData[fieldName], "MM-DD-YYYY").isValid()
                  ? dayjs(formData[fieldName], "MM-DD-YYYY")
                  : null
                : null
            }
         required={true}
         maxDate={dayjs("2008-04-04")}
         minDate={dayjs("1940-01-01")}
         onChange={(date) => {
           handleDateChange(date, "dob", validationFunctions.dob);
           setFormState((prevState) => ({
             ...prevState,
             touchedFields: {
               ...prevState.touchedFields,
               dob: true,
             },
           }));
         }}
         onBlur={() => {
           setFormState((prevState) => ({
             ...prevState,
             touchedFields: {
               ...prevState.touchedFields,
               dob: true,
             },
             fieldErrors: {
               ...prevState.fieldErrors,
               dob: validationFunctions.dob(formData.dob),
             },
           }));
         }}
         slotProps={{
           textField: {
             size: "medium",
             inputProps: {
               placeholder: "MM-DD-YYYY",
               autoComplete: "off",
               inputMode: "date",
               pattern: "\\d{2}-\\d{2}-\\d{4}",
               "-webkit-appearance": "none",
               "-moz-appearance": "textfield"
             },
             sx: {
               width: 370,
               border: "none",
               backgroundColor: "transparent",
               alignSelf: "stretch",
               fontFamily: '"Poppins", sans-serif',
               fontWeight: "500",
               fontSize: "16px",
               color: "#242929",
               zIndex: "1",
               "& .MuiOutlinedInput-root": {
                 "& fieldset": {
                   borderColor: "#242929 !important",
                 },
                 "& input": {
                   "-webkit-appearance": "none",
                   "-moz-appearance": "textfield",
                   "&::-webkit-calendar-picker-indicator": {
                     display: "none"
                   },
                   "&::-webkit-inner-spin-button": {
                     "-webkit-appearance": "none",
                     display: "none"
                   },
                   "&::-webkit-clear-button": {
                     display: "none"
                   }
                 }
               }
             },
             error: shouldShowError("dob"),
             helperText: shouldShowError("dob") ? fieldErrors.dob : "",
           },
           popper: {
             sx: {
               zIndex: 9999,
               "& .MuiPaper-root": {
                 fontFamily: '"Poppins", sans-serif',
               }
             }
           }
         }}
       />
     </LocalizationProvider>
      ) : fieldName === "driverTrainingDate" || fieldName === "driverLicenseDate" ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="MM-DD-YYYY"
          // value={formData[fieldName] ? dayjs(formData[fieldName], "MM-DD-YYYY") : null}
           value={
                formData[fieldName]
                  ? dayjs(formData[fieldName], "MM-DD-YYYY").isValid()
                    ? dayjs(formData[fieldName], "MM-DD-YYYY")
                    : null
                  : null
              }
          onChange={(date) => handleDateChange(date, fieldName)}
          slotProps={{
            textField: {
              size: "medium",
              inputProps: {
                placeholder: "MM-DD-YYYY",
                autoComplete: "off",
                inputMode: "date",
                pattern: "\\d{2}-\\d{2}-\\d{4}",
                "-webkit-appearance": "none",
                "-moz-appearance": "textfield"
              },
              sx: {
                width: 370,
                border: "none",
                alignSelf: "stretch",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "500",
                fontSize: "16px",
                color: "#242929",
                zIndex: "1",
                "&:focus": {
                  borderColor: "#000000 !important",
                  color: "#fff",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#C1C1C1 !important",
                  },
                  "& input": {
                    "-webkit-appearance": "none",
                    "-moz-appearance": "textfield",
                    "&::-webkit-calendar-picker-indicator": {
                      display: "none"
                    },
                    "&::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      display: "none"
                    },
                    "&::-webkit-clear-button": {
                      display: "none"
                    }
                  }
                }
              },
              error: !!formState.fieldErrors[fieldName],
              helperText: formState.fieldErrors[fieldName] || "",
              required: true,
            },
            popper: {
              sx: {
                zIndex: 9999,
                "& .MuiPaper-root": {
                  fontFamily: '"Poppins", sans-serif',
                }
              }
            }
          }}
        />
      </LocalizationProvider>

      ) : fieldName === "driversLicenseState" ? (
        <SearchableSelect
          options={stateList}
          value={formData[fieldName]}
          onChange={handleInputChange}
          onBlur={() => handleBlur(fieldName)}
          name={fieldName}
          label={label}
          placeholder={label}
          required={requiredFields.includes(fieldName)}
          error={touchedFields[fieldName] && !!fieldErrors[fieldName]}
          helperText={touchedFields[fieldName] ? fieldErrors[fieldName] : ""}

        />
      ): (
        <TextField
          style={{
            border: "none",
            backgroundColor: "transparent",
            alignSelf: "stretch",
            height: "52px",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "500",
            fontSize: "14px",
            color: "#242929",
            zIndex: "1",
          }}
          type={type}
          variant="outlined"
          select={type === "select"}
          InputProps={{
            style: { height: 52, fontSize: 16 },
            inputProps: {
              min: type === "number" ? "0" : "",
              maxLength: 50,
            },
            readOnly: fieldName === "relationshipToInsured" &&
                     isDriverTypeInsured(formState.formData.driverType)
          }}
          required={requiredFields.includes(fieldName)}
          id={fieldName}
          placeholder={label}
          name={fieldName}
          autoComplete={fieldName}
          value={formData[fieldName]}
          onChange={(e) => {
            if (type === "number" && e.target.value.length > 10) {
              return;
            }
            if (
              (fieldName === "firstName" || fieldName === "lastName" || fieldName === "sr22StateCaseNumber" || fieldName === "driversLicenseNumber") &&
              !/^[A-Za-z0-9\s]*$/.test(e.target.value)
            ) {
              return;
            }
            handleInputChange(e);
          }}
          error={touchedFields[fieldName] && !!fieldErrors[fieldName]}
          helperText={touchedFields[fieldName] ? fieldErrors[fieldName] : ""}
          disabled={fieldName === "relationshipToInsured" &&
                   isDriverTypeInsured(formState.formData.driverType)}
          sx={{
            "& fieldset": {
              borderColor: "#C1C1C1",
              fontFamily: '"Poppins", sans-serif',
            },
            "& .MuiInputBase-root": {
              height: "52px",
              backgroundColor: "#fff",
              fontFamily: '"Poppins", sans-serif',
              fontSize: "14px",
              "&.Mui-disabled": {
                backgroundColor: "#f5f5f5",
                "& fieldset": {
                  borderColor: "#C1C1C1 !important",
                }
              }
            },
            "& .MuiInputBase-input": {
              color: "#242929",
              fontFamily: '"Poppins", sans-serif',
            },
          }}
        >
          {type === "select" &&
            option.map((option) => (
              <MenuItem
                key={option.value}
                placeholder={label}
                value={option.value}
              >
                <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                  {option.label}
                </Box>
              </MenuItem>
            ))}
        </TextField>
      )}
    </Box>
  )
)}

                  </Box>
                </Box>


              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
              id="Cancel-button"
                //   variant="contained"
                sx={{ mt: 3, mb: 2, marginRight: "1rem" }}
                onClick={() => setShowHideDriverForm(false)}
                className="btn-secondary"
              >
                Cancel
              </Button>
              <Button
              id="savebutton"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, marginRight: "1rem" }}
                className="btn-secondary"
              >
                {loading ? (
                  <CircleOutlined size="24" color="secondary" />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
            {/* )} */}
          </Box>
          {!setshowbutton && (
            <>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: "20px 20px 50px 23px",
                }}
              >
                <Button
                id="continuebutton"
                  style={{ height: "42px", width: "140px", cursor: "pointer" }}
                  disableElevation={true}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    fontSize: "16",
                    background: "#077BFD",
                    borderRadius: "4px",
                    "&:hover": { background: "#077BFD" },
                    width: 140,
                    height: 42,
                  }}
                  className="btn-primary"
                  onClick={handleNext}
                >
                  Continue
                </Button>
              </div>
            </>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </section>
      </Box>
      {/* </Box> */}
    </>
  );
};

export default DriverSave;
