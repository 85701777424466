import { payPlanOptionIns } from "../common/StaticFieldList";
import apiService from "./apiServices";

export const getUser = async (token) => {
  try {
    const response = await apiService('/users/login-user', 'POST', null, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'Get user services failed. Please try again.';
  }
}
export const createUser = async (formData, token) => {
  try {

    const response = await apiService('/users/add-user', 'POST', formData, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'Creating user is failed. Please try again.';
  }
}

export const updateUser = async (formData, token) => {
  try {

    const response = await apiService('/users/update-user', 'PUT', formData, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'Updating user is failed. Please try again.';
  }
}

export const getUserById = async (userId, token) => {
  try {

    const response = await apiService(`/users/userId/${userId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'service is failed. Please try again.';
  }
}

export const getAllUser = async (customerId, token) => {
  try {

    const response = await apiService(`/users/all-users/customer/${customerId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'service is failed. Please try again.';
  }
}

export const getRates = async (data, quoteId, token = "") => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/${quoteId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get user services failed. Please try again.');
  }
}

// export const postRatesPearl = async (data, quoteId, token) => {
//   try {
//     // const headers = {
//     //   'Access-Control-Request-Headers': '*',
//     // };
//     const response = await apiService(`/soapConsume/responsive`, 'POST', data, null, token);
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'Get user services failed. Please try again.');
//   }
// }

export const postRatesPearl = async (data, company, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/${company}`, 'POST', data, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get user services failed. Please try again.');
  }
}

export const postRatesPearlHolding = async (data, quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/pearl`, 'POST', data, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get pearl services failed. Please try again.');
  }
}


export const postRatesUnited = async (data, quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/united`, 'POST', data, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get user services failed. Please try again.');
  }
}

// export const getAllRates = async (gpId,planOptions,term,companyName, token = "") => {
//   try {
//     // const headers = {
//     //   'Access-Control-Request-Headers': '*',
//     // };
//     const response = await apiService(`/comparison/companies/${gpId}/${planOptions}/${term}/${companyName}`, 'GET', null, null, token);
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'Get rates services failed. Please try again.');
//   }
// }

export const getAllRates = async (quoteId, planOptions, term, companyName, token = "") => {
  if (!quoteId || !planOptions || !term || !companyName) {
    throw new Error('Missing required parameters for getting rates');
  }

  try {
    const response = await apiService(
      `/comparison/companies/${quoteId}/${planOptions}/${term}/${companyName}`,
      'GET',
      null,
      null,
      token
    );
    return response;
  } catch (error) {
    console.error('Error in getAllRates:', error);
    throw new Error(
      error.response?.data?.message ||
      error.message ||
      'Get rates services failed. Please try again.'
    );
  }
}

export const getAllVehicleDetails = async (quoteId,planOptions,term,companyName, token = "") => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/comparison/VehDetails/${quoteId}/${planOptions}/${term}/${companyName}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get rates services failed. Please try again.');
  }
}


export const getEFTById = async (gpId, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    // console.log("token",token);
    const response = await apiService(`/comparison/OtherEFT/${gpId}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getInstallment = async (quoteId,planOptions,term,companyName, token = "") => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/comparison/installmentDetails/${quoteId}/${planOptions}/${term}/${companyName}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get installment services failed. Please try again.');
  }
}