
import React, { useState, useEffect } from 'react';
import { Container, Collapse, Grid, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, TablePagination,Select, MenuItem, Button, InputLabel, FormControl ,Dialog,DialogActions,
  DialogContent,
  DialogTitle, Snackbar, Alert
  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Sms from './Sms';
import Phone from './Phone';
import { getAllEmailAction, searchAction,getAllSmsAction,searchSmsAction,getAllPhoneAction,searchPhoneAction , sendMessageAction} from '../../../redux/actions/communicationAction';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';



const poppinsFont = {
  fontFamily: '"Poppins", sans-serif'
};

// Styled component for table header cells
const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: '700', // Bold font weight for header cells
  backgroundColor: '#1565c0', // Header background color
  color: '#ffffff', // White text color
  fontFamily: '"Poppins", sans-serif',
  textAlign: 'center', // Center text alignment
  padding: '10px !important', // Custom padding for header cells
}));

const StyledTable = styled(Table)({
  borderCollapse: 'collapse', // Ensure no gaps between rows or cells
  width: '100%', // Optional: Ensure table takes full width
});

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5', // Grey background for odd rows
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#ffffff', // White background for even rows
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif', // Applying Poppins font
  textAlign: 'center', // Aligning text to center
  padding: '8px', // Customize padding for the cell
  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Optional: Thin border
  margin: 0, // Ensure no extra margin
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1565c0', // Match header background color
  color: '#ffffff', // White text color
  fontFamily: '"Poppins", sans-serif', // Match font
  textTransform: 'none', // Prevent uppercase text
  padding: '10px 20px', // Add padding
  '&:hover': {
    backgroundColor: '#104a8a', // Darker blue on hover
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap', // Ensure responsiveness
  marginBottom: theme.spacing(3), // Add spacing at the bottom
}));

const StyledFormControl = styled(FormControl)({
  minWidth: '200px', // Consistent width for dropdowns
});

const CommunicationTable = ({policyId}) => {
  // State management
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [subject, setSubject] = useState("");
  const [communicationType, setCommunicationType] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Pagination states
  const [emailPage, setEmailPage] = useState(0);
  const [rowsPerPageEmail, setRowsPerPageEmail] = useState(5);
  const [smsPage, setSmsPage] = useState(0);
  const [rowsPerPageSms, setRowsPerPageSms] = useState(5);
  const [phonePage, setPhonePage] = useState(0);
  const [rowsPerPagePhone, setRowsPerPagePhone] = useState(5);

  // Redux selectors
  const emails = useSelector((state) => state.communication?.allEmail?.content || []);
  const smspagination = useSelector((state) => state.communication?.allSms || []);
  const phonespagination = useSelector((state) => state.communication?.allPhones?.content || []);
  const searchList = useSelector((state) => state.communication?.allSearch || []);
  const searchdatas=searchList?.content || [];
  // console.log("searchdatas",searchList)

  const emailsPagination = useSelector((state) => state.communication?.allEmail || { totalElements: 0, page: 0 });
  const smsPagination = useSelector((state) => state.communication?.allSms || { totalElements: 0, page: 0 });
  const phonesPagination = useSelector((state) => state.communication?.allPhones || { totalElements: 0, page: 0 });

  const dispatch = useDispatch();

  // Filtered data states
  const [emailData, setEmailData] = useState([]);
  const [smsData, setSmsData] = useState([]);
  const [phoneData, setPhoneData] = useState([]);

console.log(smspagination, 'smspagination')
   // Placeholder for search results if undefined
  // useEffect(() => {
  //   console.log("Phones Pagination:", phonesPagination);
  // }, [phonesPagination]);

  // useEffect(() => {
  //   console.log("Phone Data:", phoneData);
  // }, [phoneData]);

  // Initial data fetch
  useEffect(() => {
    // dispatch(getAllEmailAction(emailPage, rowsPerPageEmail));
    dispatch(getAllSmsAction());
    // dispatch(getAllPhoneAction(phonePage, rowsPerPagePhone));
  }, [dispatch]);


  // useEffect(() => {
    // console.log("Has Searched:", hasSearched);
    // console.log("Search Data:", searchdatas);
    // console.log("Communication Type:", communicationType);

    // if (hasSearched && Array.isArray(searchdatas)) {
    //   const filterData = (type) => searchdatas.filter(item => item?.type === type);

    //   if (communicationType) {
    //     switch (communicationType) {
    //       case 'email':
    //         setEmailData(filterData('email'));
    //         break;
    //       case 'sms':
    //         setSmsData(filterData('sms'));
    //         break;
    //       case 'phone':
    //         setPhoneData(filterData('phone'));
    //         break;
    //       default:
    //         break;
    //     }
    //   } else {
    //     setEmailData(filterData('email') || []);
    //     setSmsData(filterData('sms') || []);
    //     setPhoneData(filterData('phone') || []);
    //   }
    // } else {
    //   setEmailData(emails || []);
    //   setSmsData(smspagination || []);
    //   setPhoneData(phonespagination || []);
    // }
  // }, [emails, smspagination, phonespagination, searchdatas, hasSearched, communicationType]);

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  // Event handlers
  const handleSearch = () => {
    const searchParams = {
      fromDate,
      toDate,
      subject,
      communicationType,
    };
    dispatch(searchAction(searchParams, () => setHasSearched(true)));
  };

  const handleSendMessage = async () => {
    const payload = {
      toPhoneNumber: phoneNumber,
      messageBody: message,
    };
  
    try {
      // Dispatch the action to send the message
      await dispatch(sendMessageAction(payload));
  
      // Set the Snackbar to show success
      setSnackbar({
        open: true,
        message: "Message sent successfully!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send the message. Please try again.",
        severity: "error",
      });
    }
  
    handleDialogClose();
  };
  

  const resetSearch = () => {
    setHasSearched(false);
    setFromDate("");
    setToDate("");
    setSubject("");
    setCommunicationType("");
    dispatch(getAllEmailAction(emailPage, rowsPerPageEmail));
    dispatch(getAllSmsAction());
    dispatch(getAllPhoneAction(phonePage, rowsPerPagePhone));
  };

  const handleChangePageEmail = (event, newPage) => setEmailPage(newPage);
  const handleChangePageSms = (event, newPage) => setSmsPage(newPage);
  const handleChangePagePhone = (event, newPage) => setPhonePage(newPage);

  const handleChangeRowsPerPageEmail = (event) => {
    setRowsPerPageEmail(parseInt(event.target.value, 10));
    setEmailPage(0);
  };

  const handleChangeRowsPerPageSms = (event) => {
    setRowsPerPageSms(parseInt(event.target.value, 10));
    setSmsPage(0);
  };

  const handleChangeRowsPerPagePhone = (event) => {
    setRowsPerPagePhone(parseInt(event.target.value, 10));
    setPhonePage(0);
  };

  const handleExpandClick = (index) => {
    setExpandedRows(prev =>
      prev.includes(index) ? prev.filter(row => row !== index) : [...prev, index]
    );
  };

// Table rendering function
const renderTable = (data = [], label, pagination = { totalElements: 0 }, page, rowsPerPage, handlePageChange, handleRowsPerPageChange) => {
  const totalCount = pagination.totalElements || data.length; // Use pagination or fallback to data length
  return (
    <Accordion defaultExpanded sx={{ mt: 2, backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={poppinsFont}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.length > 0 ? (
          <TableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell className="styled-table-heading" sx={{ fontWeight: 'bold' }}>Policy Number</TableCell>
                  <TableCell className="styled-table-heading" sx={{ fontWeight: 'bold' }}>Type</TableCell>
                  <TableCell className="styled-table-heading" sx={{ fontWeight: 'bold' }}>Date</TableCell>
                  <TableCell className="styled-table-heading" sx={{ fontWeight: 'bold' }}>Subject</TableCell>
                  <TableCell className="styled-table-heading" sx={{ fontWeight: 'bold' }}>Delivered</TableCell>
                  <TableCell className="styled-table-heading" sx={{ fontWeight: 'bold' }}>Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow className="styled-table-row" key={index}>
                    <TableCell>{policyId}</TableCell>
                    <TableCell>{row.smsType}</TableCell>
                    <TableCell>{row.receivedAt}</TableCell>
                    <TableCell>
                      {row.smsType === 'SENT' ? 'Message sent to Customer' : 'Message received from Customer'}
                    </TableCell>
                    <TableCell>{row.smsType ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{row.messageBody}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        ) : (
          <Typography align="center" sx={{ mt: 2 }}>
            No Record Found
          </Typography>
        )}
        {/* <TablePagination
          rowsPerPageOptions={pagination.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        /> */}
      </AccordionDetails>
    </Accordion>
  );
};


return (
  <Box>
   <Box
  sx={{
    display: 'flex',
    gap: '20px', // Consistent space between all items
    alignItems: 'center', // Align items vertically
    justifyContent: 'flex-start', // Start all items from the left
    mb: 2, // Margin bottom for spacing
  }}
>
  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>From</Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      placeholder="MM-DD-YYYY"
        value={fromDate ? dayjs(fromDate) : null}
        format="MM-DD-YYYY"
        onChange={(newValue) => setFromDate(newValue ? newValue.format('MM-DD-YYYY') : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              width: '225px',
              '& .MuiInputBase-root': {
                height: '56px',
                display: 'flex',
                alignItems: 'center',
              },
              '& input': { height: '100%' }, // Ensure the input element stretches
            }}
          />
        )}
      />
    </LocalizationProvider>
  </Box>

  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>To</Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        placeholder="MM-DD-YYYY"
        value={toDate ? dayjs(toDate) : null}
        format="MM-DD-YYYY"
        onChange={(newValue) => setToDate(newValue ? newValue.format('MM-DD-YYYY') : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              width: 225,
              height: '56px',
              '& .MuiInputBase-root': { height: '56px' },
              ...poppinsFont,
            }}
          />
        )}
      />
    </LocalizationProvider>
  </Box>

  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>Subject</Typography>
    <TextField
      sx={poppinsFont}
      value={subject}
      onChange={(e) => setSubject(e.target.value)}
    />
  </Box>

  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <FormControl sx={{ width: '220px' }}>
      <Typography variant="body1" sx={{ mb: 0.5 }}>Select to search</Typography>
      <Select
        value={communicationType}
        onChange={(e) => setCommunicationType(e.target.value)}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="email">Email</MenuItem>
        <MenuItem value="sms">SMS</MenuItem>
        <MenuItem value="phone">Phone</MenuItem>
      </Select>
    </FormControl>
  </Box>

  <Box>
    <Button
      onClick={handleSearch}
      className="btn-primary"
      sx={{
        textTransform: 'none',
        marginTop:'30px',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        '&:hover': { backgroundColor: '#104a8a' },
      }}
    >
      Search
    </Button>
  </Box>

  <Box>
    <Button
      onClick={resetSearch}
      className="btn-secondary"
      sx={{
        textTransform: 'none',
        marginTop:'30px',

        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        '&:hover': { backgroundColor: '#104a8a' },
      }}
    >
      Reset
    </Button>

    <Button
          onClick={handleDialogOpen}
          className="btn-primary"
          sx={{ marginTop: "30px" , marginLeft: "18px"}}
          
        >
          Send Message
        </Button>
  </Box>
</Box>

  {/* Dialog for Sending Message */}
  <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
        <Box
      sx={{
        width: '400px', 
        marginBottom: '16px',
      }}
    > 

        <Typography variant="body1" sx={{ mb: 0.5 }}>
        Phone Number
      </Typography>
          <TextField
            
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            margin="dense"
          />
            <Typography variant="body1" sx={{ mb: 0.5 }}>
        Message
      </Typography>
          <TextField
           
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            margin="dense"
            multiline
            rows={4}
          />
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}className="btn-secondary" variant="outlined">Cancel</Button>
          <Button onClick={handleSendMessage} className="btn-secondary" variant="outlined">
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>

    {/* Conditionally render tables */}
    {communicationType === 'email' || !communicationType
      ? renderTable(emailData, 'Email', emailsPagination, emailPage, rowsPerPageEmail, handleChangePageEmail, handleChangeRowsPerPageEmail)
      : null}
    {communicationType === 'sms' || !communicationType
      ? renderTable(smsPagination, 'SMS', smsPagination, smsPage, rowsPerPageSms, handleChangePageSms, handleChangeRowsPerPageSms)
      : null}
    {communicationType === 'phone' || !communicationType
      ? renderTable(phoneData, 'Phone', phonesPagination, phonePage, rowsPerPagePhone, handleChangePagePhone, handleChangeRowsPerPagePhone)
      : null}
  </Box>
);
};

export default CommunicationTable;
