import React, { useEffect, useState } from 'react';
import { Link, useNavigate , useLocation, Outlet} from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography,Menu, IconButton, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signOut as signOutAction } from '../redux/actions/authActions';
import { getThemeAction } from '../redux/actions/themeActions';
import { getUser as getUserAction, openCreateUserModal} from '../redux/actions/userActions';
import MainMenu from './MainMenu';
import ChatPage from "./ChatPage"; // Import the new ChatPage component
import MessageIcon from '@mui/icons-material/Message';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const useStyles = makeStyles((customTheme) => {
  return {
      header: {
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row !important',
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
      },
      nav: {
          padding: '1em 1em 0',
          margin: '0',
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
      },
      logo: {
          maxWidth: '120px',
          marginTop: '.25em',
          fontWeight: 'bold',
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
      },
      link: {
          textDecoration: "none !important",
          padding: "2em .5em 0",
          color: customTheme?.palette?.primary?.main,
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
          "&:hover": {
              color: 'black',
          }
      },
      icon: {
          marginBottom: '-.25em',
          color: customTheme?.palette?.primary?.main,
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
      },
      menuLink: {
          color: '#077BFD',
          textDecoration: "none !important",
          padding: ".25em",
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
      },
      iconColor: {
          color: customTheme?.palette?.primary?.main,
          background: customTheme?.palette?.primary?.main,
          fontFamily: "'Poppins', sans-serif !important",
          fontSize: "1rem !important",
      }
  };
});



const RaterHeader = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use react-router-dom's useLocation

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen ]= React.useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user?.user);
    const classes = useStyles();
    const [showChat, setShowChat] = useState(false); // State to toggle ChatPage

    // useEffect(() => {
    //     if(user?.customer?.id) {
    //         dispatch(getThemeAction(user?.customer?.id))
    //     }

    // }, [user])

    useEffect(() => {
        if(user) {
            dispatch(getUserAction());
        }
    }, []);

    const handleMenu = (event, target) => {
        setOpen(target);
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setOpen(undefined);
        setAnchorEl(null);
    };
    const handleChatIconClick = () => {
      navigate("/chat"); // Navigate to /chat when the chat icon is clicked
    };

    const handleSettings = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/settings");
    };

    const handleCustomTheme = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate('/customthemesettings');
    }

    const handleProfile = () => {
      setOpen(undefined);
      setAnchorEl(null);
      navigate('/edituserprofile');
  }

    const logout = async() => {
        setOpen(undefined);
        setAnchorEl(null);

        await dispatch(signOutAction());
    }
     
   
    return (
      <>
        <header
          className='header-container'
      >
      <div
        style={{
          height: "90px",
          position: "relative",
          backgroundColor: "#fff",
          display: "none",
          maxWidth: "100%",
        }}
      />
      <Typography variant="h4">
        {/* {dynamicTheme?.fileData !== "default" ? <img src={dynamicTheme?.fileData}  /> : */}
        {/* <strong style={{color:"#077BFD"}}>Quotetek</strong> */}
        <div className='logo'></div>
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "30px",
        }}
      >
        <MainMenu />
         {/* Chat Icon */}
        <IconButton onClick={handleChatIconClick} aria-label="Chat" sx={{ color: "#000000" }}>
          <MessageIcon sx={{ width: 25, height: 22.8 }} />
        </IconButton>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 0px 0px",
          }}
        >
        </div>
       */}
      
         
        

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 0px -6px",
          }}
        > */}
      
        <IconButton
  // size="large"
            onClick={(e) => handleMenu(e, 'user-menu')}
            // color="inherit"
            // sx={theme?.palette?.primary?.main}
        >
        <AccountCircleIcon
           sx={{
            width: 25,
               height: 22.8,
              position: 'relative',
              overflow: 'hidden',
              flexShrink: 0,
              zIndex: 1,
              color: '#077BFD',
            //   paddingTop: 20,
           }}
           loading="lazy"
            alt=""
        />
        </IconButton>
        <Menu
                    id="usermenu"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    keepMounted
                    open={open === 'user-menu'}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                >
                    <MenuItem onClick={handleProfile}><Link className={classes.menuLink}>Edit Profile</Link></MenuItem>
                    <MenuItem onClick={handleSettings}><Link className={classes.menuLink}>Settings</Link></MenuItem>
                    <MenuItem onClick={handleCustomTheme}><Link className={classes.menuLink}>Custom Theme</Link></MenuItem>
                    <MenuItem onClick={logout}><Link className={classes.menuLink}>Logout</Link></MenuItem>
                </Menu>
        </div>

      {/* </div> */}
    </header>
       {/* Dynamic Content Below the Header */}
       <main>
       <Outlet /> {/* This will render the child route's component */}
     </main>
     </>
    )
}

export default RaterHeader;
