
// const initialState = {
//     customizedForm: {},
//     error: null,
//   };

//   const policyReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case 'GET_POLICY_SUCCESS':
//             return { customizedForm: action.payload, error: null };
//         case 'SAVE_POLICY_SUCCESS':
//         case 'UPDATE_POLICY_SUCCESS':
//             return { ...state, customizedForm: action.payload, error: null };
//         case 'GET_POLICY_FAILURE':
//         case 'SAVE_POLICY_FAILUTE':
//         case 'UPDATE_POLICY_FAILUTE':
//             return { ...state, error: action.payload };
//         default:
//             return state;
//     }
//   };

//   export default policyReducer;

const initialState = {
    customizedForm: JSON.parse(localStorage.getItem('customizedForm')) || {},
    allPolicies: [],
    error: null,
    allDetailPolicy:[],
    getPolicy: [],
    search_policy_list: []
  };

  const policyReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_POLICY_SUCCESS':
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allPolicies: action.payload, error: null };
      case 'GET_DETAIL_POLICY_SUCCESS':
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allDetailPolicy: action.payload, error: null };
      case 'GET_POLICY_BY_ID_SUCCESS':
      // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
      // return { customizedForm: action.payload, error: null };
      return { ...state, getPolicy: action.payload, error: null };
      case 'SEARCH_POLICY_LIST_SUCCESS':
      // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
      // return { customizedForm: action.payload, error: null };
      return { ...state, searc_hpolicy_list: action.payload, error: null };
      
      case 'SAVE_POLICY_SUCCESS':
        localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        return { ...state, customizedForm: action.payload, error: null };
      case 'UPDATE_POLICY_SUCCESS':
        localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        return { ...state, customizedForm: action.payload, error: null };
      case 'DELETE_POLICY_SUCCESS':
        const updatedPolicies = state.allPolicies.filter(policy => policy.id !== action.payload);
        return { ...state, allPolicies: updatedPolicies, error: null };
      case 'GET_POLICY_FAILURE':
      case 'SAVE_POLICY_FAILURE':
      case 'DELETE_POLICY_FAILURE':
      case 'GET_DETAIL_POLICY_FAILURE':
      case 'UPDATE_POLICY_FAILURE':
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  export default policyReducer;
