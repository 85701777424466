import apiService from "./apiServices";

export const signUp = async (formData) => {
  try {
      const response = await apiService('/auth/signup', 'POST', formData, null);
      return response.token; // Ensure `token` is returned correctly
  } catch (error) {
      // Throw the full response or a specific part of it
      throw error.response?.data || { message: 'User name or email id already exist.' };
  }
};


export const signIn = async (formData) => {
    try {
        
        const response = await apiService('/auth/signin', 'POST', formData, null);
        return response.token;
      } catch (error) {
        throw (error?.message || 'Login failed. Please try again.');
      }
  }

  export const resetPassword = async (formData) => {
    try {
        
        const response = await apiService('/auth/reset_password', 'POST', formData, null);
        return response.token;
      } catch (error) {
        throw (error?.message || 'Reset password failed. Please try again.');
      }
  }

  export const forgotPassword = async (formData) => {
    try {
        
        const response = await apiService('/auth/forgot_password', 'POST', formData, null);
        return response.token;
      } catch (error) {
        throw (error?.message || 'Forgot password service failed. Please try again.');
      }
  }



