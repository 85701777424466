
import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCarrierAction,  deleteCarrierListingByIdAction } from "../../../redux/actions/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Loader from "../../loader/loader"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    padding: 16,
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontWeight: "bold",
    backgroundColor: "#b0b0b0",
    color: "white",
    textAlign: "center",
  },
  tableRow: {
    '&:hover': {
      backgroundColor: "#f1f1f1",
    },
  },
  iconButton: {
    marginRight: 8,
    '&:hover': {
      backgroundColor: "#e0e0e0",
    },
  },
  addButton: {
    backgroundColor: "#1976d2",
    color: "white",
    borderRadius: 8,
    textTransform: "none",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: "#1565c0",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  tableContainer: {
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  dialogContent: {
    backgroundColor: "#f8f8f8",
  },
  deleteButton: {
    backgroundColor: "#d32f2f",
    color: "white",
    "&:hover": {
      backgroundColor: "#c62828",
    },
  },
  cancelButton: {
    backgroundColor: "#757575",
    color: "white",
    "&:hover": {
      backgroundColor: "#616161",
    },
  },
}));

const ManageCarrierListing = () => {
  const classes = useStyles();
  const [editingCarrier, setEditingCarrier] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to show loader while deleting
  const deleteLoading = useSelector((state) => state.agency.deleteLoading);
   
  const [formVisible, setFormVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deletingCarrierId, setDeletingCarrierId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carrierDetails = useSelector((state) => state.agency.allCarrier);

  const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success',
    });

  const carriers = carrierDetails || [];

  useEffect(() => {
    dispatch(getCarrierAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleEdit = (carrier) => {
    setSelectedUser(carrier);
    navigate('/Addinsurancecarrier', {
      state: {
        selectedUser: carrier,
      },
    });
  };

  const handleCloseForm = () => {
    setFormVisible(false);
    setEditingCarrier(null);
  };

  const handleDeleteOpen = (id) => {
    setDeletingCarrierId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {    
    setOpenDelete(false);
  };

  const handleDeleteConfirm = async () => {
    if (deletingCarrierId !== null) {
      setIsDeleting(true);
      setOpenDelete(false);
      try {
        await dispatch(deleteCarrierListingByIdAction(deletingCarrierId)); 
        dispatch(getCarrierAction()); // Refresh the carrier list
        setSnackbar({
          open: true,
          message: 'Carrier deleted successfully',
          severity: 'success',
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'You do not have access to delete the carrier',
          severity: 'error',
        });
      } finally {
        setIsDeleting(false); // Reset deleting state
      }
    }
  };
  
  const addNewInsuranceCarrier = () => {
    navigate('/Addinsurancecarrier')
  }

  const handleFormClose = () => {
    setSelectedUser(null);
    setIsEditing(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const paginatedRows = Array.isArray(carriers)
  ? carriers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  : [];

  return (
    <Paper sx={{
      margin: 1, 
      padding: 2, 
      border: "1px solid rgba(49, 49, 49, 0.1)", // Border to separate inner container
      boxShadow: "0 4px 8px rgba(104, 104, 104, 0.1)", // Optional: Adds a shadow to the inner container
    }}>
        <Box sx={{ padding: '16px' }}>

        <Box sx={{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    marginBottom: '16px'
        }}>
          <Typography variant="h5" sx={{ fontFamily: '"Poppins", sans-serif', fontWeight: '500' }} gutterBottom>
            Manage Carrier Listing
          </Typography>
          <Button variant="contained" className="btn-primary" onClick={addNewInsuranceCarrier}>
            Add New
          </Button>
        </Box>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className="styled-table-heading">Company Name</TableCell>
                <TableCell className="styled-table-heading">Program Name</TableCell>
                <TableCell className="styled-table-heading">Producer Code</TableCell>
                <TableCell className="styled-table-heading">Last Activity</TableCell>
                <TableCell className="styled-table-heading" align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { (deleteLoading || isDeleting) ? (
                <TableRow>
                  <TableCell colSpan={5} align="center"> 
                    <Loader /> {/* Show loader when delete action is in progress */}
                  </TableCell>
                </TableRow>
              ) : paginatedRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">No Data Available</TableCell>
                </TableRow>
              ) : (
                paginatedRows.map((row) => (
                  <TableRow className="styled-table-row" key={row.id}>
                    <TableCell>{row.agencyName}</TableCell>
                    <TableCell>{row.carrierProgramDescription}</TableCell>
                    <TableCell>{row.producerCode}</TableCell>
                    <TableCell>{new Date(row.updatedTimeStamp).toLocaleDateString("en-CA")}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="edit" className={classes.iconButton} onClick={() => handleEdit(row)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className={classes.iconButton} onClick={() => handleDeleteOpen(row.agencyId)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={carriers.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={carriers.length === 0 ? [] : [5, 10, 15]}
          />
        </TableContainer>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Delete Carrier</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography>Are you sure you want to delete this carrier? This action cannot be undone.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className='btn-secondary-md'  color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} className='btn-primary-md' color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000} 
          onClose={handleSnackbarClose} 
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity} 
          >
            {snackbar.message} 
          </Alert>
        </Snackbar>
         </Box>
      </Paper>
     
  );
};

export default ManageCarrierListing;
