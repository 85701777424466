import * as React from "react";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Box,
  Grid,
  FormControl,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  TablePagination,
} from "@mui/material";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import { Controller, useForm, FormProvider } from "react-hook-form";
import AddNotes from "./AddNotes";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllNoteAction,
  updateNoteAction,
  searchNoteAction,
  deleteNoteActionById,
} from "../../redux/actions/noteActions";
const notesearchData = [
  {
    fieldName: "fromDate",
    label: "From:",
    type: "date",
    id:"fromdate"
  },
  {
    fieldName: "toDate",
    label: "To:",
    type: "date",
    id:"todate"
  },
  {
    fieldName: "message",
    label: "Notes",
    type: "text",
    id:"message"
  },
];

const tableData = {
  headers: ["Policy Number", "Date", "User Id", "Notes", "Action"],
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: '16px auto',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

const PoliciesList = ({ searchParams }) => {
  const [showNote, setshowNote] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const notes = useSelector((state) => state.note.allNote);
  const searchNotes = useSelector((state) => state.note.searchNote);
  const [editingNote, setEditingNote] = useState(null);
  const dispatch = useDispatch();
  const methods = useForm();
  
  const {
    control,
    formState: { errors },
    reset,
  } = methods;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noteData, setNoteData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    dispatch(getAllNoteAction(page,rowsPerPage));
  }, [dispatch,page,rowsPerPage]);

  useEffect(() => {
    if (hasSearched) {
      setNoteData(searchNotes || []);
    } else {
      setNoteData(notes || []);
    }
  }, [notes, searchNotes, hasSearched]);


  const onSubmit = (data) => {
    // Format the dates in the required format
    const formattedData = {
      ...data,
      fromDate: data.fromDate ? dayjs(data.fromDate).format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
      toDate: data.toDate ? dayjs(data.toDate).format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
    };

    dispatch(
      searchNoteAction(formattedData, () => {
        setHasSearched(true);
      })
    );
  };

  const resetSearch = () => {
    setHasSearched(false);
    reset({
      fromDate: null,
      toDate: null,
      userId: "",
      message: "",
      searchPolicy: "",
    });
  };

  function handleClick() {
    setshowNote(true);
    setShowButton(true);
    methods.reset({
      message: "",

    });
  }

  const handleEditClick = (note) => {
    setshowNote(true);
    setShowButton(false);
    methods.reset();
    methods.setValue("policyNumber", note.policyNumber);
    methods.setValue("timestamp", note.timestamp);
    methods.setValue("notesSource", note.notesSource);
    methods.setValue("message", note.message);
    methods.setValue("policyId", note.policyId);
    methods.setValue("updatedAt", note.updatedAt);
    methods.setValue("createdBy", note.createdBy);
    methods.setValue("id", note.id);
  };

  const handleDeleteClick = (note) => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      // Assuming you have a deleteNoteAction or API call
      dispatch(
        deleteNoteActionById(note.id, () => {
          // Callback to update local state after successful deletion
          setNoteData((prevData) => prevData.filter((n) => n.id !== note.id));
          alert("Note deleted successfully!");
        })
      );
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "MM-dd-yyyy");
  };
  const dataToDisplay = hasSearched ? searchNotes : noteData;

  const paginatedRows = Array.isArray(dataToDisplay)
  ? dataToDisplay.filter((_, index) => 
      index >= page * rowsPerPage && index < page * rowsPerPage + rowsPerPage
    )
  : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {showNote ? (
        <FormProvider {...methods}>
          <AddNotes setshowNote={setshowNote} setShowButton={showButton} />
        </FormProvider>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid item xs={12} container>
                <Grid md={12} lg={12} xl={12} xs={12}>
                  <Box sx={{margin: "1rem 0 0 0", display: 'flex',
                  justifyContent: 'space-between', width: '100%', }}>
                  <Grid container rowSpacing={1} sx={{ margin: "0", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                      <Box sx={{display: 'flex', width: '80%'}}>
                          {notesearchData.map(
                            ({
                              fieldName,
                              label,
                              type,
                              options,
                              rules,
                              defaultValue,
                            }) =>
                                (<Grid
                                  key={fieldName}
                                  item
                                  sx={{ padding: "5px", }}
                                >
                                  <FormControl
                                    fullWidth
                                    margin="0"
                                    error={!!errors[fieldName]}
                                  >
                                    <Typography>{label}</Typography>
                                    <Controller
                                      name={fieldName}
                                      control={control}
                                      rules={rules}
                                      defaultValue={defaultValue}
                                      render={({ field }) => {
                                        if (type === "text" || type === "number") {
                                          return (
                                            <TextField
                                              {...field}
                                              inputProps={
                                                type === "number"
                                                  ? {
                                                      inputMode: "numeric",
                                                      pattern: "[0-9]*",
                                                      maxLength: 10,
                                                    }
                                                  : {}
                                              }
                                              id={fieldName}
                                              type={type}
                                              sx={{ height: "56px" }}
                                              error={!!errors[fieldName]}
                                            />
                                          );
                                        } else if (
                                          type === "dropdown" ||
                                          type === "select"
                                        ) {
                                          return (
                                            <Select
                                              {...field}
                                              error={!!errors[fieldName]}
                                            >
                                              {options.map((option, index) => (
                                                <MenuItem key={index} value={option}>
                                                  {option}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          );
                                        } else if (type === "date") {
                                          return (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                format="MM-DD-YYYY"
                                                {...field}
                                                value={
                                                  field.value
                                                    ? dayjs(field.value, "YYYY-MM-DD")
                                                    : null
                                                }
                                                onChange={(date) =>
                                                  field.onChange(
                                                    dayjs(date).format("YYYY-MM-DD")
                                                  )
                                                }
                                                slotProps={{
                                                  textField: {
                                                    fullWidth: true,
                                                    //sx: { width: "50%" },
                                                    error: !!errors[fieldName],
                                                  },
                                                }}
                                              />
                                            </LocalizationProvider>
                                          );
                                        }
                                      }}
                                    />
                                    {errors[fieldName] && (
                                      <FormHelperText>
                                        {errors[fieldName].message}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              )
                          )}
                      </Box>
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                          <Button
                            type="submit"
                            id="Search"
                            variant="contained"
                            className="btn-secondary"
                            sx={{ marginTop: '25px' }}
                          >
                            Search
                          </Button>
                          <Button
                            onClick={resetSearch}
                            variant="outlined"
                            id="reset"
                            className="btn-secondary"
                            sx={{marginTop: '25px', marginLeft: '10px' }}
                          >
                            Reset
                          </Button>
                        </Box>
                      </Box>
                     
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'end',
                          marginTop: '25px'
                        }}
                        >
                          <Button
                            id="addnote"
                            onClick={handleClick}
                            variant="contained"
                            className="btn-primary"
                            startIcon={<AddIcon />}
                          >
                            Add Note
                          </Button>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <StyledTableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableData.headers.map((header, index) => (
                      <TableCell key={index} className="styled-table-heading" sx={{fontWeight: 'bold'}}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRows.map((row, index) => (
                    <TableRow key={index}  className="styled-table-row">  
                      <TableCell >{row.policyNumber}</TableCell>
                      <TableCell >
                        {formatDate(row.timestamp)}
                      </TableCell>
                      <TableCell >{row.notesSource}</TableCell>
                      <TableCell >{row.message}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEditClick(row)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(row)}
                          color="primary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            {paginatedRows.length > 0 && (<TablePagination
        component="div"
        count={dataToDisplay.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15,25]}
        sx={{
          alignSelf: 'flex-end',   // Align it to the bottom-right of the container
          marginTop: '16px',       // Push it to the bottom of the container (if there's extra space)
          marginBottom: 0,   // Add some padding if you want spacing around the pagination
        }}
      />)}
             
      </StyledTableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default PoliciesList;
