import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Alert, Paper, Typography, Button, Grid, Box, TablePagination, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getAllLocationAction, getDeleteLocationAction } from '../../redux/actions/locationActions';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import AddLocationForm from './AddLocationForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";
import Loader from '../loader/loader';

// Styled table cell with hex color
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Using the specific hex color
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif',
  },
}));

function LocationList({ locations, toggleForm }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Track dialog visibility
  const [userToDelete, setUserToDelete] = useState(null); // Track the user to be deleted
  const locationDetails = useSelector((state) => state.location.allLocation);
  const deleteLoading = useSelector((state) => state.location.deleteLoading); // Specific delete loading state
  const locationData = Array.isArray(locationDetails) ? locationDetails : [];
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    dispatch(getAllLocationAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEditClick = (user) => {
    setSelectedUser(user); // Set the selected user for editing
    setIsEditing(true);    // Show the edit form
  };

  const handleFormClose = () => {
    setSelectedUser(null);  // Reset selected user after form close
    setIsEditing(false);    // Switch back to user list
  };

  const handleDeleteClick = (id) => {
    setUserToDelete(id);     // Set the user to be deleted
    setIsDialogOpen(true);   // Open the confirmation dialog
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      dispatch(getDeleteLocationAction(userToDelete)).then(() => {
        dispatch(getAllLocationAction()); // Refetch after deletion completes
        setSnackbar({
          open: true,
          message: 'Location deleted successfully',
          severity: 'success',
        });
      }) .catch(() => {
        setSnackbar({
          open: true,
          message: 'Failed to delete location',
          severity: 'error',
        });
      });
    }
    setIsDialogOpen(false); // Close the dialog
    setUserToDelete(null);  // Reset the user to delete
  };

  const handleCancelDelete = () => {
    setIsDialogOpen(false); // Close the dialog
    setUserToDelete(null);  // Reset the user to delete
  };

  const paginatedRows = locationData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper sx={{ padding: 3 }}>
      {isEditing ? (
        <AddLocationForm
          location={selectedUser}
          toggleForm={handleFormClose}
          id="editLocationForm"
          name="editLocationForm"
        />
      ) : (
        <>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
            <Grid item>
              <Typography id="alllocationlist" variant="h5" sx={{ fontFamily: '"Poppins", sans-serif' }}>
                All Location List
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="btn-primary"
                color="primary"
                onClick={toggleForm}
                id="addnewlocationbutton"
                name="addNewLocationButton"
              >
                Add New Location
              </Button>
            </Grid>
          </Grid>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="styled-table-heading">Description</TableCell>
                <TableCell className="styled-table-heading">Agency Name</TableCell>
                <TableCell className="styled-table-heading">Address</TableCell>
                <TableCell className="styled-table-heading">City</TableCell>
                <TableCell className="styled-table-heading">State</TableCell>
                <TableCell className="styled-table-heading">Phone</TableCell>
                <TableCell className="styled-table-heading">Active</TableCell>
                <TableCell className="styled-table-heading">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {deleteLoading ? (
       <TableRow>
       <TableCell colSpan={8} align="center">
         <Loader />
       </TableCell>
     </TableRow>
   ) :
              paginatedRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        No locations available.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedRows.map((location, index) => (
                  <TableRow className="styled-table-row">
                    <TableCell>{location.locationDescription}</TableCell>
                    <TableCell>{location.agencyName}</TableCell>
                    <TableCell>{`${location.address1} ${location.address2}`}</TableCell>
                    <TableCell>{location.city}</TableCell>
                    <TableCell>{location.state}</TableCell>
                    <TableCell>{location.phone}</TableCell>
                    <TableCell>{location.isActive ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleEditClick(location)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteClick(location.id)}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={locationData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
          />

          {/* Confirmation Dialog */}
          <Dialog
            open={isDialogOpen}
            onClose={handleCancelDelete}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this location? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete} className='btn-secondary-md' color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} className='btn-primary-md' color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={4000} 
            onClose={handleSnackbarClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severity} 
            >
              {snackbar.message} 
            </Alert>
          </Snackbar>
        </>
      )}
    </Paper>
  );
}

export default LocationList;
