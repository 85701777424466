import { CLOSE_CREATE_DRIVER_INFO_MODAL, UPDATED_DRIVER_SUCCESS, GET_DRIVER_DETAIL_BY_ID, OPEN_CREATE_DRIVER_INFO_MODAL, OPEN_UPDATE_DRIVER_INFO_MODAL } from "../actions/actionTypes";

const initialState = {
    allDriverInfo: [],
    allDriverInfoName:[],
    error: null,
    isCreateDriverInfoModalOpen: false,
    selectedDriverInfoId: null,
    driverDetails:[],
  };

  const driverInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_DRIVER_INFOS_SUCCESS':
            return { ...state, allDriverInfo: action.payload, error: null };
            case 'GET_DRIVER_DETAIL_BY_ID':
                return { ...state, driverDetails: action.payload, error: null }; 
                case 'UPDATED_DRIVER_SUCCESS':
                return { ...state, driverDetails: action.payload, error: null }; 
        case 'ALL_DRIVER_NAME_INFOS_SUCCESS':
            return { ...state, allDriverInfoName: action.payload, error: null };
        case 'CREATE_DRIVER_INFO_SUCCESS':
        case 'UPDATE_DRIVER_INFO_SUCCESS':
            return {
                ...state,
                allDriverInfo: [ ...state.allDriverInfo, ...action.payload ],
                error: null,
            };
        case OPEN_CREATE_DRIVER_INFO_MODAL:
            return {...state, isCreateDriverInfoModalOpen: true}
        case OPEN_UPDATE_DRIVER_INFO_MODAL:
            return {...state, isCreateDriverInfoModalOpen: true, selectedDriverInfoId: action.payload}
        case CLOSE_CREATE_DRIVER_INFO_MODAL:
            return {...state, isCreateDriverInfoModalOpen: false, selectedDriverInfoId: null}
        case 'SAVE_DRIVER_INFO_SUCCESS':
            return {
                ...state,
                driverDetails: [ ...state.driverDetails, ...action.payload ],
                error: null,
            };
        case 'ALL_DRIVER_INFOS_FAILURE':
        case 'ALL_DRIVER_NAME_INFOS_FAILURE':
        case 'CREATE_DRIVER_INFO_FAILURE':
        case 'SAVE_DRIVER_INFO_FAILURE':
        case 'UPDATE_DRIVER_INFO_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };

  export default driverInfoReducer;