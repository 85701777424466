import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PolicyInfo from "./PolicyInfo";
import Task from "./Task";
import Communication from "./Communication";
import Activity from "./Activity";
import PoliciesList from "../../notes/PoliciesList";
import Attachments from "../../attachments/Attachments";
import { useDispatch, useSelector } from "react-redux";
import {managepolicyallAction} from '../../../redux/actions/dashboardAction';


const PolicyDetails = ({ showPolicyDetails, policyId, selectedId, isRowClicked, selectedPolicyDetail }) => {

  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [defaultPolicy, setDefaultPolicy] = React.useState({});
  const policyListingData =
    useSelector((state) => state.dashboardData.manage_policy_all.content) || [];
  const methods = useForm();

  // Always call the hooks (even if showPolicyDetails is false)
  useEffect(() => {
    if (showPolicyDetails) {
      // Ensure selectedPolicyDetail is not null or undefined
      if (selectedPolicyDetail && selectedPolicyDetail.length > 0) {
        setDefaultPolicy(selectedPolicyDetail[0]); // Set the first row's data as default
      } else {
        setDefaultPolicy({}); // Fallback to an empty object if no data is available
      }
    }
  }, [selectedPolicyDetail, showPolicyDetails]); // Add showPolicyDetails as a dependency

  useEffect(() => {
    if (showPolicyDetails) {
      dispatch(managepolicyallAction());
    }
  }, [dispatch, showPolicyDetails]); // Add showPolicyDetails as a dependency

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Conditionally render the JSX based on `showPolicyDetails`
  if (!showPolicyDetails) {
    return null; // Render nothing if `showPolicyDetails` is false
  }


  const jsonData = {
    policyNumber: defaultPolicy.policyNumber || policyListingData[0]?.policyNumber,
    policyType: defaultPolicy.policyType || policyListingData[0]?.policyType,
    name: defaultPolicy.name || policyListingData[0]?.name,
    companyName: defaultPolicy.company || policyListingData[0]?.company,
    email: defaultPolicy.email || policyListingData[0]?.email,
    phone: defaultPolicy.phone || policyListingData[0]?.phone,
    effDate: defaultPolicy.effectiveDate || policyListingData[0]?.effectiveDate,
    expDate: defaultPolicy.expirationDate || policyListingData[0]?.expirationDate,
    status: defaultPolicy.status || policyListingData[0]?.status,
    nextActivity: defaultPolicy.nextActivity || policyListingData[0]?.nextActivity,
    policyPremium: defaultPolicy.premium || policyListingData[0]?.premium,
  };

  return (
    <Paper
      elevation={3}
      style={{fontFamily: '"Poppins", sans-serif' }}
      id="policydetailspaper"
      sx={{ boxShadow: "none", backgroundColor: "#ffffff" }}
    >
      <Grid container spacing={2}>
        {!isRowClicked && (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              id="policydetailstablecontainer"
              sx={{ fontFamily: '"Poppins", sans-serif', boxShadow: "0" }}
            >
              <Table
                id="policydetailstable"
                sx={{
                  fontFamily: '"Poppins", sans-serif',
                  backgroundColor: "#F8F8F8",
                  boxShadow: "none",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{fontWeight: 'bold'}}>Policy Number</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Policy Type</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Company Name</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Email</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Phone</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Eff Date</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Exp Date</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Status</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Next Activity</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}}>Policy Premium</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{jsonData.policyNumber}</TableCell>
                    <TableCell>{jsonData.policyType}</TableCell>
                    <TableCell>{jsonData.name}</TableCell>
                    <TableCell>{jsonData.companyName}</TableCell>
                    <TableCell>{jsonData.email}</TableCell>
                    <TableCell>{jsonData.phone}</TableCell>
                    <TableCell>{jsonData.effDate}</TableCell>
                    <TableCell>{jsonData.expDate}</TableCell>
                    <TableCell>{jsonData.status}</TableCell>
                    <TableCell>{jsonData.nextActivity}</TableCell>
                    <TableCell>{jsonData.policyPremium}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          
        )}
        <Grid item xs={12}>
          <Box className="policy-detail-main-container">
            <AppBar position="static" id="policydetailsappbar">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Policy Details" id="tabpolicydetails" />
                <Tab label="Notes" id="tabnotes" />
                <Tab label="Task" id="tabtask" />
                <Tab label="Communication" id="tabcommunication" />
                <Tab label="Policy Documents" id="tabpolicydocuments" />
                <Tab label="Activity" id="tabactivity" />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} id="policyinfotabpanel">
              <PolicyInfo policyId={defaultPolicy.id || policyId} />
            </TabPanel>
            <TabPanel value={value} index={1} id="notestabpanel">
              <FormProvider {...methods}>
                <PoliciesList />
              </FormProvider>
            </TabPanel>
            <TabPanel value={value} index={2} id="tasktabpanel">
            <Task policyId={defaultPolicy.id || policyId} getPolicyNumber={defaultPolicy.policyNumber } />
            </TabPanel>
            <TabPanel value={value} index={3} id="communicationtabpanel">
              <Communication policyId={defaultPolicy.policyNumber || policyId} />
            </TabPanel>
            <TabPanel value={value} index={4} id="attachmentstabpanel">
              <Attachments />
            </TabPanel>
            <TabPanel value={value} index={5} id="activitytabpanel">
              <Activity />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simpletabpanel${index}`}
      aria-labelledby={`simpletab${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default PolicyDetails;
