import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Container,
  CssBaseline,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { validateEmail, validateField } from '../common/helpers/validation';
import { signIn as signInAction } from '../redux/actions/authActions';

const useStyles = makeStyles({
  logo: {
      maxWidth: "120px",
      color: "#077BFD",
  },
  loginBox: {
      padding: "1.5em",
      boxShadow: "0em .35em .5em #ccc",
      border: "1px solid #ddd",
  },
  link: {
    fontSize: ".85em",
  }
});

const SignIn = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading.SIGN_IN);
  
    const [formData, setFormData] = useState({
      email: '',
      password: '',
    });
  
    const [touchedFields, setTouchedFields] = useState({
      email: false,
      password: false,
    });
  
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
  
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
    const handleSnackbarOpen = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleBlur = (field) => {
      setTouchedFields((prevTouched) => ({
        ...prevTouched,
        [field]: true,
      }));
  
      if (field === 'email') {
        setEmailError(validateEmail(formData.email));
      } else if (field === 'password') {
        setPasswordError(validateField(formData.password, field));
      }
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
    
      const emailError = validateEmail(formData.email);
      const passwordError = validateField(formData.password);
    
      setEmailError(emailError);
      setPasswordError(passwordError);
    
      if (!emailError && !passwordError) {
        try {
          await dispatch(signInAction({ email: formData.email, password: formData.password }));
        } catch (errorMessage) {
          // Display the error message in the Snackbar
          handleSnackbarOpen(errorMessage, 'error');
        }
      } else {
        console.log('Validation errors detected. Please correct them.');
      }
    };
    
  

  return (
      <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
              sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
              }}
          >
              <Box>
                  <h1 className={classes.logo}>BizAiTek</h1>
              </Box>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} className={classes.loginBox}>
                 <Box sx={{marginBottom: '10px'}}>
                 <label>Email Address</label>
                  <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('email')}
                      error={touchedFields.email && !!emailError}
                      helperText={touchedFields.email ? emailError : ''}
                  />
                  </Box>
                  <Box sx={{marginBottom: '10px'}}>
                    <label>Password</label>
                  <TextField
                      required
                      fullWidth
                      name="password"                      
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={formData.password}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('password')}
                      error={touchedFields.password && !!passwordError}
                      helperText={touchedFields.password ? passwordError : ''}
                  />
                  </Box>
                  <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                  />
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                  >
                      { loading ? <CircularProgress size={24} color="secondary"/> : "Sign In" }
                  </Button>
                  <Grid container>
                      <Grid item xs>
                          <Link className={classes.link} to="/forgotPassword" variant="body2">
                              Forgot password?
                          </Link>
                      </Grid>
                      <Grid item md={12}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Link className={classes.link} to="/signup" variant="body2">
                                Don't have an account? Sign Up
                            </Link>
                          </Box>
                      </Grid>
                  </Grid>
              </Box>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
      </Container>
  );
}

export default SignIn;
