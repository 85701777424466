import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { getPolicyByIdAction, updatePolicyAction } from '../../../redux/actions/policyActions';
import EditPolicyUserDetail from './EditPolicyUserDetail';
import { id } from 'date-fns/locale';

const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};

const PolicyUserDetails = ({ policy_Id, selectedRowId }) => {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.policy.allDetailPolicy) || {};
  const selectedPolicyDetail = useSelector((state) => state.policy.getPolicy) || {};
  
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({});


  useEffect(() => {
    const id = policy_Id || selectedRowId || 2; // Fallback to default id
    if (id) {
      dispatch(getPolicyByIdAction(id)); // Fetch policy details
    }
  }, [dispatch, policy_Id, selectedRowId]);

  useEffect(() => {
    if (customerData) {
      setFormData({
        firstName: customerData.firstName || '',
        lastName: customerData.lastName || '',
        dateOfBirth: customerData.dateOfBirth || '',
        gender: customerData.gender || '',
        email: customerData.email || '',
        address: customerData.address || '',
        zip: customerData.zip || '',
        city: customerData.city || '',
        state: customerData.state || '',
        yearsAtResidence: customerData.yearsAtResidence || '',
        tax: customerData.taxId || '',
        ownershipStatus: customerData.ownershipStatus || '',
        id: policy_Id || customerData.id,
      });
    }
  }, [customerData, policy_Id]);

  
  const handleEditClick = (id) => {
    dispatch(getPolicyByIdAction(id))
    setOpenDialog(true); // Open dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close dialog
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value })); // Update form data
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updatePolicyAction(formData)); // Dispatch update action
    setOpenDialog(false); // Close dialog after update
  };
  return (
    <TableContainer sx={{ verticalAlign: 'Top' }} component={Paper}>
      <Table sx={{ verticalAlign: 'Top' }}>
        <TableBody>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              <EditIcon onClick={()=> handleEditClick(customerData.id)} style={{ cursor: 'pointer' }} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ verticalAlign: 'Top' }}>
            <TableCell>
              <Table>
                <TableRow>
                  <TableCell id="firstname" sx={poppinsFont}>
                    <strong>First Name:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="lastname" sx={poppinsFont}>
                    <strong>Last Name:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="dateofBirth" sx={poppinsFont}>
                    <strong>Date of Birth:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.dateOfBirth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="gender" sx={poppinsFont}>
                    <strong>Gender:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.gender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="email" sx={poppinsFont}>
                    <strong>Email:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.email}</TableCell>
                </TableRow>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableRow>
                  <TableCell id="address" sx={poppinsFont}>
                    <strong>Address:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="zip" sx={poppinsFont}>
                    <strong>Zip:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.zip}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="city" sx={poppinsFont}>
                    <strong>City:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="state" sx={poppinsFont}>
                    <strong>State:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.state}</TableCell>
                </TableRow>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableRow>
                  <TableCell id="yearsmonthatresidence" sx={poppinsFont}>
                    <strong>Years/Month at Residence:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.yearsAtResidence}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="taxidssn" sx={poppinsFont}>
                    <strong>Tax Id/SSN:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.taxId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="ownedrented" sx={poppinsFont}>
                    <strong>Owned/Rented:</strong>
                  </TableCell>
                  <TableCell sx={poppinsFont}>{customerData.ownershipStatus}</TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Edit Popup Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <EditPolicyUserDetail
          handleChange={handleChange}
          formData={formData}
          handleDialogClose={handleDialogClose}
          handleSubmit={handleSubmit}
        />
      </Dialog>
    </TableContainer>
  );
};

export default PolicyUserDetails;
