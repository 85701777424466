// import apiService from "./apiServices";

// export const createDriverInfo = async (formData, token) => {
//   try {
//     const headers = {
//       'Access-Control-Request-Headers': '*'
//     };
//     const response = await apiService('/driverinfo/', 'POST', formData, headers, token);
//     console.log(response, 'response from service');
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'Creating organization is failed. Please try again.');
//   }
// }

// export const updateDriverInfo = async (formData, token) => {
//   try {
//     const headers = {
//       'Access-Control-Request-Headers': '*'
//     };
//     const response = await apiService(`/driverinfo/`, 'PUT', formData, headers, token);
//     console.log(response, 'response from service');
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'Updating organization is failed. Please try again.');
//   }
// }

// export const getDriverInfoById = async (quoteId, token) => {
//   try {
//     const headers = {
//       'Access-Control-Request-Headers': '*'
//     };
//     const response = await apiService(`/driverInfo/${quoteId}`, 'GET', null, headers, token);
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'service is failed. Please try again.');
//   }
// }

// export const getAllDriverInfo = async (generalPartyId) => {
//   try {
//     const headers = {
//       'Access-Control-Request-Headers': '*'
//     };
//     const token = ""
//     const response = await apiService(`/driverinfo/bygpid/${generalPartyId}`, 'GET', null, headers, token);
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'service is failed. Please try again.');
//   }
// }


import apiService from "./apiServices";

export const createDriverInfo = async (formData, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    // console.log(formData)
    const response = await apiService('/driverInfo/', 'POST', formData, null, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Creating organization is failed. Please try again.');
  }
}

export const updateDriverInfo = async (formData, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService(`/driverInfo/`, 'PUT', formData, null, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Updating organization is failed. Please try again.');
  }
}

export const getDriverInfoById = async (quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService(`/driverInfo/${quoteId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getDriverNameInfoById = async (quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService(`/driverInfo/NameBygpid/${quoteId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getAllDriverInfo = async (generalPartyId,token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    // const token = ""
    const response = await apiService(`/driverInfo/bygpid/${generalPartyId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const deleteDriverInfoById = async (quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService(`/driverInfo/${quoteId}`, 'DELETE', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

//Policy

export const saveDriver = async (formData, token) => {
  try {    
    const response = await apiService('/driver/save', 'POST', formData, null, token);    
    return response;
  } catch (error) {
    throw new Error(error.message || 'Saving driver is failed. Please try again.');
  }
}

export const getDriver = async (token, page, size) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService(`/driver/all?page=${page}&size=${size}`, 'GET', null, null, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Saving driver is failed. Please try again.');
  }
}

export const getDriverByIdServices = async (id, token) => {
  try {
    const response = await apiService(`/driver/${id}`, 'GET', null, null, token);    
    return response;
  } catch (error) {
    throw new Error(error.message || 'Saving driver is failed. Please try again.');
  }
}

export const updatedDriverServices = async (formData, token) => {
  try {
    const response = await apiService(`/driver/update`, 'PUT', formData, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Saving driver is failed. Please try again.');
  }
}

// export const getAllNote = async (token, page, size) => {
//   try {

//     const response = await apiService(`/driver/all?page=${page}&size=${size}`, 'GET', null, null, token);
//     console.log("all response",response)
//     return response;
//   } catch (error) {
//     throw (error?.message || 'service is failed. Please try again.');
//   }
// }




