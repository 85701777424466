import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Snackbar,
  Alert,
  Typography, 
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../loader/loader';
import { getAllLocationGroupsAction, deleteGrouplocationAction } from "../../redux/actions/locationGroupActions";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const LocationGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationGroups = useSelector((state) => state.locationGroup.allLocationGroups || []);
  const deleteLoading = useSelector((state) => state.locationGroup.deleteLoading); // Specific delete loading state
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    dispatch(getAllLocationGroupsAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleEditClick = (group) => {
    navigate('/locationgroup', {
      state: {
        groupName: group.locationGroupName, 
        selectedLocations: group.locations || [],
        status: group.status,
        isEdit: true,
        groupId: group.locationGroupId
      },
    });
  };

  const handleDeleteClick = (id) => {
    setSelectedGroupId(id);
    setOpenDialog(true);
  };

  
  const handleConfirmDelete = () => {
    if (selectedGroupId !== null) {
      dispatch(deleteGrouplocationAction(selectedGroupId))
        .then(() => {
          dispatch(getAllLocationGroupsAction(page, rowsPerPage)); // Refetch data after deletion
          setSnackbar({
            open: true,
            message: 'Location group deleted successfully',
            severity: 'success',
          });
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message: 'Failed to delete location group',
            severity: 'error',
          });
        });
      setOpenDialog(false);
      setSelectedGroupId(null);
    }
  };


  const handleCancelDelete = () => {
    setOpenDialog(false);
    setSelectedGroupId(null);
  };

  const handleAddNewGroup = () => {
    navigate('/locationgroup');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  // Calculate the slice indices based on pagination
  const paginatedRows = locationGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (

    <Paper sx={{ 
      margin: 3, 
      padding: 2, 
      border: "1px solid rgba(49, 49, 49, 0.1)", // Border to separate inner container
      boxShadow: "0 4px 8px rgba(104, 104, 104, 0.1)", // Optional: Adds a shadow to the inner container
    }}>
     <Box sx={{ padding: '16px' }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>        
      <Typography variant="h5" sx={{ fontFamily: '"Poppins", sans-serif' }}>Locations Group</Typography>
      <Button variant="contained" color="primary" className="btn-primary" onClick={handleAddNewGroup}>Add New Group</Button>
    </Box>


    <TableContainer component={Paper} sx={{ position: 'relative', minHeight: '200px' }}>
 
    <Table>
        <TableHead>
          <TableRow>
            <TableCell className="styled-table-heading">Group Name</TableCell>
            <TableCell className="styled-table-heading">Status</TableCell>
            <TableCell className="styled-table-heading" align='right'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
     
      {deleteLoading ? (
       <TableRow>
       <TableCell colSpan={3} align="center">
         <Loader />
       </TableCell>
     </TableRow>
   ) : paginatedRows.length === 0 ? (
     <TableRow>
       <TableCell colSpan={3} align="center">No Data Available</TableCell>
     </TableRow>
   ) : (
     
          paginatedRows.map((group) => (
            <TableRow className="styled-table-row" key={group.locationGroupId}>
              <TableCell>{group.locationGroupName}</TableCell>
              <TableCell>{group.status}</TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => handleEditClick(group)} aria-label="edit">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(group.locationGroupId)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        )}
        </TableBody>
      </Table>
 
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={locationGroups.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
  
  
    </TableContainer>
 
    {/* Confirmation Dialog */}
    <Dialog
      open={openDialog}
      onClose={handleCancelDelete}
      aria-labelledby="confirm-delete-dialog-title"
      aria-describedby="confirm-delete-dialog-description"
    >
      <DialogTitle id="confirm-delete-dialog-title">Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-dialog-description">
          Are you sure you want to delete this location group? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelDelete} className='btn-secondary-md' color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} className='btn-primary-md' color="secondary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>


    <Snackbar
        open={snackbar.open}
        autoHideDuration={4000} 
        onClose={handleSnackbarClose} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity} 
        >
          {snackbar.message} 
        </Alert>
      </Snackbar>

  </Box>
  </Paper>
  );
};

export default LocationGroup;
