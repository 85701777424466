import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
} from "@mui/material";
import {
  Send as SendIcon,
    Block as BlockIcon,
  MoreVert as MoreVertIcon,
  Search as SearchIcon,
  Done as DoneIcon,
  Alarm as AlarmIcon,
} from "@mui/icons-material";

const ChatPage = () => {
  const [messages, setMessages] = useState([
    { text: "Hey Admin", sender: "David", time: "8:20 PM" , userIcon: "OP" },
    { text: "Okay, thanks for reminder.", sender: "Admin", time: "8:30 PM", userIcon: "AD" },
  ]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [selectedContact, setSelectedContact] = useState("David");

  const [anchorEl, setAnchorEl] = useState(null);

  const contacts = [
    { name: "David", lastMessage: "Thank you..", time: "8:20 PM", unread: false, avatar: "OP" },
    { name: "John", lastMessage: "Thank you..", time: "16-01-2024", unread: true, avatar: "JS" },
  ];

  const handleSendMessage = () => {
    if (currentMessage.trim()) {
      setMessages([
        ...messages,
        { text: currentMessage, sender: "Admin", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) },
      ]);
      setCurrentMessage("");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
  sx={{
    margin: "0 auto", 
    padding: "15px",
    overflow: "hidden", 
    backgroundColor: "#f9f9f9", 
 
    height: "90vh", 

  }}
>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "90%",
        backgroundColor: "#f9f9f9",
        border: "1px solid #ddd",
        borderRadius: "8px",
      }}
    >
      {/* Left Panel - Message List */}
      <Box
        sx={{
          width: "25%",
          backgroundColor: "#fff",
          borderRight: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "#ffffff",
            color: "#000",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "500" }}>
            Message List
          </Typography>
        </Box>
        
        <TextField
          variant="outlined"
          size="small"
          placeholder="Message Search List"
          sx={{
            margin: "8px",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f7f7f7",
            },
          }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ marginRight: "8px", color: "#999" }} />,
          }}
        />
        <List sx={{ overflowY: "auto", flex: 1 }}>
          {contacts.map((contact, index) => (
            <ListItem
              button
              key={index}
              onClick={() => setSelectedContact(contact.name)}
              sx={{
                backgroundColor: selectedContact === contact.name ? "#f1f1f1" : "#fff",
                borderBottom: "1px solid #ddd",
              }}
            >
              <Avatar
                  sx={{
                    marginRight: "8px",
                    backgroundColor: "#FFF4DB", // Updated to light yellow
                    color: "#000", // Black text
                    border: "1px solid #D1A800", // Golden border
                  }}
                >
                {contact.avatar}
              </Avatar>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "500" }}
                  >
                    {contact.name}
                  </Typography>
                }
                secondary={contact.lastMessage}
              />
              <Box textAlign="right">
                <Typography
                  variant="caption"
                  sx={{ color: "#999", display: "block" }}
                >
                  {contact.time}
                </Typography>
                {contact.unread && (
                  <Badge
                    color="error"
                    variant="dot"
                    sx={{ marginTop: "4px" }}
                  />
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right Panel - Chat Area */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        {/* Chat Header */}
<Box
  sx={{
    padding: "16px",
    backgroundColor: "#ffffff",
    color: "#000",
    borderBottom: "1px solid #ddd",
    display: "flex", // Enable flexbox
    alignItems: "center", // Vertically align items
    gap: 1, // Add some spacing between avatar and text
  }}
>
  <Avatar
    sx={{
      backgroundColor: "#FFF4DB",
      color: "#000",
      border: "1px solid #D1A800",
    }}
  >
    AD
  </Avatar>
  <Typography variant="h6" sx={{ fontWeight: "500" }}>
    ADMIN
  </Typography>
</Box>


        {/* Messages */}
        <Box
          sx={{
            flex: 1,
            padding: "16px",
            overflowY: "auto",
            backgroundColor: "#f9f9f9",
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                margin: "8px 0",
                display: "flex",
                flexDirection: message.sender === "Admin" ? "row-reverse" : "row", // Align messages accordingly
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              
               {/* Avatar */}
     
     <Avatar
     sx={{
       backgroundColor: message.sender === "Admin" ? "#FFF4DB" : "#fdd835", // Light yellow for Admin
       color: message.sender === "Admin" ? "#000" : "#fff", // Black text for both cases
       border: message.sender === "Admin" ? "1px solid #D1A800" : "1px solid #ccc", // Add a golden border for Admin
     }}
   >
     {message.userIcon}
   </Avatar>
   

              <Box
                sx={{
                  backgroundColor: message.sender === "Admin" ? "#f5f5f5" : "#fff",
                  color: "#000",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  maxWidth: "100%",
                }}
              >
                <Typography variant="body1">{message.text}</Typography>
              </Box>
              <Typography
                variant="caption"
                sx={{ marginTop: "4px", color: "#999" }}
              >
                {message.time}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Message Input and Actions */}
        <Box
          sx={{
            padding: "16px",
            borderTop: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Type a message..."
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            sx={{ marginRight: "8px" }}
          />
          <IconButton
            aria-controls="message-menu"
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ color: "#999", marginRight: "8px" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="message-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
             <MenuItem onClick={handleClose}>
                <BlockIcon sx={{ marginRight: "8px" }} />
                No action need
              </MenuItem>
            <MenuItem onClick={handleClose}>
              <DoneIcon sx={{ marginRight: "8px", color: "#4caf50" }} />
              Complete
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <AlarmIcon sx={{ marginRight: "8px", color: "#ff9800" }} />
              Reminder
            </MenuItem>
          </Menu>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleSendMessage}
            sx={{
              backgroundColor: "#fdd835",
              color: "#fff",
              "&:hover": { backgroundColor: "#fbc02d" },
            }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default ChatPage;
