
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postTokenAction } from '../redux/actions/gailActions';

function Gail() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch(); // Hook to access the dispatch function

  const gaildata = useSelector((state) => state.gail.gailResponse);
  console.log("gaildata", gaildata);

  // Function to extract query parameters from the URL
  const getQueryParams = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const token = getQueryParams('quote'); // Extract the token from the query parameters

  console.log("gaitoken in gail file", token);

  // useEffect(() => {
  //   const postToken = async () => {
  //     try {
  //       // Create a FormData object to hold the token
  //       const formData = new FormData();
  //       formData.append('token', token);

  //       // Dispatch the postTokenAction with formData
  //       // const response = await dispatch(postTokenAction(token));
  //       await dispatch(postTokenAction(token));

  //       // Check if the response is successful
  //       if (gaildata) {
  //         const id = 11;
  //         navigate(`/quote/${gaildata}`);
  //       }
  //       else
  //       {
  //           console.error('Error posting token:');
  //       }
  //     } catch (error) {
  //       console.error('Error posting token:', error);
  //     }
  //   };

  //   // Only call the function if a token is available
  //   if (token) {
  //     postToken();
  //   }
  // }, [token, navigate, dispatch]);


  useEffect(() => {
    const postToken = async () => {
      try {
        // Dispatch the postTokenAction with the token
        await dispatch(postTokenAction(token));
      } catch (error) {
        console.error("Error posting token:", error);
      }
    };

    // Only call the function if a token is available
    if (token) {
      postToken();
    }
  }, [token, dispatch]);

  useEffect(() => {
    // Navigate to `/quote/${gaildata}` once `gaildata` is available
    if (gaildata) {
      navigate(`/quotes/${gaildata}`);
    }
  }, [gaildata, navigate]);

  return <div></div>;
}

export default Gail;
