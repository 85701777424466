import apiService from "./apiServices";

export const fetchData = async (token, page, size) => {
    try {
      const response = await apiService(`/quoteslist/all?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'Dashboard data is failed. Please try again.');
    }
  }

  // export const fetchData = async (token, page = 0, size) => {
  //   try {
  //     const response = await apiService(`/quoteslist/all?page=${page}&size=${size}`, 'GET', null, null, token);
  //     return response;
  //   } catch (error) {
  //     throw (error?.message || 'Dashboard data is failed. Please try again.');
  //   }
  // }

  export const fetchQuoteFilteredData = async (formData, token, page, size) => {    
    try {
        const response = await apiService(`/quoteslist/search?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const fetchDashboardPolicyData = async (token, page, size) => {
    try {
      const response = await apiService(`/policy/dashboard/policies?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'Dashboard data is failed. Please try again.');
    }
  }

  export const fetchPolicyFilteredData = async (formData, token, page, size) => {    
    try {
        const response = await apiService(`/policy/searchType?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const fetchDashboardTask = async (token, page, size) => {
    try {
      const response = await apiService(`/addtask/all?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'Dashboard data is failed. Please try again.');
    }
  }

  export const fetchTaskSearch = async (formData, token, page, size) => {
    try {
      const response = await apiService(`/addtask/searchTask?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'Dashboard data is failed. Please try again.');
    }
  }

  export const fetchAllPolicyType = async (token, page, size) => {
    try {
      const response = await apiService(`/policy/allPolicyType?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'Dashboard data is failed. Please try again.');
    }
  }

  export const fetchPolicyTypeFilteredData = async (formData, token, page, size) => {    
    try {
        const response = await apiService(`/policy/searchPolicyType?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const fetchTaskTypeData = async (token, page, size) => {    
    try {
        const response = await apiService(`/addtask/allTaskType?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const fetchFilterTaskTypeData = async (formData, token, page, size) => {    
    try {
        const response = await apiService(`/addtask/searchTaskType?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const fetchPriorityTaskData = async (token,page,size) => {    
    try {
        const response = await apiService(`/addtask/allTaskPriority?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const fetchFilterPriorityTaskData = async (formData, token, page,size) => {    
    try {
        const response = await apiService(`/addtask/searchByPriority?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
        return response;
    } catch (error) {
        console.error('Error fetching filtered data:', error);
    }
  }

  export const updatedDashboardData = async (formData, token) => {
    try {
      const response = await apiService('/addtask/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating profile is failed. Please try again.');
      }
  }
  
  
export const managePolicyAllListingServices = async (token, page, size) => {    

  try {
      const response = await apiService(`/policy/manage/policies?page=${page}&size=${size}&sort=desc`, 'GET',null, null, token);
      return response;
  } catch (error) {
      console.error('Error fetching filtered data:', error);
  }
}

export const searchPolicy = async(formData, token, page, size) => {
  
  try {
      const response = await apiService(`/policy/search?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'search policy service is failed. Please try again.');
    }
}
