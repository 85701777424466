import apiService from "./apiServices";

export const saveLocation = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/addLocation/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving location is failed. Please try again.');
        }
  }

  export const updateLocation = async (formData, token) => {
    try {

      const response = await apiService('/addLocation/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating location is failed. Please try again.');
      }
  }

  export const getAllLocation = async (token,page ,size) => {
    try {

      const response = await apiService(`/addLocation/?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
   
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const getDeleteLocation = async (locationId, token) => {
    try {

      const response = await apiService(`/addLocation/delete/${locationId}`, 'DELETE', null, null, token);

      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const getAllPermissionService = async (token) => {
    try {
      const response = await apiService('/permission/user/reachable-permissions', 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error.message || 'Service is failed. Please try again')
    }
  }

