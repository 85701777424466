import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
    Button,
    TextField,
    Grid,
    Box,
    Container,
    CssBaseline,
    Snackbar,
    Alert,
    CircularProgress,
    Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { validateEmail } from '../common/helpers/validation';
import { signUp as signUpAction, clearAuthError } from '../redux/actions/authActions';

const useStyles = makeStyles({
    logo: {
        maxWidth: "120px",
        color: "#077BFD",
    },
    loginBox: {
        padding: "1.5em",
        boxShadow: "0em .35em .5em #ccc",
        border: "1px solid #ddd",
    },
    link: {
        fontSize: ".85em",
    }
});

const SignUp = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();    
    const loading = useSelector((state) => state.loading.SIGN_IN);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
    const auth = useSelector((state) => state.auth);


    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        customer: '',
    });
    const [touchedFields, setTouchedFields] = React.useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        customer: false,
    });

    const [firstNameError, setFirstNameError] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [customerError, setCustomerError] = React.useState('');

    useEffect(() => {
        if (auth.error && auth.error.includes("User already exists")) {
            setEmailError(auth.error);
        }
    }, [auth.error]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    
        // Clear the email error when the user starts typing in the email field
        if (name === 'email') {
            setEmailError('');
        }
    
        if (name === 'password') {
            validatePassword(value);
        }
    };

    const validatePassword = (password) => {
        let passwordPolicyError = '';
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasNumericChar = /[0-9]/.test(password);

        if (password.length < 8) {
            passwordPolicyError = 'Password must be at least 8 characters long.';
        } else if (!hasUpperCase) {
            passwordPolicyError = 'Password must contain at least one uppercase letter.';
        } else if (!hasSpecialChar) {
            passwordPolicyError = 'Password must contain at least one special character.';
        } else if (!hasNumericChar) {
            passwordPolicyError = 'Password must contain at least one numeric character.';
        }

        setPasswordError(passwordPolicyError);
    };

    const validateField = (value, field) => {
        if (value.trim().length < 3) {
            return `${field} must be at least 5 characters long.`;
        }
        return '';
    };

    const handleBlur = (field) => {
        setTouchedFields((prevTouched) => ({
            ...prevTouched,
            [field]: true,
        }));

        const value = formData[field];
        let error = '';

        switch (field) {
            case 'firstName':
                error = validateField(value, 'First Name');
                setFirstNameError(error);
                break;
            case 'lastName':
                error = validateField(value, 'Last Name');
                setLastNameError(error);
                break;
            case 'email':
                error = validateEmail(value);
                setEmailError(error);
                break;
            case 'customer':
                error = validateField(value, 'Customer');
                setCustomerError(error);
                break;
            case 'password':
                validatePassword(value);
                break;
            default:
                break;
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Clear previous errors
        dispatch(clearAuthError());
    
        const firstNameError = validateField(formData.firstName, 'First Name');
        const lastNameError = validateField(formData.lastName, 'Last Name');
        const emailError = validateEmail(formData.email);
        const customerError = validateField(formData.customer, 'Customer');
        validatePassword(formData.password);
    
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);
        setEmailError(emailError);
        setCustomerError(customerError);
    
        if (firstNameError || lastNameError || emailError || customerError || passwordError) {
            return;
        }
    
        try {
            const formDataParameters = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                password: formData.password,
                customerName: formData.customer,
            };
    
            await dispatch(signUpAction(formDataParameters));
    
            if (auth.token) {
                navigate('/login');
            }
        } catch (error) {
            const responseError = error?.errors?.[0]?.errorMessage || 'An unknown error occurred.';
            if (responseError.includes("User already exists")) {
                setEmailError(responseError);
            } else {
                handleSnackbarOpen(responseError || 'Something went wrong. Please try again.', 'error');
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <h1 className={classes.logo}>BizAiTek</h1>
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} className={classes.loginBox}>
                    <Box sx={{ marginBottom: '10px' }}>
                        <label>First Name</label>
                        <TextField
                            required
                            fullWidth
                            id="firstName"
                            name="firstName"
                            autoComplete="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur('firstName')}
                            error={touchedFields.firstName && !!firstNameError}
                            helperText={touchedFields.firstName ? firstNameError : ''}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '10px' }}>
                        <label>Last Name</label>
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            name="lastName"
                            autoComplete="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur('lastName')}
                            error={touchedFields.lastName && !!lastNameError}
                            helperText={touchedFields.lastName ? lastNameError : ''}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '10px' }}>
                        <label>Email Address</label>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur('email')}
                            error={touchedFields.email && !!emailError}
                            helperText={touchedFields.email ? emailError : ''}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '10px' }}>
                        <label>Password</label>
                        <Tooltip
                            title="Password must be at least 8 characters long and contain at least one uppercase letter, one numeric character, and one special character."
                            arrow
                        >
                            <TextField
                                required
                                fullWidth
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={formData.password}
                                onChange={handleInputChange}
                                onBlur={() => handleBlur('password')}
                                error={touchedFields.password && !!passwordError}
                                helperText={touchedFields.password ? passwordError : ''}
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{ marginBottom: '10px' }}>
                        <label>Customer</label>
                        <TextField
                            required
                            fullWidth
                            id="customer"
                            name="customer"
                            autoComplete="customer name"
                            value={formData.customer}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur('customer')}
                            error={touchedFields.customer && !!customerError}
                            helperText={touchedFields.customer ? customerError : ''}
                        />
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="secondary" /> : 'Sign Up'}
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link className={classes.link} to="/login" variant="body2">
                                {"Do you have an account? Sign In"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default SignUp;
