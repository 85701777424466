import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { saveVehicleCoverageAction, getVehicleCoverageAction } from '../../../redux/actions/vehicleInfoActions';
import { coll, comp, biLimitPerPerson, liabbi_limit2, liabbi_limit1, liabbi, liabpd, biLimitPerAccident, pdLimit } from '../../../common/StaticFieldList';
import { getDetailPolicyAction } from '../../../redux/actions/policyActions';
import PolicyPremiumListing from './PolicyPremiumListing';
 
const PremiumDetails = ({ policyId, vehicleId }) => {
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false)
  const customerData = useSelector((state) => state.policy.allDetailPolicy);
  const vehicleCoverageData = customerData?.drivers?.vehicles?.coverages || [];
  const vehiclelist = customerData?.drivers?.vehicles || []
  const [coverages, setCoverages] = useState(vehicleCoverageData);
  const [limit1Options, setLimit1Options] = useState([]);
  const [getResponse, setGetResponse] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newCoverage, setNewCoverage] = useState({
    type: "",
    limit1: null,
    limit2: null,
    deductible: "",
    premium: null,
    vehicleId: vehicleId,
  });
 
  useEffect(() => {
      setNewCoverage((prevState) => ({
        ...prevState,
        vehicleId: vehicleId,
      }));
    }, [vehicleId]);
 
  useEffect(() => {
    if(vehicleId && getResponse && policyId){
      setFlag(true)
    }
    if (policyId || vehicleId) {
      dispatch(getDetailPolicyAction(policyId));
    }
  }, [dispatch, policyId, vehicleId, getResponse]);
 
  useEffect(() => {
    if (vehicleCoverageData.length !== coverages.length) {
      setCoverages(vehicleCoverageData);
    }
  }, [vehicleCoverageData]);
 
  const extractCoverageData = (data) => {
    return data.drivers?.flatMap((driver) =>
      driver?.vehicles?.flatMap((vehicle) => vehicle.coverages)
    );
  };
  const coveragesdata = extractCoverageData(customerData);
 
  const handleChangeCoverage = (e) => {
    const { name, value } = e.target;
 
    if (name === "type") {
      setNewCoverage({ ...newCoverage, type: value });
      setLimit1Options(value === "bi" || value === "pd" ? liabbi : []);
     
      // Dynamically reset fields for bi/pd or comp/coll
      const deductibleOptions = value === "comp" ? comp : value === "coll" ? coll : [];
      setNewCoverage((prev) => ({
        ...prev,
        limit1: value === "bi" || value === "pd" ? "" : prev.limit1,
        limit2: value === "bi" || value === "pd" ? "" : null,
        deductible: deductibleOptions.length ? deductibleOptions[0]?.value || "" : "",
      }));
    } else {
      setNewCoverage({ ...newCoverage, [name]: value });
    }
 
    if (value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };
 
  const validate = () => {
    const tempErrors = {};
  
    if (!newCoverage.type.trim()) tempErrors.type = "Type is required";
    if (!newCoverage.premium || isNaN(newCoverage.premium) || newCoverage.premium <= 0) {
      tempErrors.premium = "Premium is required";
    }
    if ((newCoverage.type === "comp" || newCoverage.type === "coll") && !newCoverage.deductible) {
      tempErrors.deductible = "Deductible is required";
    }
    if (["bi", "pd", "medPay", "pipDed", "umbi"].includes(newCoverage.type) && !newCoverage.limit1) {
      tempErrors.limit1 = "Limit 1 is required";
    }
    if (["bi", "medPay", "umbi"].includes(newCoverage.type) && !newCoverage.limit2) {
      tempErrors.limit2 = "Limit 2 is required";
    }
  
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  
  const callbackfn = (res) => {
    setGetResponse(res);
  };
  
  const handleAddCoverage = () => {
    setIsSubmitted(true); // Set to true when button is clicked
  
    if (validate()) {
      const payload = { ...newCoverage };
  
      if (!newCoverage.limit1 || newCoverage.limit1.trim() === "" || ["comp", "coll"].includes(newCoverage.type)) {
        payload.limit1 = null;
      }
      if (!newCoverage.limit2 || newCoverage.limit2.trim() === "" || ["comp", "coll"].includes(newCoverage.type)) {
        payload.limit2 = null;
      }
      if (!["comp", "coll"].includes(newCoverage.type)) {
        payload.deductible = null;
      }
  
      dispatch(saveVehicleCoverageAction(payload, callbackfn));
      dispatch(getVehicleCoverageAction())
      // Reset state and close the dialog
      setOpen(false);
    }
  };
 
  const handleCancel = () => {
    setNewCoverage({
      type: "",
      limit1: "",
      limit2: "",
      deductible: "",
      premium: "",
      vehicleId: policyId,
    });
    setOpen(false);  
  };
 
  return (
    <div>
      <PolicyPremiumListing listing={coveragesdata} />
 
      {/* <Button variant="contained" id="addcoverage" className='btn-primary' onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
        Add Coverage
      </Button>
  */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="addcoveragetitle" sx={{backgroundColor: '#ffae00'}}>Add Coverage</DialogTitle>
          <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {Object.keys(newCoverage)
              .filter((key) => key !== "vehicleId")
              .map((key) => (
                key === "type" ? (
                  // Type Dropdown
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <label className='fnt-we'>Type</label>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        id="typeselect"
                        name="type"
                        value={newCoverage.type}
                        onChange={handleChangeCoverage}
                      >
                        <MenuItem value="comp">Comp</MenuItem>
                        <MenuItem value="coll">Coll</MenuItem>
                        <MenuItem value="bi">BI</MenuItem>
                        <MenuItem value="pd">PD</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : key === "limit1" && (newCoverage.type === "bi" || newCoverage.type === "pd") ? (
                  // Limit 1 Dropdown
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <label className='fnt-we'>Limit 1</label>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        id="limit1select"
                        name="limit1"
                        value={newCoverage.limit1}
                        onChange={handleChangeCoverage}
                      >
                        {liabbi_limit1.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : key === "limit2" && newCoverage.type === "bi" ? (
                  // Limit 2 Dropdown
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <label className='fnt-we'>Limit 2</label>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        id="limit2select"
                        name="limit2"
                        value={newCoverage.limit2}
                        onChange={handleChangeCoverage}
                      >
                        {liabbi_limit2.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : key === "deductible" && (newCoverage.type === "comp" || newCoverage.type === "coll") ? (
                  // Deductible Dropdown
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <label className='fnt-we'>Deductible</label>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        id="deductibleselect"
                        name="deductible"
                        value={newCoverage.deductible}
                        onChange={handleChangeCoverage}
                      >
                        {(newCoverage.type === "comp" ? comp : coll).map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : key === "premium" ? (
                  // Premium Field
                  <Grid item xs={12} sm={6} key="premium">
                    <Typography>
                      <label className='fnt-we'>Premium</label>
                    </Typography>
                    <TextField
                      fullWidth
                      name="premium"
                      value={newCoverage.premium}
                      onChange={handleChangeCoverage}
                      error={!!errors.premium}
                      helperText={errors.premium}
                      type="number" // Numeric input
                    />
                  </Grid>
                ) : null
              ))}
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} id="cancelbutton" className='btn-secondary' color="secondary">Cancel</Button>
          <Button onClick={handleAddCoverage} id="addbutton" className='btn-secondary' color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
 
export default PremiumDetails;
 
