import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
  TablePagination,
} from "@mui/material";
import { Snackbar, Alert } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Botlogin from "./Botlogin";
import { useDispatch, useSelector } from "react-redux";
import {
  savebotAction,
  getAllBotAction,
  getBotByUserIdAction,
  getBotLoginAction,
  updateBotAction,
  savebotsignupAction,
  getBotCompanyAction
} from "../../redux/actions/botAction";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

// const staticEntries = [
//   {
//     id: "static-1",
//     carrierName: "Pearl Harobour",
//     startDate: "",
//     carrierAgencyCode: "",
//     carrierAgencyUserId: "",
//     carrierAgencyUserPassword: "",
//     status: "Coming Soon",
//     isReadOnly: true,
//     isPrefilled: true,
//     displayNumber: 3,
//   },
//   {
//     id: "static-2",
//     carrierName: "Geico",
//     startDate: "",
//     carrierAgencyCode: "",
//     carrierAgencyUserId: "",
//     carrierAgencyUserPassword: "",
//     status: "Coming Soon",
//     isReadOnly: true,
//     isPrefilled: true,
//     displayNumber: 4,
//   },
// ];



// const createEmptyEntry = (index) => {
//   // Define preset carrier names for first two entries
//   const presetCarriers = {
//     0: "Progressive",
//     1: "UAIC",
//   };

//   return {
//     id: `dynamic-${index + 1}`,
//     originalId: null,
//     displayNumber: index + 1,
//     checked: false,
//     carrierName: presetCarriers[index] || "", // Use preset name if available
//     startDate: "",
//     carrierAgencyCode: "",
//     carrierAgencyUserId: "",
//     carrierAgencyUserPassword: "",
//     status: "",
//     isEditing: true,
//     isPrefilled: false,
//     isReadOnly: false, // Not read-only by default
//     carrierNameReadOnly: index < 2,
//   };
// };


const formatDate = (date) => {
  if (!date) return "";
  const formattedDate = dayjs(date);
  return formattedDate.isValid() ? formattedDate.format("MM-DD-YYYY") : "";
};

export default function SignupBot() {
  const [entries, setEntries] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [flag, setFlag] = useState(false);
  const [refreshUser, setRefreshUser] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(14);
const [snackbarMessage, setSnackbarMessage] = useState('');
  const userId = useSelector((state) => state.user?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logindetails = useSelector((state) => state.bot.getLoginBot);
  const botResponse = useSelector((state) => state.bot.getBots);
  const botcompanydetails = useSelector((state) => state.bot.getbotcompany);
  const botcompanyvalue=botcompanydetails.content || [];
  // const carrierNames = botcompanyvalue.map((item) => item.carrierName);
  console.log("botcompanydetails",botcompanydetails);
  // const staticCarrierNames = botcompanyvalue.slice(3).map((item) => item.carrierName);
  const staticCarrierNames = botcompanyvalue.slice(-10).map((item) => item.carrierName);
  const dynamicName = botcompanyvalue.slice(0, 4).map((item) => item.carrierName);
  // console.log("staticCarrierNames",staticCarrierNames);
  const loginid = userId?.id || [];
  const email = userId?.email || [];

  const presetCarriers = {
    0: "Progressive",
    1: "UAIC",
    2:"Pearl Harobour",
  };
  const carrierNames = [
    ...Object.values(presetCarriers), // Preset carriers
    ...botcompanyvalue
      .map((company) => company.carrierName) // Dynamic carriers from botcompanyvalue
      .filter((name) => !Object.values(presetCarriers).includes(name)), // Exclude duplicates
  ];

  const staticEntries = staticCarrierNames.map((carrierName, index) => ({
    id: `static-${index + 1}`,
    carrierName,
    startDate: "",
    carrierAgencyCode: "",
    carrierAgencyUserId: "",
    carrierAgencyUserPassword: "",
    status: "Coming Soon",
    isReadOnly: true,
    isPrefilled: true,
    displayNumber: index + 3, // Dynamic display number
  }));

  const createEmptyEntry = (index) => {
    return {
      id: `dynamic-${index + 1}`,
      originalId: null,
      displayNumber: index + 1,
      checked: false,
      carrierName: carrierNames[index] || "", // Use the merged carrier list
      startDate: "",
      carrierAgencyCode: "",
      carrierAgencyUserId: "",
      carrierAgencyUserPassword: "",
      status: "",
      isEditing: true,
      isPrefilled: false,
      isReadOnly: false, // Not read-only by default
      carrierNameReadOnly: index < Object.keys(presetCarriers).length, // Read-only for preset entries
    };
  };

  // const usersWithId9 = logindetails.filter((item) => item.userId === loginid);
  // console.log("userswith9", usersWithId9);

  useEffect(() => {
    dispatch(getBotLoginAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBotCompanyAction(page,
      rowsPerPage));
  }, [dispatch,page, rowsPerPage]);

  useEffect(() => {
    if (logindetails && logindetails.length > 0) {
      const userFound = logindetails.some((entry) => entry.userId === loginid);
      setFlag(userFound);
    }
    dispatch(getBotByUserIdAction(loginid));
  }, [logindetails, loginid]);


  useEffect(() => {
    // Force the carrier names in the exact order we want
    const carrierNames = dynamicName; // Fixed spelling to match data

    // Convert botResponse to array if it's a single object
    const botResponseArray = Array.isArray(botResponse)
      ? botResponse
      : (botResponse ? [botResponse] : []);

    // Create entries for all three carriers
    const dynamicEntries = carrierNames.map((carrierName, index) => {
      // Find matching entry from botResponse
      const existingEntry = botResponseArray.find(
        entry => entry.carrierName === carrierName
      );

      if (existingEntry) {
        // Use existing entry data if found
        return {
          ...existingEntry,
          originalId: existingEntry.id,
          id: existingEntry.id,
          displayNumber: index + 1,
          startDate: existingEntry.startDate,
          checked: true,
          isEditing: false,
          isPrefilled: true,
          status: existingEntry.status || "",
          isReadOnly: false,
          carrierNameReadOnly: true,
          carrierName: carrierName
        };
      } else {
        // Create empty entry for carriers without data
        return {
          id: `empty-${index}`,
          displayNumber: index + 1,
          startDate: "",
          checked: false,
          isEditing: false,
          isPrefilled: false,
          status: "",
          isReadOnly: false,
          carrierNameReadOnly: true,
          carrierName: carrierName
        };
      }
    });

    // console.log('botResponseArray:', botResponseArray);
    // console.log('Final dynamicEntries:', dynamicEntries);

    // Combine with static entries
    setEntries([...dynamicEntries, ...staticEntries]);
  }, [botResponse]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // dispatch(getBotCompanyAction(page,
    //   newRowsPerPage));
  };


  const handleBotLoginSubmit = async (formData) => {
    try {
      // Validate formData before proceeding
      const hasEmptyFieldInFormData = Object.values(formData).some((value) => !value);

      if (hasEmptyFieldInFormData) {
        // Show error message if validation fails
        setRefreshUser(false);
        setSnackbarMessage('Please fill out all required fields before submitting.');
        setSnackbarOpen(true);
        return false; // Exit the function to prevent submission
      }



      // Then trigger the saveAll functionality
      const selectedEntries = entries
        .filter((entry) => entry.checked && !entry.isReadOnly)
        .map((entry) => ({
          carrierName: entry.carrierName,
          startDate: entry.startDate,
          carrierAgencyCode: entry.carrierAgencyCode,
          carrierAgencyUserId: entry.carrierAgencyUserId,
          carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
          status: entry.status,
          purchasedByUserId: loginid,
          purchasedByUserEmail: email,
        }));

      // Validation: Check if any selectedEntries fields are empty
      const hasEmptyFieldInSelectedEntries = selectedEntries.some((entry) => {
        return (
          !entry.carrierName ||
          !entry.startDate ||
          // !entry.carrierAgencyCode ||
          !entry.carrierAgencyUserId ||
          !entry.carrierAgencyUserPassword
        );
      });


      if (hasEmptyFieldInSelectedEntries) {
        // Show error message if validation fails
        setRefreshUser(false);
        setSnackbarMessage('Please fill out all required fields in the entries before saving.');
        setSnackbarOpen(true);
        return; // Exit the function to prevent saving
      }
      setRefreshUser(true);
      await dispatch(savebotsignupAction(formData));


      // Proceed with saving if validation is successful
      if (selectedEntries.length > 0) {
        setRefreshUser(true);
        await dispatch(
          savebotAction(selectedEntries, (response) => {
            if (response) {
              dispatch(getBotByUserIdAction(loginid));

            }
          })
        );
      }
    } catch (error) {
      console.error("Error in form submission:", error);
    }
  };


  const handleSave = (id) => {
    const entryToUpdate = entries.find((entry) => entry.id === id);

    // Only process save for non-static entries
    if (
      entryToUpdate &&
      !entryToUpdate.isReadOnly &&
      entryToUpdate.originalId
    ) {
      const updatedEntry = {
        id: entryToUpdate.originalId,
        carrierName: entryToUpdate.carrierName,
        startDate: formatDate(entryToUpdate.startDate),
        carrierAgencyCode: entryToUpdate.carrierAgencyCode,
        carrierAgencyUserId: entryToUpdate.carrierAgencyUserId,
        carrierAgencyUserPassword: entryToUpdate.carrierAgencyUserPassword,
        status: entryToUpdate.status || "Active",
        purchasedByUserId: loginid,
        purchasedByUserEmail: email,
      };

      try {
        // dispatch(
        //   updateBotAction(updatedEntry, (response) => {
        //     // console.log("Bot data updated successfully:", response);
        //     dispatch(getBotByUserIdAction(loginid));
        //   })
        // );
        dispatch(updateBotAction(updatedEntry));
        dispatch(getBotByUserIdAction(loginid));
        // setSnackbarMessage('Data updated successfully!');
        // setSnackbarOpen(true);
        window.location.reload();
        setSnackbarMessage('Data updated successfully!');
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error in updateBotAction:", error);
      }
    }

    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, isEditing: false } : entry
      )
    );
  };

  const handleInputChange = (id, field, value) => {
    const entry = entries.find((e) => e.id === id);
    if (entry?.isReadOnly) return;

    // Don't allow carrier name changes for first two entries
    if (field === "carrierName" && entry?.carrierNameReadOnly) return;

    const lettersOnly = /^[A-Za-z]*$/;
    const numbersOnly = /^[0-9]*$/;
    const maxLength = 20;

    if (field === "startDate") {
      setEntries((prevEntries) =>
        prevEntries.map((entry) =>
          entry.id === id ? { ...entry, [field]: value } : entry
        )
      );
      return;
    }

    // Special handling for status changes in dynamic entries
    if (field === "status") {
      setEntries((prevEntries) =>
        prevEntries.map((entry) => {
          if (entry.id === id) {
            const isActivated = value === "Active";
            // For prefilled/dynamic entries, update both status and checked
            if (!entry.isReadOnly) {
              // If the entry is prefilled and has an originalId, update it in the backend
              if (entry.isPrefilled && entry.originalId) {
                const updatedEntry = {
                  id: entry.originalId,
                  carrierName: entry.carrierName,
                  startDate: formatDate(entry.startDate),
                  carrierAgencyCode: entry.carrierAgencyCode,
                  carrierAgencyUserId: entry.carrierAgencyUserId,
                  carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
                  status: value,
                  purchasedByUserId: loginid,
                  purchasedByUserEmail: email,
                };

                dispatch(
                  updateBotAction(updatedEntry, (response) => {
                    dispatch(getBotByUserIdAction(loginid));
                  })
                );
              }
              return {
                ...entry,
                [field]: value,
                checked: isActivated, // Automatically check/uncheck based on Active status
              };
            }
            return { ...entry, [field]: value };
          }
          return entry;
        })
      );
      return;
    }

    if (field === "carrierName") {
      if (!lettersOnly.test(value) || value.length > maxLength) return;
    } else if (field === "carrierAgencyCode") {
      if (!numbersOnly.test(value) || value.length > maxLength) return;
    }

    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, [field]: value } : entry
      )
    );
  };

  // Also update the handleCheckboxChange function to sync status with checkbox
  const handleCheckboxChange = (id) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry) => {
        if (entry.id === id && !entry.isReadOnly) {
          const newChecked = !entry.checked;
          // Update status based on checkbox state for prefilled entries
          if (entry.isPrefilled) {
            const newStatus = newChecked ? "Active" : "Inactive";
            // If the entry is prefilled and has an originalId, update it in the backend
            if (entry.originalId) {
              const updatedEntry = {
                id: entry.originalId,
                carrierName: entry.carrierName,
                startDate: formatDate(entry.startDate),
                carrierAgencyCode: entry.carrierAgencyCode,
                carrierAgencyUserId: entry.carrierAgencyUserId,
                carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
                status: newStatus,
                purchasedByUserId: loginid,
                purchasedByUserEmail: email,
              };

              dispatch(
                updateBotAction(updatedEntry, (response) => {
                  dispatch(getBotByUserIdAction(loginid));
                })
              );
            }
            return {
              ...entry,
              checked: newChecked,
              status: newStatus,
            };
          }
          return { ...entry, checked: newChecked };
        }
        return entry;
      })
    );
  };

  const handleEdit = (id) => {
    const entry = entries.find((e) => e.id === id);
    if (entry?.isReadOnly) return;

    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id && entry.isPrefilled
          ? { ...entry, isEditing: true }
          : entry
      )
    );
  };

  const handleSaveAll = () => {
    const selectedEntries = entries
      .filter((entry) => entry.checked && !entry.isReadOnly)
      .map((entry) => ({
        carrierName: entry.carrierName,
        startDate: formatDate(entry.startDate),
        carrierAgencyCode: entry.carrierAgencyCode,
        carrierAgencyUserId: entry.carrierAgencyUserId,
        carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
        status: entry.status || "Active",
        purchasedByUserId: loginid,
        purchasedByUserEmail: email,
        id: entry.originalId || null, // Include originalId if it exists
      }));

    // Validation: Check if any field is empty
    const hasEmptyField = selectedEntries.some((entry) => {
      return (
        !entry.carrierName ||
        !entry.startDate ||
        // !entry.carrierAgencyCode ||
        !entry.carrierAgencyUserId ||
        !entry.carrierAgencyUserPassword
      );
    });

    if (hasEmptyField) {
      // Show error message if validation fails
      setSnackbarMessage('Please fill out all required fields before saving.');
      setSnackbarOpen(true);
      return; // Exit the function to prevent saving/updating
    }

    const newEntries = selectedEntries.filter((entry) => !entry.id); // New entries (without originalId)
    const existingEntries = selectedEntries.filter((entry) => entry.id); // Prefilled entries (with originalId)

    // Save new entries if there are no validation errors
    if (newEntries.length > 0) {
      dispatch(
        savebotAction(newEntries, (response) => {
          if (response) {
            dispatch(getBotByUserIdAction(loginid));
            setSnackbarMessage('Data saved successfully!');
            setSnackbarOpen(true);
          }
        })
      );
    }

    // Update existing entries if there are no validation errors
    existingEntries.forEach((entry) => {
      dispatch(updateBotAction(entry));
      setSnackbarMessage('Data updated successfully!');
      setSnackbarOpen(true);
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Saving entries:", entries);
  };

  const handleSignup = () => {
    setDialogOpen(true);
    setFormVisible(true);
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setFormVisible(false);
  };


console.log("entries length",botcompanydetails .totalElements)
  return (
    <div className="max-w-4xl mx-auto" >

      <Box sx={{padding: "16px 50px", marginTop: '50px' }}>
          <Grid className="section-title" spacing={2}>
              <Typography
                variant="h5"
                component="h1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "22px",
                  fontWeight: 600,
                  lineHeight: "19.6px",
                  textAlign: "left",
                }}
              >
                Signup Bot
              </Typography>
            {flag && (
              <Button className="btn-secondary" onClick={handleSignup}>CRM</Button>
            )}
      </Grid>
  </Box>


  <form onSubmit={handleSubmit}>
  <Grid container spacing={2} sx={{ px: "32px" }}>
    {entries.map((entry) => (
      <Grid item xs={12} key={entry.id}>
        <Card
          variant="outlined"
          sx={{
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            '&:hover': { boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }
          }}
        >
          <CardContent sx={{ pt: 2, pb: 2 }}>
            <Grid container spacing={2} alignItems="center">
            <FormControlLabel
      control={
        <Checkbox
          checked={entry.checked}
          onChange={() => handleCheckboxChange(entry.id)}
          sx={{
            color: '#2196f3',
            '&.Mui-checked': { color: '#2196f3' },
            ml:1 // Adds left margin to position checkbox slightly right
          }}
        />
      }
      sx={{ pl: 2 }} // Adds padding to move the label and checkbox to the right
    />
              <Grid item xs={8} sm={5} md={1.6}>
                <Typography variant="body1" sx={{
                  mb: 1,
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  color: '#333'
                }}>
                  Carrier Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={entry.carrierName}
                  variant="outlined"
                  onChange={(e) => handleInputChange(entry.id, "carrierName", e.target.value)}
                  InputProps={{
                    readOnly: entry.carrierNameReadOnly || (entry.isPrefilled && !entry.isEditing) || entry.isReadOnly,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#fff',
                      '&:hover fieldset': { borderColor: '#2196f3' },
                      '& fieldset': { borderColor: '#242929' }
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={1.8}>
                <Typography variant="body1" sx={{
                  mb: 1,
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  color: '#333'
                }}>
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MM-DD-YYYY"
                    value={entry.startDate ? dayjs(entry.startDate) : null}
                    maxDate={dayjs("2040-04-04")}
                    minDate={dayjs("1940-04-04")}
                    onChange={(date) => handleInputChange(entry.id, "startDate", date ? formatDate(date) : "")}
                    disabled={entry.isPrefilled && !entry.isEditing}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: {
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: '#fff',
                            '& fieldset': { borderColor: '#242929' }
                          }
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={1.8}>
  <Typography variant="body1" sx={{
    mb: 1,
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#333'
  }}>
    Carrier Agency Code
  </Typography>
  <TextField
    fullWidth
    size="small"
    value={entry.carrierAgencyCode}
    variant="outlined"
    onChange={(e) => handleInputChange(entry.id, "carrierAgencyCode", e.target.value)}
    InputProps={{
      readOnly: entry.isPrefilled && !entry.isEditing,
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#fff',
        '&:hover fieldset': { borderColor: '#2196f3' },
        '& fieldset': { borderColor: '#242929' }
      }
    }}
  />
</Grid>

<Grid item xs={12} sm={6} md={1.8}>
  <Typography variant="body1" sx={{
    mb: 1,
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#333'
  }}>
    Carrier Agency User Id
  </Typography>
  <TextField
    fullWidth
    size="small"
    value={entry.carrierAgencyUserId}
    variant="outlined"
    onChange={(e) => handleInputChange(entry.id, "carrierAgencyUserId", e.target.value)}
    InputProps={{
      readOnly: entry.isPrefilled && !entry.isEditing,
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#fff',
        '&:hover fieldset': { borderColor: '#2196f3' },
        '& fieldset': { borderColor: '#242929' }
      }
    }}
  />
</Grid>

<Grid item xs={12} sm={6} md={2.2}>
  <Typography variant="body1" sx={{
    mb: 1,
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#333',
    whiteSpace: 'nowrap'
  }}>
    Carrier Agency Password
  </Typography>
  <TextField
    fullWidth
    size="small"
    type="password"
    value={entry.carrierAgencyUserPassword}
    variant="outlined"
    onChange={(e) => handleInputChange(entry.id, "carrierAgencyUserPassword", e.target.value)}
    InputProps={{
      readOnly: entry.isPrefilled && !entry.isEditing,
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#fff',
        '&:hover fieldset': { borderColor: '#2196f3' },
        '& fieldset': { borderColor: '#242929' }
      }
    }}
  />
</Grid>

<Grid item xs={10} sm={5} md={1.6}>
  <Typography variant="body1" sx={{
    mb: 1,
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#333'
  }}>
    Status
  </Typography>
  {entry.isReadOnly ? (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      value={entry.isReadOnly ? entry.status || "Coming Soon" : entry.status}
      onChange={(e) => {
        if (!entry.isReadOnly) {
          handleInputChange(entry.id, "status", e.target.value);
        }
      }}
      InputProps={{
        readOnly: entry.isReadOnly,
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#fff',
          '&:hover fieldset': { borderColor: '#2196f3' },
          '& fieldset': { borderColor: '#242929' }
        }
      }}
    />
  ) : (
    <FormControl fullWidth size="small" variant="outlined">
      <Select
        value={entry.status || "coming soon"}
        onChange={(e) => handleInputChange(entry.id, "status", e.target.value)}
        disabled={entry.isPrefilled && !entry.isEditing}
        sx={{
          backgroundColor: '#fff',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#242929'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2196f3'
          }
        }}
      >
        <MenuItem value="Active">Active</MenuItem>
        <MenuItem value="Inactive">Inactive</MenuItem>
      </Select>
    </FormControl>
  )}
</Grid>


<Grid item xs={10} sm={8} md={0.5} sx={{ display: 'flex', alignItems: 'flex-end', height: '100%', pb: 1 }}>
  <CardHeader
    sx={{
      p: 0,
      m: 0,
      '& .MuiCardHeader-action': {
        margin: 0,
        alignSelf: 'flex-end'
      }
    }}
    action={
      entry.isPrefilled && !entry.isReadOnly ? (
        entry.isEditing ? (
          <IconButton
            onClick={() => handleSave(entry.id)}
            sx={{
              color: '#2196f3',
              mt: '20px' // Aligns with form fields
            }}
          >
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => !entry.isReadOnly && handleEdit(entry.id)}
            disabled={entry.isReadOnly}
            sx={{
              color: '#2196f3',
              mt: '20px' // Aligns with form fields
            }}
          >
            <EditIcon />
          </IconButton>
        )
      ) : null
    }
  />
</Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
        <TablePagination
  component="div"
  count={botcompanydetails.totalElements || 0} // Added fallback for undefined
  page={page}
  onPageChange={handleChangePage}
  rowsPerPage={rowsPerPage}
  onRowsPerPageChange={handleChangeRowsPerPage}
  rowsPerPageOptions={
    botcompanydetails.totalElements === 0
      ? [5] // Show at least one option even when empty
      : [5, 10, 15, 25]
  }
  sx={{
    '.MuiTablePagination-select': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem'
    }
  }}
/>
      </Grid> */}
        <Typography
    variant="body2"
    color="textSecondary"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "19.6px",
      textAlign: "center",
      my: 4
    }}
  >
    Minimum Cost $100 per month, Cancel Anytime *2 cent for each information
  </Typography>

  <Grid className="footer-section">
    <Button
      id="submitbutton"
      onClick={handleCancel}
      className="btn-secondary"
      sx={{margin: '0 20px'}}
    >
      Cancel
    </Button>

    <Button
      id="actionButton"
      type="submit"
      onClick={flag ? handleSaveAll : handleSignup}
      variant="contained"
      className="btn-primary"
    >
      {flag ? "Save" : "Signup"}
    </Button>
  </Grid>
</form>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Signup Confirmation</DialogTitle>
        <DialogContent>
          <Botlogin
            toggleform={handleCloseDialog}
            onSubmitSuccess={handleBotLoginSubmit}
            logindetails={logindetails}
            loginid={loginid}
            refreshUser={refreshUser}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
