import { useCallback } from "react";
import {
  Button,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination
} from "@mui/material";
import dayjs from "dayjs";
import DriverManagement from "./driverComponents/DriverManagement";
import PolicyInformation from "./driverComponents/PolicyInformation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";
import FrameComponent from "./driverComponents/FrameComponent";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { Container } from "@mui/material";
import DriverSave from "./driverSave1";
import {
  deleteDriverInfoById,
  getAllDriverInfo,
  updateDriverInfo,
} from "../../redux/actions/driverInfoActions";
import DrvierTab from "./drivers";
import {
  alphanumericWithSpaceValidation,
  validateField,
} from "../../common/helpers/validation";
import { borderRadius } from "polished";
import { getGeneralPartyInfoById } from "../../redux/actions/generalPartyInfoActions";
import { populateValue } from "../../common/helpers/utils";
import Loader from "../loader/loader";
const roles = ["Market", "Finance", "Development"];

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-window": {
      borderTop: "none",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontFamily: '"Poppins", sans-serif',
  },
  thead: {
    backgroundColor: "#f5f5f5",
    textAlign: "left",
    fontWeight: 600,
    fontSize: "16px",
  },
  th: {
    padding: "12px 16px",
    textAlign: "left",
    fontWeight: '500',
    borderBottom: "2px solid #ddd",
  },
  tbody: {
    fontSize: "14px",
    color: "#333",
  },
  td: {
    padding: "12px 16px",
    borderBottom: "1px solid #ddd",
    fontWeight: '500',
  },
  actionCell: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
});

const initialRows = [
  {
    id: 1,
    name: "Chetan Wagh",
    age: 33,
    driver_type: "Rated",
    gender: "Male",
    marital: "Married",
    violations: 0,
    priorInsurance: "Yes",
  },
  {
    id: 2,
    name: "Rajiv",
    age: 34,
    gender: "Male",
    marital: "Married",
    violations: 1,
    priorInsurance: "No",
  },
];
const DriverInformationList = ({ handleNext, handleBack,id }) => {
  const dispatch = useDispatch();
  let { quoteId } = useParams();
  const classes = useStyles();
  const [showButton, setShowButton] = useState(false);
  const [showHideDriverForm, setShowHideDriverForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});

  const allDriverInfo = useSelector((state) => state.driverInfo.allDriverInfo);

  const generalPartyInfo = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );

  // console.log("allDriverInfo", generalPartyInfo);


  // const [rows, setRows] = React.useState(allDriverInfo);
  const [rows, setRows] = useState([]);
  const [driverData, setDriverData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false); // To control dialog visibility
  const [driverToDelete, setDriverToDelete] = useState(null); // Stores the driver ID to be deleted
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const [formState, setFormState] = useState({
    dob: "",
    driverTrainingDate: "",
    driverLicenseDate: "",
    fieldErrors: {}
  });
  
  useEffect(() => {
    if (id) {
      dispatch(getGeneralPartyInfoById(id));


    } else {
      dispatch(getGeneralPartyInfoById(quoteId));
    }
  }, [id, quoteId, dispatch]);
  // dispatch(getAllDriverInfo(id));

  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getAllDriverInfo(quoteId));
    }
    else
    {
      // console.log("id in driver useeffect",id);
      dispatch(getAllDriverInfo(id));
    }
    // }
  }, [id, quoteId, dispatch]);

  useEffect(() => {
    allDriverInfo.filter((row) => (row.id = row.did));
    setRows(allDriverInfo);
  }, [allDriverInfo]);


  useEffect(() => {
    setRows(allDriverInfo);
  }, [allDriverInfo]);

  // Handle dialog open when delete icon is clicked
  const handleOpenDialog = (id) => {
    setDriverToDelete(id);
    setOpenDialog(true);
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
    setDriverToDelete(null); // Clear the driver to delete
  };
  
  // Handle delete logic
  const handleConfirmDelete = async () => {
    if (driverToDelete !== null) {
      // Start loading
      handleCloseDialog();
  
      setIsLoading(true); // Show the loader in the table

      // Perform the delete action
      const response = await dispatch(deleteDriverInfoById(driverToDelete));
  
      if (response) {
        // Simulate removing the deleted driver from the table
        setRows(rows.filter((row) => row.id !== driverToDelete));
      }
      setIsLoading(false);  // Stop loading
      // After deletion, reload data or re-fetch the driver list
      // Simulating data re-fetching
    }
   

  
  };
  


  // console.log("row", rows);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => async () => {
    setShowHideDriverForm(true);
    setShowButton(true);
    const editedRow = rows.find((row) => row.id === id);
    console.log("\ud83d\ude80 Edited Row Data:", editedRow);
    setDriverData(editedRow);
    setFormState((prevState) => ({
      ...prevState,
      dob: editedRow?.dob || "",
      driverTrainingDate: editedRow?.driverTrainingDate || "",
      driverLicenseDate: editedRow?.driverLicenseDate || "",
    }));
    console.log("\ud83d\ude80 Updated Form Data State:", {
      dob: editedRow?.dob || "",
      driverTrainingDate: editedRow?.driverTrainingDate || "",
      driverLicenseDate: editedRow?.driverLicenseDate || "",
    });
  };


  const handleSaveClick = (id) => () => {
    setShowHideDriverForm(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    const response = await dispatch(deleteDriverInfoById(id));

    if (response) {
      setRows(rows.filter((row) => row.id !== id));
    }
    // setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };



  function handleClick() {
    setShowHideDriverForm(true);

    if (rows.length === 0 && generalPartyInfo) {
      const preFilledData = {
        firstName: generalPartyInfo.firstName || '',
        lastName: generalPartyInfo.lastName || '',
        // dob: generalPartyInfo.dob || '',
        dob: generalPartyInfo.dob ? dayjs(generalPartyInfo.dob).format("MM-DD-YYYY") : '',
        gender: generalPartyInfo.gender || '',
        // marital: generalPartyInfo.maritalStatus || '',
        // Add any other fields you want to pre-fill
      };
      setDriverData(preFilledData);
      // console.log("driver data 23879327",driverData)
    } else {
      setDriverData({});
    }

    // setDriverData({});
  }


  return (
    <>
      {showHideDriverForm ? (
        <>
          <Box sx={{ marginLeft: "0px" }}>
            <DriverSave
              setShowHideDriverForm={setShowHideDriverForm}
              driverData={driverData}
              setshowbutton={showButton}
              id={id}
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0px 0px 34px",
              boxSizing: "border-box",
              lineHeight: "normal",
              letterSpacing: "normal",
            }}
          >
            <DriverManagement
              handleBack={handleBack}
              // handleClick={() => setShowHideDriverForm(true)}
              handleClick={handleClick}
            />
            <Box
              sx={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0px 23px 0px 20px",
                boxSizing: "border-box",
                maxWidth: "100%",
              }}
            >
              <Box
                sx={{
                  width: "853px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  gap: "24px",
                  maxWidth: "100%",
                }}
              >
                <Paper
                  sx={{
                    alignSelf: "stretch",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    border: "1px solid #e3e3e3",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "28px 46px 22px 47px",
                    gap: "24px",
                    maxWidth: "100%",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#242929",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 6px",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    <Typography
                    id="drivers"
                      variant="h3"
                      sx={{
                        margin: "0",
                        position: "relative",
                        fontSize: "inherit",
                        lineHeight: "20px",
                        fontWeight: "600",
                        fontFamily: "inherit",
                        display: "inline-block",
                        minWidth: "115px",
                        zIndex: "1",
                        fontFamily: '"Poppins", sans-serif',
                      }}
                    >
                      Driver
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                      gap: "20px",
                      fontSize: "16px",
                      fontFamily: '"Poppins", sans-serif',
                      color: "#888",
                      width: "100%",
                    }}
                  >
                    <table className={classes.table}>
                      <thead className={classes.thead}>
                        <tr>
                          <th className={classes.th}>#</th>
                          <th className={classes.th}>First Name</th>
                          <th className={classes.th}>Last Name</th>
                          <th className={classes.th}>Marital Status</th>
                          <th className={classes.th}>Date of Birth</th>
                          <th className={classes.th}>Gender</th>
                          <th className={classes.th}>Actions</th>
                        </tr>
                      </thead>
                      <tbody className={classes.tbody}>
                        { isLoading ? (
                          <tr> 
                             <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                              <Loader/> </td>
                          </tr>
                        ) :
                        rows.length === 0 ? (
                          <tr>
                            <td
                              className={classes.td}
                              colSpan="7"
                              style={{
                                textAlign: "center",
                                padding: "20px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "#888",
                              }}
                            >
                              No driver found
                            </td>
                          </tr>
                        ) : (
                          paginatedRows.map((row, index) => (
                            <tr key={row.id}>
                              <td className={classes.td}> {page * rowsPerPage + index + 1}</td>
                              <td className={classes.td}>{row.firstName}</td>
                              <td className={classes.td}>{row.lastName}</td>
                              <td className={classes.td}>{row.marital}</td>
                              <td className={classes.td}>
                                {row.dob}
                              </td>
                              <td className={classes.td}>{row.gender}</td>
                              <td className={classes.td}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "11.5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                  id="editicon"
                                  onClick={handleEditClick(row.id)}>
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                  id="deleteicon"
                                 onClick={() => handleOpenDialog(row.id)}>
                        <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </Box>
                  <TablePagination
                  component="div"
                  count={rows.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                />

<Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this driver? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            variant="outlined" 
            
             className="btn-secondary" color="secondary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            variant="contained" 
            className="btn-primary" 
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
                </Paper>
                <Box
                  sx={{
                    width: "222px",
                    height: "42px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontFamily: '"Poppins", sans-serif',
                    gap: "14px",
                  }}
                >
                  <Button
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      cursor: "pointer",
                      zIndex: "1",
                    }}
                    startIcon={<ArrowBackIosIcon />}
                    disableElevation={true}
                    variant="outlined"
                    id="backbutton"
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      textTransform: "none",
                      color: "#077BFD",
                      fontSize: "16px",
                      borderColor: "#077BFD",
                      borderRadius: "4px",
                      "&:hover": { borderColor: "#077BFD" },
                    }}
                    onClick={handleBack}
                    className="btn-secondary"
                  >
                    Back
                  </Button>

                  <Button
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      cursor: "pointer",
                      zIndex: "1",
                      marginRight: "20px",
                    }}
                    endIcon={<ArrowForwardIosIcon />}
                    disableElevation={true}
                    variant="contained"
                    id="nextbutton"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      fontSize: "16px",
                      background: "rgba(7, 123, 253, 0.6)",
                      borderRadius: "4px",
                      fontFamily: '"Poppins", sans-serif',
                      "&:hover": { background: "rgba(7, 123, 253, 1)" },
                    }}
                    className="btn-secondary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Box>
                <Box
                  sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    padding: "0px 20px 0px 23px",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  <Button
                    style={{
                      height: "42px",
                      width: "140px",
                      cursor: "pointer",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                    disableElevation={true}
                    variant="contained"
                    id="continuebutton"
                    className="btn-primary"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      fontSize: "16px",
                      background: "#077BFD",
                      borderRadius: "4px",
                      fontFamily: '"Poppins", sans-serif',
                      "&:hover": { background: "#077BFD" },
                      width: 140,
                      height: 42,
                    }}
                    onClick={handleNext}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default DriverInformationList;
