import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControl,
  Select,
  MenuItem,
  TableRow,
  Paper,
  TablePagination,
  IconButton
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveVehicleAction, VehicleUpdateAction, getVehicleAction, getVehicleInfoByVIN, getVehicleInfoByIdAction } from '../../../redux/actions/vehicleInfoActions';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailPolicyAction } from '../../../redux/actions/policyActions';
import { useMemo } from 'react';
import { coll, comp, biLimitPerPerson, liabbi_limit2, liabbi_limit1, liabbi, liabpd, umbi_limit1, umbi_limit2, biLimitPerAccident, pdLimit } from '../../../common/StaticFieldList';

 
const PolicyVehicle = ({ policyId, driverId, setVehicleId, setPremiumId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const customerData = useSelector((state) => state.policy.allDetailPolicy);

  const vehicleData = useMemo(() => {
    if (customerData?.drivers) {
      return customerData.drivers.flatMap((driver, index) =>
        driver.vehicles.map((vehicle) => ({
          ...vehicle,
          driverLabel: `Driver ${index + 1}`, // Add driver count
        }))
      );
    }
    return [];
  }, [customerData]);
  

  const get_driver_id = customerData?.drivers?.[0] || [];
  
  const [open, setOpen] = useState(false);
  const [vehicles, setVehicles] = useState(vehicleData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errors, setErrors] = useState({});
  const [getResponse, setGetResponse] = useState();
  const initialVehicleState = {
    vin: "",
    year:"",
    make:"",
    model:"",
    bodyType: "",
    engineType: "",
    territory: "",
    driverId: driverId || get_driver_id.id // Set driverId directly from propsgit 
  };
 
  const [newVehicle, setNewVehicle] = useState(initialVehicleState);
  const [coverages, setCoverages] = useState([
    { type: "", limit1: null, limit2: null, deductible: "", premium: null },
  ]);
  
  const limitOptions = {
    bi: { limit1: liabbi_limit1, limit2: liabbi_limit2 },
    pd: { limit1: liabpd },
    comp: { deductible: comp },
    coll: { deductible: coll },
    umbi: { limit1: umbi_limit1, limit2: umbi_limit2 }, // Add UMBI limits
  };
  
  useEffect(() => {
    if (get_driver_id?.id) {
      setNewVehicle((prev) => ({
        ...prev,
        driverId: get_driver_id.id, // Update driverId dynamically
      }));
    }
  }, [get_driver_id?.id]);
  
 
  useEffect(() => {
    const fetchData = async () => {
      if (policyId) {
        await dispatch(getDetailPolicyAction(policyId, page, rowsPerPage));
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, policyId, driverId, page, rowsPerPage]);
 
useEffect(() => {
  if (vehicleData.length > 0) {
    setVehicles(vehicleData);
  }
}, [vehicleData]);

  useEffect(() => {
    if (Array.isArray(customerData?.drivers)) {
      const allVehicles = customerData.drivers.flatMap((driver) => driver.vehicles || []);
      setVehicles(allVehicles);
    }
  }, [customerData]);
 
  const handleVinPopulate = async (value) => { 
    try {
      const response = await dispatch(getVehicleInfoByVIN(value, ""));
      if (response?.data) {
        // If data is valid, populate the fields
        setNewVehicle(prevState => ({
          ...prevState,
          year: response.data.year || "",
          make: response.data.make || "",
          model: response.data.model || "",
          bodyType: response.data.bodyType || "",
          engineType: response.data.engineType || "",
          territory: response.data.territory || "",
        }));
        setErrors(prev => ({ ...prev, vin:"" }));
      } else {
        // If no valid data is returned, clear the fields
        setNewVehicle(prevState => ({
          ...prevState,
          year:"",
          make:"",
          model:"",
          bodyType: "",
          engineType: "",
          territory: "",
        }));
        setErrors(prev => ({ ...prev, vin: "Invalid VIN" }));
      }
    } catch (error) {
      // In case of error, also clear the fields
      setNewVehicle(prevState => ({
        ...prevState,
        year:"",
        make:"",
        model:"",
        bodyType: "",
        engineType: "",
        territory: "",
      }));
      setErrors(prev => ({ ...prev, vin: "Invalid VIN" }));
    }
  };

  
  const callbackfn = (response) => {    
    if(response){
      setNewVehicle({
        vin: response.vin || "",
        model: response.model || "",
        make: response.make || "",
        year: response.year || "",
        bodyType: response.bodyType || "",
        engineType: response.engineType || "",
        territory: response.territory || "",
        driverId: response.driverId || driverId,
      });
    }
    setVehicleId(response.id);
    setGetResponse(response)
    if(response){
      setPremiumId(policyId)
    }
  }
  
  const handleSaveVehicle = async () => {
    const payload = {
      ...newVehicle,
      coverages: coverages.map((coverage) => ({
        type: coverage.type,
        limit1: coverage.limit1,
        limit2: coverage.limit2,
        deductible: coverage.deductible,
        premium: parseFloat(coverage.premium) || 0,
      })),
    };
  
    try {
      if (editMode) {
        await dispatch(VehicleUpdateAction(payload));
      } else {
        await dispatch(saveVehicleAction(payload));
      }
      dispatch(getDetailPolicyAction(policyId));
      setOpen(false);
    } catch (error) {
      console.error("Error saving vehicle:", error);
    }
  };
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    const validationRules = {
      model: { pattern: /^[A-Za-z]*$/, maxLength: 30 },
      make: { pattern: /^[A-Za-z]*$/, maxLength: 30 },
      bodyType: { pattern: /^[A-Za-z]*$/, maxLength: 30 },
      engineType: { pattern: /^[A-Za-z]*$/, maxLength: 30 },
      territory: { pattern: /^[A-Za-z]*$/, maxLength: 30 },
      vin: { pattern: /^[A-Za-z0-9]*$/, maxLength: 30 },
      year: { pattern: /^[0-9]*$/, maxLength: 4 },
    };
  
    const rule = validationRules[name];
    if (rule && (!rule.pattern.test(value) || value.length > rule.maxLength)) {
      return;
    }
  
    setNewVehicle((prev) => ({
      ...prev,
      [name]: value,
      driverId: get_driver_id?.id || driverId, // Ensure driverId is always present
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  
    if (name === "vin") {
      if (value.length >= 17) {
        handleVinPopulate(value); // Populate data if VIN is valid
      } else if (value.length < 17) {
        // Clear the fields when VIN length is less than 17
        setNewVehicle((prevState) => ({
          ...prevState,
          year: "",
          make: "",
          model: "",
          bodyType: "",
          engineType: "",
          territory: "",
        }));
      }
    }
  };
  
  
  const validate = () => {
    const newErrors = {};
    const requiredFields = ['vin','year', 'make','model'];
 
    requiredFields.forEach(field => {
      const value = newVehicle[field];
      if (!value || (typeof value === 'string' && !value.trim())) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });
 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleAddCoverage = () => {
    setCoverages((prev) => [
      ...prev,
      { type: "", limit1: null, limit2: null, deductible: "", premium: null },
    ]);
  };
  
  const handleDeleteCoverage = (index) => {
    setCoverages((prev) => prev.filter((_, i) => i !== index));
  };
  
  const handleCoverageChange = (index, field, value) => {
    setCoverages((prev) =>
      prev.map((coverage, i) =>
        i === index ? { ...coverage, [field]: value } : coverage
      )
    );
  };
  const handleCancel = () => {
    setNewVehicle(initialVehicleState);
    setErrors({});
    setOpen(false);
  };
 
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditVehicle = async (vehicleId) => {
    setEditMode(true);
    setSelectedVehicleId(vehicleId);
    setOpen(true);
  
    try {
      const response = await dispatch(getVehicleInfoByIdAction(vehicleId, callbackfn));
     
      if (response?.data) {
        // Directly use the response data to update the vehicle state
        setNewVehicle({
          vin: response.data.vin || "",
          model: response.data.model || "",
          make: response.data.make || "",
          year: response.data.year || "",
          bodyType: response.data.bodyType || "",
          engineType: response.data.engineType || "",
          territory: response.data.territory || "",
          driverId: response.data.driverId || driverId,
        });
      } else {
        console.error("Error: No data found for the vehicle ID.");
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };
  
 
  const paginatedRows = vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 
  return (
    <div>
      {paginatedRows.map((vehicle, index) => (
  <Accordion key={index} id={`accordionvehicle${index}`}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`accordionheader${index}`}>
      <Typography>
        <strong>
          Vehicle {index + 1}: {vehicle.year} - {vehicle.make} - {vehicle.model}
        </strong>
      </Typography>
      <IconButton onClick={() => handleEditVehicle(vehicle.id)} style={{ marginLeft: "auto" }}>
        <EditIcon />
      </IconButton>
    </AccordionSummary>
    <AccordionDetails id={`accordiondetails${index}`}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>VIN</TableCell>
              <TableCell>{vehicle.vin}</TableCell>
              <TableCell>
                <strong>Year:</strong>
              </TableCell>
              <TableCell>{vehicle.year}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Make:</strong>
              </TableCell>
              <TableCell>{vehicle.make}</TableCell>
              <TableCell>
                <strong>Model:</strong>
              </TableCell>
              <TableCell>{vehicle.model}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Body Type:</strong>
              </TableCell>
              <TableCell>{vehicle.bodyType}</TableCell>
              <TableCell>
                <strong>Engine Type:</strong>
              </TableCell>
              <TableCell>{vehicle.engineType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Territory:</strong>
              </TableCell>
              <TableCell>{vehicle.territory}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDetails>
  </Accordion>
))}

      {vehicles.length > 4 && <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={vehicles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
      
      <Button variant="contained" id="Addvehiclebutton" className='btn-primary' onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
        Add Vehicle
      </Button>
      <Dialog open={open} maxWidth="md" fullWidth  onClose={() => setOpen(false)}>
        <DialogTitle id="addvehicletitle" sx={{backgroundColor: '#ffae00'}}>Add Vehicle</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {Object.keys(newVehicle).filter((key) => key !== 'driverId').map((key) => (
              <Grid item xs={12} sm={3} key={key}>
                <Typography>
                  <label className='fnt-we'>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                </Typography>
                <TextField
                  fullWidth
                  name={key}
                  value={newVehicle[key]}
                  onChange={handleChange}
                  error={!!errors[key]}
                  helperText={errors[key]}
                />
              </Grid>
            ))}
          </Grid>
          {/* Dynamic Coverage Fields */}
          <Box sx={{width: '100%', margin: '0', padding:'0',}}>
            <h3>Add Coverage</h3></Box>
            <Grid container spacing={2} sx={{ mt: 2 }}>
  {coverages.map((coverage, index) => (
    <Box key={index} sx={{width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center', padding:'0 18px'}}>
      {/* Type Dropdown */}
      <Grid item xs={12} sm={3} sx={{margin:'10px 5px 10px 5px'}}>
        <FormControl fullWidth>
          <Typography>
            <label className="fnt-we">Select Type</label>
          </Typography>
          <Select
            value={coverage.type}
            onChange={(e) => handleCoverageChange(index, "type", e.target.value)}
            displayEmpty
          >
            <MenuItem value="">Select Type</MenuItem>
            <MenuItem value="bi">BI</MenuItem>
            <MenuItem value="pd">PD</MenuItem>
            <MenuItem value="comp">Comp</MenuItem>
            <MenuItem value="coll">Coll</MenuItem>
            <MenuItem value="umbi">UMBI</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Limit 1 Dropdown */}
      {["bi", "pd", "umbi"].includes(coverage.type) && (
        <Grid item xs={12} sm={3} sx={{margin:'0 5px'}}>
          <FormControl fullWidth>
            <Typography>
              <label className="fnt-we">Limit 1</label>
            </Typography>
            <Select
              value={coverage.limit1}
              onChange={(e) => handleCoverageChange(index, "limit1", e.target.value)}
            >
              {limitOptions[coverage.type]?.limit1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Limit 2 Dropdown */}
      {["bi", "umbi"].includes(coverage.type) && (
        <Grid item xs={12} sm={3} sx={{margin:'0 5px'}}>
          <FormControl fullWidth>
            <Typography>
              <label className="fnt-we">Limit 2</label>
            </Typography>
            <Select
              value={coverage.limit2}
              onChange={(e) => handleCoverageChange(index, "limit2", e.target.value)}
            >
              {limitOptions[coverage.type]?.limit2.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Deductible Dropdown */}
      {["comp", "coll"].includes(coverage.type) && (
        <Grid item xs={12} sm={3} sx={{margin:'0 5px'}}>
          <FormControl fullWidth>
            <Typography>
              <label className="fnt-we">Deductible</label>
            </Typography>
            <Select
              value={coverage.deductible}
              onChange={(e) => handleCoverageChange(index, "deductible", e.target.value)}
            >
              {limitOptions[coverage.type]?.deductible.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Premium Field */}
      <Grid item xs={12} sm={3} sx={{margin:'0 5px'}}>
        <Typography>
          <label className="fnt-we">Premium</label>
        </Typography>
        <TextField
          fullWidth
          type="number"
          value={coverage.premium}
          onChange={(e) => handleCoverageChange(index, "premium", e.target.value)}
        />
       
      </Grid>
      <DeleteOutlineIcon onClick={() => handleDeleteCoverage(index)} />
    </Box>
  ))}

  {/* Add More Button */}
  <Grid item xs={12}>
    <Button onClick={handleAddCoverage} className="btn-secondary" variant="outlined">
      Add More
    </Button>
  </Grid>
</Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} id="cancelbutton" className='btn-secondary' color="secondary">Cancel</Button>
          <Button onClick={handleSaveVehicle} id="addbutton" className='btn-secondary' color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
 
export default PolicyVehicle;