import React, { useState } from 'react';
import {
    TextField, Button, Typography, Grid, Container, Box, CssBaseline,
    Alert, Snackbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { resetPassword } from '../services/loginServices';
import { useLocation, useNavigate } from 'react-router';

const useStyles = makeStyles({
    logo: {
        maxWidth: "120px",
        color: "#077BFD",
    },
    loginBox: {
        padding: "1.5em",
        boxShadow: "0em .35em .5em #ccc",
        border: "1px solid #ddd",
    },
});

const ResetPasswordForm = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        if (severity === 'success') {
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword !== formData.confirmPassword) {
            setPasswordMismatch(true);
            return;
        }
        setPasswordMismatch(false);

        // Password policy validation
        const passwordPolicyRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/;
        if (!passwordPolicyRegex.test(formData.newPassword)) {
            handleSnackbarOpen('Password must be at least 8 characters long and contain at least one uppercase letter, one numeric character, and one special character.');
            return;
        }

        const data = {
            token,
            password: formData.newPassword,
        };

        try {
            await resetPassword(data);
            handleSnackbarOpen('Password reset completed successfully', 'success');
        } catch (error) {
            if (error.response?.status === 403) {
                const errorMessage = error.response.data?.message || 'Forbidden: Unable to reset password.';
                handleSnackbarOpen(errorMessage, 'error');
            } else {
                handleSnackbarOpen('New password cannot be the same as the old password. Please choose a different password.', 'error');
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <h1 className={classes.logo}>BizAiTek</h1>
                </Box>
                <Box p={3} sx={{ mt: 1 }} className={classes.loginBox}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Reset Password
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <Typography
      variant="body1"
      sx={{
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '19.6px',
        textAlign: 'left',
        marginBottom: '8px',
      }}
    >
      New Password
    </Typography>
                                <TextField
                                    name="newPassword"
                                    type="password"
                                    fullWidth
                                    value={formData.newPassword}
                                    onChange={handleInputChange}
                                    error={!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/.test(formData.newPassword)}
                                    helperText="Password must be at least 8 characters long and contain at least one uppercase letter, one numeric character, and one special character."
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <Typography
      variant="body1"
      sx={{
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '19.6px',
        textAlign: 'left',
        marginBottom: '8px',
      }}
    >
      Confirm Password
    </Typography>
                                <TextField
                                    name="confirmPassword"
                                    type="password"
                                    fullWidth
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    error={passwordMismatch}
                                    helperText={passwordMismatch ? 'Passwords do not match' : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPasswordForm;
